import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Linking } from 'react-native'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { Link } from '../atoms'

const highAlertEmergencyInfo = (
  <FormattedMessage
    defaultMessage='If you feel you’re at risk of harming yourself or someone else, please call <website>Resources for Living</website> at <phone>1-833-721-2323</phone> where counselors are available 24/7 to provide confidential support available to all Amazon employees and their household members.'
    description='Information for high alert contact resources'
    values={{
      website: (chunks: string) => (
        <Link
          text={chunks}
          onPress={() =>
            Linking.openURL('https://atoz.amazon.work/amazonbenefits/mentalhealth/provider/resource-for-living')
          }
        >
          {chunks}
        </Link>
      ),
      phone: (chunks: string) => (
        <Link text={chunks} underline onPress={() => Linking.openURL(`tel:18337212323`)}>
          {chunks}
        </Link>
      ),
    }}
  />
)

export const amazonHighAlertEmergencyInfo = highAlertEmergencyInfo

export const amazon: GetCustomerCopyFunc = () => {
  return {
    highAlertEmergencyInfo,
  }
}
