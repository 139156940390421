import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { amazonHighAlertEmergencyInfo } from './amazon'
import { DefaultCustomerRedirectionHeaderText } from './default'
import { BodyText } from '../atoms'

const subpopulations: CustomerSubpopulations = {
  header: <DefaultCustomerRedirectionHeaderText customerName='Amazon' boldCustomerName={true} />,
  options: [
    {
      label: (
        <FormattedMessage
          defaultMessage='{companyName} (Mental Health Support for Kids and Caregivers EAP)'
          description='Option for user to select the employee subpopulation or health plan the they belong to'
          values={{
            companyName: (
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
              <BodyText bold={true} text='Amazon' />
            ),
          }}
        />
      ),
      description: (
        <FormattedMessage
          defaultMessage='Seeking mental health care for a child (under the age of 18) or coaching for a caregiver'
          description='Description for the employee subpopulation or health plan option (Mental Health Support for Kids and Caregivers EAP)'
        />
      ),
    },
    {
      label: (
        <FormattedMessage
          defaultMessage='{companyName} (Mental Health Support for Health Plan Members)'
          description='Option for user to select the employee subpopulation or health plan the they belong to'
          values={{
            companyName: (
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
              <BodyText bold={true} text='Amazon' />
            ),
          }}
        />
      ),
      description: (
        <FormattedMessage
          defaultMessage='Enrolled in relevant {companyName} health plan (Premera, Cigna, or Aetna) seeking care for myself'
          description='Description for the employee subpopulation or health plan option (Mental Health Support for Health Plan Members)'
          values={{
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            companyName: <BodyText bold={true} text='Amazon' />,
          }}
        />
      ),
    },
  ],
}

export const amazonkids: GetCustomerCopyFunc = () => {
  return {
    subpopulations,
    highAlertEmergencyInfo: amazonHighAlertEmergencyInfo,
  }
}
