import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

const ToolBarContainer = styled.View<{ topInset: number; backgroundColor: string }>`
  z-index: 1;
  padding-top: ${({ topInset }) => topInset}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

/**
 * Component shown on the home page that consists of texts and icons that assist in
 * navigation within the app. Not to be confused with Header component which is used
 * for mainly navigation in mobile mvp V1
 */
export const Toolbar: FunctionComponent<ToolbarProps> = ({ topInset = 0, backgroundColor, children }) => {
  const { colors } = useTheme()
  const { toolbar } = colors.components
  return (
    <ToolBarContainer topInset={topInset} backgroundColor={backgroundColor || toolbar.background}>
      {children}
    </ToolBarContainer>
  )
}

type ToolbarProps = {
  topInset?: number
  backgroundColor?: string
}
