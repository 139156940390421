import { useEffect, useMemo, useState } from 'react'

import { useSearchAgeAndType } from './useSearchAgeAndType'
import { useShouldShowForkedTriageEntrypoint } from './useShouldShowForkedTriageEntrypoint'
import { CustomerPropertiesMap, GetTriageMetadataActions, TriageScreener } from '../../../models'
import { COMMON_MIXPANEL_PAGE } from '../../common/constants/mixpanelConstants'
import {
  useIsWellnessToolkitAvailable,
  useIsWellnessToolkitAvailableParams,
} from '../../wellnessToolkit/hooks/useIsWellnessToolkitAvailable'
import { WELLNESS_TOOLKIT_TYPE } from '../../wellnessToolkit/types'
import { getWellnessToolkitAvailableConfig } from '../../wellnessToolkit/wellnessToolkitUtil'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../constants'
import { getAge } from '../utils'

export interface useHomebaseArgs extends Omit<useIsWellnessToolkitAvailableParams, 'toolkitType' | 'page'> {
  getTriageMetadata: (arg: GetTriageMetadataActions) => Promise<any>
  customerPropertiesMap?: CustomerPropertiesMap
  triageScreenersMetadataVersion?: string
  shouldFetchTriageMetadata?: boolean
}

/** For shared homebase code between LW and native. Currently gets forked triage entrypoint availability, primary
 *  needs triage metadata and toolkit availability for family hub, parent coaching and individual coaching */
export const useHomebase = ({
  lyraId,
  isUserTeen,
  triageSearches,
  isCoachingEnabled,
  riskFactors,
  customerPropertiesMap,
  episodes,
  isUserLoggedIn,
  isInternationalUser,
  postSpeculativeSearch,
  userDOB,
  userCountryName,
  isEssentialsEnabled,
  hideDependentField,
  hideChildSearchCTA,
  hasIndicatedIsGuardian,
  userChildren,
  isFamilyHubEnabled,
  isCoachingEntryPointEnabled,
  isParentCoachingEntryPointEnabled,
  displayLanguage,
  useFetchChildrenHook,
  isSpeculativeFilterEnabled,
  speculativeSearchThresholds,
  route,
  logToSumo,
  getTriageMetadata,
  triageScreenersMetadataVersion,
  shouldFetchTriageMetadata = false,
}: useHomebaseArgs) => {
  const [isLoadingTriageMetadata, setIsLoadingTriageMetadata] = useState(shouldFetchTriageMetadata)
  const [primaryNeedsMetadata, setPrimaryNeedsMetadata] = useState<TriageScreener>()
  const getMetadataAge = useSearchAgeAndType()

  const sharedWellnessToolkitParams = useMemo(() => {
    return {
      page: COMMON_MIXPANEL_PAGE.HOME_BASE,
      lyraId,
      isUserTeen,
      triageSearches,
      isCoachingEnabled,
      riskFactors,
      episodes,
      isUserLoggedIn,
      isInternationalUser,
      postSpeculativeSearch,
      userDOB,
      userCountryName,
      isEssentialsEnabled,
      hideDependentField,
      hideChildSearchCTA,
      hasIndicatedIsGuardian,
      userChildren,
      isFamilyHubEnabled,
      isCoachingEntryPointEnabled,
      isParentCoachingEntryPointEnabled,
      displayLanguage,
      useFetchChildrenHook,
      isSpeculativeFilterEnabled,
      speculativeSearchThresholds,
      route,
      logToSumo,
    }
  }, [
    displayLanguage,
    episodes,
    hasIndicatedIsGuardian,
    hideChildSearchCTA,
    hideDependentField,
    isCoachingEnabled,
    isCoachingEntryPointEnabled,
    isEssentialsEnabled,
    isFamilyHubEnabled,
    isInternationalUser,
    isParentCoachingEntryPointEnabled,
    isSpeculativeFilterEnabled,
    isUserLoggedIn,
    isUserTeen,
    logToSumo,
    lyraId,
    postSpeculativeSearch,
    riskFactors,
    route,
    speculativeSearchThresholds,
    triageSearches,
    useFetchChildrenHook,
    userChildren,
    userCountryName,
    userDOB,
  ])

  const individualCoachingToolkitParamsMemoized = useMemo(() => {
    return {
      toolkitType: WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING,
      ...sharedWellnessToolkitParams,
    }
  }, [sharedWellnessToolkitParams])

  const parentCoachingToolkitParamsMemoized = useMemo(() => {
    return {
      toolkitType: WELLNESS_TOOLKIT_TYPE.PARENT_COACHING,
      ...sharedWellnessToolkitParams,
    }
  }, [sharedWellnessToolkitParams])

  const familyHubParamsMemoized = useMemo(() => {
    return {
      toolkitType: WELLNESS_TOOLKIT_TYPE.FAMILY_HUB,
      ...sharedWellnessToolkitParams,
    }
  }, [sharedWellnessToolkitParams])

  const {
    isToolkitAvailable: isIndividualCoachingAvailable,
    reasonsForAvailabilityStatus: reasonsForIndividualCoachingAvailabilityStatus,
  } = useIsWellnessToolkitAvailable(individualCoachingToolkitParamsMemoized)

  // Overriding/ignoring known parent validation since its not required for showing forked triage entry point
  // but is required for parent coaching toolkit entrypoint. We will validate this separately below.
  const validationOverridesMemoized = useMemo(() => {
    return { shouldBeKnownParent: false }
  }, [])
  const {
    isToolkitAvailable: isParentCoachingToolkitAvailable,
    reasonsForAvailabilityStatus: reasonsForParentCoachingAvailabilityStatus,
  } = useIsWellnessToolkitAvailable({
    ...parentCoachingToolkitParamsMemoized,
    validationOverrides: validationOverridesMemoized,
  })

  const isFamilyHubAvailable = useIsWellnessToolkitAvailable(familyHubParamsMemoized).isToolkitAvailable

  const shouldShowForkedTriage = useShouldShowForkedTriageEntrypoint({
    isUserTeen,
    customerPropertiesMap,
    coachingToolkitAvailability: {
      individual: {
        available: isIndividualCoachingAvailable,
        reasons: reasonsForIndividualCoachingAvailabilityStatus,
      },
      parent: {
        available: isParentCoachingToolkitAvailable,
        reasons: reasonsForParentCoachingAvailabilityStatus,
      },
    },
  })

  const parentCachingToolkitConditions = useMemo(() => {
    return getWellnessToolkitAvailableConfig(WELLNESS_TOOLKIT_TYPE.PARENT_COACHING, COMMON_MIXPANEL_PAGE.HOME_BASE)
  }, [])
  const { configExtras: parentCoachingConfigExtras } = parentCachingToolkitConditions
  const isKnownParent =
    hasIndicatedIsGuardian ||
    userChildren?.length ||
    (parentCoachingConfigExtras?.triageConditionsChecker &&
      parentCoachingConfigExtras?.triageConditionsChecker(triageSearches))

  useEffect(() => {
    async function fetchData() {
      const userAge = getAge({ date: userDOB ?? '' })
      const { age } = getMetadataAge({ dob: userDOB, matchFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF, age: userAge })
      try {
        const results = await getTriageMetadata({ age, version: triageScreenersMetadataVersion, hideError: [404] })
        setPrimaryNeedsMetadata(
          ('payload' in results ? results.payload : results).find(
            (screener: TriageScreener) => screener.name === 'primaryNeeds',
          ),
        )
      } catch (e) {
        console.error(e)
        const error = e as Error | string
        logToSumo && logToSumo({ message: error instanceof Error ? error.message : error, type: 'ERROR' })
      } finally {
        setIsLoadingTriageMetadata(false)
      }
    }
    if (shouldFetchTriageMetadata && !primaryNeedsMetadata) {
      fetchData()
    }
  }, [
    userDOB,
    shouldFetchTriageMetadata,
    getMetadataAge,
    getTriageMetadata,
    triageScreenersMetadataVersion,
    primaryNeedsMetadata,
    logToSumo,
  ])

  return {
    isFamilyHubAvailable,
    // isKnownParent parent check occurs here since we override the validation above
    isParentToolkitAvailable: isParentCoachingToolkitAvailable && Boolean(isKnownParent),
    isIndividualCoachingToolkitsAvailable: isIndividualCoachingAvailable,
    shouldShowForkedTriage,
    isLoadingTriageMetadata,
    primaryNeedsMetadata,
  }
}
