import { HEADER_NAV_ITEM_LABELS } from './messages'
import { HEADER_NAV_ITEM } from './types'

export const headerNavItemData = {
  [HEADER_NAV_ITEM.HOME]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.HOME],
    activePathnames: ['/search-care'],
    activeScreenNames: ['Homebase'],
  },
  [HEADER_NAV_ITEM.CARE]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.CARE],
    activePathnames: ['/assignments', '/care/initial'],
    activeScreenNames: ['Activities', 'CareInitial'],
  },
  [HEADER_NAV_ITEM.SESSIONS]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.SESSIONS],
    activePathnames: ['/sessions'],
    activeScreenNames: ['Sessions'],
  },
  [HEADER_NAV_ITEM.PROGRESS]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.PROGRESS],
    activePathnames: ['/progress'],
    activeScreenNames: ['Progress'],
  },
  [HEADER_NAV_ITEM.ESSENTIALS]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.ESSENTIALS],
    activePathnames: ['/essentials', '/work-hub'],
    activeScreenNames: ['Essentials', 'Workhub'],
  },
  [HEADER_NAV_ITEM.BENEFITS]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.BENEFITS],
    activePathnames: ['/search-care/benefits'],
    activeScreenNames: ['Benefits'],
  },
  [HEADER_NAV_ITEM.ACCOUNT]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.ACCOUNT],
    activePathnames: ['/profile'],
    activeScreenNames: ['Profile'],
  },
  [HEADER_NAV_ITEM.FAQS]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.FAQS],
    activePathnames: ['/faq'],
  },
  [HEADER_NAV_ITEM.REFERRAL]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.REFERRAL],
  },
  [HEADER_NAV_ITEM.LOG_OUT]: {
    label: HEADER_NAV_ITEM_LABELS[HEADER_NAV_ITEM.LOG_OUT],
  },
}
