import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { ChevronV2Icon } from '../../atoms/icons'
import { Link } from '../../atoms/link/Link'
import { BodyTextSize } from '../../styles'
import { RowView } from '../../templates'
import { tID } from '../../utils/utils'

export interface LatestMatchesLinkProps {
  onLatestMatches: () => void
  hasLatestMatches?: boolean
}

const LinkContainer = styled(RowView)({
  alignItems: 'center',
})

const ChevronIconContainer = styled(ChevronV2Icon)(({ theme }) => ({
  marginLeft: theme.spacing['4px'],
  marginTop: '2px',
}))

export const LatestMatchesLink: FunctionComponent<LatestMatchesLinkProps> = ({
  onLatestMatches,
  hasLatestMatches = false,
}) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()

  return (
    <>
      {hasLatestMatches && (
        <LinkContainer>
          <View>
            <Link
              size={BodyTextSize.SMALL}
              text={
                <>
                  {formatMessage({
                    defaultMessage: 'Show past matches',
                    description: 'Link to navigate user to their past provider matches',
                  })}
                </>
              }
              testID={tID('LatestMatchesLink')}
              onPress={onLatestMatches}
            />
          </View>
          <View>
            <ChevronIconContainer fillColor={colors.iconActive} size={8} />
          </View>
        </LinkContainer>
      )}
    </>
  )
}
