import React from 'react'
import { Circle, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const VideoIconWithEllipse: React.FC<VideoIconWithEllipseProps> = ({
  backgroundColor,
  fillColor,
  size = 24,
}) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 40 40' fill='none'>
      <Circle cx='20' cy='20' r='20' fill={backgroundColor || colors.backgroundHighlightPrimary} />
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.6667 15H21.375C22.8967 15 24.125 16.2283 24.125 17.75V23.25C24.125 24.7717 22.8967 26 21.375 26H12.6667C11.145 26 9.91669 24.7717 9.91669 23.25V17.75C9.91669 16.2283 11.145 15 12.6667 15ZM31 24.8542V16.1458C31 15.8983 30.8717 15.6783 30.6609 15.55C30.45 15.4308 30.1934 15.4308 29.9825 15.5408L25.8575 17.8325C25.6375 17.9608 25.5 18.19 25.5 18.4375V22.5625C25.5 22.81 25.6375 23.0392 25.8575 23.1675L29.9825 25.4592C30.0834 25.5142 30.1934 25.5417 30.3125 25.5417C30.4317 25.5417 30.5509 25.5142 30.6609 25.45C30.8717 25.3217 31 25.1017 31 24.8542Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type VideoIconWithEllipseProps = {
  backgroundColor?: string
  fillColor?: string
  size?: string | number
}
