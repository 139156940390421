import { useMemo } from 'react'

import { useAddCustomerTreatmentOptions } from './useAddCustomerTreatmentOptions'
import { isAgeTeen, isAgeYoungerThanTeen } from '../../../features/common/utils/userUtils'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../../../features/searchForCare/constants'
import {
  CustomerCopy,
  CustomerTreatmentOption,
  CustomerTreatmentsOptionLocation,
  INTERNATIONAL_CONSENT_STATUS,
  TreatmentRecommendations,
} from '../../../models'
import {
  childPrimaryTreatmentOptionsToDisplay,
  childSecondaryTreatmentOptionsToDisplay,
  tierOnePrimaryTreatmentOptionsToDisplay,
  tierOneSecondaryTreatmentOptionsToDisplay,
  tierThreePrimaryTreatmentOptionsToDisplay,
  tierThreeSecondaryTreatmentOptionsToDisplay,
  tierTwoPrimaryTreatmentOptionsToDisplay,
  tierTwoSecondaryTreatmentOptionsToDisplay,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { TREATMENT_RECOMMENDATION_TIER } from '../../common/constants/treatmentRecommendationsConstants'

export type CustomTreatmentCardConfig = {
  [key in TREATMENT_OPTIONS_TYPE]?: CustomerTreatmentOption['config']
}

export type useGetTreatmentOptionsArgs = {
  treatmentRecs: TreatmentRecommendations | null
  searcherAge: number
  shouldSeeCareAdvocateRequestForm: boolean
  customerCopy?: CustomerCopy
  hasAccessToInternationallySupportedTreatmentOptions: boolean
  gdprInternationalConsent?: INTERNATIONAL_CONSENT_STATUS
  isUserInternational?: boolean
  matchFor?: SEARCHER_TYPE_TO_MATCH_FOR
}

/**
 * Gets treatment options for the treatment recommendations page for s4c
 * - Filters down treatment recommendations by tier, if user is international
 * - Adds custom treatment options per customer
 * @param treatmentRecs Treatment recs returned from triage flow
 * @param customerCopy Used to add in custom treatment options based on customer
 * @param searcherAge Use for child searches
 */
export const useGetTreatmentOptions = ({
  treatmentRecs,
  searcherAge,
  shouldSeeCareAdvocateRequestForm,
  customerCopy,
  isUserInternational,
  gdprInternationalConsent = INTERNATIONAL_CONSENT_STATUS.NONE,
  hasAccessToInternationallySupportedTreatmentOptions,
  matchFor,
}: useGetTreatmentOptionsArgs) => {
  const addCustomerTreatmentOptions = useAddCustomerTreatmentOptions()

  const result = useMemo(() => {
    if (
      isUserInternational &&
      (isAgeYoungerThanTeen(searcherAge) || matchFor === SEARCHER_TYPE_TO_MATCH_FOR.OTHER_CHILD)
    ) {
      return {
        primaryTreatmentOption: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD_ICAS,
        secondaryTreatmentOptions: [],
      }
    }

    if (
      isUserInternational &&
      (isAgeTeen(searcherAge) ||
        !hasAccessToInternationallySupportedTreatmentOptions ||
        gdprInternationalConsent !== INTERNATIONAL_CONSENT_STATUS.ACCEPTED)
    ) {
      return {
        primaryTreatmentOption: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
        secondaryTreatmentOptions: [],
      }
    }

    if (treatmentRecs === null) {
      return {
        primaryTreatmentOption: undefined,
        secondaryTreatmentOptions: [],
        configForSpecificTreatmentOptions: {},
      }
    }

    const givenPrimaryTreatmentOptions = treatmentRecs.primaryTreatmentOptions ?? []
    const givenSecondaryTreatmentOptions = treatmentRecs.secondaryTreatmentOptions ?? []

    const shownTierPage = treatmentRecs.shownTierPage
    let primaryTreatmentRecs: TREATMENT_OPTIONS_TYPE[] = []
    let secondaryTreatmentRecs: TREATMENT_OPTIONS_TYPE[] = []

    if (searcherAge <= 17) {
      primaryTreatmentRecs = givenPrimaryTreatmentOptions.filter((option) =>
        childPrimaryTreatmentOptionsToDisplay.includes(option),
      )
      secondaryTreatmentRecs = givenSecondaryTreatmentOptions.filter((option) =>
        childSecondaryTreatmentOptionsToDisplay.includes(option),
      )
    } else {
      switch (shownTierPage) {
        case TREATMENT_RECOMMENDATION_TIER.TIER_1:
          primaryTreatmentRecs = givenPrimaryTreatmentOptions.filter((option) =>
            tierOnePrimaryTreatmentOptionsToDisplay.includes(option),
          )
          secondaryTreatmentRecs = givenSecondaryTreatmentOptions.filter((option) =>
            tierOneSecondaryTreatmentOptionsToDisplay.includes(option),
          )
          // Customers with assertive triage should not be able to book individual therapy directly.
          if (shouldSeeCareAdvocateRequestForm) {
            secondaryTreatmentRecs = secondaryTreatmentRecs.filter(
              (option) => option !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
            )
          }
          break
        case TREATMENT_RECOMMENDATION_TIER.TIER_2:
          primaryTreatmentRecs = givenPrimaryTreatmentOptions.filter((option) =>
            tierTwoPrimaryTreatmentOptionsToDisplay.includes(option),
          )
          secondaryTreatmentRecs = givenSecondaryTreatmentOptions.filter((option) =>
            tierTwoSecondaryTreatmentOptionsToDisplay.includes(option),
          )
          break
        case TREATMENT_RECOMMENDATION_TIER.TIER_3:
          primaryTreatmentRecs = givenPrimaryTreatmentOptions.filter((option) =>
            tierThreePrimaryTreatmentOptionsToDisplay.includes(option),
          )
          secondaryTreatmentRecs = givenSecondaryTreatmentOptions.filter((option) =>
            tierThreeSecondaryTreatmentOptionsToDisplay.includes(option),
          )
          break
      }
    }

    const { treatmentOptions: secondaryTreatmentOptionsWithCustomOptions, configForSpecificTreatmentOptions } =
      addCustomerTreatmentOptions({
        searcherAge,
        location: shownTierPage as CustomerTreatmentsOptionLocation,
        treatmentOptions: secondaryTreatmentRecs,
        customerCopy,
      })

    return {
      primaryTreatmentOption: primaryTreatmentRecs[0],
      alternateTreatmentOption: primaryTreatmentRecs[1],
      secondaryTreatmentOptions: isUserInternational
        ? secondaryTreatmentRecs
        : secondaryTreatmentOptionsWithCustomOptions,
      configForSpecificTreatmentOptions,
    }
  }, [
    hasAccessToInternationallySupportedTreatmentOptions,
    treatmentRecs,
    addCustomerTreatmentOptions,
    searcherAge,
    customerCopy,
    shouldSeeCareAdvocateRequestForm,
    isUserInternational,
    gdprInternationalConsent,
    matchFor,
  ])

  return result
}
