import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { useSelector } from 'react-redux'

import styled from 'styled-components/native'

import { ALLOWED_CROSS_ORIGIN_URLS, CMS_MICROSITE_MESSAGE } from '@lyrahealth-inc/shared-app-logic'
import { LoadingIndicator, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import { trackEventWithObj } from '../../../data/mixpanel'
import { useAppDispatch } from '../../../data/storeConfiguration/store'
import { getId } from '../../../data/user/userSelectors'
import { LYRA_HEALTH_NAME, MICROSITE_IFRAME_MESSAGES } from '../../constants/appConstants'

type CMSMicrositeIframeProps = {
  testId: string
  iFrameTitle: string
  micrositeUrl: string
  documentTitle: string
  messageHandlers: { [key in CMS_MICROSITE_MESSAGE]?: (event: MessageEvent) => void }
  /** Dynamic text content for the microsite. This should be a map of key -> message. In the cms, anything with `[[CustomMessage:key]]` will be replaced  */
  customMessages?: Dict
  /** Custom mixpanel event data */
  eventProps?: Dict
  useStickyHeaderAndFooter?: boolean
}

const LoadingIndicatorContainer = styled.View(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing['32px'],
}))

const IFrameContainer = styled.View<{ shouldFlex: boolean }>(({ shouldFlex }) => ({
  ...(shouldFlex && { flexGrow: 1 }),
  border: 0,
}))

/** iFrame to any microsite set up through goodness/craft cms */
const CMSMicrositeIframe: FunctionComponent<CMSMicrositeIframeProps> = ({
  testId,
  iFrameTitle,
  micrositeUrl,
  documentTitle,
  messageHandlers,
  customMessages,
  eventProps,
  useStickyHeaderAndFooter,
}) => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useSelector(getId)
  const [isLoading, setIsLoading] = useState(true)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const intervalRef = useRef(0)

  const HEADER_HEIGHT_WEB = 81
  const FOOTER_HEIGHT_WEB = isLoggedIn ? 119 : 137
  const HEADER_AND_FOOTER_HEIGHT_SAFARI_MWEB = 116
  const getApplicationContentHeight = useCallback(
    () =>
      isSafari
        ? window.innerHeight - HEADER_AND_FOOTER_HEIGHT_SAFARI_MWEB
        : window.innerHeight - HEADER_HEIGHT_WEB - FOOTER_HEIGHT_WEB,
    [FOOTER_HEIGHT_WEB],
  )
  const [iFrameHeight, setIFrameHeight] = useState<number>(getApplicationContentHeight())
  const [receivedHeightFromIFrame, setReceivedHeightFromIFrame] = useState(false)

  const iFrameSrcUrl = micrositeUrl.startsWith('/') ? `${window.location.origin}${micrositeUrl}` : micrositeUrl
  document.title = `${documentTitle} | ${LYRA_HEALTH_NAME}`

  useEffect(() => {
    const handlePageShow = (event: { persisted: any }) => {
      if (event.persisted) {
        window.location.reload()
      }
    }
    window.addEventListener('pageshow', handlePageShow)
    return () => {
      window.removeEventListener('pageshow', handlePageShow)
    }
  }, [])

  useEffect(() => () => window.clearInterval(intervalRef.current), [])

  const handleIFrameSizingEvent = useCallback(
    (height: number) => {
      if (useStickyHeaderAndFooter) {
        return
      }
      iFrameHeight !== height && setIFrameHeight(height)
      setReceivedHeightFromIFrame(true)
    },
    [iFrameHeight, useStickyHeaderAndFooter],
  )

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      if (
        ALLOWED_CROSS_ORIGIN_URLS.some((allowedUrlStr) => {
          const allowedUrl = new URL(allowedUrlStr)
          return allowedUrl.origin === message.origin
        }) ||
        message.origin === window.location.origin
      ) {
        const event = message.data.event
        const mixpanelData = message.data.mixPanelData
        switch (event) {
          case MICROSITE_IFRAME_MESSAGES.DOCUMENT_OBJECT:
            handleIFrameSizingEvent(message.data.height)
            break
          case MICROSITE_IFRAME_MESSAGES.IFRAME_READY:
            if (customMessages) {
              iFrameRef?.current?.contentWindow?.postMessage(
                {
                  event: MICROSITE_IFRAME_MESSAGES.SET_CUSTOM_MESSAGES,
                  customMessages,
                },
                message.origin,
              )
            }
            break
          default:
            const handleMessageForEvent = event && messageHandlers[event as CMS_MICROSITE_MESSAGE]
            if (handleMessageForEvent) {
              handleMessageForEvent(message)
            }
            if (mixpanelData && mixpanelData.event) {
              dispatch(trackEventWithObj({ ...mixpanelData, ...eventProps }))
            }
        }
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [dispatch, messageHandlers, handleIFrameSizingEvent, customMessages, eventProps])

  const onIFrameLoad = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <>
      {isLoading && (
        <LoadingIndicatorContainer>
          <LoadingIndicator size={45} />
        </LoadingIndicatorContainer>
      )}
      <IFrameContainer testID={tID(testId)} shouldFlex={!receivedHeightFromIFrame}>
        <iframe
          title={iFrameTitle}
          onLoad={onIFrameLoad}
          ref={iFrameRef}
          src={iFrameSrcUrl}
          width='100%'
          height={iFrameHeight}
          style={{ border: 'none', ...(!receivedHeightFromIFrame && { flexGrow: 1 }) }}
          scrolling={receivedHeightFromIFrame ? 'no' : ''}
        />
      </IFrameContainer>
    </>
  )
}

export default CMSMicrositeIframe
