import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const SelfCareValuePropSpotIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 96 96' fill='none'>
      <Path
        d='M48.3908 0.333496C41.7643 0.333496 35.3352 1.46984 29.2816 3.71027C14.8696 9.0524 4.73743 20.8958 1.48207 36.2032C-0.669203 46.1836 -0.394128 55.4148 2.32816 64.4106C5.80358 75.8993 14.3347 85.5876 25.7341 90.9904C31.3931 93.6786 37.8943 95.2133 45.0538 95.551C45.9739 95.5927 46.9092 95.6155 47.8387 95.6155C53.583 95.6155 59.3615 94.8282 65.0147 93.2764C65.4492 93.1682 65.8627 93.0563 66.2991 92.9273C71.3794 91.44 75.9513 89.0877 79.8631 85.9442C84.1106 82.5504 87.6069 78.2478 90.2438 73.1637C90.7636 72.162 91.2606 71.1073 91.8089 69.8457C94.8252 62.8418 96.2822 55.0012 96.0242 47.1625C95.9028 43.6966 95.3867 40.0125 94.4951 36.2032C93.0268 30.0226 90.6327 24.2099 87.5708 19.3838C86.7323 18.0881 85.9621 16.9992 85.1995 16.0203C79.6961 8.92151 71.1214 3.93982 60.4106 1.6216C56.4761 0.767924 52.4315 0.335393 48.3927 0.335393L48.3908 0.333496Z'
        fill='white'
      />
      <Mask
        id='mask0_284_5164'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='96'
        height='96'
      >
        <Path
          d='M48.3909 0.333496C41.7645 0.333496 35.3353 1.46984 29.2818 3.71027C14.8698 9.0524 4.73755 20.8958 1.48219 36.2032C-0.669081 46.1836 -0.394006 55.4148 2.32828 64.4106C5.8037 75.8993 14.3348 85.5876 25.7343 90.9904C31.3932 93.6786 37.8944 95.2133 45.054 95.551C45.974 95.5927 46.9093 95.6155 47.8389 95.6155C53.5832 95.6155 59.3616 94.8282 65.0149 93.2764C65.4493 93.1682 65.8629 93.0563 66.2992 92.9273C71.3795 91.44 75.9514 89.0877 79.8632 85.9442C84.1107 82.5504 87.607 78.2478 90.2439 73.1637C90.7637 72.162 91.2608 71.1073 91.809 69.8457C94.8253 62.8418 96.2823 55.0012 96.0243 47.1625C95.9029 43.6966 95.3869 40.0125 94.4953 36.2032C93.0269 30.0226 90.6328 24.2099 87.571 19.3838C86.7325 18.0881 85.9623 16.9992 85.1996 16.0203C79.6963 8.92151 71.1215 3.93982 60.4107 1.6216C56.4762 0.767924 52.4316 0.335393 48.3928 0.335393L48.3909 0.333496Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_284_5164)'>
        <Path
          d='M48.6124 52.6169C48.918 49.5239 49.6856 47.1759 50.0703 46.2631C49.8345 46.8213 47.4035 45.9481 46.8189 45.7897C44.1068 51.689 44.0578 69.8434 45.1007 76.252C45.8514 76.4179 48.1013 76.5575 48.8595 76.682C47.9184 72.1405 47.1791 67.1502 48.6124 52.6169Z'
          fill='#127719'
        />
        <Path
          d='M54.9445 58.1405C49.1583 61.9559 47.0253 68.3889 46.7179 70.5371C46.6915 70.7238 46.7179 70.9821 46.9065 71.0067C46.9838 71.018 47.0517 70.9595 47.1271 70.9388C51.5799 69.8072 58.6655 66.8726 65.9171 54.6609C65.9812 54.5534 66.0963 54.4289 66.0076 54.3403C65.7606 54.3799 61.4587 53.8443 54.9426 58.1386L54.9445 58.1405Z'
          fill='#127719'
        />
        <Path
          d='M35.0536 57.092C38.1579 61.5033 43.8121 65.4412 44.9851 64.4529C46.3826 63.2761 43.3858 57.8935 42.8804 57.2089C36.6529 48.7616 31.9266 47.9035 31.3439 48.1883C30.084 48.8069 34.0898 55.7228 35.0536 57.092Z'
          fill='#127719'
        />
        <Path
          d='M46.234 44.2203C45.5984 44.2146 45.2966 44.1317 45.2457 43.6187C45.7361 43.4716 45.8926 43.7865 46.234 44.2203Z'
          fill='#FFF5DB'
        />
        <Path
          d='M47.3129 45.0352C46.9829 45.0522 46.6604 45.0597 46.5981 44.6014C46.9282 44.3091 47.0828 44.6071 47.2696 44.7938C47.3412 44.8353 47.3714 44.8862 47.3601 44.9428C47.3488 44.9994 47.3337 45.0295 47.3129 45.0352Z'
          fill='#FFECB9'
        />
        <Path
          d='M38.3618 37.7061C37.349 35.2732 36.77 32.7498 37.9356 30.3452C39.0294 28.0895 40.6457 26.262 43.0334 25.3322C45.0759 24.5364 47.6785 24.6306 49.1402 26.2658C50.664 24.5307 53.3365 23.9196 55.4638 24.8192C57.5912 25.7189 59.0133 28.0594 58.8322 30.3621C60.3749 29.868 62.1421 30.6903 63.0304 32.0425C64.9616 34.9809 64.1129 39.4393 62.3533 42.2042C60.3353 45.3745 56.9387 47.5453 53.2101 48.0224C49.1138 48.5486 42.7731 48.2751 39.616 45.2104C38.2486 43.8827 37.3698 42.108 36.9209 40.256C36.7776 39.6638 36.6795 39.0244 36.9077 38.4605C37.1359 37.8966 37.8017 37.4704 38.3637 37.7061H38.3618Z'
          fill='#F47352'
        />
        <Path
          d='M50.934 30.2396C52.1259 29.7983 53.5423 30.5527 54.2344 31.6579C55.4094 33.5326 53.1179 35.8033 51.2282 34.898C50.0061 34.3115 49.0122 32.0634 50.0268 30.8865C50.2984 30.5716 50.6077 30.3622 50.9321 30.2415L50.934 30.2396Z'
          fill='#FFD974'
        />
        <Path
          d='M41.0527 116.666C83.5936 116.666 118.08 107.436 118.08 96.0489C118.08 84.6622 83.5936 75.4314 41.0527 75.4314C-1.48817 75.4314 -35.9744 84.6622 -35.9744 96.0489C-35.9744 107.436 -1.48817 116.666 41.0527 116.666Z'
          fill='#5DAD3E'
        />
      </G>
    </Svg>
  )
}
