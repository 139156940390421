import { IntlShape, MessageDescriptor } from 'react-intl'

import { isValid as dateFnsIsValid, isFuture, parseISO } from 'date-fns'
import { AsYouType, CountryCode, isValidPhoneNumber as isAValidPhoneNumber } from 'libphonenumber-js'
import { isBoolean, isEmpty, isNaN, isNil, isString, isNumber as lodashIsNumber, set, trim } from 'lodash-es'
import * as _ from 'lodash-es'
import moment from 'moment'

import { PasswordPolicy } from '../../../models/customer/Customer'
import { getFormattedMessageSafe } from '../../intl/utils/getFormattedMessageSafe'
import { getAge, getAgeISO } from '../../searchForCare/utils'
import { DEFAULT_AGE_CONSTRAINTS } from '../constants/constants'
import {
  creditCardErrorMessages,
  dateCannotBeInFutureError,
  emailAddressMustMatchErrorMessage,
  enterAValidDateErrorMessage,
  enterAValidEmailErrorMessage,
  enterAValidPhoneNumberError,
  invalidConfirmMatchingPasswordErrorMessage,
  invalidDateErrorMessage,
  invalidDateOfBirthErrorMessage,
  invalidFamilyNameErrorMessage,
  invalidFirstNameErrorMessage,
  invalidFormatValidationError,
  invalidGivenNameErrorMessage,
  invalidLastNameErrorMessage,
  invalidNumberErrorMessage,
  invalidPasswordErrorMessage,
  invalidPhoneNumberError,
  invalidYearErrorMessage,
  invalidZipCodeErrorMessage,
  maxNumErrorMessage,
  mustBeANumberErrorMessage,
  mustBeOlderErrorMessage,
  mustBeStringErrorMessage,
  mustBeYoungerErrorMessage,
  overLimitCharactersErrorMessage,
  requiredFieldErrorMessage,
  thisFieldIsRequiredErrorMessage,
  yearCannotBeInFutureErrorMessage,
} from '../constants/messages'

export interface FinalFormValidation {
  (
    value: any,
    fieldName: string,
    validation: boolean | number,
    formatMessage: IntlShape['formatMessage'],
    customErrorMessage?: MessageDescriptor,
  ): Record<string, string>
}

interface AgeValidationCustomErrorMessages {
  belowMin?: MessageDescriptor
  overMax?: MessageDescriptor
  invalid?: MessageDescriptor
}

export interface AgeValidation {
  (
    value: any,
    fieldName: string,
    ageConstraints: {
      min: number
      max: number
      dateFormat: string
    },
    formatMessage: IntlShape['formatMessage'],
  ): Record<string, string>
}

export interface AgeValidationWithCustomErrors {
  (
    value: any,
    fieldName: string,
    validation: {
      ageConstraints: { min: number; max: number }
      dateFormat?: string
      customErrorMessages?: AgeValidationCustomErrorMessages
    },
    formatMessage: IntlShape['formatMessage'],
  ): Record<string, string>
}

export interface NameValidation {
  (
    value: any,
    fieldName: string,
    nameConstraints: { showInternationalFormat: boolean },
    formatMessage: IntlShape['formatMessage'],
  ): Record<string, string>
}

export interface PasswordValidation {
  (
    value: string,
    fieldName: string,
    validation: {
      passwordPolicy: PasswordPolicy
    },
  ): Record<string, string>
}

export enum CardFieldName {
  CARD_NUMBER = 'CardNumber',
  CVC = 'CVC',
  EXPIRY_DATE = 'ExpiryDate',
  POSTAL_CODE = 'PostalCode',
}
export type CardFieldValidationState = 'Valid' | 'Invalid' | 'Incomplete' | 'Unknown'
const CardFieldNameToLabel: Record<CardFieldName, string> = {
  [CardFieldName.CARD_NUMBER]: 'card number',
  [CardFieldName.CVC]: 'card verification code',
  [CardFieldName.POSTAL_CODE]: 'postal code',
  [CardFieldName.EXPIRY_DATE]: "card's expiration date",
}

/**
 * Validation functions copied from ui-core
 */

export const isValidEmail: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  if (!(value && isValidEmailString(value))) {
    set(errors, fieldName, formatMessage(enterAValidEmailErrorMessage))
  }
  return errors
}
export const isValidEmailString = (value: string): boolean =>
  !!(value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value))

export const isNumber = (value: string | number): boolean => !isNaN(value) && lodashIsNumber(value)

export const isRequired = (value: string | number | boolean | string[], formatMessage: IntlShape['formatMessage']) => {
  if (!isBoolean(value) && !lodashIsNumber(value) && isEmpty(value)) {
    return formatMessage(thisFieldIsRequiredErrorMessage)
  } else {
    return undefined
  }
}

// Validation related to Password field
export const required = (value: string) => (value && /\S/.test(value) ? undefined : requiredFieldErrorMessage)

export const isValidUpperCase = (value: string, count: number) => (value?.match(/[A-Z]/g) || '').length >= count
export const isValidLowerCase = (value: string, count: number) => (value?.match(/[a-z]/g) || '').length >= count
export const isValidNumber = (value: string, count: number) => (value?.match(/\d/g) || '').length >= count
export const isValidLength = (value: string, count: number) => value?.length >= count

const validatePasswordPolicies = (passwordValidator: boolean[]) => {
  return passwordValidator.every((meetsReq) => meetsReq === true) ? undefined : invalidPasswordErrorMessage
}

export const isValidPassword: PasswordValidation = (value, fieldName, validation) => {
  if (!validation?.passwordPolicy) return {}
  const errors = {}

  if (!value) {
    set(errors, fieldName, thisFieldIsRequiredErrorMessage)
  } else {
    const { minLength, minLowerCase, minUpperCase, minNumber } = validation.passwordPolicy

    const passwordValidator = [
      isValidUpperCase(value, minUpperCase),
      isValidLowerCase(value, minLowerCase),
      isValidNumber(value, minNumber),
      isValidLength(value, minLength),
    ]

    const errorMessage = validatePasswordPolicies(passwordValidator)

    if (errorMessage) {
      set(errors, fieldName, errorMessage)
    }
  }
  return errors
}

export const validatePassword = ({ value, passwordPolicy }: { value: string; passwordPolicy: PasswordPolicy }) => {
  const { minLength, minLowerCase, minUpperCase, minNumber } = passwordPolicy

  const passwordValidator = [
    isValidUpperCase(value, minUpperCase),
    isValidLowerCase(value, minLowerCase),
    isValidNumber(value, minNumber),
    isValidLength(value, minLength),
  ]

  return required(value) ?? validatePasswordPolicies(passwordValidator)
}

export const validateConfirmPassword = ({ value, valueConfirm }: { value: string; valueConfirm: string }) => {
  const isMatching = value !== valueConfirm ? invalidConfirmMatchingPasswordErrorMessage : undefined
  return required(valueConfirm) ?? isMatching
}

/**
 * Checks if a string is a valid currency amount.
 *
 * Valid amounts examples:
 *   - .57
 *   - 0.57
 *   - 0
 *   - 12
 *
 * @param {string} value The string to check
 * @return {boolean} `true` if the string contains only digits and decimal separator, false otherwise.
 */
export const isValidCurrencyAmount = (value: string): RegExpMatchArray | null => value.match(/^(0?|\d+)(\.\d+)?$/)

/**
 * Checks if a string is a valid integer.
 *
 * @param {string} value The string to check
 * @return {boolean} `true` if the string is a valid integer, false otherwise.
 */
export const isValidInteger = (value: string): RegExpMatchArray | null => value.match(/^(0|[1-9]\d*)$/)

export const isValidNameString = (value: string): boolean => /^[A-Z a-z \u00C0-\u017F ' ’ \- .]+$/.test(value)

/**
 * Checks whether a given date is a valid date of birth for a user.
 * You can specify a minimum and maximum age (in years) that the user has to meet.
 * A user has to be at least a minimum age and can be no older than the maximum age.
 *
 * @deprecated Prefer to use `isValidDateOfBirthISO` to avoid dealing with `dateFormat`
 */
export const isValidDateOfBirth = (
  value: string,
  ageConstraints = DEFAULT_AGE_CONSTRAINTS,
  dateFormat = 'MM/DD/YYYY',
  customErrorMessages?: AgeValidationCustomErrorMessages,
): { isValid: boolean; message: MessageDescriptor | undefined } => {
  let { isValid, message } = isValidDate(value, dateFormat)
  if (!isValid) {
    return { isValid, message }
  } else {
    const age = getAge({ date: value, dateFormat })
    if (!Number.isFinite(age)) {
      // Weeds out null, undefined, NaN
      isValid = false
      message = (customErrorMessages && customErrorMessages.invalid) ?? invalidDateOfBirthErrorMessage
    } else if (age < ageConstraints.min) {
      isValid = false
      message = (customErrorMessages && customErrorMessages.belowMin) ?? mustBeOlderErrorMessage
    } else if (age > ageConstraints.max) {
      isValid = false
      message = (customErrorMessages && customErrorMessages.overMax) ?? mustBeYoungerErrorMessage
    }
    return { isValid, message }
  }
}

/**
 * Checks whether a given date is a real date.
 *
 * @deprecated Prefer `isValidDateISO` to avoid dealing with `dateFormat`
 */
export const isValidDate = (
  value: string,
  dateFormat = 'MM/DD/YYYY',
): { isValid: boolean; message: MessageDescriptor | undefined } => {
  const sysTime = new Date()
  let isValid = true
  let message
  const momentDate = moment(value, dateFormat, true /* strict parsing */)
  const isValidDate = momentDate.isValid()
  if (!isValidDate) {
    isValid = false
    message = invalidDateErrorMessage
    return {
      isValid,
      message,
    }
  }
  // Check for date in the future
  if (sysTime.getTime() - new Date(value).getTime() < 0) {
    isValid = false
    message = invalidDateErrorMessage
    return {
      isValid,
      message,
    }
  }
  return {
    isValid,
    message,
  }
}

/**
 * Validates date of birth
 *
 * @param date ISO 8601 date string, ex: '2023-02-01'
 * @param ageConstraints Specify min and max ages (inclusive) in years. User must be least the min age, and no older than the max age.
 * @param customErrorMessages
 */
export const isValidDateOfBirthISO = (
  iso8601Date: string,
  ageConstraints = DEFAULT_AGE_CONSTRAINTS,
  customErrorMessages?: AgeValidationCustomErrorMessages,
): { isValid: boolean; message?: MessageDescriptor } => {
  const { isValid } = isValidDateISO(iso8601Date)
  if (!isValid) {
    return {
      isValid,
      message: customErrorMessages?.invalid ?? invalidDateOfBirthErrorMessage,
    }
  }

  const age = getAgeISO(iso8601Date)
  if (age < ageConstraints.min) {
    return { isValid: false, message: customErrorMessages?.belowMin ?? mustBeOlderErrorMessage }
  } else if (age > ageConstraints.max) {
    return { isValid: false, message: customErrorMessages?.overMax ?? mustBeYoungerErrorMessage }
  }
  return { isValid: true }
}

/**
 * Validates the date is a real date
 *
 * @param iso8601Date ISO 8601 date string, ex: '2023-02-01'
 * @param noFutureDates Dates in the future are invalid when true, defaults to true
 */
export const isValidDateISO = (
  iso8601Date: string,
  noFutureDates = true,
): { isValid: boolean; message?: MessageDescriptor } => {
  const date = parseISO(iso8601Date)
  const isValid = dateFnsIsValid(date) && (!noFutureDates || !isFuture(date))
  return {
    isValid,
    message: isValid ? undefined : invalidDateErrorMessage,
  }
}

// Checks whether a given range of dates is valid, assuming that the individual dates themselves are valid.
export const isValidDateRange = (start: string, end: string): boolean => moment(start).isSameOrBefore(end)

export const isValidPhoneNumber = (value: string, countryCode: CountryCode = 'US'): boolean => {
  /*
  Args:
    value: A phone number that can contain dashes
  Returns:
    true if it is a valid international based phone number, false otherwise

    We now have a dropdown for a country code, thus we accept the country code as a parameter or default to US
    otherwise.

    We can achieve this by doing as an example:
    isAValidPhoneNumber(numberDigitsOnly, [country here])
        
    package source: https://github.com/catamphetamine/libphonenumber-js
  */
  if (!isString(value)) {
    return false
  }
  const numberDigitsOnly = value.replace(/\D/g, '')
  // isAValidPhoneNumber is considered a more strict check, where we check the length as well as the digits of the phoneNumber
  // To use a less strict check, we can utilize isPossible() from the same package.
  return isAValidPhoneNumber(numberDigitsOnly, countryCode)
}

// The functions below are made to be used with use with redux-form.
// They return an error object mapping the field name with the error message.

export const requiredField: FinalFormValidation = (value, fieldName, validation, formatMessage, customErrorMessage) => {
  const errors = {}
  if (!validation) return errors
  if (
    (!isBoolean(value) && !lodashIsNumber(value) && isEmpty(trim(value))) ||
    (typeof value.isEmpty === 'function' && value.isEmpty())
  ) {
    set(errors, fieldName, customErrorMessage ?? formatMessage(thisFieldIsRequiredErrorMessage))
  }
  return errors
}

export const hasValue: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (value !== validation) {
    set(errors, fieldName, formatMessage(thisFieldIsRequiredErrorMessage))
  }
  return errors
}

export const isBooleanTrue: FinalFormValidation = (
  value,
  fieldName,
  validation,
  formatMessage,
  customErrorMessage = thisFieldIsRequiredErrorMessage,
) => {
  const errors = {}
  if (validation && value !== true) {
    set(errors, fieldName, getFormattedMessageSafe(customErrorMessage, formatMessage))
  }
  return errors
}

export const characterLimit: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  if (typeof value !== 'string') {
    set(errors, fieldName, formatMessage(mustBeStringErrorMessage))
  }
  if (value.length > validation) {
    set(errors, fieldName, formatMessage(overLimitCharactersErrorMessage, { max: validation }))
  }
  return errors
}

export const isValidPastDate: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  const momentObject = moment(value)
  // Since hermes on native returns an invalid moment object with "/" formating (MM/DD/YYY)
  // we should convert it to "-" formatting (MM-DD-YYY) before attempting to validate the date
  const val = momentObject.isValid() ? momentObject : moment(value.replaceAll('/', '-'))
  if (!val.isValid()) {
    set(errors, fieldName, formatMessage(enterAValidDateErrorMessage))
  } else if (moment().diff(val, 'days') < 0) {
    set(errors, fieldName, formatMessage(dateCannotBeInFutureError))
  }
  return errors
}

export const validPhoneNumber: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  if (!isValidPhoneNumber(value)) {
    // We need to check for formatMessage here and return a non-translated string because
    // this function is currently being used in libs/ui-core/lib/lyraTherapy/formBody/contentValidation.ts
    // which doesn't have a parent ancestry to the useIntil hook which provides the formatMessage function.
    // Therefore, we need to check for the formatMessage here for this one case.
    set(errors, fieldName, formatMessage ? formatMessage(invalidPhoneNumberError) : 'Invalid phone number')
  }
  return errors
}

export const isValidCustomerCode: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors

  // customer code should contain only digits (0-9)
  const isValid = /^\d+$/.test(value.toString())

  if (!isValid) {
    set(errors, fieldName, formatMessage(invalidFormatValidationError))
  }
  return errors
}

export const validInternationalPhoneNumber: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  const asYouType = new AsYouType()
  /**
   * Since react-final-form does validation on mount, if a user
   * has a phone number value already i.e. via draft, we do not want to validate
   * the field until we have called convertToInternationalFormat and convertToNationalFormat as there will be no country code in
   * the value.
   */
  if (!validation || isNil(value)) return errors

  value && asYouType.input(value)
  const countryCode = asYouType.country
  const phoneNumber = asYouType.getNationalNumber()
  if ((isNil(countryCode) && !isValidPhoneNumber(value)) || !isValidPhoneNumber(phoneNumber, countryCode)) {
    set(errors, fieldName, formatMessage(enterAValidPhoneNumberError))
  }

  return errors
}

export const isPositiveInteger: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  if (!/^\+?[0-9][\d]*$/.test(value)) {
    set(errors, fieldName, formatMessage(invalidNumberErrorMessage))
  }
  return errors
}

export const rangeHasValidValue: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  if (!/^\+?[0-9][\d]*$/.test(value)) {
    set(errors, fieldName, formatMessage(thisFieldIsRequiredErrorMessage))
  }
  return errors
}

export const maxValue: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (isNil(value)) {
    return errors
  }
  const parsedValue = parseInt(value)
  if (isNaN(parsedValue) || !/^\d*\.?\d+$/.test(value)) {
    set(errors, fieldName, formatMessage(mustBeANumberErrorMessage))
  } else if (value > validation) {
    set(errors, fieldName, formatMessage(maxNumErrorMessage, { max: validation }))
  }
  return errors
}

export const isValidPastYear: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || isNil(value)) return errors
  if (value.length !== 4 || !/^\+?[1-9][\d]*$/.test(value)) {
    set(errors, fieldName, formatMessage(invalidYearErrorMessage))
  } else if (parseInt(value) > new Date().getFullYear()) {
    set(errors, fieldName, formatMessage(yearCannotBeInFutureErrorMessage))
  }
  return errors
}

export const isValidFirstName: NameValidation = (value, fieldName, nameConstraints, formatMessage) => {
  if (!value) return {} // this util function is not taking responsibility for 'required' validation

  const { showInternationalFormat } = nameConstraints

  const errors = {}
  if (!isValidNameString(value)) {
    set(
      errors,
      fieldName,
      formatMessage(showInternationalFormat ? invalidGivenNameErrorMessage : invalidFirstNameErrorMessage),
    )
  }
  return errors
}

export const isValidLastName: NameValidation = (value, fieldName, nameConstraints, formatMessage) => {
  if (!value) return {} // this util function is not taking responsibility for 'required' validation

  const { showInternationalFormat } = nameConstraints

  const errors = {}
  if (!isValidNameString(value)) {
    set(
      errors,
      fieldName,
      formatMessage(showInternationalFormat ? invalidFamilyNameErrorMessage : invalidLastNameErrorMessage),
    )
  }
  return errors
}

export const validateName = (
  value: string,
  fieldName: string,
  validateNameFunc: NameValidation,
  formatMessage: IntlShape['formatMessage'],
  isUserInternational: boolean,
  isOptional = false,
) => {
  let error
  if (!isOptional) {
    error = isRequired(value, formatMessage)
  }
  if (!error) {
    error = validateNameFunc(value, fieldName, { showInternationalFormat: isUserInternational }, formatMessage)[
      fieldName
    ]
  }
  return error
}

export const isValidAge: AgeValidation = (value, fieldName, { min, max }, formatMessage) => {
  const { isValid, message } = isValidDateOfBirthISO(value, { min, max })
  const errors = {}
  if (!isValid && message) {
    set(errors, fieldName, getFormattedMessageSafe(message, formatMessage, { min, max }))
  }
  return errors
}

export const isValidAgeCustomErrors: AgeValidationWithCustomErrors = (
  value,
  fieldName,
  { ageConstraints, customErrorMessages },
  formatMessage,
) => {
  const { isValid, message } = isValidDateOfBirthISO(value, ageConstraints, customErrorMessages)
  const errors = {}
  if (!isValid && message) {
    set(errors, fieldName, getFormattedMessageSafe(message, formatMessage, ageConstraints))
  }
  return errors
}

export const isValid5DigitZipCode: FinalFormValidation = (value, fieldName, validation, formatMessage) => {
  const errors = {}
  if (!validation || _.isNil(value)) return errors
  if (!/^\d{5}/.test(value)) {
    _.set(errors, fieldName, formatMessage(invalidZipCodeErrorMessage))
  }
  return errors
}

export const isValidMFACode = (value: string, formatMessage: IntlShape['formatMessage']) => {
  let error
  if (!value) {
    error = formatMessage({
      defaultMessage: 'Passcode required',
      description: 'Text displaying input passcode requirement.',
    })
  } else if (!(value.length === 6 && isNumber(Number(value)))) {
    error = formatMessage({
      defaultMessage: 'Invalid passcode',
      description: 'Text displaying input passcode is invalid.',
    })
  }
  return error
}

export const isValidCreditCard = (
  cardFieldName: CardFieldName,
  cardFieldValidationState: CardFieldValidationState,
  formatMessage: IntlShape['formatMessage'],
) => {
  if (cardFieldValidationState === 'Valid') {
    return undefined
  } else if (cardFieldValidationState === 'Incomplete') {
    return {
      creditCardField: formatMessage(creditCardErrorMessages.CARD_FIELD_INCOMPLETE, {
        cardFieldName: CardFieldNameToLabel[cardFieldName],
      }),
    }
  } else {
    return {
      creditCardField: formatMessage(creditCardErrorMessages.CARD_FIELD_INVALID, {
        cardFieldName: CardFieldNameToLabel[cardFieldName],
      }),
    }
  }
}

export const validateEmail = (value: string, formatMessage: IntlShape['formatMessage']) => {
  let error = ''
  if (!value) {
    error = formatMessage(thisFieldIsRequiredErrorMessage)
  } else if (!isValidEmailString(value)) {
    error = formatMessage(enterAValidEmailErrorMessage)
  }
  return error
}

export const validateEmailMatch = (value: string, confirmValue: string, formatMessage: IntlShape['formatMessage']) =>
  value !== confirmValue ? formatMessage(emailAddressMustMatchErrorMessage) : undefined
