import { useEffect, useState } from 'react'

import { MIXPANEL_VENDOR_API_URL, shouldDisableCostlyVendors, useFlags, User } from '@lyrahealth-inc/shared-app-logic'

import mixpanel from '../../data/mixpanel/mixpanel-browser'
import { APP_ID, APP_PLATFORM } from '../constants/appConstants'

function mixpanelTrackingOptInOrOut(username: string | undefined) {
  /**
   * Remove tracking opt in/out flag to default user to being tracked
   * Opt in flag set to true => user is tracked
   * Opt in flag set to false => user is not tracked
   * No opt in flag => user is tracked
   */
  mixpanel.clear_opt_in_out_tracking()

  // Check for a lyrahealth username. If in the production environment,
  // we do not want to log their events in Mixpanel
  const lyraEmployeeEmailsRegex = /@(lyrahealth|lyraclinical)\.com$/
  username = username ?? ''
  const isLyraEmployee = lyraEmployeeEmailsRegex.test(username)
  if ((process.env.NODE_ENV === 'production' && isLyraEmployee) || shouldDisableCostlyVendors) {
    mixpanel.opt_out_tracking()
  } else {
    mixpanel.opt_in_tracking()
  }
}

const useMixpanel = ({ mixpanelToken, user }: { mixpanelToken: string; user: User }) => {
  const [mixpanelInitialized, setMixpanelInitialized] = useState(false)
  const { enableMixpanelDebug } = useFlags()
  const cookieDomain = window.location.hostname

  useEffect(() => {
    if (mixpanelToken && !mixpanelInitialized) {
      mixpanel.init(mixpanelToken, {
        ignore_dnt: true,
        api_host: MIXPANEL_VENDOR_API_URL,
        ...(enableMixpanelDebug && { debug: true }),
        loaded: () => {
          mixpanel.register({
            app_platform: APP_PLATFORM,
            app_id: APP_ID,
          })
          mixpanel.unregister('essentials')
          setMixpanelInitialized(true)
        },
        cookie_domain: cookieDomain, // ensure we are placing the full sub-domain (and not the parent domain) as the cookie domain
        secure_cookie: true, // prevent transmitting cookie with non-HTTPs requests
        cross_subdomain_cookie: false, // prevent cookie persistence across subdomains
        opt_out_tracking_by_default: shouldDisableCostlyVendors,
      })
    }
  }, [enableMixpanelDebug, mixpanelToken, mixpanelInitialized, cookieDomain])

  const userId = user?.id
  const username = user.username
  const customerId = user.customers?.id
  const customerName = user.customers?.name

  useEffect(() => {
    if (mixpanelInitialized && userId) {
      mixpanelTrackingOptInOrOut(username)
      mixpanel.identify(userId)
      mixpanel.people.set({
        customerId,
        customerName,
        userId,
      })
    }
  }, [customerId, customerName, mixpanelInitialized, userId, username])

  return mixpanelInitialized
}

export default useMixpanel
