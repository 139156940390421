import { WELLNESS_CHECK_IN_INITIAL_VALUES, WELLNESS_CHECK_IN_NUM_DOMAINS } from './wellnessCheckInMetadata'

export const getWellnessCheckInTotalNumPages = (socialCareEnabled: boolean) => {
  const howAreYouFeelingPage = 1 // first page of the wellness check in flow
  const socialCarePage = 1 // last page of the wellness check in flow if customer has social care enabled
  const numPages = howAreYouFeelingPage + WELLNESS_CHECK_IN_NUM_DOMAINS
  return socialCareEnabled ? numPages + socialCarePage : numPages
}

export const getInitialWellnessCheckinValues = (socialCareEnabled: boolean) => {
  const initialValues = WELLNESS_CHECK_IN_INITIAL_VALUES
  if (!socialCareEnabled) {
    delete initialValues.socialCareSupport
  }
  return initialValues
}
