// @intl project:microsite

import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import cx from 'classnames'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { IS_PRODUCTION, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { DefaultButton, TextButton, XIcon } from '@lyrahealth-inc/ui-core'
import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './cookieBanner.module.scss'
import {
  getShouldShowCookieBannerAndSettings,
  setShouldShowCookieBanner,
} from '../../../data/appGlobals/appGlobalsActions'
import { getShouldShowCookieBannerSelector } from '../../../data/appGlobals/appGlobalsSelectors'
import { HAS_CONSENTED_TO_SITE_COOKIES } from '../../constants/appConstants'
import { logToSumoLogic, SUMO_CATEGORY } from '../../utils/userUtils'

interface CookieBannerProps {
  getShouldShowCookieBannerAndSettings: boolean
  setShouldShowCookieBanner: (val: boolean) => ThunkAction<Promise<void>, Map<string, any>, unknown, AnyAction>
  shouldShowCookieBanner: boolean
}

declare global {
  interface Window {
    OneTrust: any
  }
}

const CookieBanner = ({
  getShouldShowCookieBannerAndSettings,
  setShouldShowCookieBanner,
  shouldShowCookieBanner,
}: CookieBannerProps) => {
  const { isCookieBannerEnabled } = useFlags()
  const [didBannerReloadFail, setDidBannerReloadFail] = useState(false)
  const location = useLocation()
  useFetcher([getShouldShowCookieBannerAndSettings])
  const intl = useIntl()
  const customizeCookieLinkText = intl.formatMessage({
    defaultMessage: 'Customize',
    description: "Button to customize which cookies are tracked on a user's computer",
  })

  useEffect(() => {
    const reloadOTBanner = () => {
      const otConsentSdk = document.getElementById('onetrust-consent-sdk')
      if (otConsentSdk) {
        otConsentSdk.remove()
      }
      if (window.OneTrust) {
        window.OneTrust.Init()

        setTimeout(function () {
          window.OneTrust.LoadBanner()

          const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings')
          if (toggleDisplay && toggleDisplay.length > 0) {
            // Banner reload seems to reset the link text, so changing it back here
            toggleDisplay[0].textContent = customizeCookieLinkText

            for (let i = 0; i < toggleDisplay.length; i++) {
              const toggleDisplayElement = toggleDisplay[i] as HTMLElement
              toggleDisplayElement.onclick = function (event) {
                event.stopImmediatePropagation()
                window.OneTrust.ToggleInfoDisplay()
              }
            }
          } else {
            setDidBannerReloadFail(true)
            logToSumoLogic(IS_PRODUCTION ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG, null, {
              message: 'Unable to load OneTrust banner',
              type: 'error',
            })
          }
        }, 1000)
      }
    }

    if (shouldShowCookieBanner && isCookieBannerEnabled && location.key !== 'default') {
      try {
        reloadOTBanner()
      } catch (err) {
        setDidBannerReloadFail(true)
        logToSumoLogic(IS_PRODUCTION ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG, null, {
          message: `Unable to load OneTrust banner ${err}`,
          type: 'error',
        })
        console.error(err)
      }
    }
  }, [shouldShowCookieBanner, customizeCookieLinkText, location, isCookieBannerEnabled])

  const banner = (
    <div data-testid='CookieBanner' className={styles['banner-container']}>
      <p className={styles.banner}>
        <FormattedMessage
          defaultMessage='This website uses cookies for performance and functionality—not for advertising.'
          description='Consent to cookies, banner text'
        />{' '}
        {/* @ts-expect-error TS(2741): Property 'onClick' is missing in type '{ isLink: t... Remove this comment to see the full error message */}
        <TextButton
          isLink
          href={'#'}
          text={customizeCookieLinkText}
          className={cx(styles.cta, 'ot-sdk-show-settings')} // Hardcoded class name from OneTrust
        />
      </p>
      <DefaultButton
        unstyled
        onClick={() => {
          setShouldShowCookieBanner(false)
          localStorage.setItem(HAS_CONSENTED_TO_SITE_COOKIES, JSON.stringify(true))
        }}
        aria-label={intl.formatMessage({
          defaultMessage: 'Close cookie banner',
          description: 'Button to close the cookie banner after popping on the screen',
        })}
      >
        <XIcon isFilled fillColor={styles.x_brand} height={24} width={24} />
      </DefaultButton>
    </div>
  )
  return shouldShowCookieBanner && isCookieBannerEnabled && !didBannerReloadFail ? banner : null
}

const mapStateToProps = (state: any) => {
  const hasConsentedToSiteCookies = localStorage.getItem(HAS_CONSENTED_TO_SITE_COOKIES)
  return {
    shouldShowCookieBanner: getShouldShowCookieBannerSelector(state) && !hasConsentedToSiteCookies,
  }
}

export default connect(mapStateToProps, { getShouldShowCookieBannerAndSettings, setShouldShowCookieBanner })(
  // @ts-expect-error TS(2345): Argument of type '({ getShouldShowCookieBannerAndS... Remove this comment to see the full error message
  CookieBanner,
)
