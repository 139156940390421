// strictly used to specify the care description in the lyra offerings section of the microsite
export enum CARE_OFFERING_DESCRIPTION_TYPE {
  EAP_ONLY = 'eapOnly',
  EAP_ONLY_DEPENDENT_DISABLED = 'eapOnlyDependentDisabled',
  HPI_ONLY = 'hpiOnly',
  HPI_ONLY_CHILDREN_DISABLED = 'hpiOnlyChildrenDisabled',
  EAP_AND_HPI = 'eapAndHpi',
}

export const getMemberCareOfferingDescriptionType = ({
  isHPIExclusive,
  isDependentDisabled,
  isEAPExclusive,
  isEAPAndHPISupported,
  numberOfSessions,
  isUserAssumedInternational,
  shouldHideChildSearchCTA,
  hideNumberOfSessions,
}: {
  isHPIExclusive?: boolean
  isDependentDisabled?: boolean
  isEAPExclusive?: boolean
  isEAPAndHPISupported?: boolean
  numberOfSessions?: string
  isUserAssumedInternational?: boolean
  shouldHideChildSearchCTA?: boolean
  hideNumberOfSessions?: boolean
}) => {
  const isNumberOfSessionsZero = !numberOfSessions || numberOfSessions === '0'
  const numberOfSessionsAvailable = !isNumberOfSessionsZero && !hideNumberOfSessions
  const isEAPOnlyDescriptionAvailable = (isEAPExclusive || isUserAssumedInternational) && numberOfSessionsAvailable
  const isHPIOnlyChildrenEnabled = isHPIExclusive && !shouldHideChildSearchCTA
  const isHPIOnlyChildrenDisabled = isHPIExclusive && shouldHideChildSearchCTA
  const isEAPOnlyDependentDisabled = isEAPOnlyDescriptionAvailable && isDependentDisabled
  const isEAPOnlyDependentEnabled = isEAPOnlyDescriptionAvailable && !isDependentDisabled
  const isEAPAndHPI = isEAPAndHPISupported && numberOfSessionsAvailable

  if (isHPIOnlyChildrenEnabled) {
    return CARE_OFFERING_DESCRIPTION_TYPE.HPI_ONLY
  } else if (isHPIOnlyChildrenDisabled) {
    return CARE_OFFERING_DESCRIPTION_TYPE.HPI_ONLY_CHILDREN_DISABLED
  } else if (isEAPOnlyDependentDisabled) {
    return CARE_OFFERING_DESCRIPTION_TYPE.EAP_ONLY_DEPENDENT_DISABLED
  } else if (isEAPOnlyDependentEnabled) {
    return CARE_OFFERING_DESCRIPTION_TYPE.EAP_ONLY
  } else if (isEAPAndHPI) {
    return CARE_OFFERING_DESCRIPTION_TYPE.EAP_AND_HPI
  } else {
    return
  }
}
