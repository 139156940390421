import { useDispatch } from 'react-redux'

import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from 'redux'

import Interceptor from '../../common/http/axiosInstance'

/**
 * Based on the current environment variable, we need to make sure
 * to exclude any DevTools-related code from the production builds.
 */

let loadedStore = null

const env = process.env.NODE_ENV as 'production' | 'demo' | 'staging' | 'prerelease'

if (env === 'production' || env === 'staging' || env === 'demo' || env === 'prerelease') {
  loadedStore = require('./configureStore.prod').default
} else {
  loadedStore = require('./configureStore.dev').default
}

declare global {
  interface Window {
    Cypress: any
    store: Store
  }
}
// Export the store to Cypress to give access to it in the tests.
if (window.Cypress) {
  window.store = loadedStore
}

Interceptor.interceptor(loadedStore)

export type AppDispatch = ThunkDispatch<any, null, AnyAction> &
  ThunkDispatch<any, undefined, AnyAction> &
  Dispatch<AnyAction | any> // need to add `any` due to auto-thunk usages
export default loadedStore as Store

export const useAppDispatch = () => useDispatch<AppDispatch>()
