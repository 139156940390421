import { useCallback } from 'react'

import { cloneDeep, isNil } from 'lodash-es'

import {
  CustomerTreatmentOption,
  CustomerTreatmentsOptionLocation,
  TREATMENT_OPTIONS_TYPE,
} from '@lyrahealth-inc/shared-app-logic'

type AddCustomerTreatmentOptionsParams = {
  searcherAge: number
  location: CustomerTreatmentsOptionLocation
  treatmentOptions: TREATMENT_OPTIONS_TYPE[]
  customerCopy?: { [key: string]: any }
}

/** Takes in a list of treatment options and adds in the customer specific options based on `customerCopy` */
export const useAddCustomerTreatmentOptions = () => {
  return useCallback(({ searcherAge, location, treatmentOptions, customerCopy }: AddCustomerTreatmentOptionsParams) => {
    const treatmentOptionsCloned = cloneDeep(treatmentOptions)
    const configForSpecificTreatmentOptions = {}
    const customerTreatmentOptions: CustomerTreatmentOption[] = customerCopy?.treatmentOptions || []
    customerTreatmentOptions.forEach((item: CustomerTreatmentOption) => {
      const insertionIndex = item.optionRanking[location]
      if (searcherAge > (item.config?.minAge ?? 0) && !isNil(insertionIndex)) {
        treatmentOptionsCloned.splice(insertionIndex, 0, item.option)
        configForSpecificTreatmentOptions[item.option] = item.config
      }
    })
    return { treatmentOptions: treatmentOptionsCloned, configForSpecificTreatmentOptions }
  }, [])
}
