import { useCallback, useMemo } from 'react'

import {
  FullLocationObject,
  MEETING_FORMATS,
  PROVIDER_TYPES,
  ProviderEnums,
  SearchRequestData,
  TriageScreenerNames,
  TriageSearch,
} from '../../../models'
import { SESSION_FORMAT } from '../../common/constants/constants'
import {
  PROGRAMS,
  TRIAGE_TREATMENT_OPTIONS_TO_PROVIDER_TYPES_FOR_DISPLAY,
} from '../../common/constants/customerProgram'
import {
  convertTreatmentOptionValueForBackend,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MemberPreferencesForUserV2 } from '../../memberPreferences/types'
import {
  convertAvailabilitiesDayOfWeek,
  filterMemberPreferencesOptions,
  getIsValidSearchForModalityPreferenceForMemberPreferences,
  getVisiblePreferenceCategories,
  isCareLanguageValidPreference,
} from '../../memberPreferences/utils'
import { SEARCHER_TYPE_TO_MATCH_FOR, TriageScreenerField, TriageWords } from '../constants'
import { convertTriageTreatmentOptionToProgram, isSearchingForTherapy } from '../utils'

type WordGridValues = { [key in TriageScreenerField]: TriageWords }

type WordGridContext = { [key in TriageScreenerNames]: { metadataId: string } | WordGridValues } | undefined

type Params = {
  searchId?: string | null
  search?: TriageSearch
  locationSearchData: FullLocationObject | null
  isUserInternational: boolean
  meetingFormat?: MEETING_FORMATS | null
  treatmentOption: TREATMENT_OPTIONS_TYPE | null
  selectedCareLanguage: string
  matchFor?: SEARCHER_TYPE_TO_MATCH_FOR
  memberPreferencesV2?: MemberPreferencesForUserV2 | null
  modalityPreference?: string
  isValidMemberPreferencesTreatmentOption?: boolean
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
  ageInteger?: number
  shouldShowAvailabilityPreference?: boolean
  lyraId?: string
}

/**
 * Hook to generate the search request payload for provider recommendations and onsite providers
 */
export const useGenerateSearchRequestPayload = ({
  search,
  locationSearchData,
  isUserInternational,
  meetingFormat,
  treatmentOption,
  selectedCareLanguage,
  matchFor = SEARCHER_TYPE_TO_MATCH_FOR.SELF,
  memberPreferencesV2,
  modalityPreference,
  isValidMemberPreferencesTreatmentOption,
  searchForCareProgramOptionFlow,
  ageInteger,
  shouldShowAvailabilityPreference = false,
  lyraId,
}: Params): SearchRequestData => {
  /**
   * Given a treatment option, get the provider type corresponding to it.
   */
  const getProviderTypeForSelectedTreatmentOption = useCallback((treatmentOption: TREATMENT_OPTIONS_TYPE | null) => {
    return treatmentOption
      ? TRIAGE_TREATMENT_OPTIONS_TO_PROVIDER_TYPES_FOR_DISPLAY[treatmentOption]
      : PROVIDER_TYPES[ProviderEnums.PROVIDER]
  }, [])

  // Convert meetingFormats to a sessionFormat object of the form: { "All": ["Live-Messaging"] }
  // The key of this object can also be "Any". This depends on the value of "areAllRequired" param.
  // This is a new format that BE expects. Matcher is expecting this format from any new code written.
  const convertMeetingFormatToSessionFormat = useCallback(
    (meetingFormats: Array<MEETING_FORMATS>, areAllRequired = true) => {
      const sessionFormatOptions = {
        [MEETING_FORMATS.VIDEO]: SESSION_FORMAT[0].text,
        [MEETING_FORMATS.IN_PERSON]: SESSION_FORMAT[1].text,
        [MEETING_FORMATS.LIVE_MESSAGING]: SESSION_FORMAT[2].text,
      }
      // Member Preferences allows users to select both video and live messaging as a valid modality preference
      // This represents both the default none selected state and the both-selected state in what Arbiter expects
      if ([MEETING_FORMATS.NONE_SPECIFIED].includes(meetingFormats[0])) {
        return null
      } else if ([MEETING_FORMATS.VIDEO_AND_LIVE_MESSAGING].includes(meetingFormats[0])) {
        return {
          All: [sessionFormatOptions[MEETING_FORMATS.VIDEO], sessionFormatOptions[MEETING_FORMATS.LIVE_MESSAGING]],
        }
      } else {
        const sessionFormats = meetingFormats.map((meetingFormat) => sessionFormatOptions[meetingFormat])
        return areAllRequired ? { All: sessionFormats } : { Any: sessionFormats }
      }
    },
    [],
  )

  const generateProgramSpecificDataForProviderRecommendations = useCallback(() => {
    const sessionFormat = meetingFormat && convertMeetingFormatToSessionFormat([meetingFormat])
    const shouldSendSessionFormat = treatmentOption === TREATMENT_OPTIONS.COACHING && !!sessionFormat

    /**
     * For direct booking, the values (meetingFormat, selectedTreatmentOption, providerType)
     * below will have to handle using the values from the booking link first
     * before defaulting to the store. LW does this as will using the outsideOnboarding flag
     */
    const providerType = getProviderTypeForSelectedTreatmentOption(treatmentOption)
    return {
      blendedCareProgramId:
        convertTriageTreatmentOptionToProgram({ treatmentOptionName: treatmentOption })?.blendedCareProgramId ||
        PROGRAMS?.[providerType?.program]?.blendedCareProgramId,
      ...(shouldSendSessionFormat && { sessionFormat }),
    }
  }, [convertMeetingFormatToSessionFormat, getProviderTypeForSelectedTreatmentOption, meetingFormat, treatmentOption])

  const generateProviderSearchPreferences = useCallback(() => {
    /**
     * A null/undefined member preferences value is possible for exisiting users who have not done a fetch for
     * member preferences with the flag on, so temporarily pass null until it is defined
     */
    if (!memberPreferencesV2) {
      return { providerSearchPreferences: null }
    }

    const isValidSearchForModalityPreferenceForMemberPreferences =
      getIsValidSearchForModalityPreferenceForMemberPreferences({ searchForCareProgramOptionFlow })

    // PROSPECT-3796: Filter out MENA option if user is searching for coaching
    const filteredEthnicities = filterMemberPreferencesOptions({
      preferenceCategory: memberPreferencesV2.ethnicities,
      shouldFilterMemberPreferencesOptions: [
        S4C_PROGRAM_OPTION_FLOW.COACHING,
        S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS,
      ].includes(searchForCareProgramOptionFlow),
      filterOptions: [MEMBER_PREFERENCE_IDS.MIDDLE_EASTERN_OR_NORTH_AFRICAN],
    })

    // Convert days of week and times of day for availability preference to conform to BE/Aribter mappings
    const convertedAvailabilities =
      memberPreferencesV2.availabilities?.length && !memberPreferencesV2.availabilities.includes('flexible')
        ? convertAvailabilitiesDayOfWeek(memberPreferencesV2.availabilities)
        : memberPreferencesV2.availabilities

    const shouldSendMeetingStyleMemberPreferenceOption =
      searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN ||
      searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD ||
      searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS

    const visiblePreferences = getVisiblePreferenceCategories({
      searchForCareProgramOptionFlow,
      shouldShowAvailabilityPreference,
    })

    const availabilityPreference = {
      availabilities:
        memberPreferencesV2.availabilities === null
          ? null
          : convertedAvailabilities?.length
          ? convertedAvailabilities.join(',')
          : '[]',
      timeZone: memberPreferencesV2.timeZone,
    }

    if (shouldSendMeetingStyleMemberPreferenceOption) {
      // Filter out all options except for the meeting style and availability preference category
      return {
        providerSearchPreferences: {
          visiblePreferences,
          isBipoc: false,
          ethnicities: '[]',
          gender: '[]',
          religions: '[]',
          isLgbtqia: false,
          isExperiencedWithVeterans: false,
          modalityPreference: modalityPreference !== undefined ? modalityPreference : '[]',
          ...(shouldShowAvailabilityPreference && availabilityPreference),
        },
      }
    } else {
      return {
        providerSearchPreferences: {
          visiblePreferences,
          isBipoc: memberPreferencesV2.isBipoc,
          ethnicities: filteredEthnicities.length ? filteredEthnicities.join(',') : '[]',
          gender: memberPreferencesV2.gender?.length ? memberPreferencesV2.gender.join(',') : '[]',
          isLgbtqia: memberPreferencesV2.isLgbtqia,
          religions: memberPreferencesV2.religions?.length ? memberPreferencesV2.religions.join(',') : '[]',
          isExperiencedWithVeterans: memberPreferencesV2.isExperiencedWithVeterans,
          modalityPreference:
            isValidSearchForModalityPreferenceForMemberPreferences && modalityPreference !== undefined
              ? modalityPreference
              : '[]',
          ...(shouldShowAvailabilityPreference && availabilityPreference),
        },
      }
    }
  }, [memberPreferencesV2, searchForCareProgramOptionFlow, modalityPreference, shouldShowAvailabilityPreference])

  // Only pass `selectedCareLanguage` if it's defined/not empty.
  // For the rest of the conditional:
  // Pre-Member Preferences v1, when a user's preferred care language is sent to Arbiter, Arbiter uses this value for
  // both care language ranking and preference identification and selection.
  // Because we currently have an organizational requirement that all providers speak English, all providers
  // currently match on English when it's specified as the preferred care language, meaning that matching
  // preference pills appear spurious / unhelpful when it's just language that matches.
  // Thus, the adapted product requirement for Member Preferences v1 is to NOT send the user's preferred care language
  // if the user is a US user who selects English, so as to avoid English language displaying as a matched preference
  // for every returned provider.
  const shouldPassSelectedCareLanguage = isCareLanguageValidPreference({
    isSearchingForAdultTherapy: isSearchingForTherapy(treatmentOption),
    isUserInternational,
    selectedCareLanguage,
  })

  // Depending on the member preferences version, generate the provider preferences option that will be sent to BE
  const preferences = generateProviderSearchPreferences()
  // Only pass member preferences if its defined / not empty (null is ok) and ensure the treatment option is valid
  const userMemberPreferences = memberPreferencesV2
  const includeMemberPreferences = userMemberPreferences !== undefined && isValidMemberPreferencesTreatmentOption
  return useMemo(() => {
    const payload: SearchRequestData = {
      lyraId,
      searchId: search?.id,
      searchTransactionId: search?.transaction_id,
      // TODO: Remove the hardcode below as the backend will handle getting this in: https://lyrahealth.atlassian.net/browse/LYRA-8610
      wordGrid: (search?.body?.questionnaires as WordGridContext)?.wordCloud1?.[
        TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING
      ],
      gender: search?.body?.gender,
      // TODO: Handle without location, i.e. direct booking
      ...locationSearchData,
      matchFor,
      isUserInternational,
      maxCnt: PROVIDER_TYPES[ProviderEnums.PROVIDER].MAX_LISTED,
      ...generateProgramSpecificDataForProviderRecommendations(),
      treatmentPreference: treatmentOption ? convertTreatmentOptionValueForBackend(treatmentOption) : '',
      ...(shouldPassSelectedCareLanguage && { preferredCareLanguageTags: [selectedCareLanguage] }),
      // Only pass member preferences if its defined / not empty (null is ok)
      ...(includeMemberPreferences && preferences),
    }

    if (ageInteger) {
      payload.age = typeof search?.body?.age === 'string' || !search?.body?.age ? ageInteger : search?.body?.age
    } else {
      payload.ageBucket = search?.body?.age
    }

    return payload
  }, [
    search?.id,
    search?.transaction_id,
    search?.body?.questionnaires,
    search?.body?.age,
    search?.body?.gender,
    ageInteger,
    locationSearchData,
    matchFor,
    isUserInternational,
    generateProgramSpecificDataForProviderRecommendations,
    treatmentOption,
    shouldPassSelectedCareLanguage,
    selectedCareLanguage,
    includeMemberPreferences,
    preferences,
    lyraId,
  ])
}
