import { defineMessage } from 'react-intl'

import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

const subpopulations: CustomerSubpopulations = {
  header: defineMessage({
    defaultMessage: 'Your Lyra access through AT&T',
    description: 'AT&T subpopulation redirection header',
  }),
  options: [
    {
      label: defineMessage({
        defaultMessage: 'Management',
        description: 'Type of AT&T employee',
      }),
      description: defineMessage({
        defaultMessage: 'I have management benefits, or am a dependent or household member of one who does.',
        description: 'AT&T employee description text',
      }),
    },
    {
      label: defineMessage({
        defaultMessage: 'Bargained',
        description: 'Type of AT&T employee',
      }),
      description: defineMessage({
        defaultMessage: 'I have bargained benefits, or am a dependent or household member of one who does.',
        description: 'AT&T employee description text',
      }),
    },
  ],
}

export const att: GetCustomerCopyFunc = () => {
  return {
    subpopulations,
  }
}
