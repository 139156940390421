import {
  CustomerCopies,
  CustomerPropertiesMap,
  EligibilityType,
  generateCustomerCopyWithProperties,
} from '@lyrahealth-inc/shared-app-logic'

import { customers } from '../../customerConstants'

/**
 * This function is called in two scenarios, initially when the user has not signed in yet
 * and again after the user has signed in and we have their eligibility type.
 *
 * The eligibility type determines specific copies from showing and won't exist if the user
 * has not signed in yet
 */
export const getCustomerCopy = (
  initialCustomerName?: string,
  customerPropertiesMap?: CustomerPropertiesMap,
  eligibilityType?: EligibilityType,
  customerDisplayName?: string,
  isProgramLevelSessionLimitEnabled: boolean = false,
): CustomerCopies => {
  const customerName = initialCustomerName ?? 'defaultCustomer'
  const customerCopy = _getCustomerCopy(
    customerName,
    customerDisplayName ?? '',
    isProgramLevelSessionLimitEnabled,
    customerPropertiesMap,
    eligibilityType,
  )
  _extendCustomerCopyWithFeatureVariations(customerName, customerCopy)

  return customerCopy
}

const _getCustomerCopy = (
  customerName: string,
  customerDisplayName: string,
  isProgramLevelSessionLimitEnabled: boolean,
  customerPropertiesMap?: CustomerPropertiesMap,
  eligibilityType?: EligibilityType,
): CustomerCopies => {
  const customer = customers[customerName] ?? { copy: {}, template: {} }
  const customerInfo = customerPropertiesMap ? customerPropertiesMap : {}
  const customerInfoPlusEligibility = { ...customerInfo, eligibilityType }
  const customerSpecificCopy = generateCustomerCopyWithProperties({
    customerName,
    copy: customer?.copy ? customer.copy : {},
    customerInfo: customerInfoPlusEligibility,
    customerDisplayName: customerDisplayName,
    isProgramLevelSessionLimitEnabled,
  })
  const templateWithCustomerSpecificCopy = generateCustomerCopyWithProperties({
    customerName,
    copy: customer?.template ? customer.template : {},
    customerInfo,
    customerDisplayName,
    isProgramLevelSessionLimitEnabled,
  })
  const defaultCustomerCopy = generateCustomerCopyWithProperties({
    customerName,
    copy: customers.defaultCustomer.copy ? customers.defaultCustomer.copy : {},
    customerInfo,
    customerDisplayName,
    isProgramLevelSessionLimitEnabled,
  })

  const customerCopy: CustomerCopies = {
    ...defaultCustomerCopy,
    ...templateWithCustomerSpecificCopy,
    ...customerSpecificCopy,
  }

  return customerCopy
}

const _extendCustomerCopyWithFeatureVariations = (
  customerName: string,
  customerCopy: CustomerCopies,
): CustomerCopies => {
  const variations = {}
  // Add copies for feature variations, for example:
  // if (customerName === CustomerName.WALMART) {
  //   variations = {
  //     isWalmartHardEligibilityCheckEnabled: _getCustomerCopy('walmart_isWalmartHardEligibilityCheckEnabled'),
  //   }
  // }
  customerCopy._FEATURE_VARIATIONS = variations

  return customerCopy
}
