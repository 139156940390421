import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FLAGS_PARAM_NAME, LANG_QUERY_PARAM } from '@lyrahealth-inc/shared-app-logic'

import { oneLine } from '../utils/stringUtils'

export const LYRA_HEALTH_NAME = 'Lyra Health'
export const CSRF_HEADER_NAME = 'CSRF-HEADER_NAME'
export const CSRF_TOKEN = 'CSRF-TOKEN'
export const MATCHER_PREFERENCE = {
  NO_PREFERENCE: { color: 'rgba(73,73,73, 0.3)' },
  ACCEPTABLE: {},
  PREFERRED: { color: '#32CD32' },
}

export const DEFAULT_CUSTOMER = 'defaultCustomer'

export const BUTTON_LABELS = {
  BOOK_PROVIDER_WITH_AVAILABILITY: 'See schedule',
  REQUEST_BOOKING_PROVIDER_WITHOUT_AVAILABILITY: 'Contact for availability',
}

export const APPOINTMENT_CONFLICTS = {
  EXISTING_COACHING_WITH_SAME_COACH_BOOKED: 'same-coach-appointment-conflict',
  EXISTING_COACHING_WITH_DIFFERENT_COACH_BOOKED: 'different-coach-appointment-conflict',
  EXISTING_BLENDED_CARE_EPISODE_OF_SAME_TYPE_WITH_SAME_PROVIDER_BOOKED: 'same-provider-appointment-conflict',
  EXISTING_BLENDED_CARE_EPISODE_OF_SAME_TYPE_WITH_DIFFERENT_PROVIDER_BOOKED: 'different-provider-appointment-conflict',
  EXISTING_SINGLE_SESSION_COACHING_EPISODE: 'existing-single-session-coaching-episode',
}

export const MEETING_FORMATS = {
  IN_PERSON: 'inPerson',
  VIDEO: 'video',
  PHONE: 'phone',
  LIVE_MESSAGING: 'live_messaging',
}

/* eslint-enable */

export const NEW_BLENDED_CARE_APPOINTMENT_INFORMATION = 'newBlendedCareAppointmentInformation'
export const DISPLAY_LANGUAGE_DISCLOSURE_DISMISSED = 'displayLanguageDisclosureDismissed'
export const DISPLAY_APPOINTMENT_BOOKING_SUCCESS = 'displayNewAppointmentSuccessBanner'
export const HAS_CONSENTED_TO_SITE_COOKIES = 'hasConsentedToSiteCookies'

export const sumologicConfig =
  process.env.NODE_ENV === 'production'
    ? {
        endpoint:
          'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV2Gz5VAJPKZF0I6hqZv9KI-ZHGC2pjo5vRoygvkx7JG6lkT6RJS5d38slVyCuGLh4bFwj__ugx8MAqfLFe5RJU-KuARiLfmAS8mrBeohSO_ZA==',
        hostName: window.location.hostname,
      } // Production config
    : {
        endpoint:
          'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV2Xaz5OcXN4-4D4qRVgvgdWWU5N6Ys5c1HMzr_NTUqX9FE-ZPSk7KvYAuf7EPpNqOrATtaQe9XZsxjVRI85VUBuUoqLZA9t__mdE3RoRr-xPg==',
        hostName: window.location.hostname,
      } // Staging config

export const careToolProviderSearchFilter = [
  {
    title: 'Gender',
    values: [
      { filterKey: 'genders', filterValue: 'Man', filterName: 'Man' },
      { filterKey: 'genders', filterValue: 'Woman', filterName: 'Woman' },
      { filterKey: 'genders', filterValue: 'Non-binary', filterName: 'Non-binary' },
      { filterKey: 'genders', filterValue: 'Agender', filterName: 'Agender' },
      { filterKey: 'genders', filterValue: 'Gender non-conforming', filterName: 'Gender non-conforming' },
      { filterKey: 'genders', filterValue: 'Genderqueer', filterName: 'Genderqueer' },
      { filterKey: 'genders', filterValue: 'Intersex', filterName: 'Intersex' },
      { filterKey: 'genders', filterValue: 'Two-spirit', filterName: 'Two-spirit' },
      { filterKey: 'genders', filterValue: 'Prefer to self-describe', filterName: 'Prefer to self-describe' },
      { filterKey: 'genders', filterValue: 'Prefer not to state', filterName: 'Prefer not to state' },
    ],
  },
  {
    title: 'Credentials',
    values: [
      { filterKey: 'credentials', filterValue: 'LCSW', filterName: 'LCSW' },
      { filterKey: 'credentials', filterValue: 'LMFT', filterName: 'LMFT' },
      { filterKey: 'credentials', filterValue: 'PhD', filterName: 'PhD' },
      { filterKey: 'credentials', filterValue: 'PsyD', filterName: 'PsyD' },
    ],
  },
  {
    title: 'Provider Type',
    values: [
      { filterKey: 'lyraType', filterValue: 'LyraPrescriber', filterName: 'LC Prescriber' },
      { filterKey: 'lyraType', filterValue: 'LyraTherapist', filterName: 'LC Therapist' },
    ],
  },
  {
    title: 'Suicidality',
    values: [
      { filterKey: 'activeSuicidality', filterValue: 'true', filterName: 'SI Positive' },
      { filterKey: 'neutralSuicidality', filterValue: 'true', filterName: 'SI Neutral' },
    ],
  },
  {
    title: 'Conditions',
    values: [
      { filterKey: 'conditions', filterValue: 'Gender Dysphoria', filterName: 'Gender Dysphoria' },
      { filterKey: 'conditions', filterValue: 'Self-Harm', filterName: 'Self-Harm' },
      { filterKey: 'conditions', filterValue: 'Sexual Orientation', filterName: 'Sexual Orientation' },
      { filterKey: 'conditions', filterValue: 'Alcohol Use', filterName: 'Alcohol Use' },
      { filterKey: 'conditions', filterValue: 'Drug Use', filterName: 'Drug Use' },
      { filterKey: 'conditions', filterValue: 'Cannabis Use', filterName: 'Cannabis Use' },
      { filterKey: 'conditions', filterValue: 'Trauma and PTSD', filterName: 'Trauma and PTSD' },
    ],
  },
  {
    title: 'Ethnicities',
    values: [
      { filterKey: 'providerEthnicity', filterValue: 'White', filterName: 'White' },
      {
        filterKey: 'providerEthnicity',
        filterValue: 'Black or African American',
        filterName: 'Black or African American',
      },
      { filterKey: 'providerEthnicity', filterValue: 'Asian', filterName: 'Asian' },
      { filterKey: 'providerEthnicity', filterValue: 'Hispanic or Latino', filterName: 'Hispanic or Latino' },
      { filterKey: 'providerEthnicity', filterValue: 'Native American', filterName: 'Native American' },
      {
        filterKey: 'providerEthnicity',
        filterValue: 'Native Hawaiian or Pacific Islander',
        filterName: 'Native Hawaiian or Pacific Islander',
      },
      { filterKey: 'providerEthnicity', filterValue: 'Two or More', filterName: 'Two or More' },
      { filterKey: 'providerEthnicity', filterValue: 'Other', filterName: 'Other' },
      {
        filterKey: 'providerEthnicity',
        filterValue: 'Prefer to self-describe',
        filterName: 'Prefer to self-describe',
      },
      { filterKey: 'providerEthnicity', filterValue: 'Prefer not to state', filterName: 'Prefer not to state' },
    ],
  },
  {
    title: 'Experience',
    values: [
      { filterKey: 'yearsOfExperience', filterValue: '< 5 years', filterName: '< 5 years' },
      { filterKey: 'yearsOfExperience', filterValue: '5-10 years', filterName: '5-10 years' },
      { filterKey: 'yearsOfExperience', filterValue: '10-15 years', filterName: '10-15 years' },
      { filterKey: 'yearsOfExperience', filterValue: '15-20 years', filterName: '15-20 years' },
      { filterKey: 'yearsOfExperience', filterValue: '> 20 years', filterName: '> 20 years' },
    ],
  },
  {
    title: 'Video',
    values: [
      { filterKey: 'videoIndividual', filterValue: 'true', filterName: 'Individual' },
      { filterKey: 'videoChild', filterValue: 'true', filterName: 'Child' },
      { filterKey: 'videoCouples', filterValue: 'true', filterName: 'Couples' },
    ],
  },
  { title: 'Calendar', values: [{ filterKey: 'isCalendarConnected', filterValue: 'true', filterName: 'Connected' }] },
  {
    title: 'Languages',
    values: [
      { filterKey: 'languages', filterValue: 'Chinese', filterName: 'Chinese' },
      { filterKey: 'languages', filterValue: 'Russian', filterName: 'Russian' },
      { filterKey: 'languages', filterValue: 'Spanish', filterName: 'Spanish' },
    ],
  },
  {
    title: 'Treatments',
    values: [
      { filterKey: 'treatments', filterValue: 'ACT', filterName: 'ACT' },
      { filterKey: 'treatments', filterValue: 'Behavior Modification', filterName: 'Behavior Modification' },
      { filterKey: 'treatments', filterValue: 'CBT-I', filterName: 'CBT-I' },
      { filterKey: 'treatments', filterValue: 'CPT', filterName: 'CPT' },
      { filterKey: 'treatments', filterValue: 'DBT', filterName: 'DBT' },
      { filterKey: 'treatments', filterValue: 'EFT', filterName: 'EFT' },
      { filterKey: 'treatments', filterValue: 'EMDR', filterName: 'EMDR' },
      { filterKey: 'treatments', filterValue: 'ERP', filterName: 'ERP' },
      { filterKey: 'treatments', filterValue: 'Gottman', filterName: 'Gottman' },
      { filterKey: 'treatments', filterValue: 'MBCT', filterName: 'MBCT' },
      { filterKey: 'treatments', filterValue: 'Prolonged Exposure', filterName: 'Prolonged Exposure' },
    ],
  },
  {
    title: 'Racial Stress',
    values: [{ filterKey: 'racialStressPreferred', filterValue: 'true', filterName: 'Specializes in racial stress' }],
  },
  {
    title: 'Provider Transgender',
    values: [{ filterKey: 'providerTransgender', filterValue: 'true', filterName: 'Provider Transgender' }],
  },
  {
    title: 'Provider Sexual Orientation',
    values: [
      { filterKey: 'providerSexualOrientation', filterValue: 'Asexual', filterName: 'Asexual' },
      { filterKey: 'providerSexualOrientation', filterValue: 'Bisexual/Pansexual', filterName: 'Bisexual/Pansexual' },
      { filterKey: 'providerSexualOrientation', filterValue: 'Lesbian', filterName: 'Lesbian' },
      { filterKey: 'providerSexualOrientation', filterValue: 'Gay', filterName: 'Gay' },
      {
        filterKey: 'providerSexualOrientation',
        filterValue: 'Heterosexual/Straight',
        filterName: 'Heterosexual/Straight',
      },
      {
        filterKey: 'providerSexualOrientation',
        filterValue: 'Prefer to self-describe',
        filterName: 'Prefer to self-describe',
      },
      { filterKey: 'providerSexualOrientation', filterValue: 'Prefer not to state', filterName: 'Prefer not to state' },
      {
        filterKey: 'providerSexualOrientation',
        filterValue: 'Does not apply',
        filterName: 'Does not apply',
      },
    ],
  },
  {
    title: 'Religions',
    values: [
      { filterKey: 'religions', filterValue: 'Christian', filterName: 'Christian' },
      { filterKey: 'religions', filterValue: 'Jewish', filterName: 'Jewish' },
      { filterKey: 'religions', filterValue: 'Hindu', filterName: 'Hindu' },
      {
        filterKey: 'religions',
        filterValue: 'Mormon (Church of Jesus Christ/LDS)',
        filterName: 'Mormon (Church of Jesus Christ/LDS)',
      },
      { filterKey: 'religions', filterValue: 'Buddhist', filterName: 'Buddhist' },
      { filterKey: 'religions', filterValue: 'Muslim', filterName: 'Muslim' },
      {
        filterKey: 'religions',
        filterValue: 'Non-religious/atheist/agnostic',
        filterName: 'Non-religious/atheist/agnostic',
      },
    ],
  },
]

export const DEFAULT_CANCELLATION_WINDOW_HOURS = 24

export const ATTENDANCE_STATUSES = {
  attended: (
    <FormattedMessage
      defaultMessage='Attended'
      description='Attendance event status for user showcasing the user attended the session.'
    />
  ),
  last_minute_cancellation: (
    <FormattedMessage
      defaultMessage='Last Minute Cancellation'
      description='Attendance event status for user showcasing the user cancelled the session last minute.'
    />
  ),
  no_show: (
    <FormattedMessage
      defaultMessage='No Show'
      description='Attendance event status for user showcasing the user did not show up for the session.'
    />
  ),
}

export const PAYMENT_CARD_TYPES = {
  CREDIT_DEBIT: 'credit_debit',
  FSA_HSA: 'fsa_hsa',
  PREPAID: 'prepaid',
  UNKNOWN: 'unknown',
  OTHER: 'other',
}

export const PAYMENT_CARD_TYPES_DISPLAY = {
  [PAYMENT_CARD_TYPES.CREDIT_DEBIT]: 'Credit/Debit',
  [PAYMENT_CARD_TYPES.FSA_HSA]: 'FSA/HSA',
  [PAYMENT_CARD_TYPES.PREPAID]: 'Prepaid',
  [PAYMENT_CARD_TYPES.OTHER]: 'Other',
  [PAYMENT_CARD_TYPES.UNKNOWN]: 'Unknown',
}

/**
 * Default services that Lyra does not cover.
 * You should only be importing this if you need this list in a custom string, e.g. a custom "servicesNotCovered" string
 * that doesn't match the content of the servicesNotCovered string here. Otherwise, you probably want to be importing
 * servicesNotCovered instead.
 * If your customer has a custom "servicesNotCovered" string and doesn't cover meds, check defaultWithoutMeds.js
 */
export const excludedServices = oneLine(
  `inpatient or residential treatment, hospitalization (including partial),
  intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication,
  autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care`,
)

export const APP_ID = 'Lyraweb'
export const APP_PLATFORM = 'web'

export const VENDOR_API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://vendorapi.lyrahealth.com'
    : 'https://vendorapi.staging.lyrahealth.com'

export const PROVIDER_EXPLORE_EXPERIENCES = {
  BLENDED_CARE_MEDS: 'blended_care',
  GENERIC: 'generic',
  AUD: 'aud',
}

export const INTERNATIONAL_CONSENT_USER_STATUS = {
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  NONE: 'None',
}

export const POINT_OF_REQUEST = {
  DIRECT_LINK: 'direct_link',
  TRIAGE: 'triage',
}

// eslint-disable-next-line formatjs/no-literal-string-in-jsx
export const careTeamEmailLink = <a href='mailto:care@lyrahealth.com'>care@lyrahealth.com</a>

export const clientSessionContainerId = 'clientSession'

export enum SelfCareResourceConditions {
  INTERNATIONAL_ONLY = 'INTERNATIONAL_ONLY',
  DOMESTIC_ONLY = 'DOMESTIC_ONLY',
}

// iframe post messages
export const SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE = 'LW_SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE'
export const GET_SIGN_UP_BUTTON_WIDTH_MESSAGE = 'LW_GET_SIGN_UP_BUTTON_WIDTH_MESSAGE'
export const GET_APPLE_SIGN_UP_ERROR = 'LW_GET_APPLE_SIGN_UP_ERROR'
export const GET_APPLE_SIGN_UP_TOKEN = 'LW_GET_APPLE_SIGN_UP_TOKEN'
export const GET_GOOGLE_SIGN_UP_TOKEN = 'LW_GET_GOOGLE_SIGN_UP_TOKEN'
export const CLICK_SSO_SIGN_UP_BUTTON = 'LW_CLICK_SSO_SIGN_UP_BUTTON'

/**
 * List of global query params that should be preserved in the URL across route changes
 */
export const STICKY_QUERY_PARAMS = [
  LANG_QUERY_PARAM, // Override the app language
  FLAGS_PARAM_NAME, // Override feature flags (via useFlags)
] as const

export const ATTESTATION_VERIFICATION = 'attestationVerification'
export const MOBILE_PROMO_DISMISSED = 'mobilePromoDismissed'

export const INACTIVITY_CHECK_INTERVAL_MS = 500
export const USER_ACTIVITY_THROTTLE_MS = 30 * 1000

export const ERROR_CODES = {
  UNPROCESSABLE_ENTITY: 422,
}

export const COST_SHARE_TERMS_LINK = 'https://www.lyrahealth.com/cost-share-terms-conditions/'
export const LYRA_MARKETING_LAUNCH_DAY_REMINDER_LINK = 'https://cloud.mc.lyrahealth.com/launch_date_reminder_signup'

export enum MICROSITE_IFRAME_MESSAGES {
  SET_CUSTOM_MESSAGES = 'setCustomMessages',
  DOCUMENT_OBJECT = 'Document Object',
  IFRAME_READY = 'iframeReady',
}

// Registry of messages sent by Bukwild to LW using window.postMessage
export enum ESSENTIALS_IFRAME_MESSAGES {
  MODAL_CLOSED = 'modalClosed', // example: { event: 'modalClosed' }
  MODAL_OPENED = 'modalOpened', // example: { event: 'modalOpened' }
  ROUTE_CHANGE = 'routeChange', // example: { event: 'routeChange, uri: '/articles/the-name-of-the-article' }
  NAVIGATE_TO_PARENT_APP_ROUTE = 'navigateToParentAppRoute',
  NAVIGATE_TO_SEARCH_FOR_CARE = 'navigate to search for care',
  CONTENT_PLAYING = 'content playing', // sent in 20s intervals while Essentials media is playing to prevent inactive log out
  CREATE_COURSE_COMPLETION_CERTIFICATE = 'create course completion certificate', // example: { event: "create course completion certificate", courseName: "Tackling Mental Health Stigma", courseCompletionDate: Date object }
  REGISTER_FOR_EVENT = 'registerForEvent', // example: {event: 'registerForEvent', zoom_event_id: '12345', zoom_event_type: 'MEETING'}
  DEREGISTER_FOR_EVENT = 'deregisterForEvent', // example: {event: 'deregisterForEvent', zoom_event_id: '12345', zoom_event_type: 'MEETING', registrant_id: '12928139218'}
  EVENT_REGISTERED_COUNT = 'registeredCount', // example: {event: 'registeredCount', events: [{zoom_event_id: '12345', zoom_event_type: 'MEETING'}]}
  NAVIGATE_BETWEEN_LIBRARY_APPS = 'navigate between library apps', // {event: 'navigate between library apps', url: <workhub or essentials url>}
  NAVIGATE_TO_COACHING_DIRECT_PATH = 'direct path to coaching', // example: { event: 'direct path to coaching', coachingUrl: https://lyra.app.link/directPathBooking?directPath=true&clientele=Individual&treatment=Coaching&partner=BlendedCare&offering=Default&directLinkIntent=DIRECT_LINK_WORKPLACE&$fallback_url=https://{customer.lyrahealth.com}/secure/onboard/match-location }
  /** Send mixpanel properties under `props` to Goodness to be sent from events fired by Goodness */
  SET_MIXPANEL_PROPS = 'setMixpanelProps',
  /** Remove specific properties from mixpanel by sending the prop keys under `props` */
  UNSET_MIXPANEL_PROPS = 'unsetMixpanelProps',
  /** Removes all super props set in mixpanel */
  RESET_MIXPANEL_PROPS = 'resetMixpanelProps',
  /** Essentials iframe is ready. Should be more reliable than iFrame onLoad */
  IFRAME_APP_READY = 'iframeAppReady',
}

export enum LOCAL_STORAGE_KEY {
  TOOLKIT_ESSENTIALS_CONTENTS = 'toolkitEssentialsContents',
  NOTIFICATIONS_FEED_DISMISSED = 'notificationsFeedDismissed',
  WELLNESS_CHECK_IN = 'wellnessCheckIn',
  ANIMATE_MENU_BADGE = 'animateMenuBadge',
}

export const IS_SESSION_TIMER_DISABLED = 'isSessionTimerDisabled'
export const TAB_ID = 'tabId'
