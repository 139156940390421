import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill'
import { match } from '@formatjs/intl-localematcher'
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'

export async function loadLocaleData(locale: string, checkLocaleImported = true) {
  const promises = []

  if ((pluralRules.hasOwnProperty(locale) && checkLocaleImported) || shouldPolyfillPluralRules(locale)) {
    promises.push(getPolyfill(pluralRules, locale, 'pluralRules'))
  }
  if ((displayNames.hasOwnProperty(locale) && checkLocaleImported) || !(Intl as any).DisplayNames) {
    promises.push(getPolyfill(displayNames, locale, 'displayNames'))
  }
  if ((listFormat.hasOwnProperty(locale) && checkLocaleImported) || shouldPolyfillListFormat(locale)) {
    promises.push(getPolyfill(listFormat, locale, 'listFormat'))
  }
  if ((numberFormat.hasOwnProperty(locale) && checkLocaleImported) || shouldPolyfillNumberFormat(locale)) {
    promises.push(getPolyfill(numberFormat, locale, 'numberFormat'))
  }
  if ((relativeTimeFormat.hasOwnProperty(locale) && checkLocaleImported) || shouldPolyfillRelativeTimeFormat(locale)) {
    promises.push(getPolyfill(relativeTimeFormat, locale, 'relativeTimeFormat'))
  }
  if ((dateTimeFormat.hasOwnProperty(locale) && checkLocaleImported) || shouldPolyfillDateTimeFormat(locale)) {
    promises.push(getPolyfill(dateTimeFormat, locale, 'dateTimeFormat'))
  }

  await Promise.allSettled(promises)
}

function getPolyfill(obj: Record<string, () => Promise<any>>, locale: string, debugString: string): Promise<any> {
  // shouldPolyfill(locale) usually matches the input locale to the available locales and returns the result,
  // but since we currently cannot dynamically fetch the modules individually,
  // match here against a subset of the locales that we bundled, listed below
  const key = match([locale], Object.keys(obj), 'en')
  console.debug(`Loading locale data for ${debugString}: locale ${locale} matched ${key}`)
  return obj[key]()
}

/** For mobile: In release builds, these `import()`s are bundled; they are not code-split.
 * See https://facebook.github.io/metro/docs/module-api/#import-dynamic-import
 * Dynamic import expressions like `import('../locale-data/${locale}`)` are not supported,
 * so list the subset of locales we need here.
 * */

// See https://github.com/formatjs/formatjs/blob/main/packages/intl-pluralrules/supported-locales.generated.ts
const pluralRules = {
  bg: () => import(/* webpackChunkName: "polyfill_pluralrules_bg" */ '@formatjs/intl-pluralrules/locale-data/bg'),
  cs: () => import(/* webpackChunkName: "polyfill_pluralrules_cs" */ '@formatjs/intl-pluralrules/locale-data/cs'),
  da: () => import(/* webpackChunkName: "polyfill_pluralrules_da" */ '@formatjs/intl-pluralrules/locale-data/da'),
  de: () => import(/* webpackChunkName: "polyfill_pluralrules_de" */ '@formatjs/intl-pluralrules/locale-data/de'),
  el: () => import(/* webpackChunkName: "polyfill_pluralrules_el" */ '@formatjs/intl-pluralrules/locale-data/el'),
  en: () => import(/* webpackChunkName: "polyfill_pluralrules_en" */ '@formatjs/intl-pluralrules/locale-data/en'),
  es: () => import(/* webpackChunkName: "polyfill_pluralrules_es" */ '@formatjs/intl-pluralrules/locale-data/es'),
  fi: () => import(/* webpackChunkName: "polyfill_pluralrules_fi" */ '@formatjs/intl-pluralrules/locale-data/fi'),
  fil: () => import(/* webpackChunkName: "polyfill_pluralrules_fil" */ '@formatjs/intl-pluralrules/locale-data/fil'),
  fr: () => import(/* webpackChunkName: "polyfill_pluralrules_fr" */ '@formatjs/intl-pluralrules/locale-data/fr'),
  hi: () => import(/* webpackChunkName: "polyfill_pluralrules_hi" */ '@formatjs/intl-pluralrules/locale-data/hi'),
  hu: () => import(/* webpackChunkName: "polyfill_pluralrules_hu" */ '@formatjs/intl-pluralrules/locale-data/hu'),
  id: () => import(/* webpackChunkName: "polyfill_pluralrules_id" */ '@formatjs/intl-pluralrules/locale-data/id'),
  it: () => import(/* webpackChunkName: "polyfill_pluralrules_it" */ '@formatjs/intl-pluralrules/locale-data/it'),
  ja: () => import(/* webpackChunkName: "polyfill_pluralrules_ja" */ '@formatjs/intl-pluralrules/locale-data/ja'),
  ko: () => import(/* webpackChunkName: "polyfill_pluralrules_ko" */ '@formatjs/intl-pluralrules/locale-data/ko'),
  ms: () => import(/* webpackChunkName: "polyfill_pluralrules_ms" */ '@formatjs/intl-pluralrules/locale-data/ms'),
  nb: () => import(/* webpackChunkName: "polyfill_pluralrules_nb" */ '@formatjs/intl-pluralrules/locale-data/nb'),
  nl: () => import(/* webpackChunkName: "polyfill_pluralrules_nl" */ '@formatjs/intl-pluralrules/locale-data/nl'),
  pl: () => import(/* webpackChunkName: "polyfill_pluralrules_pl" */ '@formatjs/intl-pluralrules/locale-data/pl'),
  pt: () => import(/* webpackChunkName: "polyfill_pluralrules_pt" */ '@formatjs/intl-pluralrules/locale-data/pt'),
  'pt-PT': () =>
    import(/* webpackChunkName: "polyfill_pluralrules_pt_pt" */ '@formatjs/intl-pluralrules/locale-data/pt-PT'),
  ro: () => import(/* webpackChunkName: "polyfill_pluralrules_ro" */ '@formatjs/intl-pluralrules/locale-data/ro'),
  sv: () => import(/* webpackChunkName: "polyfill_pluralrules_sv" */ '@formatjs/intl-pluralrules/locale-data/sv'),
  th: () => import(/* webpackChunkName: "polyfill_pluralrules_th" */ '@formatjs/intl-pluralrules/locale-data/th'),
  tr: () => import(/* webpackChunkName: "polyfill_pluralrules_tr" */ '@formatjs/intl-pluralrules/locale-data/tr'),
  vi: () => import(/* webpackChunkName: "polyfill_pluralrules_vi" */ '@formatjs/intl-pluralrules/locale-data/vi'),
  zh: () => import(/* webpackChunkName: "polyfill_pluralrules_zh" */ '@formatjs/intl-pluralrules/locale-data/zh'),
}

// See https://github.com/formatjs/formatjs/blob/main/packages/intl-displaynames/supported-locales.generated.ts
const displayNames = {
  bg: () => import(/* webpackChunkName: "polyfill_displaynames_bg" */ '@formatjs/intl-displaynames/locale-data/bg'),
  cs: () => import(/* webpackChunkName: "polyfill_displaynames_cs" */ '@formatjs/intl-displaynames/locale-data/cs'),
  da: () => import(/* webpackChunkName: "polyfill_displaynames_da" */ '@formatjs/intl-displaynames/locale-data/da'),
  // da-GL available
  de: () => import(/* webpackChunkName: "polyfill_displaynames_de" */ '@formatjs/intl-displaynames/locale-data/de'),
  el: () => import(/* webpackChunkName: "polyfill_displaynames_el" */ '@formatjs/intl-displaynames/locale-data/el'),
  // el-CY available
  en: () => import(/* webpackChunkName: "polyfill_displaynames_en" */ '@formatjs/intl-displaynames/locale-data/en'),
  'en-AU': () =>
    import(/* webpackChunkName: "polyfill_displaynames_en-au" */ '@formatjs/intl-displaynames/locale-data/en-AU'),
  'en-CA': () =>
    import(/* webpackChunkName: "polyfill_displaynames_en-ca" */ '@formatjs/intl-displaynames/locale-data/en-CA'),
  'en-GB': () =>
    import(/* webpackChunkName: "polyfill_displaynames_en-gb" */ '@formatjs/intl-displaynames/locale-data/en-GB'),
  'en-IE': () =>
    import(/* webpackChunkName: "polyfill_displaynames_en-ie" */ '@formatjs/intl-displaynames/locale-data/en-IE'),
  'en-NZ': () =>
    import(/* webpackChunkName: "polyfill_displaynames_en-nz" */ '@formatjs/intl-displaynames/locale-data/en-NZ'),
  // other en-* are available
  es: () => import(/* webpackChunkName: "polyfill_displaynames_es" */ '@formatjs/intl-displaynames/locale-data/es'),
  'es-419': () =>
    import(/* webpackChunkName: "polyfill_displaynames_es-419" */ '@formatjs/intl-displaynames/locale-data/es-419'),
  // excluded other available es-<country> locales, potentially relevant
  fi: () => import(/* webpackChunkName: "polyfill_displaynames_fi" */ '@formatjs/intl-displaynames/locale-data/fi'),
  fil: () => import(/* webpackChunkName: "polyfill_displaynames_fil" */ '@formatjs/intl-displaynames/locale-data/fil'),
  fr: () => import(/* webpackChunkName: "polyfill_displaynames_fr" */ '@formatjs/intl-displaynames/locale-data/fr'),
  'fr-CA': () =>
    import(/* webpackChunkName: "polyfill_displaynames_fr-ca" */ '@formatjs/intl-displaynames/locale-data/fr-CA'),
  hi: () => import(/* webpackChunkName: "polyfill_displaynames_hi" */ '@formatjs/intl-displaynames/locale-data/hi'),
  hu: () => import(/* webpackChunkName: "polyfill_displaynames_hu" */ '@formatjs/intl-displaynames/locale-data/hu'),
  id: () => import(/* webpackChunkName: "polyfill_displaynames_id" */ '@formatjs/intl-displaynames/locale-data/id'),
  it: () => import(/* webpackChunkName: "polyfill_displaynames_it" */ '@formatjs/intl-displaynames/locale-data/it'),
  ja: () => import(/* webpackChunkName: "polyfill_displaynames_ja" */ '@formatjs/intl-displaynames/locale-data/ja'),
  ko: () => import(/* webpackChunkName: "polyfill_displaynames_ko" */ '@formatjs/intl-displaynames/locale-data/ko'),
  ms: () => import(/* webpackChunkName: "polyfill_displaynames_ms" */ '@formatjs/intl-displaynames/locale-data/ms'),
  nb: () => import(/* webpackChunkName: "polyfill_displaynames_nb" */ '@formatjs/intl-displaynames/locale-data/nb'),
  // nb-SJ available
  nl: () => import(/* webpackChunkName: "polyfill_displaynames_nl" */ '@formatjs/intl-displaynames/locale-data/nl'),
  pl: () => import(/* webpackChunkName: "polyfill_displaynames_pl" */ '@formatjs/intl-displaynames/locale-data/pl'),
  pt: () => import(/* webpackChunkName: "polyfill_displaynames_pt" */ '@formatjs/intl-displaynames/locale-data/pt'),
  'pt-PT': () =>
    import(/* webpackChunkName: "polyfill_displaynames_pt-pt" */ '@formatjs/intl-displaynames/locale-data/pt-PT'),
  ro: () => import(/* webpackChunkName: "polyfill_displaynames_ro" */ '@formatjs/intl-displaynames/locale-data/ro'),
  // `ro-MD` Moldova available
  sv: () => import(/* webpackChunkName: "polyfill_displaynames_sv" */ '@formatjs/intl-displaynames/locale-data/sv'),
  // sv-AX, sv-FI available
  th: () => import(/* webpackChunkName: "polyfill_displaynames_th" */ '@formatjs/intl-displaynames/locale-data/th'),
  tr: () => import(/* webpackChunkName: "polyfill_displaynames_tr" */ '@formatjs/intl-displaynames/locale-data/tr'),
  // `tr-CY` Turkish (Cyprus) available
  vi: () => import(/* webpackChunkName: "polyfill_displaynames_vi" */ '@formatjs/intl-displaynames/locale-data/vi'),
  zh: () => import(/* webpackChunkName: "polyfill_displaynames_zh" */ '@formatjs/intl-displaynames/locale-data/zh'),
  'zh-Hans': () =>
    import(/* webpackChunkName: "polyfill_displaynames_zh-hans" */ '@formatjs/intl-displaynames/locale-data/zh-Hans'),
  'zh-Hans-HK': () =>
    import(
      /* webpackChunkName: "polyfill_displaynames_zh-hans-hk" */ '@formatjs/intl-displaynames/locale-data/zh-Hans-HK'
    ),
  'zh-Hant': () =>
    import(/* webpackChunkName: "polyfill_displaynames_zh-hant" */ '@formatjs/intl-displaynames/locale-data/zh-Hans'),
  'zh-Hant-HK': () =>
    import(
      /* webpackChunkName: "polyfill_displaynames_zh-hant-hk" */ '@formatjs/intl-displaynames/locale-data/zh-Hans-HK'
    ),
  // other zh-* are available
}

// See https://github.com/formatjs/formatjs/blob/main/packages/intl-listformat/supported-locales.generated.ts
const listFormat = {
  bg: () => import(/* webpackChunkName: "polyfill_listformat_bg" */ '@formatjs/intl-listformat/locale-data/bg'),
  cs: () => import(/* webpackChunkName: "polyfill_listformat_cs" */ '@formatjs/intl-listformat/locale-data/cs'),
  da: () => import(/* webpackChunkName: "polyfill_listformat_da" */ '@formatjs/intl-listformat/locale-data/da'),
  // da-GL available
  de: () => import(/* webpackChunkName: "polyfill_listformat_de" */ '@formatjs/intl-listformat/locale-data/de'),
  el: () => import(/* webpackChunkName: "polyfill_listformat_el" */ '@formatjs/intl-listformat/locale-data/el'),
  // el-CY available
  en: () => import(/* webpackChunkName: "polyfill_listformat_en" */ '@formatjs/intl-listformat/locale-data/en'),
  'en-AU': () =>
    import(/* webpackChunkName: "polyfill_listformat_en-au" */ '@formatjs/intl-listformat/locale-data/en-AU'),
  'en-CA': () =>
    import(/* webpackChunkName: "polyfill_listformat_en-ca" */ '@formatjs/intl-listformat/locale-data/en-CA'),
  'en-GB': () =>
    import(/* webpackChunkName: "polyfill_listformat_en-gb" */ '@formatjs/intl-listformat/locale-data/en-GB'),
  'en-IE': () =>
    import(/* webpackChunkName: "polyfill_listformat_en-ie" */ '@formatjs/intl-listformat/locale-data/en-IE'),
  'en-NZ': () =>
    import(/* webpackChunkName: "polyfill_listformat_en-nz" */ '@formatjs/intl-listformat/locale-data/en-NZ'),
  // other en-* are available
  es: () => import(/* webpackChunkName: "polyfill_listformat_es" */ '@formatjs/intl-listformat/locale-data/es'),
  'es-419': () =>
    import(/* webpackChunkName: "polyfill_listformat_es-419" */ '@formatjs/intl-listformat/locale-data/es-419'),
  // excluded other available es-<country> locales, potentially relevant
  fi: () => import(/* webpackChunkName: "polyfill_listformat_fi" */ '@formatjs/intl-listformat/locale-data/fi'),
  fil: () => import(/* webpackChunkName: "polyfill_listformat_fil" */ '@formatjs/intl-listformat/locale-data/fil'),
  fr: () => import(/* webpackChunkName: "polyfill_listformat_fr" */ '@formatjs/intl-listformat/locale-data/fr'),
  'fr-CA': () =>
    import(/* webpackChunkName: "polyfill_listformat_fr-ca" */ '@formatjs/intl-listformat/locale-data/fr-CA'),
  hi: () => import(/* webpackChunkName: "polyfill_listformat_hi" */ '@formatjs/intl-listformat/locale-data/hi'),
  hu: () => import(/* webpackChunkName: "polyfill_listformat_hu" */ '@formatjs/intl-listformat/locale-data/hu'),
  id: () => import(/* webpackChunkName: "polyfill_listformat_id" */ '@formatjs/intl-listformat/locale-data/id'),
  it: () => import(/* webpackChunkName: "polyfill_listformat_it" */ '@formatjs/intl-listformat/locale-data/it'),
  ja: () => import(/* webpackChunkName: "polyfill_listformat_ja" */ '@formatjs/intl-listformat/locale-data/ja'),
  ko: () => import(/* webpackChunkName: "polyfill_listformat_ko" */ '@formatjs/intl-listformat/locale-data/ko'),
  ms: () => import(/* webpackChunkName: "polyfill_listformat_ms" */ '@formatjs/intl-listformat/locale-data/ms'),
  nb: () => import(/* webpackChunkName: "polyfill_listformat_nb" */ '@formatjs/intl-listformat/locale-data/nb'),
  // nb-SJ available
  nl: () => import(/* webpackChunkName: "polyfill_listformat_nl" */ '@formatjs/intl-listformat/locale-data/nl'),
  pl: () => import(/* webpackChunkName: "polyfill_listformat_pl" */ '@formatjs/intl-listformat/locale-data/pl'),
  pt: () => import(/* webpackChunkName: "polyfill_listformat_pt" */ '@formatjs/intl-listformat/locale-data/pt'),
  'pt-PT': () =>
    import(/* webpackChunkName: "polyfill_listformat_pt-pt" */ '@formatjs/intl-listformat/locale-data/pt-PT'),
  ro: () => import(/* webpackChunkName: "polyfill_listformat_ro" */ '@formatjs/intl-listformat/locale-data/ro'),
  // `ro-MD` Moldova available
  sv: () => import(/* webpackChunkName: "polyfill_listformat_sv" */ '@formatjs/intl-listformat/locale-data/sv'),
  // sv-AX, sv-FI available
  th: () => import(/* webpackChunkName: "polyfill_listformat_th" */ '@formatjs/intl-listformat/locale-data/th'),
  tr: () => import(/* webpackChunkName: "polyfill_listformat_tr" */ '@formatjs/intl-listformat/locale-data/tr'),
  // `tr-CY` Turkish (Cyprus) available
  vi: () => import(/* webpackChunkName: "polyfill_listformat_vi" */ '@formatjs/intl-listformat/locale-data/vi'),
  zh: () => import(/* webpackChunkName: "polyfill_listformat_zh" */ '@formatjs/intl-listformat/locale-data/zh'),
  'zh-Hans': () =>
    import(/* webpackChunkName: "polyfill_listformat_zh-hans" */ '@formatjs/intl-listformat/locale-data/zh-Hans'),
  'zh-Hans-HK': () =>
    import(/* webpackChunkName: "polyfill_listformat_zh-hans-hk" */ '@formatjs/intl-listformat/locale-data/zh-Hans-HK'),
  'zh-Hant': () =>
    import(/* webpackChunkName: "polyfill_listformat_zh-hant" */ '@formatjs/intl-listformat/locale-data/zh-Hans'),
  'zh-Hant-HK': () =>
    import(/* webpackChunkName: "polyfill_listformat_zh-hant-hk" */ '@formatjs/intl-listformat/locale-data/zh-Hans-HK'),
  // other zh-* are available
}

// See https://github.com/formatjs/formatjs/blob/main/packages/intl-numberformat/supported-locales.generated.ts
const numberFormat = {
  bg: () => import(/* webpackChunkName: "polyfill_numberformat_bg" */ '@formatjs/intl-numberformat/locale-data/bg'),
  cs: () => import(/* webpackChunkName: "polyfill_numberformat_cs" */ '@formatjs/intl-numberformat/locale-data/cs'),
  da: () => import(/* webpackChunkName: "polyfill_numberformat_da" */ '@formatjs/intl-numberformat/locale-data/da'),
  de: () => import(/* webpackChunkName: "polyfill_numberformat_de" */ '@formatjs/intl-numberformat/locale-data/de'),
  el: () => import(/* webpackChunkName: "polyfill_numberformat_el" */ '@formatjs/intl-numberformat/locale-data/el'),
  // el-CY available
  en: () => import(/* webpackChunkName: "polyfill_numberformat_en" */ '@formatjs/intl-numberformat/locale-data/en'),
  'en-AU': () =>
    import(/* webpackChunkName: "polyfill_numberformat_en-au" */ '@formatjs/intl-numberformat/locale-data/en-AU'),
  'en-CA': () =>
    import(/* webpackChunkName: "polyfill_numberformat_en-ca" */ '@formatjs/intl-numberformat/locale-data/en-CA'),
  'en-GB': () =>
    import(/* webpackChunkName: "polyfill_numberformat_en-gb" */ '@formatjs/intl-numberformat/locale-data/en-GB'),
  'en-IE': () =>
    import(/* webpackChunkName: "polyfill_numberformat_en-ie" */ '@formatjs/intl-numberformat/locale-data/en-IE'),
  'en-NZ': () =>
    import(/* webpackChunkName: "polyfill_numberformat_en-nz" */ '@formatjs/intl-numberformat/locale-data/en-NZ'),
  // other en-* are available
  es: () => import(/* webpackChunkName: "polyfill_numberformat_es" */ '@formatjs/intl-numberformat/locale-data/es'),
  'es-419': () =>
    import(/* webpackChunkName: "polyfill_numberformat_es-419" */ '@formatjs/intl-numberformat/locale-data/es-419'),
  // excluded other available es-<country> locales, potentially relevant
  fi: () => import(/* webpackChunkName: "polyfill_numberformat_fi" */ '@formatjs/intl-numberformat/locale-data/fi'),
  fil: () => import(/* webpackChunkName: "polyfill_numberformat_fil" */ '@formatjs/intl-numberformat/locale-data/fil'),
  fr: () => import(/* webpackChunkName: "polyfill_numberformat_fr" */ '@formatjs/intl-numberformat/locale-data/fr'),
  'fr-CA': () =>
    import(/* webpackChunkName: "polyfill_numberformat_fr-ca" */ '@formatjs/intl-numberformat/locale-data/fr-CA'),
  hi: () => import(/* webpackChunkName: "polyfill_numberformat_hi" */ '@formatjs/intl-numberformat/locale-data/hi'),
  hu: () => import(/* webpackChunkName: "polyfill_numberformat_hu" */ '@formatjs/intl-numberformat/locale-data/hu'),
  id: () => import(/* webpackChunkName: "polyfill_numberformat_id" */ '@formatjs/intl-numberformat/locale-data/id'),
  it: () => import(/* webpackChunkName: "polyfill_numberformat_it" */ '@formatjs/intl-numberformat/locale-data/it'),
  ja: () => import(/* webpackChunkName: "polyfill_numberformat_ja" */ '@formatjs/intl-numberformat/locale-data/ja'),
  ko: () => import(/* webpackChunkName: "polyfill_numberformat_ko" */ '@formatjs/intl-numberformat/locale-data/ko'),
  ms: () => import(/* webpackChunkName: "polyfill_numberformat_ms" */ '@formatjs/intl-numberformat/locale-data/ms'),
  nb: () => import(/* webpackChunkName: "polyfill_numberformat_nb" */ '@formatjs/intl-numberformat/locale-data/nb'),
  // nb-SJ available
  nl: () => import(/* webpackChunkName: "polyfill_numberformat_nl" */ '@formatjs/intl-numberformat/locale-data/nl'),
  pl: () => import(/* webpackChunkName: "polyfill_numberformat_pl" */ '@formatjs/intl-numberformat/locale-data/pl'),
  pt: () => import(/* webpackChunkName: "polyfill_numberformat_pt" */ '@formatjs/intl-numberformat/locale-data/pt'),
  'pt-PT': () =>
    import(/* webpackChunkName: "polyfill_numberformat_pt-pt" */ '@formatjs/intl-numberformat/locale-data/pt-PT'),
  ro: () => import(/* webpackChunkName: "polyfill_numberformat_ro" */ '@formatjs/intl-numberformat/locale-data/ro'),
  // `ro-MD` Moldova available
  sv: () => import(/* webpackChunkName: "polyfill_numberformat_sv" */ '@formatjs/intl-numberformat/locale-data/sv'),
  // sv-AX, sv-FI available
  th: () => import(/* webpackChunkName: "polyfill_numberformat_th" */ '@formatjs/intl-numberformat/locale-data/th'),
  tr: () => import(/* webpackChunkName: "polyfill_numberformat_tr" */ '@formatjs/intl-numberformat/locale-data/tr'),
  // `tr-CY` Turkish (Cyprus) available
  vi: () => import(/* webpackChunkName: "polyfill_numberformat_vi" */ '@formatjs/intl-numberformat/locale-data/vi'),
  zh: () => import(/* webpackChunkName: "polyfill_numberformat_zh" */ '@formatjs/intl-numberformat/locale-data/zh'),
  'zh-Hans': () =>
    import(/* webpackChunkName: "polyfill_numberformat_zh-hans" */ '@formatjs/intl-numberformat/locale-data/zh-Hans'),
  'zh-Hans-HK': () =>
    import(
      /* webpackChunkName: "polyfill_numberformat_zh-hans-hk" */ '@formatjs/intl-numberformat/locale-data/zh-Hans-HK'
    ),
  'zh-Hant': () =>
    import(/* webpackChunkName: "polyfill_numberformat_zh-hant" */ '@formatjs/intl-numberformat/locale-data/zh-Hans'),
  'zh-Hant-HK': () =>
    import(
      /* webpackChunkName: "polyfill_numberformat_zh-hant-hk" */ '@formatjs/intl-numberformat/locale-data/zh-Hans-HK'
    ),
  // additional zh-* are available
}

// See https://github.com/formatjs/formatjs/blob/main/packages/intl-relativetimeformat/supported-locales.generated.ts
const relativeTimeFormat = {
  bg: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_bg" */ '@formatjs/intl-relativetimeformat/locale-data/bg'),
  cs: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_cs" */ '@formatjs/intl-relativetimeformat/locale-data/cs'),
  da: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_da" */ '@formatjs/intl-relativetimeformat/locale-data/da'),
  // da-GL available
  de: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_de" */ '@formatjs/intl-relativetimeformat/locale-data/de'),
  el: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_el" */ '@formatjs/intl-relativetimeformat/locale-data/el'),
  // el-CY available
  en: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_en" */ '@formatjs/intl-relativetimeformat/locale-data/en'),
  'en-AU': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_en-au" */ '@formatjs/intl-relativetimeformat/locale-data/en-AU'
    ),
  'en-CA': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_en-ca" */ '@formatjs/intl-relativetimeformat/locale-data/en-CA'
    ),
  'en-GB': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_en-gb" */ '@formatjs/intl-relativetimeformat/locale-data/en-GB'
    ),
  'en-IE': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_en-ie" */ '@formatjs/intl-relativetimeformat/locale-data/en-IE'
    ),
  'en-NZ': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_en-nz" */ '@formatjs/intl-relativetimeformat/locale-data/en-NZ'
    ),
  // other en-* are available
  es: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_es" */ '@formatjs/intl-relativetimeformat/locale-data/es'),
  'es-419': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_es-419" */ '@formatjs/intl-relativetimeformat/locale-data/es-419'
    ),
  fi: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_fi" */ '@formatjs/intl-relativetimeformat/locale-data/fi'),
  fil: () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_fil" */ '@formatjs/intl-relativetimeformat/locale-data/fil'
    ),
  fr: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_fr" */ '@formatjs/intl-relativetimeformat/locale-data/fr'),
  'fr-CA': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_fr-ca" */ '@formatjs/intl-relativetimeformat/locale-data/fr-CA'
    ),
  hi: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_hi" */ '@formatjs/intl-relativetimeformat/locale-data/hi'),
  hu: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_hu" */ '@formatjs/intl-relativetimeformat/locale-data/hu'),
  id: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_id" */ '@formatjs/intl-relativetimeformat/locale-data/id'),
  it: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_it" */ '@formatjs/intl-relativetimeformat/locale-data/it'),
  ja: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_ja" */ '@formatjs/intl-relativetimeformat/locale-data/ja'),
  ko: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_ko" */ '@formatjs/intl-relativetimeformat/locale-data/ko'),
  ms: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_ms" */ '@formatjs/intl-relativetimeformat/locale-data/ms'),
  nb: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_nb" */ '@formatjs/intl-relativetimeformat/locale-data/nb'),
  // nb-SJ available
  nl: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_nl" */ '@formatjs/intl-relativetimeformat/locale-data/nl'),
  pl: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_pl" */ '@formatjs/intl-relativetimeformat/locale-data/pl'),
  pt: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_pt" */ '@formatjs/intl-relativetimeformat/locale-data/pt'),
  'pt-PT': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_pt-pt" */ '@formatjs/intl-relativetimeformat/locale-data/pt-PT'
    ),
  ro: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_ro" */ '@formatjs/intl-relativetimeformat/locale-data/ro'),
  // `ro-MD` Moldova available
  sv: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_sv" */ '@formatjs/intl-relativetimeformat/locale-data/sv'),
  // sv-AX, sv-FI available
  th: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_th" */ '@formatjs/intl-relativetimeformat/locale-data/th'),
  tr: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_tr" */ '@formatjs/intl-relativetimeformat/locale-data/tr'),
  // `tr-CY` Turkish (Cyprus) available
  vi: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_vi" */ '@formatjs/intl-relativetimeformat/locale-data/vi'),
  zh: () =>
    import(/* webpackChunkName: "polyfill_relativetimeformat_zh" */ '@formatjs/intl-relativetimeformat/locale-data/zh'),
  'zh-Hans': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_zh-hans" */ '@formatjs/intl-relativetimeformat/locale-data/zh-Hans'
    ),
  'zh-Hans-HK': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_zh-hans-hk" */ '@formatjs/intl-relativetimeformat/locale-data/zh-Hans-HK'
    ),
  'zh-Hant': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_zh-hant" */ '@formatjs/intl-relativetimeformat/locale-data/zh-Hans'
    ),
  'zh-Hant-HK': () =>
    import(
      /* webpackChunkName: "polyfill_relativetimeformat_zh-hant-hk" */ '@formatjs/intl-relativetimeformat/locale-data/zh-Hans-HK'
    ),
  // additional zh-* are available
}

// See https://github.com/formatjs/formatjs/blob/main/packages/intl-datetimeformat/supported-locales.generated.ts
const dateTimeFormat = {
  bg: () => import(/* webpackChunkName: "polyfill_datatimeformat_bg" */ '@formatjs/intl-datetimeformat/locale-data/bg'),
  cs: () => import(/* webpackChunkName: "polyfill_datatimeformat_cs" */ '@formatjs/intl-datetimeformat/locale-data/cs'),
  da: () => import(/* webpackChunkName: "polyfill_datatimeformat_da" */ '@formatjs/intl-datetimeformat/locale-data/da'),
  // da-GL available
  de: () => import(/* webpackChunkName: "polyfill_datatimeformat_de" */ '@formatjs/intl-datetimeformat/locale-data/de'),
  el: () => import(/* webpackChunkName: "polyfill_datatimeformat_el" */ '@formatjs/intl-datetimeformat/locale-data/el'),
  // el-CY available
  en: () => import(/* webpackChunkName: "polyfill_datatimeformat_en" */ '@formatjs/intl-datetimeformat/locale-data/en'),
  'en-AU': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_en-au" */ '@formatjs/intl-datetimeformat/locale-data/en-AU'),
  'en-CA': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_en-ca" */ '@formatjs/intl-datetimeformat/locale-data/en-CA'),
  'en-GB': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_en-gb" */ '@formatjs/intl-datetimeformat/locale-data/en-GB'),
  'en-IE': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_en-ie" */ '@formatjs/intl-datetimeformat/locale-data/en-IE'),
  'en-NZ': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_en-nz" */ '@formatjs/intl-datetimeformat/locale-data/en-NZ'),
  // other en-* are available
  es: () => import(/* webpackChunkName: "polyfill_datatimeformat_es" */ '@formatjs/intl-datetimeformat/locale-data/es'),
  'es-419': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_es-419" */ '@formatjs/intl-datetimeformat/locale-data/es-419'),
  fi: () => import(/* webpackChunkName: "polyfill_datatimeformat_fi" */ '@formatjs/intl-datetimeformat/locale-data/fi'),
  fil: () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_fil" */ '@formatjs/intl-datetimeformat/locale-data/fil'),
  fr: () => import(/* webpackChunkName: "polyfill_datatimeformat_fr" */ '@formatjs/intl-datetimeformat/locale-data/fr'),
  'fr-CA': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_fr-ca" */ '@formatjs/intl-datetimeformat/locale-data/fr-CA'),
  hi: () => import(/* webpackChunkName: "polyfill_datatimeformat_hi" */ '@formatjs/intl-datetimeformat/locale-data/hi'),
  hu: () => import(/* webpackChunkName: "polyfill_datatimeformat_hu" */ '@formatjs/intl-datetimeformat/locale-data/hu'),
  id: () => import(/* webpackChunkName: "polyfill_datatimeformat_id" */ '@formatjs/intl-datetimeformat/locale-data/id'),
  it: () => import(/* webpackChunkName: "polyfill_datatimeformat_it" */ '@formatjs/intl-datetimeformat/locale-data/it'),
  ja: () => import(/* webpackChunkName: "polyfill_datatimeformat_ja" */ '@formatjs/intl-datetimeformat/locale-data/ja'),
  ko: () => import(/* webpackChunkName: "polyfill_datatimeformat_ko" */ '@formatjs/intl-datetimeformat/locale-data/ko'),
  ms: () => import(/* webpackChunkName: "polyfill_datatimeformat_ms" */ '@formatjs/intl-datetimeformat/locale-data/ms'),
  nb: () => import(/* webpackChunkName: "polyfill_datatimeformat_nb" */ '@formatjs/intl-datetimeformat/locale-data/nb'),
  // nb-SJ available
  nl: () => import(/* webpackChunkName: "polyfill_datatimeformat_nl" */ '@formatjs/intl-datetimeformat/locale-data/nl'),
  pl: () => import(/* webpackChunkName: "polyfill_datatimeformat_pl" */ '@formatjs/intl-datetimeformat/locale-data/pl'),
  pt: () => import(/* webpackChunkName: "polyfill_datatimeformat_pt" */ '@formatjs/intl-datetimeformat/locale-data/pt'),
  'pt-PT': () =>
    import(/* webpackChunkName: "polyfill_datatimeformat_pt-pt" */ '@formatjs/intl-datetimeformat/locale-data/pt-PT'),
  ro: () => import(/* webpackChunkName: "polyfill_datatimeformat_ro" */ '@formatjs/intl-datetimeformat/locale-data/ro'),
  // `ro-MD` Moldova available
  sv: () => import(/* webpackChunkName: "polyfill_datatimeformat_sv" */ '@formatjs/intl-datetimeformat/locale-data/sv'),
  // sv-AX, sv-FI available
  th: () => import(/* webpackChunkName: "polyfill_datatimeformat_th" */ '@formatjs/intl-datetimeformat/locale-data/th'),
  tr: () => import(/* webpackChunkName: "polyfill_datatimeformat_tr" */ '@formatjs/intl-datetimeformat/locale-data/tr'),
  // `tr-CY` Turkish (Cyprus) available
  vi: () => import(/* webpackChunkName: "polyfill_datatimeformat_vi" */ '@formatjs/intl-datetimeformat/locale-data/vi'),
  zh: () => import(/* webpackChunkName: "polyfill_datatimeformat_zh" */ '@formatjs/intl-datetimeformat/locale-data/zh'),
  'zh-Hans': () =>
    import(
      /* webpackChunkName: "polyfill_datatimeformat_zh-hans" */ '@formatjs/intl-datetimeformat/locale-data/zh-Hans'
    ),
  'zh-Hans-HK': () =>
    import(
      /* webpackChunkName: "polyfill_datatimeformat_zh-hans-hk" */ '@formatjs/intl-datetimeformat/locale-data/zh-Hans-HK'
    ),
  'zh-Hant': () =>
    import(
      /* webpackChunkName: "polyfill_datatimeformat_zh-hant" */ '@formatjs/intl-datetimeformat/locale-data/zh-Hans'
    ),
  'zh-Hant-HK': () =>
    import(
      /* webpackChunkName: "polyfill_datatimeformat_zh-hant-hk" */ '@formatjs/intl-datetimeformat/locale-data/zh-Hans-HK'
    ),
  // additional zh-* are available
}
