import React, { cloneElement, FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize, Size } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { FieldBadge } from '../../atoms/formElements/FieldBadge'
import { Headline, Size as HeadlineSize } from '../../atoms/headline/Headline'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { SecondaryButton } from '../../atoms/secondaryButton/SecondaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TextButton } from '../../atoms/textButton/TextButton'
import { TruncatedText, TruncatedTextHandle } from '../../atoms/truncate/TruncatedText'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface TreatmentCardTemplateProps {
  icon?: ReactElement
  name?: string
  title: React.ReactNode
  titleCaption?: React.ReactNode
  badgeText?: string
  description: string
  truncateDescription?: boolean
  content?: ReactElement
  contentStyle?: ViewStyle
  expandableContent?: ReactElement
  buttonText?: React.ReactNode
  buttonRightSide?: boolean
  onButtonPress?: (name: string) => void
  locationsDropdown?: React.ReactNode
  footnote?: ReactElement
  footnoteBelowCard?: boolean
  background?: string | number
  smallCardView?: boolean
  isLast?: boolean
  hasPrimaryButton?: boolean
  isLoading?: boolean
  costSharingNotice?: React.ReactNode
  isCTADisabled?: boolean
  showModalView?: boolean
}

const ShowHideButton = styled(TextButton)(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const Container = styled.View<{
  theme: ThemeType
  bottomMargin?: boolean
  smallCardView?: boolean
  icon?: boolean
  showModalView?: boolean
}>(({ theme, bottomMargin, smallCardView, icon, showModalView }) => ({
  ...(!smallCardView && {
    flexDirection: 'row',
    borderRadius: '16px',
    boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
  }),
  backgroundColor: theme.colors.backgroundPrimary,
  ...(bottomMargin && {
    marginBottom: smallCardView
      ? showModalView
        ? theme.spacing['8px']
        : theme.spacing['16px']
      : theme.spacing['32px'],
  }),
  ...(smallCardView &&
    theme.breakpoints.isMinWidthLaptop &&
    !showModalView && {
      padding: icon ? theme.spacing['24px'] : `${theme.spacing['32px']} ${theme.spacing['24px']}`,
      borderRadius: '16px',
      boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
      marginBottom: theme.spacing['24px'],
    }),
  ...(showModalView &&
    !theme.breakpoints.isMobileSized && { marginTop: '-16px', backgroundColor: theme.colors.backgroundTransparent }),
}))

const IconContainer = styled.View<{ theme: ThemeType; smallCardView?: boolean }>(({ theme, smallCardView }) => ({
  alignSelf: 'center',
  marginBottom: theme.spacing['16px'],
  ...(!smallCardView && {
    alignSelf: 'flex-start',
  }),
}))

const TruncatedTextContainer = styled.View<{ theme: ThemeType; smallCardView?: boolean; showModalView?: boolean }>(
  ({ theme, smallCardView, showModalView }) => ({
    marginTop: smallCardView ? (showModalView ? theme.spacing['16px'] : theme.spacing['8px']) : theme.spacing['0px'],
  }),
)

const TitleContainer = styled.View<{ theme: ThemeType; background?: string | number; smallCardView?: boolean }>(
  ({ theme, background, smallCardView }) =>
    !smallCardView && {
      justifyContent: 'flex-end',
      padding: theme.spacing['48px'],
      maxWidth: '322px',
      backgroundColor: theme.colors.backgroundHighlightPrimary,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      backgroundPosition: 'top left',
      backgroundImage: `url(${background})`,
      borderTopLeftRadius: '16px',
      borderBottomLeftRadius: '16px',
    },
)

const BadgeContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMinWidthLaptop ? '10px' : '14px',
  marginBottom: theme.breakpoints.isMinWidthLaptop ? '2px' : '6px',
}))

const ContentContainer = styled.View<{ theme: ThemeType; smallCardView?: boolean }>(
  ({ theme, smallCardView }) =>
    !smallCardView && {
      flexShrink: 1,
      padding: theme.spacing['48px'],
    },
)

const SubContentContainer = styled.View<{ theme: ThemeType; icon?: boolean; showModalView?: boolean }>(
  ({ theme, icon, showModalView }) => ({
    margin: icon
      ? showModalView
        ? `${theme.spacing['24px']} ${theme.spacing['0px']} ${theme.spacing['16px']}`
        : `${theme.spacing['32px']} ${theme.spacing['0px']}`
      : `${theme.spacing['12px']} ${theme.spacing['0px']}`,
  }),
)

const ExpandableContent = styled.View<{ theme: ThemeType; bottomMargin?: boolean; largeTopMargin?: boolean }>(
  ({ theme, bottomMargin, largeTopMargin }) => ({
    marginTop: largeTopMargin || theme.breakpoints.isMinWidthLaptop ? theme.spacing['32px'] : theme.spacing['16px'],
    marginBottom: bottomMargin ? theme.spacing['32px'] : theme.spacing['0px'],
  }),
)

const TitleCaption = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing['4px'],
}))

const FooterContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const FooterBelowCardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMinWidthLaptop ? '-8px' : theme.spacing['8px'],
}))

const CostSharingNoticeContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const MobileDivider = styled(Divider)<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['16px']} 0 ${theme.spacing['32px']} 0`,
}))

const CardButtonContainer = styled.View<{ buttonRightSide?: boolean }>(({ buttonRightSide }) => ({
  ...(buttonRightSide && { alignItems: 'flex-end' }),
}))

export const TreatmentCardTemplate: FunctionComponent<TreatmentCardTemplateProps> = ({
  icon,
  title,
  name = '',
  titleCaption,
  badgeText = '',
  description,
  truncateDescription = true,
  content,
  contentStyle,
  expandableContent,
  buttonText,
  onButtonPress,
  locationsDropdown,
  footnote,
  footnoteBelowCard,
  background,
  smallCardView,
  isLast,
  hasPrimaryButton = true,
  isLoading,
  isCTADisabled,
  costSharingNotice,
  buttonRightSide,
  showModalView,
}) => {
  const [showExpandableContent, setShowExpandableContent] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const truncatedTextRef = useRef<TruncatedTextHandle>(null)
  const { breakpoints, colors } = useTheme()
  const isLaptop = smallCardView ? false : breakpoints.isMinWidthLaptop
  const displayExpandableContent = !!expandableContent && showExpandableContent && isLaptop
  smallCardView = smallCardView || !breakpoints.isMinWidthLaptop
  const { formatMessage } = useIntl()

  useEffect(() => {
    // On large screen sizes expandable content is always shown
    if (isLaptop) {
      setShowExpandableContent(true)
    }
  }, [isLaptop])

  useEffect(() => {
    // When truncateDescription is false we should make sure the truncation state matches
    if (!truncateDescription) {
      setIsTruncated(false)
    }
  }, [truncateDescription])

  const onShowMorePress = () => {
    setShowExpandableContent(!showExpandableContent)
    showExpandableContent ? truncatedTextRef?.current?.close() : truncatedTextRef?.current?.open()
  }

  const isTruncatedCallback = useCallback((truncated: boolean) => {
    setIsTruncated(truncated)
  }, [])

  const ButtonComponent = hasPrimaryButton ? PrimaryButton : SecondaryButton

  return (
    <>
      <Container
        smallCardView={smallCardView}
        bottomMargin={!displayExpandableContent}
        icon={!!icon}
        showModalView={showModalView}
      >
        <TitleContainer background={background} smallCardView={smallCardView}>
          {!!icon && (
            <IconContainer smallCardView={smallCardView}>
              {isLaptop ? cloneElement(icon, { size: 64 }) : icon}
            </IconContainer>
          )}
          {!!titleCaption && (
            <TitleCaption text={titleCaption} size={HeadlineSize.EYEBROW} textAlign={isLaptop ? 'left' : 'center'} />
          )}
          {isLaptop ? (
            <Headline text={title} size={HeadlineSize.SMALL} />
          ) : (
            <Subhead text={title} size={SubheadSize.MEDIUM} textAlign='center' />
          )}
        </TitleContainer>
        {!!badgeText && (
          <BadgeContainer>
            <FieldBadge text={badgeText} level='3' />
          </BadgeContainer>
        )}
        <ContentContainer smallCardView={smallCardView}>
          <TruncatedTextContainer smallCardView={smallCardView} showModalView={showModalView}>
            {truncateDescription ? (
              <TruncatedText
                ref={truncatedTextRef}
                text={description}
                collapseExpandText={[
                  formatMessage({
                    defaultMessage: 'Less',
                    description: 'Read less for truncated treatment content',
                  }),
                  formatMessage({
                    defaultMessage: 'More',
                    description: 'Read more for truncated treatment content',
                  }),
                ]}
                collapseExpandTextSize={BodyTextSize.DEFAULT}
                maxNumberOfLines={3}
                isTruncatedCallback={isTruncatedCallback}
                testIDPrefix={'treatmentCard-description'}
                toggleCollapseExpand={isLaptop}
                noMargin
                fontColor={colors.textPrimary}
              />
            ) : (
              <BodyText text={description} size={Size.DEFAULT} />
            )}
          </TruncatedTextContainer>
          {showExpandableContent && !isLaptop && (
            <ExpandableContent testID={tID('treatmentCard-expandableContent')}>{expandableContent}</ExpandableContent>
          )}
          {!isLaptop && (isTruncated || !!expandableContent) && (
            <ShowHideButton
              text={
                showExpandableContent ? (
                  <FormattedMessage defaultMessage='Show less' description='Button collapses expandable text content' />
                ) : (
                  <FormattedMessage
                    defaultMessage='Show more'
                    description='Button expands text that has been truncated'
                  />
                )
              }
              rightIcon={
                <ChevronIcon direction={showExpandableContent ? ChevronIconDirection.UP : ChevronIconDirection.DOWN} />
              }
              onPress={onShowMorePress}
            />
          )}
          <SubContentContainer icon={!!icon && !locationsDropdown} style={contentStyle} showModalView={showModalView}>
            {content}
          </SubContentContainer>
          {!!locationsDropdown && <SubContentContainer>{locationsDropdown}</SubContentContainer>}
          {!!onButtonPress && !!buttonText && (
            <CardButtonContainer buttonRightSide={buttonRightSide && !breakpoints.isMobileSized}>
              <ButtonComponent
                testID={tID(`${name}-treatment-card-button`)}
                text={buttonText}
                onPress={() => onButtonPress(name)}
                fullWidth={!isLaptop}
                loading={isLoading}
                disabled={isCTADisabled}
              />
            </CardButtonContainer>
          )}
          {!!costSharingNotice && <CostSharingNoticeContainer>{costSharingNotice}</CostSharingNoticeContainer>}
          {!!footnote && !footnoteBelowCard && <FooterContainer>{footnote}</FooterContainer>}
        </ContentContainer>
      </Container>
      {displayExpandableContent && (
        <ExpandableContent bottomMargin largeTopMargin testID={tID('treatmentCard-expandableContent')}>
          {expandableContent}
        </ExpandableContent>
      )}
      {!!footnote && footnoteBelowCard && <FooterBelowCardContainer>{footnote}</FooterBelowCardContainer>}
      {!breakpoints.isMinWidthLaptop && !isLast && !showModalView && (
        <MobileDivider color={colors.dividerPrimary} height={2} />
      )}
    </>
  )
}

export type TreatmentCardTemplateConsumerProps = Pick<
  TreatmentCardTemplateProps,
  'name' | 'onButtonPress' | 'smallCardView' | 'truncateDescription' | 'isLast' | 'buttonRightSide' | 'showModalView'
>
