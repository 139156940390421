import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { UNAUTHENTICATED_REDIRECT_URL } from '@lyrahealth-inc/lyraweb-mobile/src/hooks/useShareSessionStorageAcrossTabs'
import {
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  ELECTION_CMS_MICROSITE_MESSAGE,
  ELECTION_CMS_MICROSITE_MESSAGE_KEY,
  ELECTION_MICROSITE_URL,
  ELECTION_UTM_CAMPAIGN,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'

import CMSMicrositeIframe from '../../common/components/CMSMicrositeIFrame/CMSMicrositeIFrame'
import LegalFooter from '../../common/components/footer/LegalFooter'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import { getEssentialsDisabled } from '../../data/customer/customerSelectors'
import { getIsUserInternational, getIsUserLoggedIn } from '../../data/user/userSelectors'
import { PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'
import RegistrationEmailCaptureContainer from '../register/setupAccount/RegistrationEmailCaptureContainer'

const ElectionMicrosite: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isShowingRegistrationModal, setIsShowingRegistrationModal] = useState(false)
  const initialParams = useRef(searchParams)
  const { isElectionsEnabled } = useFlags()
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isInternational = useSelector(getIsUserInternational)
  const isEssentialsDisabled = useSelector(getEssentialsDisabled)
  const micrositeUrl = ELECTION_MICROSITE_URL
  const mixpanelEntryPoint = location.state?.mixpanelEntryPoint
  const mixpanelProps = { [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.ELECTION_STRESS_TOOLKIT }

  useTrackViewPage(COMMON_MIXPANEL_PAGE.ELECTION_STRESS_TOOLKIT, {
    ...mixpanelProps,
    ...(mixpanelEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint }),
    utm_campaign: ELECTION_UTM_CAMPAIGN,
  })

  useEffect(() => {
    if (!isElectionsEnabled || isEssentialsDisabled || isInternational) {
      navigate('/')
    }
  }, [isElectionsEnabled, isEssentialsDisabled, isInternational, navigate])

  useEffect(() => {
    // utm_campaign should be set for direct traffic
    const newParams = new URLSearchParams(initialParams.current)
    newParams.set('utm_campaign', ELECTION_UTM_CAMPAIGN)
    setSearchParams(newParams)
  }, [setSearchParams])

  const documentTitle = formatMessage({
    defaultMessage: 'Election resources',
    description: 'Browser tab title text when visiting the elections resources page',
  })

  const goToElectionWorkshop = useCallback(() => {
    const electionWorkshopUrl = `/secure/index/essentials/events?${searchParams.toString()}`
    if (isLoggedIn) {
      navigate(electionWorkshopUrl)
    } else {
      setIsShowingRegistrationModal(true)
      window.sessionStorage.setItem(UNAUTHENTICATED_REDIRECT_URL, electionWorkshopUrl)
    }
  }, [isLoggedIn, navigate, searchParams])

  const goToCareOptions = useCallback(() => {
    navigate({
      pathname: PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE,
      search: `?${searchParams.toString()}`,
    })
  }, [navigate, searchParams])

  const callbacks: { [key in ELECTION_CMS_MICROSITE_MESSAGE]?: () => void } = useMemo(() => {
    return {
      [ELECTION_CMS_MICROSITE_MESSAGE_KEY.CLICK_WORKSHOP_REGISTER_NOW]: goToElectionWorkshop,
      [ELECTION_CMS_MICROSITE_MESSAGE_KEY.CLICK_ELECTIONS_BOOK_A_SESSION_TODAY]: goToCareOptions,
    }
  }, [goToCareOptions, goToElectionWorkshop])

  const onCloseRegistrationModal = () => {
    setIsShowingRegistrationModal(false)
    window.sessionStorage.removeItem(UNAUTHENTICATED_REDIRECT_URL)
  }

  return (
    <>
      <CMSMicrositeIframe
        testId='ElectionPage'
        iFrameTitle={formatMessage({
          defaultMessage: 'Election-Resources',
          description: 'iFrame title for election resources page',
        })}
        micrositeUrl={micrositeUrl}
        documentTitle={documentTitle}
        messageHandlers={callbacks}
      />
      <LegalFooter />
      {isShowingRegistrationModal && (
        <RegistrationEmailCaptureContainer
          isModalView
          enableModalClose
          onCloseRegistrationModal={onCloseRegistrationModal}
        />
      )}
    </>
  )
}

export default ElectionMicrosite
