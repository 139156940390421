import { defineMessage, MessageDescriptor } from 'react-intl'

import { ProgramCustomerPropertyId } from '../../features'
import { CalendarCardAppointment } from '../appointment/Appointment'
import { Capacity, HealthPlanCostSharingType } from '../user/User'

export enum DistanceUnitAbbreviation {
  KM = 'km',
  MI = 'mi',
}

export interface DistanceToProviderValue {
  value: number
  unit: DistanceUnitAbbreviation
}

export interface ProviderCalendar {
  availability: ProviderCalendarEntry[] | []
  availabilityBreakdown: ProviderAvailabilityBreakdown
  id: string
  interval: number
  slotLength: number
  timeZoneId: string
}

export interface ProviderLanguage {
  name: string // e.g. "arabic"
  tag: string // e.g. "ar"
}

export interface ProviderMemberPreferenceV2 {
  type: ProviderPreferenceTypeV2
  match: ProviderPreferenceMatchV2
  matchingScheduleSlotsString?: string[]
}

export interface LanguageItemForUIDisplay {
  id: string // e.g. "en", "fr", "de"
  name: React.ReactNode // e.g. "English", "French", "German"
  isSelected: boolean // for adding a "selected" style to the language item
}

export interface AdditionalProviderSummary {
  summary_text: string
  language: ProviderLanguage
}

export interface ProviderProgramTaxonomy {
  clientele: string
  treatment: string
  partner: string
  offering: string
  role?: string | null
  region?: string
}

export interface Provider {
  appointments: CalendarCardAppointment[]
  provider: ProviderInfo
}

export interface ProviderObject {
  requestId: unknown
  providersInfo: ProviderInfo[]
  address?: Address | null
  providerAvailabilities?: unknown[]
  providersWithUpcomingAvailability?: unknown[]
  providersWithVideoAvailability?: unknown[]
  providersByDistance?: unknown[]
  providersWhoIdentifyAsPeopleOfColor?: unknown[]
  topRecommendation?: unknown[]
}

// Used in direct booking flow from location search -> next screen
export interface ProviderLocationObject {
  timeZoneId?: string
  providers: ProviderObject[]
}

export interface ProviderObjectWithPartialInfo extends Omit<ProviderObject, 'providersInfo'> {
  providersInfo: Array<Partial<ProviderInfo>>
}

export interface ProviderCalendarEntry {
  date: string
  times: string[]
}

export interface ProviderAvailabilityBreakdown {
  afternoons: ProviderCalendarEntry[] | []
  evenings: ProviderCalendarEntry[] | []
  mornings: ProviderCalendarEntry[] | []
  weekends: ProviderCalendarEntry[] | []
}

export enum TreatmentModeEnums {
  ONLY_INPERSON,
  ONLY_VIDEO,
  BOTH_VIDEO_INPERSON,
  BOTH_PHONE_INPERSON,
  BOTH_PHONE_VIDEO,
  INPERSON_PHONE_VIDEO,
  NONE,
}

export interface CredentialParts {
  credential: string
  education?: string
  context: string
}

export interface SessionFormat {
  isVideo?: boolean
  isLiveMessaging?: boolean
  isInPerson?: boolean
}

export type ProviderCapacity = {
  [programName: string]: number
}

export interface ProviderProfileVideo {
  /** ex: https://vimeo.com/909275758 */
  videoLink: string
  publishedDate: string
}

export interface ProviderExplainClinicalMatch {
  match: ProviderClinicalFieldMatch
  reason: ProviderClinicalFieldReason
}

export interface ProviderClinicalFieldMatch {
  field: string
  value: string
}

export interface ProviderClinicalFieldReason {
  component: string
  componentName: string
  // TODO: This field isn't being returned from BE
  // isSensitive: boolean
  query: string
}

export interface ProviderInfo {
  first_name: string
  last_name: string
  display_first_name?: string
  display_name: string
  credentials: string[]
  additional_provider_summaries?: AdditionalProviderSummary[]
  summary: string
  negative_conditions: string[]
  modalities: string[]
  demographics: string[]
  conditions: string[]
  treatments: string[]
  addresses: Address[]
  education: Education[]
  languages?: string[]
  languages_bcp47?: ProviderLanguage[]
  certifications: string[]
  email: string
  video: boolean
  videoOnly: boolean
  clinical_training: string[]
  lyra_id: string
  lyra_type: string
  prescriber: boolean
  access_type: string
  onsite: boolean
  license_info: Licenseinfo[]
  schedule: string[]
  evening_weekdays: string[]
  relevance?: number
  years_of_experience?: string
  intake_representative_email?: string
  intake_representative_phone?: string
  surface: boolean
  is_blocked: boolean
  is_continuing_care: boolean
  ethnicities: unknown[]
  religions: string[]
  sensitivities: string[]
  genders: string[]
  video_interested?: boolean
  video_individual: boolean
  video_couples?: boolean
  video_child?: boolean
  active_suicidality?: boolean
  is_in_process: boolean
  is_non_direct_access: boolean
  is_specialist_high_value: boolean
  is_specialist_long_term: boolean
  is_available: boolean
  is_display_as_video?: boolean
  matchScore?: number
  lyraProviderImage: LyraProviderImage
  lyraType: string
  contact_methods?: string[]
  contactMethods?: string[]
  nearestDistance: number
  nearestTravelTime: number
  intake_representative_name?: string
  intake_representative_title?: string
  website?: string
  score?: number
  intake_representative_contact?: string[]
  supportedVisitTypes: string
  guaranteed_time?: boolean
  is_provisional?: boolean
  availability?: Availability[]
  connectedCalendar?: boolean
  timestamp_approved?: string
  phone?: string
  episodeSessionCounts?: { [key: string]: number }
  nearestAddress?: Address
  text_sessions_enabled?: boolean
  sessionFormat?: SessionFormat
  calendar?: ProviderCalendar | []
  provider_ethnicity?: string[]
  clinic_name?: string
  racialStressPreferred?: boolean
  program_taxonomy?: ProviderProgramTaxonomy[]
  costInfo?: CostInfo
  npiId?: string
  providerTransgender?: boolean
  providerSexualOrientation?: string[]
  surfacesWhenAvailable?: boolean
  providerCapacity?: ProviderCapacity
  memberPreferences?: ProviderMemberPreferenceV2[]
  profileVideo?: ProviderProfileVideo
  explainClinicalMatch?: ProviderExplainClinicalMatch[]
  /** If true, this provider was available to be booked previously but is no longer available. Should still be surfaced to the user */
  isMissingForSearch?: boolean
  bio_summary?: string
  bio_summary_surfaceable?: boolean
}

export interface Address {
  createDate?: string
  updateDate?: string
  id?: number
  version?: number
  name?: string
  street1?: string
  street2?: string
  city: string
  state?: string
  zip?: string
  location?: {
    lat?: number
    lon?: number
  }
  confidence?: number
  country?: string
  lat?: number
  lon?: number
  address1?: string
  zipcode: string
  knownLocationIdentifier?: string
}

export interface PreferredContactInfo {
  phone?: string
  email?: string
}

export interface Availability {
  timestamp: string
  contact_method?: string
  is_available: boolean
  updated_by?: string
}

export interface LyraProviderImage {
  imageUrl: string
  rectangularImageUrl: string
  portraitImageUrl: string
}

export interface Licenseinfo {
  state: string
  num?: string
  country?: string
  license_type?: string
}

export interface Education {
  school: string
}

export enum ProviderEnums {
  COACH = 'COACH',
  LYRATHERAPIST = 'LYRATHERAPIST',
  PROVIDER = 'PROVIDER',
  'PSYCHIATRIC NURSE PRACTITIONER' = 'PSYCHIATRIC NURSE PRACTITIONER',
  THERAPIST = 'THERAPIST',
  PSYCHIATRIST = 'PSYCHIATRIST',
  LYRAPRESCRIBER = 'LYRAPRESCRIBER',
  LYRAFELLOW = 'LYRAFELLOW',
  LYRACLINICALLEAVEEVALUATOR = 'LYRACLINICALLEAVEEVALUATOR',
  LYRATEENSTHERAPIST = 'LYRATEENSTHERAPIST',
}

export enum ProviderPreferenceTypeV2 {
  CARE_LANGUAGE = 'CARE_LANGUAGE',
  ETHNICITY = 'ETHNICITY',
  GRANULAR_ETHNICITY = 'GRANULAR_ETHNICITY',
  SEXUAL_ORIENTATION = 'SEXUAL_ORIENTATION',
  SESSION_MODALITY = 'SESSION_MODALITY',
  EXPERIENCE_VETERAN = 'EXPERIENCE_VETERAN',
  AVAILABILITY = 'AVAILABILITY',
  EXPERIENCE_RELIGION = 'EXPERIENCE_RELIGION',
  GENDER = 'GENDER',
}

export enum ProviderPreferenceMatch {
  FULL = 'FULL',
  NONE = 'NONE',
  UNSPECIFIED = 'UNSPECIFIED',
  PARTIAL = 'PARTIAL',
}

export enum ProviderPreferenceMatchV2 {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE',
  UNSPECIFIED = 'UNSPECIFIED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export type ProviderType = {
  plural: string
  displayName: string
  lyraType: string
  TITLE: string
  MAX_LISTED: number
  program: string
  displayTitle: MessageDescriptor
}

type ProviderTypes = {
  [key in ProviderEnums]: ProviderType
}

export const PROVIDER_TYPES: ProviderTypes = {
  [ProviderEnums.COACH]: {
    plural: 'coaches',
    displayName: 'coach',
    lyraType: 'coach',
    TITLE: 'Mental Health Coach',
    displayTitle: defineMessage({
      defaultMessage: 'Mental Health Coach',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'Coaching',
  },
  [ProviderEnums.LYRATHERAPIST]: {
    plural: 'therapists',
    displayName: 'therapist',
    lyraType: 'lyratherapist',
    TITLE: 'Therapist',
    displayTitle: defineMessage({
      defaultMessage: 'Therapist',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'BlendedCareTherapy',
  },
  [ProviderEnums.PROVIDER]: {
    plural: 'providers',
    displayName: 'provider',
    lyraType: 'provider',
    TITLE: 'Provider',
    displayTitle: defineMessage({
      defaultMessage: 'Provider',
      description:
        "Provider Profile: A generic fallback title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: '',
  },
  [ProviderEnums['PSYCHIATRIC NURSE PRACTITIONER']]: {
    plural: 'providers',
    displayName: 'provider',
    lyraType: 'psychiatric nurse practitioner',
    TITLE: 'Prescriber',
    displayTitle: defineMessage({
      defaultMessage: 'Prescriber',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'DIRECT_ACCESS_MEDS',
  },
  [ProviderEnums.PSYCHIATRIST]: {
    plural: 'providers',
    displayName: 'provider',
    lyraType: 'psychiatrist',
    TITLE: 'Prescriber',
    displayTitle: defineMessage({
      defaultMessage: 'Prescriber',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'DIRECT_ACCESS_MEDS',
  },
  [ProviderEnums.THERAPIST]: {
    plural: 'therapists',
    displayName: 'therapist',
    lyraType: 'therapist',
    TITLE: 'Therapist',
    displayTitle: defineMessage({
      defaultMessage: 'Therapist',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'DIRECT_ACCESS_THERAPY',
  },
  [ProviderEnums.LYRAPRESCRIBER]: {
    plural: 'physicians',
    displayName: 'physician',
    lyraType: 'lyraprescriber',
    TITLE: 'Physician',
    displayTitle: defineMessage({
      defaultMessage: 'Mental Health Physician',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'MedicationManagement',
  },
  [ProviderEnums.LYRAFELLOW]: {
    plural: 'therapists',
    displayName: 'therapist',
    lyraType: 'lyrafellow',
    TITLE: 'Therapist',
    displayTitle: defineMessage({
      defaultMessage: 'Therapist',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'BlendedCareTherapy',
  },
  [ProviderEnums.LYRACLINICALLEAVEEVALUATOR]: {
    plural: 'Counselors',
    displayName: 'Counselor',
    lyraType: 'lyraclinicalleaveevaluator',
    TITLE: 'Counselor',
    displayTitle: defineMessage({
      defaultMessage: 'Physician',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'ClinicalLeaveEvaluation',
  },
  [ProviderEnums.LYRATEENSTHERAPIST]: {
    plural: 'therapists',
    displayName: 'therapist',
    lyraType: 'lyratherapist',
    TITLE: 'Therapist',
    displayTitle: defineMessage({
      defaultMessage: 'Therapist',
      description:
        "Provider Profile: A title for the type of provider, usually displayed along with provider's name and credentials",
    }),
    MAX_LISTED: 10,
    program: 'TeensTherapy',
  },
}

export enum PROVIDER_LYRA_TYPES {
  COACH = 'coach',
  LYRATHERAPIST = 'lyratherapist',
  PROVIDER = 'provider',
  'PSYCHIATRIC NURSE PRACTITIONER' = 'psychiatric nurse practitioner',
  PSYCHIATRIST = 'psychiatrist',
  THERAPIST = 'therapist',
  LYRAPRESCRIBER = 'lyraprescriber',
  LYRAFELLOW = 'lyrafellow',
  LYRACLINICALLEAVEEVALUATOR = 'lyraclinicalleaveevaluator',
  LYRATEENSTHERAPIST = 'lyrateenstherapist',
}

export const sessionSupportedProviderTypes = [
  PROVIDER_TYPES[ProviderEnums.LYRAFELLOW],
  PROVIDER_TYPES[ProviderEnums.LYRATHERAPIST],
  PROVIDER_TYPES[ProviderEnums.LYRAPRESCRIBER],
  PROVIDER_TYPES[ProviderEnums.COACH],
  PROVIDER_TYPES[ProviderEnums.LYRACLINICALLEAVEEVALUATOR],
  PROVIDER_TYPES[ProviderEnums.LYRATEENSTHERAPIST],
]

export enum SESSION_TYPES {
  'MESSAGE' = 'MESSAGE',
  'VIDEO' = 'VIDEO',
}

export enum USER_ROLES {
  'CLIENT' = 'CLIENT',
  'PROVIDER' = 'PROVIDER',
}

export interface ProviderAdminProviderInfo {
  account_identifier?: any
  account_name?: string
  account_number?: null
  account_type?: string
  addresses?: Address[] | null
  approved?: boolean
  available?: string
  bank?: string
  calendar_state?: string
  capacity_updated_at?: string
  capacity_value?: Capacity
  city?: string
  created_at?: string
  date_of_birth?: string
  email?: string
  employment_status?: string
  first_name?: string
  full_name?: string
  guaranteed_time_eligible?: string
  id: string
  is_verified?: boolean
  last_name?: string
  lyra_id: string
  lyra_type?: string
  modalities?: any
  name_to_send_payments_to?: string
  npi?: string
  on_hold?: boolean
  onsite?: string
  outcomes_agreed?: string
  payment_preference?: string
  phone?: string
  rates?: ProviderRates
  roles?: Array<string>
  routing_number?: string
  scheduling_enabled?: boolean
  ssn?: number
  state?: string
  street?: string
  stripe_account_id?: string
  text_sessions_enabled?: string
  zip_code?: string
  differential_pricing_enabled?: boolean
  differential_pricing_in_person_modifier?: number
  program_taxonomies?: ProviderProgramTaxonomy[]
}
export interface ProviderRates {
  couples_follow_up_session?: string
  couples_initial_evaluation?: string
  family_follow_up_session?: string
  family_initial_evaluation?: string
  group_follow_up_session?: string
  group_initial_evaluation?: string
  individual_follow_up_session?: string
  individual_initial_evaluation?: string
  medication_management_follow_up_session?: string
  medication_management_initial_evaluation?: string
  smp_session?: string
  ssc_session?: string
  child_initial_evaluation?: string
  child_follow_up_session?: string
  minor_medication_management_initial_evaluation?: string
  minor_medication_management_follow_up_evaluation?: string
}
export interface ProviderSelfHarmTask {
  resource_id?: string | null
  expiration_datetime?: string | null
  update_datetime?: string | null
  create_datetime?: string | null
  due_datetime?: string | null
  type?: string | null
  acknowledged_datetime?: string | null
  id: string
  status?: string | null
  provider_id?: string | null
  arguments?: {
    patient_id?: string | null
    self_harm_plan?: boolean | null
    self_harm_intent?: boolean | null
    self_harm_date?: string | null
    self_harm_time?: string | null
  }
}

export type appointmentUtilization = {
  completedCurrentWeek: number
  providerNoFaultCurrentWeek: number
  scheduledCurrentWeek: number
  scheduledNextWeek: number
  completedUpToCurrentWeek: number
  providerNoFaultUpToCurrentWeek: number
  completedThisQuarter: number
  providerNoFaultThisQuarter: number
  currentWeekCount: number
  totalWeekCount: number
}

export type UtilizationCharges = {
  completedCurrentWeek: number
  completedThisQuarter: number
  completedUpToCurrentWeek: number
  providerNoFaultCurrentWeek: number
  providerNoFaultThisQuarter: number
  providerNoFaultUpToCurrentWeek: number
}

export type HistoricCharges = {
  endDate: string
  sessionsCredited: number
  sessionsNonCredited: number
  startDate: string
}

export interface targetUtilization {
  providerExpectedWeekWorkHours: number
  providerUtilizationPercentage?: number | null
  quarterPaidTimeOff: number
  quarterSessionTarget: number
  quarterSessionTargetPlus: number
  targetPercent: number
  targetPlusPercent: number
  upcomingWeekPaidTimeOff: number
  upcomingWeekSessionTarget: number
  upcomingWeekSessionTargetPlus: number
}

export interface weekUtilizationData {
  startDate: string
  endDate: string
  sessionsCredited: number
  sessionsNonCredited: number
  providerUtilizationPercentage?: number | null
  providerPaidTimeOffHours?: string
  bookableExpected?: string
  bookable?: number
  target?: string
  achieved_target?: string
  providerAvailableEvents?: number
  sessionsAttended?: number
  sessionsUnattended?: number
  sessionsScheduled?: number
  sessionsNoShow?: number
}

export type historicUtilization = Array<weekUtilizationData>

export type utilizationQuarters = {
  [key: string]: [string, string]
}

export interface ChargeEligiblityPayload {
  company: string
  first_name: string
  last_name: string
  visit_date: string
  eligible_member_first_name?: string
  eligible_member_last_name?: string
  eligible_member_dob?: string
  lyra_code?: string
}

export interface ChargeHealthPlanEligibilityPayload extends ChargeEligiblityPayload {
  session_type: string
  date_of_birth: string
  is_guaranteed_time: string
  provider_lyra_id: string
  patient_relationship?: string
}

export interface ChargeHealthPlanEligiblityResponse {
  health_plan_extension_eligible: boolean
  speciality_care_attestation_status: string
  patient_lyra_id: string
  next_session_uses_health_plan: boolean
}

export interface CostInfo {
  client_session_cost?: string
  total_session_cost?: string
  status?: string
}

export interface CoverageDetailsInfo {
  providerCostInfo: CostInfo | null
  isHPIExclusive: boolean
  isHPIEligible: boolean
  showFaqModal: boolean
  selectedProgram: ProgramCustomerPropertyId
  costOfLyraFAQModal: React.ReactNode
  shouldShowCostEstimateEAPTransition?: boolean
  isCostInfoLoading?: boolean
  isCopayForMaxCostEstimateEnabled: boolean
  healthPlanCopayAmount: number | undefined
  healthPlanCostSharingType?: HealthPlanCostSharingType
}
