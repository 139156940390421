import React, { FunctionComponent, memo } from 'react'

import LottieView from 'lottie-react-native'
import styled from 'styled-components/native'

import loadingRing from '../../assets/lottie/LoadingRing.json'

const Container = styled.View<{ size: number | string }>(({ size }) => ({
  height: `${size}px`,
  width: `${size}px`,
}))

export interface LoadingSpinnerProps {
  size?: string | number
}

export const LoadingRingComponent: FunctionComponent<LoadingSpinnerProps> = ({ size = 150 }) => {
  return (
    <Container size={size}>
      <LottieView source={loadingRing} loop autoPlay style={{ width: size, height: size }} />
    </Container>
  )
}

export const LoadingRing = memo(LoadingRingComponent)
