// @intl project:microsite

import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  COMMON_MIXPANEL_PROPERTIES,
  Customer,
  initializeICASChat,
  openICASChat,
  useFlags,
  useLyraIntl,
  User,
} from '@lyrahealth-inc/shared-app-logic'
import { ChatFilledIcon, TextButton } from '@lyrahealth-inc/ui-core'
import { toJS, useMediaQuerySize } from '@lyrahealth-inc/ui-core-crossplatform'

import { useInAppMessaging } from './useInAppMessaging'
import { useOmniChat } from './useOmniChat'
import { getDetectedCountryISO } from '../../../data/appGlobals/appGlobalsSelectors'
import { getCurrentCountryName } from '../../../data/currentCountry/currentCountrySelectors'
import { getCustomer, getCustomerName } from '../../../data/customer/customerSelectors'
import { MIXPANEL_ACTION, MIXPANEL_EVENTS, trackOnboardEvent } from '../../../data/mixpanel'
import {
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getIsUserLoggedIn,
  getUser,
  getUserCountryCode,
} from '../../../data/user/userSelectors'
import {
  CONTACT_US_TYPE,
  handleContactSubmit,
  openContactModal,
} from '../../../features/page-components/modals/contact-modal/contact-modal-actions'

const hiddenStyle = {
  display: 'none',
}

type TextLinkProps = {
  isSmall?: boolean
  isLink?: boolean
  customClass?: string
  isPrimary?: boolean
}
interface ChatLiveProps {
  user: User
  actions: {
    openContactModal: (type: string) => ThunkAction<void, Map<string, any>, unknown, AnyAction>
    trackOnboardEvent: ({
      event,
      action,
    }: {
      event: string
      action?: string
      page?: string
      [key: string]: unknown
    }) => ThunkAction<void, Map<string, any>, unknown, AnyAction>
  }
  textLinkProps?: TextLinkProps // Use this prop to customize the default TextButton that opens the chat modal
  trigger?: React.ReactElement // Use this prop to pass in a custom component to use as the clickable trigger instead of the default TextButton that opens the chat modal
  closeContactInfoModal?: () => void
  customer: Customer
  hideCloseIcon?: boolean
  startChat?: boolean
  native?: boolean // indicates if we need to transition to the native app route instead if the user is coming from native mobile app
  mixpanelPage?: string
  mixpanelPageLocation?: string
  enableChatIcon?: boolean
  hideButton?: boolean
  isUserInternational?: boolean
  countryName?: string
  customerName?: string
  countryIsoCode?: string
  loggedIn: boolean
  isUserAssumedInternational: boolean
  mixpanelEntryPoint?: string
  detectedCountryIsoCode: string
}

const ChatLive = ({
  user,
  textLinkProps: { isSmall = true, isLink = false, customClass = '', isPrimary = false } = {},
  trigger,
  actions: { trackOnboardEvent, openContactModal },
  customer,
  hideCloseIcon,
  startChat,
  native,
  loggedIn,
  isUserAssumedInternational,
  mixpanelPage = '',
  mixpanelPageLocation = '',
  enableChatIcon = false,
  hideButton = false,
  isUserInternational = false,
  countryName = '',
  customerName = '',
  countryIsoCode = '',
  mixpanelEntryPoint,
  detectedCountryIsoCode,
}: ChatLiveProps) => {
  const { isMobileSized } = useMediaQuerySize()
  const navigate = useNavigate()
  const { activeLanguage } = useLyraIntl()
  const isInternational = loggedIn ? isUserInternational : isUserAssumedInternational
  const { shouldShowInAppMessaging } = useFlags()

  const [isICASChatInitialized, setIsICASChatInitialized] = useState(false)

  const onOnline = () => {
    trackOnboardEvent({
      page: mixpanelPage,
      page_location: mixpanelPageLocation,
      event: MIXPANEL_EVENTS.CHAT_LIVE,
      action: MIXPANEL_ACTION.CHAT,
      action_detail: 'Online',
      ...(mixpanelEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint }),
    })
  }

  const navigateToContactUsPage = () => {
    isMobileSized && navigate(`${native ? '/lyraweb-mobile/contact-us' : '/contact-us'}`)
  }

  const onOffline = () => {
    trackOnboardEvent({
      page: mixpanelPage,
      page_location: mixpanelPageLocation,
      event: MIXPANEL_EVENTS.CHAT_LIVE,
      action: MIXPANEL_ACTION.CHAT,
      action_detail: 'Offline',
    })
    !isMobileSized && openContactModal(CONTACT_US_TYPE.CONTACT_US)
    isMobileSized && navigateToContactUsPage()
  }

  const onDisabled = () => {
    trackOnboardEvent({
      page: mixpanelPage,
      page_location: mixpanelPageLocation,
      event: MIXPANEL_EVENTS.CHAT_LIVE,
      action: MIXPANEL_ACTION.CHAT,
      action_detail: 'Disabled',
    })
    !isMobileSized && openContactModal(CONTACT_US_TYPE.CONTACT_US)
    isMobileSized && navigateToContactUsPage()
  }

  const { loadOmniChat } = useOmniChat({ user, events: { onOnline, onOffline, onDisabled } })
  const { loadInAppMessaging } = useInAppMessaging({
    lyraId: user.lyraId,
    userFirstName: user.firstname,
    userLastName: user.lastname,
    userEmail: user.username || '',
    customerName: customerName || '',
    events: { onOnline, onOffline, onDisabled },
  })

  useEffect(() => {
    if (startChat) {
      if (isInternational) {
        const isoCode = countryIsoCode || detectedCountryIsoCode
        initializeICASChat({
          customerLanguage: activeLanguage,
          countryIsoCode: isoCode,
          testMode: true,
          isICASChatInitialized,
        })

        // Prevent ICAS chat from being reinitialized when useEffect hook retriggers from dependency array updates
        // Otherwise ICAS zoho script may reinitialize onto the window object while we're trying to use it, which leads to buggy behavior
        setIsICASChatInitialized(true)
        if ((window as $TSFixMe)?.parent?.$ZSIQWidget) {
          openICASChat({ customerName, countryName })
        }
      } else {
        if (window.hasLoadedInAppMessaging && shouldShowInAppMessaging) {
          loadInAppMessaging()
        } else {
          loadOmniChat()
        }
      }
    }
  }, [
    startChat,
    loadOmniChat,
    customer.name,
    countryName,
    isInternational,
    customerName,
    countryIsoCode,
    activeLanguage,
    detectedCountryIsoCode,
    isICASChatInitialized,
    shouldShowInAppMessaging,
    loadInAppMessaging,
  ])

  const getTriggerElement = () => {
    return trigger ? (
      React.cloneElement(trigger, {
        onClick: (_e: React.MouseEvent<HTMLElement>) => {
          if (isUserInternational) {
            openICASChat({ customerName, countryName })
          } else {
            if (window.hasLoadedInAppMessaging && shouldShowInAppMessaging) {
              loadInAppMessaging()
            } else {
              loadOmniChat()
            }
          }
        },
        // Buttons in ui-core-crossplatform have an onPress prop instead of onClick
        onPress: (_e: React.MouseEvent<any>) => {
          if (isUserInternational) {
            openICASChat({ customerName, countryName })
          } else {
            if (window.hasLoadedInAppMessaging && shouldShowInAppMessaging) {
              loadInAppMessaging()
            } else {
              loadOmniChat()
            }
          }
        },
      })
    ) : (
      <TextButton
        isSmall={isSmall}
        isLink={isLink}
        customClass={customClass}
        isPrimary={isPrimary}
        data-test-id='ChatLive-button'
        onClick={(e) => {
          e.preventDefault()
          if (isUserInternational) {
            openICASChat({ customerName, countryName })
          } else {
            if (window.hasLoadedInAppMessaging && shouldShowInAppMessaging) {
              loadInAppMessaging()
            } else {
              loadOmniChat()
            }
          }
        }}
        style={hideButton ? hiddenStyle : {}}
      >
        {enableChatIcon && <ChatFilledIcon />}
        <FormattedMessage
          defaultMessage='Chat Live'
          description='Button to open a modal dialog to start a live chat session with the care navigator team'
        />
      </TextButton>
    )
  }

  return getTriggerElement()
}

const mapStateToProps = ($$state: any) => {
  return {
    user: getUser($$state),
    customer: getCustomer($$state),
    isUserInternational: getIsUserInternational($$state),
    loggedIn: getIsUserLoggedIn($$state),
    isUserAssumedInternational: getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer($$state),
    countryName: getCurrentCountryName($$state),
    customerName: getCustomerName($$state),
    countryIsoCode: getUserCountryCode($$state),
    detectedCountryIsoCode: getDetectedCountryISO($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      submitForm: handleContactSubmit,
      openContactModal,
      trackOnboardEvent,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ChatLive))
