import React from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { AxiosError } from 'axios'
import { isString } from 'lodash-es'

import { expiredAccountErrorMessage, loginErrorMessages, LW_API_ERROR_CODE } from '@lyrahealth-inc/shared-app-logic'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { getPasswordResetFailedText } from '../../../data/customer/customerSelectors'
import { isInRegisterFlow } from '../../../features/register/data/registerUtils'
import { careTeamEmailLink } from '../../constants/appConstants'
import { INVALID_PAYMENT_ERROR } from '../../constants/errorConstants'

export interface APIErrorMessageProps {
  statusCode?: number // http status code
  errorCode?: LW_API_ERROR_CODE // error code within response
  errorMessage?: string // error message within response
  requestUrl?: string
  requestMethod?: string

  passwordResetFailedText: MessageDescriptor
}

const APIErrorMessageImpl: React.FunctionComponent<APIErrorMessageProps> = ({
  statusCode,
  errorCode,
  errorMessage,
  passwordResetFailedText,
}) => {
  console.error('API error', { statusCode, errorCode, errorMessage })

  switch (errorCode) {
    case 'USER_REGISTERED':
      return (
        <FormattedMessage
          defaultMessage='This account has already registered. Please use different email address or <link>click here to login</link>.'
          description='Error message when user is finishing registration of an account which has already been registered'
          values={{
            link: (text: string) => <Link to='/login'>{text}</Link>,
          }}
        />
      )

    case 'USER_REGISTERED_DIFFERENT_CUSTOMER':
      return (
        <FormattedMessage
          defaultMessage='Your Lyra account is attached to a different organization. If you have switched organizations, please contact {careTeamEmailLink} to update your account. You may still log in below.'
          description='Error message when user is logging in with an account which is already registered with a different employer'
          values={{ careTeamEmailLink }}
        />
      )

    case 'USER_ACCOUNT_UNRECOGNIZED':
      return (
        <FormattedMessage
          defaultMessage='We do not recognize that account. Please log in using another email address or create a new account.'
          description='Error message when user is logging in with an account which cannot be recognized'
        />
      )

    case 'USER_ACCOUNT_PENDING_VERIFICATION':
      const userEmail = errorMessage
      return (
        <FormattedMessage
          defaultMessage='Please check your email to finish setting up your account. Email address: {userEmail}.'
          description='Error message when user is logging in an account which has not completed registration'
          values={{ userEmail }}
        />
      )

    case 'INELIGIBLE_USER':
      return (
        <FormattedMessage
          defaultMessage='Something went wrong. Please check your provided information and ensure that it is correct. If you keep getting this error, please contact {careTeamEmailLink}.'
          description='Error message when user is identified as ineligible'
          values={{ careTeamEmailLink }}
        />
      )

    case 'INVALID_FACTOR_CODE':
      return (
        <FormattedMessage
          defaultMessage='Invalid passcode'
          description='Error message when user entered incorrect passcode for enabling multi-factor authentication'
        />
      )

    case 'INVALID_PAYMENT_INFO':
      return <FormattedMessage {...INVALID_PAYMENT_ERROR} />

    case 'INVALID_OKTA_PASSWORD':
      if (isInRegisterFlow(window.location.pathname)) {
        return (
          <FormattedMessage
            defaultMessage='Please try again. Your password cannot contain parts of your email.'
            description='Error message when user tries to sign up with password that contains part of username'
          />
        )
      }
      return <FormattedMessage {...passwordResetFailedText} />

    case 'USER_ACCOUNT_EXPIRED_MESSAGE':
      return <FormattedMessage {...expiredAccountErrorMessage} />

    case 'SSO_LOGIN_EMAIL_CONFLICT':
      return <FormattedMessage {...loginErrorMessages.SSO_LOGIN_EMAIL_CONFLICT} />

    default:
      if (statusCode === 401) {
        return (
          <FormattedMessage
            defaultMessage='Authorization failed for the given request. Please make sure you are logged in.'
            description='Error message when authorization error occurs'
          />
        )
      }

      return (
        <FormattedMessage
          defaultMessage='Something went wrong. If you keep getting this error, please contact {careTeamEmailLink}.'
          description='Error message when unexpected error occurs'
          values={{ careTeamEmailLink }}
        />
      )
  }
}

const mapStateToProps = (state: $TSFixMe) => {
  return {
    passwordResetFailedText: getPasswordResetFailedText(state),
  }
}

export function buildAPIErrorMessageProps(
  axiosError: AxiosError<{ message?: string; errorCode?: LW_API_ERROR_CODE }>,
): Partial<APIErrorMessageProps> {
  const errorResponse = axiosError.response?.data || {}
  const statusCode = axiosError.response?.status
  const errorCode = isString(errorResponse.errorCode) ? errorResponse.errorCode : undefined
  const errorMessage = isString(errorResponse) ? errorResponse : errorResponse.message
  const requestUrl = axiosError.config.url
  const requestMethod = axiosError.config.method?.toUpperCase()

  return { statusCode, errorCode, errorMessage, requestUrl, requestMethod }
}

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: APIError... Remove this comment to see the full error message
export const APIErrorMessage = connect(mapStateToProps)(toJS(APIErrorMessageImpl))
