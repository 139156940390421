import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type MemberPreferencesAvailabilityIllustrationIconProps = {
  size?: number
}

export const MemberPreferencesAvailabilityIllustrationIcon: FunctionComponent<
  MemberPreferencesAvailabilityIllustrationIconProps
> = ({ size = 519, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 519 519' fill='none' {...props} pointerEvents='none'>
      <G clip-path='url(#clip0_6506_5521)'>
        <G clip-path='url(#clip1_6506_5521)'>
          <Path
            d='M260.655 -0.000129874C224.917 -0.000129874 190.263 6.14033 157.616 18.2181C79.8909 47.0466 25.2691 110.934 7.71608 193.537C-3.88061 247.402 -2.39154 297.202 12.2735 345.739C31.0223 407.731 77.0255 460.015 138.483 489.159C169.009 503.675 204.07 511.938 242.673 513.766C247.637 513.992 252.691 514.105 257.699 514.105C288.677 514.105 319.834 509.861 350.315 501.485C352.661 500.898 354.895 500.312 357.241 499.589C384.631 491.552 409.291 478.865 430.386 461.911C453.286 443.603 472.148 420.395 486.362 392.944C489.159 387.548 491.844 381.859 494.8 375.042C511.067 337.251 518.918 294.945 517.542 252.639C516.888 233.947 514.113 214.058 509.284 193.515C501.365 160.171 488.46 128.814 471.945 102.762C467.41 95.7639 463.281 89.8943 459.152 84.6117C429.484 46.3016 383.232 19.4371 325.475 6.90788C304.267 2.30254 282.449 -0.0227051 260.655 -0.0227051V-0.000129874Z'
            fill='white'
          />
          <Path
            d='M496.108 253.384C495.477 235.459 492.747 216.903 488.415 198.436C481.353 168.705 469.756 139.379 453.85 114.276C450.173 108.587 446.36 103.101 442.254 97.8184C412.427 59.3276 367.214 37.9263 320.94 27.903C269.409 16.7282 214.584 20.0016 165.061 38.3327C93.3826 64.9263 44.5817 123.125 28.6758 198.007C22.5616 226.384 20.7792 258.441 23.5091 289.234H23.3061C24.8854 306.729 28.044 323.819 32.782 339.531C50.3801 397.753 93.1796 443.941 147.666 469.767C177.177 483.787 210.162 490.762 243.688 492.343C277.937 493.923 312.727 489.611 344.878 480.739C346.976 480.22 348.984 479.678 351.105 479.046C376.283 471.664 398.213 460.263 416.985 445.183C437.425 428.839 454.302 408.273 467.365 383.056C470.208 377.57 472.734 372.085 475.171 366.508C490.558 330.749 497.417 291.943 496.041 253.452L496.153 253.339L496.108 253.384Z'
            fill='#FFE9E3'
          />
          <Path
            d='M408.921 382.546L270.932 395.716L143.819 411.782C138.905 412.317 134.477 408.788 133.942 403.846L109.006 177.509C108.47 172.592 111.998 168.161 116.936 167.626L382.038 138.39C386.953 137.855 391.38 141.385 391.915 146.326L416.852 372.663C417.387 377.58 413.859 382.011 408.921 382.546Z'
            fill='#3BA5B5'
          />
          <Path
            d='M358.075 369.231C356.397 369.62 354.913 369.864 353.672 369.961C352.65 370.034 293.071 376.071 292.049 376.12C249.5 378.603 147.152 393.598 141.07 394.62C134.915 395.643 130.804 395.643 128.736 390.506C126.741 385.516 105.405 178.239 104.14 165.995C104.091 165.605 104.067 165.24 104.067 164.851C104.067 159.228 108.252 154.481 113.823 153.75C163.841 147.275 392.864 119.452 393.716 129.457C401.939 225.05 412.984 250.171 420.428 289.777C420.501 290.142 420.55 290.483 420.623 290.848C428.213 335.857 378.535 364.362 358.075 369.231Z'
            fill='#85CCD5'
          />
          <Path
            d='M394.105 148.322L338.151 151.949C333.991 152.217 329.831 152.631 325.695 153.166L107.4 181.598C103.508 182.061 99.907 178.507 99.3475 173.687L95.8686 143.648C95.309 138.829 98.0094 134.544 101.902 134.106L230.718 121.74L388.631 100.83C392.524 100.367 396.124 103.921 396.684 108.741L400.163 138.78C400.722 143.6 398.022 147.884 394.129 148.322H394.105Z'
            fill='#F69170'
          />
          <Path
            d='M358.075 369.231C365.422 354.869 377.416 328.043 375.251 309.324C375.251 309.324 403.617 307.498 420.623 290.872C428.213 335.881 378.535 364.387 358.075 369.255V369.231Z'
            fill='#E6F8F9'
          />
          <Path
            d='M275.015 176.521L230.035 181.673C225.496 182.193 222.238 186.296 222.758 190.837L226.595 224.381C227.114 228.922 231.215 232.182 235.753 231.663L280.734 226.511C285.273 225.991 288.531 221.888 288.011 217.347L284.174 183.803C283.654 179.261 279.554 176.001 275.015 176.521Z'
            fill='#F7F5EF'
          />
          <Path
            d='M360.917 167.075L315.936 172.227C311.398 172.747 308.139 176.85 308.659 181.391L312.496 214.935C313.016 219.476 317.116 222.736 321.655 222.217L366.635 217.065C371.174 216.545 374.432 212.442 373.913 207.901L370.075 174.357C369.556 169.815 365.455 166.555 360.917 167.075Z'
            fill='#C2EBF0'
          />
          <Path
            d='M368.256 232.081L323.275 237.232C318.737 237.752 315.479 241.855 315.998 246.396L319.835 279.94C320.355 284.482 324.455 287.742 328.994 287.222L373.975 282.07C378.513 281.55 381.771 277.447 381.252 272.906L377.414 239.362C376.895 234.821 372.794 231.561 368.256 232.081Z'
            fill='#F7F5EF'
          />
          <Path
            d='M185.969 187.026L140.989 192.178C136.45 192.697 133.192 196.8 133.711 201.342L137.549 234.886C138.068 239.427 142.169 242.687 146.707 242.167L191.688 237.015C196.227 236.496 199.485 232.393 198.965 227.851L195.128 194.307C194.608 189.766 190.508 186.506 185.969 187.026Z'
            fill='#F7F5EF'
          />
          <Path
            d='M194.348 250.943L149.367 256.095C144.828 256.614 141.57 260.717 142.09 265.259L145.927 298.803C146.447 303.344 150.547 306.604 155.086 306.084L200.066 300.932C204.605 300.413 207.863 296.31 207.344 291.768L203.506 258.224C202.987 253.683 198.886 250.423 194.348 250.943Z'
            fill='#C2EBF0'
          />
          <Path
            d='M201.682 318.028L156.701 323.18C152.163 323.7 148.905 327.803 149.424 332.344L153.262 365.888C153.781 370.43 157.882 373.69 162.42 373.17L207.401 368.018C211.939 367.498 215.197 363.395 214.678 358.854L210.841 325.31C210.321 320.768 206.221 317.508 201.682 318.028Z'
            fill='#F7F5EF'
          />
          <Path
            d='M290.75 309.652L245.769 314.804C241.231 315.323 237.972 319.426 238.492 323.968L242.329 357.512C242.849 362.053 246.949 365.313 251.488 364.793L296.468 359.641C301.007 359.122 304.265 355.019 303.746 350.477L299.908 316.933C299.389 312.392 295.288 309.132 290.75 309.652Z'
            fill='#C2EBF0'
          />
          <Path
            d='M282.355 241.527L237.374 246.678C232.836 247.198 229.578 251.301 230.097 255.842L233.935 289.386C234.454 293.928 238.554 297.188 243.093 296.668L288.074 291.516C292.612 290.996 295.87 286.894 295.351 282.352L291.513 248.808C290.994 244.267 286.894 241.007 282.355 241.527Z'
            fill='#F7F5EF'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_6506_5521'>
          <Rect width={519} height={519} fill='white' />
        </ClipPath>
        <ClipPath id='clip1_6506_5521'>
          <Rect width={519} height={519} fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
