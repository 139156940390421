import React, { FunctionComponent, useContext } from 'react'

import { noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { AddIcon } from '../../atoms/icons'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { LatestMatchesLink, LatestMatchesLinkProps } from '../../molecules/latestMatchesLink/LatestMatchesLink'
import { ProgramDetailsContent, ProgramDetailsModal } from '../../organisms/programDetailsModal/ProgramDetailsModal'
import { BodyTextSize } from '../../styles'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { ColumnView, RowView } from '../content/CommonViews'

export interface CareOptionCardProps {
  onPress: (() => void) | undefined
  optionIcon?: React.ReactNode
  optionHeader?: string | React.ReactNode
  optionDescription?: string | React.ReactNode
  testId?: string
  customStyle?: CSSObject
  customHeaderText?: React.ReactNode
  customBodyText?: React.ReactNode
  chevronSize?: number
  latestMatchesLinkProps?: LatestMatchesLinkProps
  ctaText?: React.ReactNode
  /** Opens ProgramDetailsModal instead of triggering `onPress` directly */
  programDetailsModalContent?: ProgramDetailsContent
  useAddIcon?: boolean
  tooltipContent?: string
  useBorderStyles?: boolean
  hideTrailingIcon?: boolean
}

const CardContainer = styled.View<{
  theme: ThemeType
  isDedicatedCareNavigatorEnabled?: boolean
  customStyle?: CSSObject
  useBorderStyles?: boolean
}>(({ theme, isDedicatedCareNavigatorEnabled, customStyle, useBorderStyles }) => ({
  backgroundColor: isDedicatedCareNavigatorEnabled && !useBorderStyles ? undefined : theme.colors.backgroundPrimary,
  borderColor: isDedicatedCareNavigatorEnabled && !useBorderStyles ? undefined : theme.colors.borderDefault,
  borderRadius: isDedicatedCareNavigatorEnabled && !useBorderStyles ? undefined : theme.spacing['8px'],
  borderStyle: isDedicatedCareNavigatorEnabled && !useBorderStyles ? undefined : 'solid',
  borderWidth: isDedicatedCareNavigatorEnabled && !useBorderStyles ? undefined : '1px',
  minHeight: '72px',
  paddingTop: theme.spacing['16px'],
  paddingRight: theme.spacing['16px'],
  paddingBottom: theme.spacing['16px'],
  paddingLeft: isDedicatedCareNavigatorEnabled && !useBorderStyles ? undefined : theme.spacing['16px'],
  flexGrow: 1,
  justifyContent: 'center',
  ...customStyle,
}))

const ContentContainer = styled(RowView)<{ theme: ThemeType }>({
  alignItems: 'center',
})

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['4px'],
}))

const BodyContainer = styled(ColumnView)({
  flex: 'auto',
})

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'center',
  marginRight: theme.spacing['16px'],
}))

const ChevronContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
}))

const CardPressable = styled(PressableOpacity)({
  ...(IS_WEB && { height: '100%' }),
})

export const CareOptionCard: FunctionComponent<CareOptionCardProps> = ({
  onPress = noop,
  optionIcon,
  optionHeader,
  optionDescription,
  testId,
  customStyle,
  customHeaderText,
  customBodyText,
  chevronSize,
  latestMatchesLinkProps,
  programDetailsModalContent,
  useAddIcon,
  useBorderStyles = false,
  hideTrailingIcon = false,
}) => {
  const { colors } = useTheme() as ThemeType
  const { isDedicatedCareNavigatorEnabled } = useContext(AppContext)

  const card = (
    <CardContainer
      customStyle={customStyle}
      isDedicatedCareNavigatorEnabled={isDedicatedCareNavigatorEnabled}
      useBorderStyles={useBorderStyles}
    >
      <ContentContainer>
        {optionIcon && <IconContainer>{optionIcon}</IconContainer>}
        <BodyContainer>
          <HeaderContainer>
            {customHeaderText ?? <BodyText text={optionHeader} size={BodyTextSize.DEFAULT} />}
          </HeaderContainer>
          {customBodyText ??
            (optionDescription && (
              <BodyText text={optionDescription} size={BodyTextSize.SMALL} color={colors.textSecondary} />
            ))}
          {latestMatchesLinkProps && <LatestMatchesLink {...latestMatchesLinkProps} />}
        </BodyContainer>
        {!hideTrailingIcon && (
          <ChevronContainer>
            {useAddIcon ? (
              <AddIcon fillColor={colors.iconDefault} width={chevronSize} />
            ) : (
              <ChevronIcon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconDefault} size={chevronSize} />
            )}
          </ChevronContainer>
        )}
      </ContentContainer>
    </CardContainer>
  )

  if (programDetailsModalContent) {
    return (
      <ProgramDetailsModal
        testId={testId || 'ProgramDetails'}
        modalTrigger={card}
        onCtaPress={onPress}
        programContent={programDetailsModalContent}
      />
    )
  }
  return (
    <CardPressable onPress={onPress} testID={tID(testId)}>
      {card}
    </CardPressable>
  )
}
