import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { MicrositeConfig, MicrositeConfigSectionNames } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../../utils/utils'
import { useGetMicrositeSections } from '../../hooks/useGetMicrositeSections'
import {
  LegalAndCopyrightFooter,
  LegalAndCopyrightFooterProps,
} from '../../organisms/legalAndCopyrightFooter/LegalAndCopyrightFooter'
import { LyraMembershipSummaryProps } from '../../organisms/lyraMembershipSummary/LyraMembershipSummary'
import { LyraOfferingsCarouselProps } from '../../organisms/lyraOfferingsCarousel/LyraOfferingsCarousel'
import { MicrositeCustomerBrandedBannerProps } from '../../organisms/micrositeCustomerBrandedBanner/MicrositeCustomerBrandedBanner'
import { MicrositeMobileAppPromoBannerProps } from '../../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoBanner'
import { MicrositeMobileAppPromoSectionProps } from '../../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoSection'
import { PathwaysCareNavigatorSectionProps } from '../../organisms/pathwaysCareNavigatorSection/PathwaysCareNavigatorSection'
import { PathwaysFAQSectionProps } from '../../organisms/pathwaysFAQSection/PathwaysFAQSection'
import { PathwaysHomepageHeroSectionProps } from '../../organisms/PathwaysHomepageHeroSection/PathwaysHomepageHeroSection'
import { PathwaysSelfCareSectionProps } from '../../organisms/pathwaysSelfCareSection/PathwaysSelfCareSection'

export interface PathwaysMicrositeHomePageProps {
  heroSectionProps: PathwaysHomepageHeroSectionProps
  faqSectionProps: PathwaysFAQSectionProps
  lyraOfferingsSectionProps: LyraOfferingsCarouselProps
  selfCareSectionProps: PathwaysSelfCareSectionProps
  careNavigatorSectionProps: PathwaysCareNavigatorSectionProps
  legalAndCopyrightFooterProps: LegalAndCopyrightFooterProps
  membershipSectionProps: LyraMembershipSummaryProps
  mobileAppPromoSectionProps: MicrositeMobileAppPromoSectionProps
  mobileAppPromoBannerProps: MicrositeMobileAppPromoBannerProps
  customerBrandedBannerProps: MicrositeCustomerBrandedBannerProps
  micrositeConfig: MicrositeConfig
}

export const PathwaysMicrositeHomePage: FunctionComponent<PathwaysMicrositeHomePageProps> = ({
  heroSectionProps,
  faqSectionProps,
  lyraOfferingsSectionProps,
  selfCareSectionProps,
  careNavigatorSectionProps,
  legalAndCopyrightFooterProps,
  micrositeConfig,
  membershipSectionProps,
  mobileAppPromoSectionProps,
  mobileAppPromoBannerProps,
  customerBrandedBannerProps,
}) => {
  const sectionToPropsMap = {
    [MicrositeConfigSectionNames.HERO]: heroSectionProps,
    [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: mobileAppPromoBannerProps,
    [MicrositeConfigSectionNames.CUSTOMER_BRANDED_BANNER]: customerBrandedBannerProps,
    [MicrositeConfigSectionNames.OFFERINGS_CAROUSEL]: {
      ...lyraOfferingsSectionProps,
      ...micrositeConfig[MicrositeConfigSectionNames.OFFERINGS_CAROUSEL],
    },
    [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: mobileAppPromoSectionProps,
    [MicrositeConfigSectionNames.SELF_CARE]: selfCareSectionProps,
    [MicrositeConfigSectionNames.TESTIMONIALS]: {},
    [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: membershipSectionProps,
    [MicrositeConfigSectionNames.CARE_NAVIGATOR]: careNavigatorSectionProps,
    [MicrositeConfigSectionNames.FAQ]: faqSectionProps,
  }

  const sectionsDisplayed = useGetMicrositeSections(micrositeConfig)

  return (
    <View testID={tID('PathwaysMicrositeHomePage')}>
      {sectionsDisplayed.map((section) => {
        const { name: sectionName, component: Component, backgroundColor } = section
        const sectionProps = { ...sectionToPropsMap[sectionName], ...(backgroundColor && { backgroundColor }) }
        return <Component key={sectionName} {...sectionProps} />
      })}
      <LegalAndCopyrightFooter {...legalAndCopyrightFooterProps} />
    </View>
  )
}
