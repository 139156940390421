import React, { FunctionComponent, useContext } from 'react'
import { AccessibilityRole, TextProps, TextStyle } from 'react-native'

import { includes } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { AccessibilityRolesNative } from '../../constants'
import { AppContext } from '../../context'
import {
  moderatBold,
  moderatMedium,
  moderatRegular,
  StyledText,
  TextAlign,
  TextSize,
  TextType,
} from '../../styles/typeStyles'

// @ts-ignore next-line
export interface BaseTextProps extends TextProps {
  text?: string | React.ReactNode
  size: TextSize
  type: TextType
  level?: string
  color?: string
  textAlign?: TextAlign
  style?: TextStyle
  bold?: boolean
  medium?: boolean
  /** Font weight regular */
  regular?: boolean
  testID?: string
  wrap?: boolean
  noRole?: boolean
  selectable?: boolean
  underline?: boolean
  role?: AccessibilityRolesNative
  nativeID?: string
}

type BaseTextContainerProps = {
  color: string
  textAlign: TextAlign
  wrap?: boolean
  bold?: boolean
  medium?: boolean
  regular?: boolean
  underline?: boolean
}

const BaseTextContainer = styled(StyledText)<BaseTextContainerProps>(
  ({ color, textAlign, bold, medium, regular, underline, wrap }) => ({
    color,
    textAlign,
    ...(bold && moderatBold),
    ...(medium && moderatMedium),
    ...(regular && moderatRegular),
    ...(underline && { textDecorationLine: 'underline' }),
    ...(wrap && { flexShrink: 1, flexWrap: 'wrap' }),
  }),
)

/**
 * A Heading component that encapsulates the typeStyle definitions
 * for font types. Refer to typeStyles for the correct heading styles
 */
export const BaseText: FunctionComponent<BaseTextProps> = ({
  text,
  size,
  type,
  level,
  color,
  textAlign = 'left',
  style,
  bold = false,
  medium = false,
  regular = false,
  underline = false,
  testID,
  wrap,
  noRole,
  children,
  role,
  selectable = true,
  nativeID,
  ...rest
}) => {
  const { copyPasteDisabled } = useContext(AppContext)
  const { colors } = useTheme()
  const accessibilityRole =
    (role as AccessibilityRole) ||
    (includes([TextType.HEADLINE, TextType.SUBHEAD], type) && !noRole ? AccessibilityRolesNative.HEADER : undefined)
  const accessibilitylevel = !noRole ? level : undefined
  return (
    <BaseTextContainer
      size={size}
      type={type}
      color={color || colors.textPrimary}
      textAlign={textAlign}
      style={style}
      underline={underline}
      // ignoring the next line because it looks like styled-components/native AccessibilityRole is different than the 0.68 react-native
      // @ts-ignore next-line
      accessibilityRole={accessibilityRole}
      // @ts-ignore next-line
      accessibilityLevel={accessibilitylevel}
      testID={testID}
      wrap={wrap}
      selectable={selectable && !copyPasteDisabled}
      bold={bold}
      medium={medium}
      regular={regular}
      nativeID={nativeID}
      {...rest}
    >
      {text}
      {children}
    </BaseTextContainer>
  )
}
