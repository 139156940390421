import React, { FunctionComponent, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  CustomerSubpopulationOption,
  CustomerSubpopulations,
  isRequired,
  RadioButtonType,
} from '@lyrahealth-inc/shared-app-logic'
import { formatMessageHelper } from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import { PressableOpacity, RadioGroupRFF, Subhead, XIcon } from '../../atoms'
import { IS_WEB } from '../../constants'
import { SubheadSize } from '../../styles'
import { Flex1View } from '../../templates'
import { FixedButtonFormPageTemplate } from '../../templates/fixedButtonFormPageTemplate/FixedButtonFormPageTemplate'
import { ThemeType } from '../../utils'

export interface ChooseSubpopulationFormProps {
  subpopulationData: CustomerSubpopulations
  onSubmit?: (value?: number) => void
  onBackPress?: () => void
  showCloseIcon?: boolean
}

const HeaderAndCloseButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing['24px'],
  marginBottom: theme.spacing['24px'],
  ...(IS_WEB && { marginRight: theme.breakpoints.isMinWidthTablet ? '0' : theme.spacing['24px'] }),
}))

const Subheader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
  marginTop: theme.breakpoints.isMinWidthTablet ? theme.spacing['8px'] : '0',
}))

const FormContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  ...(!IS_WEB && { marginHorizontal: theme.spacing['16px'] }),
}))

const defaultSubheaderCopy = defineMessage({
  defaultMessage: 'Select the scenario that applies to you',
  description: 'Subpopulation redirection subheader informing user to pick group they belong to',
})

export const ChooseSubpopulationForm: FunctionComponent<ChooseSubpopulationFormProps> = ({
  subpopulationData,
  onSubmit,
  onBackPress,
  showCloseIcon = true,
}) => {
  const submit = useRef(noop)
  const { formatMessage } = useIntl()
  const { breakpoints, colors } = useTheme() as ThemeType
  const { header, subHeader, options } = subpopulationData

  const optionsWithValue = options.map((item, index) => {
    // Set value as index so we can retrieve the selected option
    const newItem: CustomerSubpopulationOption & { value: number } = { ...item, value: index }
    newItem.label = formatMessageHelper(newItem.label)
    newItem.description = formatMessageHelper(newItem.description)
    return newItem
  })

  const onFormSubmit = (values: { subpopulation: number }) => {
    const selectedOptionIndex = values.subpopulation
    if (onSubmit) {
      onSubmit(selectedOptionIndex)
    }
  }

  const navBarProps = {
    onBack: onBackPress,
  }

  return (
    <FixedButtonFormPageTemplate
      onSubmitPress={() => submit.current()}
      testID={'ChooseSubpopulationForm'}
      buttonText={
        <FormattedMessage defaultMessage='Continue' description='Button to confirm subpopulation selection' />
      }
      isButtonTransparent
      hideHeaderNav={IS_WEB}
      navBarProps={navBarProps}
      customStyle={breakpoints.isMobileSized ? undefined : { marginTop: 0 }}
      buttonContainerCustomStyle={breakpoints.isMobileSized ? { paddingHorizontal: 24 } : { marginVertical: 0 }}
    >
      <FormContainer>
        <HeaderAndCloseButtonContainer>
          <Subhead text={formatMessageHelper(header)} size={SubheadSize.LARGE} />
          {IS_WEB && breakpoints.isMinWidthTablet && showCloseIcon && (
            <PressableOpacity onPress={onBackPress}>
              <XIcon size={20} fillColor={colors.iconDefault} />
            </PressableOpacity>
          )}
        </HeaderAndCloseButtonContainer>
        <Subheader text={formatMessageHelper(subHeader || defaultSubheaderCopy)} size={SubheadSize.XSMALL} />
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit }) => {
            submit.current = handleSubmit
            return (
              <Field
                name='subpopulation'
                component={RadioGroupRFF}
                buttonType={RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE}
                buttons={optionsWithValue}
                validate={(value) => isRequired(value, formatMessage)}
                baseInputStyle={breakpoints.isMinWidthTablet ? { marginBottom: 24 } : undefined}
              />
            )
          }}
        />
      </FormContainer>
    </FixedButtonFormPageTemplate>
  )
}
