import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const AppleIcon: FunctionComponent<AppleIconProps> = ({ size = 20, color }) => {
  const { colors } = useTheme()
  return (
    <Svg testID={`${tID('AppleIcon')}`} width={size} height={size} viewBox='0 0 17 20' fill='none'>
      <Path
        d='M8.738 4.537c.887 0 2-.6 2.662-1.4.6-.724 1.037-1.737 1.037-2.75 0-.137-.012-.275-.037-.387-.988.037-2.175.663-2.888 1.5-.562.638-1.075 1.638-1.075 2.662 0 .15.026.3.038.35.063.013.162.026.263.026zM5.613 19.663c1.212 0 1.75-.813 3.262-.813 1.537 0 1.875.787 3.225.787 1.325 0 2.213-1.224 3.05-2.425.937-1.374 1.325-2.724 1.35-2.787-.087-.025-2.625-1.063-2.625-3.975 0-2.525 2-3.662 2.113-3.75-1.325-1.9-3.338-1.95-3.888-1.95-1.487 0-2.7.9-3.463.9-.825 0-1.912-.85-3.2-.85C2.987 4.8.5 6.825.5 10.65c0 2.375.925 4.887 2.063 6.513.974 1.375 1.825 2.5 3.05 2.5z'
        fill={color || colors.icons.appleIcon.fill}
      />
    </Svg>
  )
}
type AppleIconProps = {
  size?: string | number
  color?: string
}
