import React, { FunctionComponent, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { GestureResponderEvent, Pressable, PressableStateCallbackType, ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'
import { useFocusVisible } from 'use-focus-visible'

import { XIcon } from '../../atoms/icons'
import { Link } from '../../atoms/link/Link'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks'
import { getFocusBoxShadow } from '../../styles/commonStyles'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface XButtonProps {
  onPress: (event: GestureResponderEvent) => void
  style?: ViewStyle
  fillColor?: string
  showExitText?: boolean
  testID?: string
  size?: number
  alternateText?: React.ReactNode
}

const StyledView = styled.View<{ theme: ThemeType; focused?: boolean }>(({ theme, focused }) => ({
  alignItems: 'center',
  backgroundColor: focused ? theme.colors.backgroundHighlightPrimary : theme.colors.backgroundTransparent,
  borderRadius: '50px',
}))

const StyledPressable = styled(Pressable)<{ isMinWidthTablet: boolean; theme: ThemeType }>(
  ({ isMinWidthTablet, theme }) => ({
    borderRadius: '50px',
    ...(isMinWidthTablet && IS_WEB ? { width: 'fit-content' } : { alignSelf: 'flex-start', top: theme.spacing['4px'] }),
  }),
)

const Exit = ({
  onPress,
  focused,
  hovered,
  fillColor,
  alternateText,
}: {
  onPress?: (e: GestureResponderEvent) => void
  focused?: boolean
  hovered?: boolean
  fillColor?: string
  alternateText?: React.ReactNode
}) => {
  const { colors } = useTheme()
  return (
    <Link
      color={hovered || focused ? colors.linkHover : fillColor}
      text={alternateText ? alternateText : <FormattedMessage defaultMessage='Exit' description='exit button' />}
      onPress={onPress ?? noop}
    />
  )
}

export const XButton: FunctionComponent<XButtonProps> = ({
  onPress,
  style,
  fillColor,
  showExitText = true,
  testID = 'XButton',
  size = 18,
  alternateText,
}) => {
  const { focusVisible, onBlur, onFocus } = useFocusVisible()
  const { isMinWidthTablet } = useMediaQuerySize()
  const pressableRef = useRef<any>(null)
  const { breakpoints, colors } = useTheme()

  return (
    <StyledPressable
      ref={pressableRef}
      accessible
      onFocus={onFocus}
      onBlur={onBlur}
      onPress={onPress}
      isMinWidthTablet={isMinWidthTablet}
      style={[style, focusVisible ? getFocusBoxShadow({ colors }) : {}]}
      testID={tID(testID)}
      hitSlop={30}
    >
      {({ hovered }: PressableStateCallbackType) => (
        <StyledView focused={focusVisible}>
          {!breakpoints.isMobileSized && showExitText ? (
            <Exit
              hovered={hovered}
              onPress={onPress}
              focused={focusVisible}
              fillColor={fillColor || colors.iconDefault}
              alternateText={alternateText}
            />
          ) : (
            <XIcon fillColor={fillColor || colors.iconDefault} size={size} />
          )}
        </StyledView>
      )}
    </StyledPressable>
  )
}
