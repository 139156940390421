import { type NavigateFunction } from 'react-router-dom'

import { List } from 'immutable'

import {
  SEARCHER_TYPE_TO_MATCH_FOR,
  shouldNavigateToHPIPage,
  TREATMENT_OPTIONS,
} from '@lyrahealth-inc/shared-app-logic'
import { Linking } from '@lyrahealth-inc/ui-core-crossplatform'

import * as appointmentDashboardConstants from './appointmentDashboardConstants'
import {
  areAllCustomerProgramsBHBExclusive,
  HEALTH_PLAN_ELIGIBILITY_STATUSES,
  isAnyCustomerProgramBHBExclusive,
} from '../../../common/constants/healthPlanConstants'
import { TREATMENT_RECOMMENDATIONS_ROUTE } from '../../../common/constants/routingConstants'
import { deleteAppointmentById, getAppointmentsByUserId } from '../../../common/http/data/appointmentDashboard'
import { matchPaths } from '../../../common/utils/stringUtils'
import {
  getCustomerHealthPlansList,
  getHealthPlanExtensionEnabled,
  getIsEAPSessionCountExhausted,
  getProgramCoverageBreakdown,
} from '../../../data/customer/customerSelectors'
import * as MixpanelActions from '../../../data/mixpanel/index'
import {
  getIsUserInternational,
  getIsUserInternationalAndMinor,
  getShouldUserSeeInternationalConsentForDataCollection,
} from '../../../data/user/userSelectors'
import { getIsHealthPlanConfirmed } from '../../healthPlan/data/healthPlanSelectors'
import {
  clearDirectLinkIntent,
  clearDirectLinkSource,
  setIsCurrentSearchDirectPath,
  setMeetingFormat,
} from '../../onboard/data/onboardDataActions'
import {
  getIsLatestMatches,
  getLatestUserFavoriteProviders,
  getOnboardIsCurrentSearchDirectPath,
} from '../../onboard/data/onboardSelectors'
import { PAGE_ROUTES } from '../../onboard/data/page-navigation/location-actions'
import { updateTriageDemographics } from '../../onboard/data/triage-demographics/triage-demographics-actions'
import {
  clearTreatmentOptions,
  setSelectedTreatmentOption,
} from '../../onboard/treatmentOptions/data/treatmentOptionsActions'
import { saveHealthPlanSelectionFromTriage } from '../../onboard/triageHealthPlan/data/triageHealthPlanActions'
import { resetSelfHarmContactModalShownIndicator } from '../../triage/data/triageActions'
import { getIsChildSearchForCare } from '../../triage/data/triageSelectors'

export interface handleZeroViewSelectArgs {
  whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR
  navigate: NavigateFunction
  currentLocation: string
  showSummaryView: boolean
}

/* ---------------SCHEDULE CONFIRMATION PAGE----------------- */

export function clearSelectedAppointment() {
  return function (dispatch: $TSFixMe) {
    dispatch({
      type: appointmentDashboardConstants.CLEAR_SELECTED_APPOINTMENT,
    })
  }
}

export function clearBookingStatus() {
  return function (dispatch: $TSFixMe) {
    dispatch({
      type: appointmentDashboardConstants.CLEAR_BOOKING_STATUS,
    })
  }
}

export function appointmentDataLoaded() {
  return function (dispatch: $TSFixMe) {
    dispatch({
      type: appointmentDashboardConstants.APPOINTMENT_DATA_LOADED,
    })
  }
}

export function getAppointments({ userId }: $TSFixMe) {
  return function (dispatch: $TSFixMe) {
    return getAppointmentsByUserId(userId).then((response) => {
      dispatch({
        type: appointmentDashboardConstants.SET_APPOINTMENT_DASHBOARD_VALUE,
        data: response.data,
      })
      return response
    })
  }
}

export function deleteAppointment(appointmentId: $TSFixMe) {
  return function (_dispatch: $TSFixMe, getState: $TSFixMe) {
    const userId = getState().getIn(['user', 'id'])
    return deleteAppointmentById({ userId, appointmentId })
  }
}

export function getAppointmentIcs(appointmentId: { appointmentId: string | number }) {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    dispatch(
      MixpanelActions.trackEventWithObj({
        event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
        action: MixpanelActions.MIXPANEL_ACTION.ADD_TO_CALENDAR,
      }),
    )
    const userId = getState().getIn(['user', 'id'])
    Linking.openURL(`${window.location.origin}/services/v1/calendar/ics/${userId}/${appointmentId.appointmentId}`)
  }
}

export function handleZeroViewSelect({
  whoAmIHereFor,
  navigate,
  currentLocation,
  showSummaryView,
}: handleZeroViewSelectArgs) {
  return (dispatch: $TSFixMe) => {
    dispatch(clearDirectPathState())
    if (!matchPaths(currentLocation, ['/pathways/care-options', '/secure/index/family-care-options'])) {
      // Don't clear the direct link source for interim pages that may lead to other direct path searches.
      // See useNavigateToSearchForCare in mobile for matching logic
      dispatch(clearDirectLinkSource())
    }
    dispatch(resetSelfHarmContactModalShownIndicator())
    dispatch(setMeetingFormat(null))
    dispatch(updateTriageDemographics('matchFor', whoAmIHereFor))
    dispatch(goToViewAfterSearchCare(navigate, currentLocation, showSummaryView))
  }
}

export function clearDirectPathState() {
  return (dispatch: $TSFixMe) => {
    dispatch(clearTreatmentOptions())
    dispatch(setIsCurrentSearchDirectPath(false))
    dispatch(clearDirectLinkIntent())
  }
}

const goToViewAfterSearchCare = (navigate: NavigateFunction, currentLocation: string, showSummaryView: boolean) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState()
    const isHealthPlanConfirmed = getIsHealthPlanConfirmed(state)
    const customerPrograms = getProgramCoverageBreakdown(state)
    const isAllTreatmentOptionBHBExclusive = areAllCustomerProgramsBHBExclusive(customerPrograms)
    const isEAPSessionCountExhausted = getIsEAPSessionCountExhausted(state)
    const isChildSearch = getIsChildSearchForCare(state)
    const healthPlanList: List<$TSFixMe> = getCustomerHealthPlansList(state)
    const isUserInternational = getIsUserInternational(state)

    // When all options are HPI exclusive, an eligible health plan and payment info is required before
    // searching for care. Always go to overview page for user to add/edit or
    // confirm their health plan and payment info.
    // When some options are HPI exclusive and there is no eligible health plan saved, we should
    // do a soft health plan check.
    // For all other cases (i.e. EAP exclusive or some options are HPI exclusive and user has already saved an eligible health plan),
    // skip any health plan check and go to the onboarding flow.
    if (isAllTreatmentOptionBHBExclusive) {
      if (!isHealthPlanConfirmed) {
        if (healthPlanList.size < 1 && isUserInternational) {
          // HEALTH_PLAN_FORM page will not load if customer has no health plans
          return navigate(TREATMENT_RECOMMENDATIONS_ROUTE)
        }
        return navigate(PAGE_ROUTES.HPI_COVERAGE_CHECK.ONBOARD.HEALTH_PLAN_FORM)
      }
    }

    const healthPlanInfoRequired = shouldNavigateToHPIPage({
      customerPrograms: customerPrograms.toJS(),
      forChild: isChildSearch,
      isHealthPlanConfirmed,
      isEAPSessionCountExhausted,
    })
    if (healthPlanInfoRequired) {
      return navigate(PAGE_ROUTES.HPI_COVERAGE_CHECK.ONBOARD.HEALTH_PLAN_FORM)
    }

    dispatch(applySavedHealthPlanSelectionAndGoToOnboardingFlow(navigate, currentLocation, showSummaryView))
  }
}

export const applySavedHealthPlanSelectionAndGoToOnboardingFlow = (
  navigate: NavigateFunction,
  currentLocation: string,
  showSummaryView: boolean,
) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState()
    const healthPlanName = state.getIn(['healthPlan', 'healthPlanName'])
    const healthPlanList = getCustomerHealthPlansList(state)

    dispatch(applySavedHealthPlanSelection(healthPlanName, healthPlanList))
    return dispatch(goToOnboardingFlow(navigate, currentLocation, showSummaryView))
  }
}

// @ts-expect-error TS(7009): 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const applySavedHealthPlanSelection = (healthPlanName: $TSFixMe, healthPlanList = new List()) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const customerPrograms = getProgramCoverageBreakdown(getState())
    const healthPlanEligibilityStatus = getState().getIn(['healthPlan', 'medicalPlanEligibilityStatus'], null)
    const isAnyTreatmentOptionBHBExclusive = isAnyCustomerProgramBHBExclusive(customerPrograms)
    const isHealthPlanEnabled = getHealthPlanExtensionEnabled(getState())
    // Save previously selected healthplan selection as this will affect care options filtering
    let selectedHealthPlan = healthPlanList.find(
      (healthPlan: $TSFixMe) => healthPlan.get('eligibility_name') === healthPlanName,
    )
    if (
      isAnyTreatmentOptionBHBExclusive &&
      isHealthPlanEnabled &&
      healthPlanEligibilityStatus !== HEALTH_PLAN_ELIGIBILITY_STATUSES.ELIGIBLE
    ) {
      selectedHealthPlan = { planName: 'unknown', bhbEligible: true }
    }
    if (selectedHealthPlan) {
      dispatch(saveHealthPlanSelectionFromTriage(selectedHealthPlan))
    }
  }
}

export const goToOnboardingFlow = (navigate: NavigateFunction, currentLocation: string, showSummaryView: boolean) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const whoAmIHereFor = getState().getIn(['triageDemographics', 'matchFor'], 'self')
    const shouldSeeInternationalConsentForDataCollection = getShouldUserSeeInternationalConsentForDataCollection(
      getState(),
    )
    const isUserInternationalAndMinor = getIsUserInternationalAndMinor(getState())
    const isLatestMatches = getIsLatestMatches(getState())
    const userFavoriteProviders = getLatestUserFavoriteProviders(getState())
    // get the last provider in the list as that is the latest provider
    const treatmentPreference = userFavoriteProviders[userFavoriteProviders.length - 1]?.treatmentPreference
    const isDirectPath = getOnboardIsCurrentSearchDirectPath(getState())

    switch (whoAmIHereFor) {
      case 'otherChild':
        navigate('/secure/onboard/about-child')
        break
      default:
        if (isDirectPath) {
          navigate('/secure/onboard/match-location')
        } else if (isUserInternationalAndMinor) {
          navigate(TREATMENT_RECOMMENDATIONS_ROUTE)
        } else if (shouldSeeInternationalConsentForDataCollection) {
          navigate('/secure/onboard/international-consent')
        } else if (isLatestMatches) {
          dispatch(setSelectedTreatmentOption(treatmentPreference))
          if (treatmentPreference === TREATMENT_OPTIONS.COACHING) {
            navigate({
              pathname: `/secure/onboard/${PAGE_ROUTES.COACHES}/${PAGE_ROUTES.LATEST_PROVIDER_MATCHES}`,
            })
          } else {
            navigate({
              pathname: `/secure/onboard/${PAGE_ROUTES.T1_MULTI_PROVIDERS}/${PAGE_ROUTES.LATEST_PROVIDER_MATCHES}`,
            })
          }
        } else if (showSummaryView) {
          navigate(PAGE_ROUTES.ONBOARD.SUMMARY)
        } else {
          navigate('/secure/onboard/about', { state: { from: currentLocation } })
        }
    }
  }
}
