import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl'

import { ETHNICITY, GENDER_IDENTITY } from '../../models'

export const GENDER_IDENTITY_MESSAGES: Record<GENDER_IDENTITY, MessageDescriptor> = defineMessages({
  [GENDER_IDENTITY.MALE]: {
    defaultMessage: 'Male',
    description: 'Option for choosing male as gender identity',
  },
  [GENDER_IDENTITY.FEMALE]: {
    defaultMessage: 'Female',
    description: 'Option for choosing female as gender identity',
  },
  [GENDER_IDENTITY.TRANSGENDER]: {
    defaultMessage: 'Transgender',
    description: 'Option for choosing transgender as gender identity',
  },
  [GENDER_IDENTITY.NON_BINARY]: {
    defaultMessage: 'Non-binary',
    description: 'Option for choosing non-binary as gender identity',
  },
  [GENDER_IDENTITY.OTHER]: {
    defaultMessage: 'Other',
    description: 'Option for choosing "other" as gender identity',
  },
  [GENDER_IDENTITY.PREFER_NOT_TO_SAY]: {
    defaultMessage: 'Prefer not to say',
    description: 'Option for not selecting a specific type as gender identity',
  },
})

export const ETHNICITY_MESSAGES: Record<ETHNICITY, MessageDescriptor> = defineMessages({
  [ETHNICITY.ASIAN]: {
    defaultMessage: 'Asian',
    description: `Option text for user's race/ethnicity`,
  },
  [ETHNICITY.BLACK_OR_AFRICAN_AMERICAN]: {
    defaultMessage: 'Black or African American',
    description: `Option text for user's race/ethnicity`,
  },
  [ETHNICITY.HISPANIC_OR_LATINX]: {
    defaultMessage: 'Hispanic or Latinx',
    description: `Option text for user's race/ethnicity`,
  },
  [ETHNICITY.NATIVE_AMERICAN_OR_ALASKA_NATIVE]: {
    defaultMessage: 'Native American or Alaska Native',
    description: `Option text for user's race/ethnicity`,
  },
  [ETHNICITY.NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER]: {
    defaultMessage: 'Native Hawaiian or Pacific Islander',
    description: `Option text for user's race/ethnicity`,
  },
  [ETHNICITY.WHITE]: {
    defaultMessage: 'White',
    description: `Option text for user's race/ethnicity`,
  },
  [ETHNICITY.PREFER_NOT_TO_SAY]: {
    defaultMessage: 'Prefer not to say',
    description: `Option text for user's race/ethnicity when user choose to not disclose`,
  },
})

export const ALREADY_HAVE_AN_ACCOUNT_MSG = defineMessage({
  defaultMessage: 'Already have an account? <link>Log In</link>',
  description: 'Message on registration page prompting users to sign in with an existing account',
})

export const ACTIVATE_ACCOUNT_MSG = defineMessage({
  defaultMessage: 'Activate account',
  description: 'Label for a button to continue account registration',
})

export const CHECK_AGAIN_MSG = defineMessage({
  defaultMessage: 'Check again',
  description: 'Label for a button to check eligibility status during account registration',
})

export const CONTACT_A_CARE_NAVIGATOR_MSG = defineMessage({
  defaultMessage: 'Contact a Care Navigator',
  description: 'Button text to get help with the Care Navigator team. Redirects the user to a contact page.',
})
