import { useMemo } from 'react'
import { matchPath, PathMatch, useLocation, useSearchParams } from 'react-router-dom'

import { isObject } from 'lodash-es'

import { COMMON_MIXPANEL_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'

import { usePreviousRoute } from './usePreviousRoute'
import { routeToPageMap } from '../constants/mixpanelConstants'

/**
 * - Get entry point for mixpanel events
 * - If using this for any view events, make sure to wait until the result is not null.
 * - Make sure there is a mapping of route -> page for your entry point inside `routeToPageMap`
 * @param defaultEntryPoint If there is no previous route, use this as the entry point (eg Direct Link)
 * @returns An object with `entryPoint` prop. `location.state?.mixpanelEntryPoint` takes priority, then `entryPoint` defaults to the previously viewed page.
 * - If `null` - the route is currently changing and entry point is not ready
 * - If `{}` - there is no entry point (no previous route)
 */
export const useDefaultMixpanelEntryPoint = (defaultEntryPoint?: string) => {
  const prevRoute = usePreviousRoute()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const utmCampaignEntryPoint = searchParams.get('utm_campaign')
  const prevRouteMatch = Object.keys(routeToPageMap).find((route) => {
    if (!prevRoute) {
      return null
    }
    return matchPath(route, prevRoute)
  })

  return useMemo(() => {
    const entryPointFromParam =
      location.state?.mixpanelEntryPoint || searchParams.get(COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT)
    if (entryPointFromParam) {
      return { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: entryPointFromParam }
    }

    if (prevRoute === null) {
      return null
    }

    const mixpanelPage = prevRouteMatch
      ? routeToPageMap[isObject(prevRouteMatch) ? (prevRouteMatch as PathMatch)?.pattern?.path : prevRouteMatch]
      : undefined
    if (prevRoute && mixpanelPage) {
      return { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelPage }
    }
    if (Boolean(utmCampaignEntryPoint)) {
      return { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: utmCampaignEntryPoint }
    }
    if (defaultEntryPoint) {
      return { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: defaultEntryPoint }
    }

    return {}
  }, [
    defaultEntryPoint,
    location.state?.mixpanelEntryPoint,
    prevRoute,
    searchParams,
    utmCampaignEntryPoint,
    prevRouteMatch,
  ])
}
