import { useSelector } from 'react-redux'

import {
  getCustomerInfo,
  getCustomerName,
  getDisplayVisitsPerIssuePerYear,
  getHideChildSearchCTA,
  getHideDependentField,
  getIsCustomerEAPExclusive,
  getIsCustomerHPIExclusive,
  getNumberOfSessions,
  getNumberOfSessionsByProgram,
} from '../../data/customer/customerSelectors'
import { getIsUserInternational } from '../../data/user/userSelectors'

/**
 * Hook to retrieve customer cost properties for microsite FAQ pages
 */
export const useCustomerCostProperties = () => {
  const customerName = useSelector(getCustomerName)
  const customerDisplayName = useSelector(getCustomerInfo)
  const numberOfSessions = useSelector(getNumberOfSessions)
  const isCustomerEAPExclusive = useSelector(getIsCustomerEAPExclusive)
  const isCustomerHPIExclusive = useSelector(getIsCustomerHPIExclusive)
  const shouldHideChildSearchCTA = useSelector(getHideChildSearchCTA)
  const isInternational = useSelector(getIsUserInternational)
  const hideDependentField = useSelector(getHideDependentField)
  const displayVisitsPerIssuePerYear = useSelector(getDisplayVisitsPerIssuePerYear)
  const numberOfSessionsByProgram = useSelector(getNumberOfSessionsByProgram)

  return {
    customerName,
    customerDisplayName,
    isCustomerEAPExclusive,
    isCustomerHPIExclusive,
    supportsDependents: (!shouldHideChildSearchCTA && !isInternational) || !hideDependentField,
    numberOfSessions,
    displayVisitsPerIssuePerYear,
    numberOfSessionsByProgram,
  }
}
