import React, { useEffect, useRef, useState } from 'react'

import { useTheme } from 'styled-components/native'

import { useScript } from '@lyrahealth-inc/shared-app-logic'
import { SsoButton } from '@lyrahealth-inc/ui-core-crossplatform'
import {
  SSO_BUTTON_MESSAGE_TYPE,
  SSO_BUTTON_TYPE,
} from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/ssoButton/SsoButton'

const GOOGLE_AUTH_SDK = 'https://accounts.google.com/gsi/client'

declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          initialize: (options: any) => void
          renderButton: (container: HTMLElement, options: any) => void
        }
      }
    }
  }
}

export interface GoogleJwtCredentials {
  given_name: string
  family_name: string
  email: string
  picture: string
  sub: string
}

export interface GoogleJwtResponse {
  credential?: string
}

export type GoogleInitializationCallback = (response: GoogleJwtResponse) => void

export interface SignUpWithGoogleButtonProps {
  clientId: string
  locale: string
  buttonWidth: number
  responseHandler: GoogleInitializationCallback
  onButtonPress?: () => void
}

export const SignUpWithGoogleButton = ({
  clientId,
  responseHandler,
  buttonWidth,
  locale,
  onButtonPress,
}: SignUpWithGoogleButtonProps) => {
  const scriptStatus = useScript(GOOGLE_AUTH_SDK, /* defer */ true, /* crossorigin */ undefined)
  const containerRef = useRef<HTMLDivElement>(null)
  const [googleSSOButtonElement, setGoogleSSOButtonElement] = useState<Element | null>(null)
  const { colors } = useTheme()

  useEffect(() => {
    if (scriptStatus !== 'ready') return

    const { google } = window
    if (!google) return

    google.accounts.id.initialize({
      client_id: clientId,
      callback: responseHandler,
      context: 'signup',
      auto_select: false,
    })
  }, [clientId, scriptStatus, responseHandler])

  useEffect(() => {
    if (scriptStatus !== 'ready') return

    const { google } = window
    if (!google) return

    if (containerRef.current) {
      google.accounts.id.renderButton(containerRef.current, {
        theme: 'outline',
        size: 'large',
        text: 'signup_with',
        width: buttonWidth,
        locale: locale.replace('-', '_'),
      })
      setGoogleSSOButtonElement(containerRef.current?.querySelector('div[role=button]'))
    }
  }, [clientId, locale, scriptStatus, buttonWidth])

  const onGoogleSSOButtonPress = () => {
    if (googleSSOButtonElement && googleSSOButtonElement instanceof HTMLElement) {
      onButtonPress && onButtonPress()
      googleSSOButtonElement?.click()
    }
  }

  return (
    <>
      <div ref={containerRef} style={{ display: 'none' }} />

      <SsoButton
        type={SSO_BUTTON_TYPE.GOOGLE}
        messageType={SSO_BUTTON_MESSAGE_TYPE.SIGN_UP}
        onPress={onGoogleSSOButtonPress}
        style={{ margin: 5, backgroundColor: colors.backgroundPrimary }}
      />
    </>
  )
}
