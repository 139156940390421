import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { HEADER_NAV_ITEM, headerNavItemData } from '@lyrahealth-inc/shared-app-logic'

import { HeaderProps } from './Header'
import { tID } from '../../utils'
import { LightHomeToolbar } from '../lightHomeToolbar/LightHomeToolbar'

/** Main header for the Lyra app (web) */
export const Header: FunctionComponent<HeaderProps> = ({
  isInternationalUser,
  shouldShowCareNavigatorHeaderEntrypoint,
  careNavigatorModal,
  careNavigatorEntryPoint,
  headerNavMenuProps,
  mainNavItemsProps,
  currentPathname,
  currentScreen,
  onLogoPressed,
  shouldShowNavMenu = true,
  shouldShowBack,
  onBackPressed,
  title,
  messageButton,
}) => {
  const { formatMessage } = useIntl()

  const navItems = [
    {
      id: HEADER_NAV_ITEM.HOME,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.HOME].label),
      onPressItem: mainNavItemsProps?.handleHomePress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.HOME].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.HOME].activeScreenNames,
      condition: mainNavItemsProps?.shouldShowHome,
    },
    {
      id: HEADER_NAV_ITEM.CARE,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.CARE].label),
      onPressItem: mainNavItemsProps?.handleCarePress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.CARE].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.CARE].activeScreenNames,
      condition: mainNavItemsProps?.shouldShowCare,
    },
    {
      id: HEADER_NAV_ITEM.SESSIONS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.SESSIONS].label),
      onPressItem: mainNavItemsProps?.handleSessionsPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.SESSIONS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.SESSIONS].activeScreenNames,
      condition: mainNavItemsProps?.shouldShowSessions,
    },
    {
      id: HEADER_NAV_ITEM.PROGRESS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.PROGRESS].label),
      onPressItem: mainNavItemsProps?.handleProgressPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.PROGRESS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.PROGRESS].activeScreenNames,
      condition: mainNavItemsProps?.shouldShowProgress,
    },
    {
      id: HEADER_NAV_ITEM.ESSENTIALS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.ESSENTIALS].label),
      onPressItem: mainNavItemsProps?.handleEssentialsPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.ESSENTIALS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.ESSENTIALS].activeScreenNames,
      condition: mainNavItemsProps?.shouldShowEssentials,
    },
  ]

  return (
    <LightHomeToolbar
      testID={tID('AppHeader')}
      isInternational={isInternationalUser}
      shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
      careNavigatorModal={careNavigatorModal}
      careNavigatorEntryPoint={careNavigatorEntryPoint}
      showNavMenu={shouldShowNavMenu}
      headerNavMenuProps={headerNavMenuProps}
      navItems={navItems}
      currentPathname={currentPathname}
      currentScreen={currentScreen}
      onLogoPressed={onLogoPressed}
      showBack={shouldShowBack}
      onBackPressed={onBackPressed}
      title={title}
      messageButton={messageButton}
    />
  )
}
