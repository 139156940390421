import { useCallback } from 'react'

import { checkBusinessHours, User } from '@lyrahealth-inc/shared-app-logic'

type OmniChatParams = {
  user: User
  events: { onOnline: () => void; onOffline: () => void; onDisabled: () => void }
}

declare global {
  interface Window {
    hasLoadedOmniChat: boolean
  }
}

export const useOmniChat = ({ user, events }: OmniChatParams) => {
  const setOmniChatProps = useCallback(() => {
    const lyraId = user?.lyraId
    const userFirstName = user?.firstname
    const userLastName = user?.lastname
    const userEmail = user?.username
    if (window.hasLoadedOmniChat) {
      const extraPrechatFormDetails = [
        {
          label: 'Lyra ID',
          value: lyraId,
          displayToAgent: true,
        },
        {
          label: 'First Name',
          value: userFirstName,
          displayToAgent: true,
        },
        {
          label: 'Last Name',
          value: userLastName,
          displayToAgent: true,
        },
        {
          label: 'Email',
          value: userEmail,
          displayToAgent: true,
        },
        {
          label: 'Case Request Type',
          value: 'New Chat',
          displayToAgent: false,
        },
        {
          label: 'Case Origin',
          value: 'Chat',
          displayToAgent: false,
        },
      ]

      const extraPrechatInfo = [
        {
          entityName: 'Contact',
          showOnCreate: true,
          linkToEntityName: 'Case',
          linkToEntityField: 'ContactId',
          saveToTranscript: 'ContactId',
          entityFieldMaps: [
            {
              isExactMatch: true,
              fieldName: 'Lyra_ID__c',
              doCreate: false,
              doFind: true,
              label: 'Lyra ID',
            },
          ],
        },
        {
          entityName: 'Case',
          showOnCreate: true,
          saveToTranscript: 'CaseId',
          entityFieldMaps: [
            {
              isExactMatch: false,
              fieldName: 'Subject',
              doCreate: true,
              doFind: false,
              label: 'Subject',
            },
            {
              isExactMatch: false,
              fieldName: 'Status',
              doCreate: true,
              doFind: false,
              label: 'Status',
            },
            {
              isExactMatch: false,
              fieldName: 'Origin',
              doCreate: true,
              doFind: false,
              label: 'Case Origin',
            },
            {
              isExactMatch: false,
              fieldName: 'Case_Request_Type__c',
              doCreate: true,
              doFind: false,
              label: 'Case Request Type',
            },
          ],
        },
      ]

      // https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/embedded_services_web_start_and_end_chat.htm#:~:text=embedded_svc.liveAgentAPI.startChat(attributes)%3B
      ;(window as $TSFixMe).embedded_svc.liveAgentAPI.startChat({
        directToAgentRouting: {
          buttonId: '5737X0000008WWI',
          fallback: true,
        },
        extraPrechatInfo: extraPrechatInfo,
        extraPrechatFormDetails: extraPrechatFormDetails,
      })
    }
  }, [user?.firstname, user?.lastname, user?.lyraId, user?.username])

  const loadOmniChat = useCallback(() => {
    const { onOnline, onOffline, onDisabled } = events

    if (window.hasLoadedOmniChat) {
      const online = checkBusinessHours()
      if (online && !window.Cypress) {
        onOnline()
        setOmniChatProps()
      } else {
        onOffline()
      }
    } else {
      onDisabled()
    }
  }, [setOmniChatProps, checkBusinessHours, events])

  return { loadOmniChat }
}
