import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { Map } from 'immutable'
import { noop } from 'lodash-es'

import {
  PathwaysRedirectUrls,
  REGISTRATION_PAGES,
  sanitizeEmailAddressInput,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { Layout, Modal, ReCaptchaPrivacyAndTerms, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import {
  EmailCaptureFormValues,
  RegistrationEmailCaptureForm,
} from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/registrationEmailCaptureForm/RegistrationEmailCaptureForm'
import { RegistrationVariantEmailCaptureForm } from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/registrationEmailCaptureForm/RegistrationVariantEmailCaptureForm'

import styles from './registrationEmailCaptureContainer.module.scss'
import { ERROR_CODES, LOCAL_STORAGE_KEY } from '../../../common/constants/appConstants'
import { REDIRECT_URL_KEY } from '../../../common/constants/routingConstants'
import { useDefaultMixpanelEntryPoint } from '../../../common/hooks/useDefaultMixpanelEntryPoint'
import { useDefaultMixpanelPage } from '../../../common/hooks/useDefaultMixpanelPage'
import { useGetSsoIframeContent } from '../../../common/hooks/useGetSsoIframeContent'
import { useTrackViewPage } from '../../../common/hooks/useTrackViewPage'
import { postEmailVerification } from '../../../common/http/data/verifyEmail'
import { getCaptchaKey } from '../../../common/utils/customerUtils'
import { getDeviceUUID } from '../../../data/appGlobals/appGlobalsSelectors'
import { getCustomerName, getDepartingRedirectURL } from '../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { getSelectedEssentialsTopicIds, getSelectedPathwayOption } from '../../../data/register/registerSelectors'
import { dispatchMixpanelForEmailConfirmation } from '../data/registerActions'

const { UNPROCESSABLE_ENTITY: USER_ALREADY_VERIFIED_STATUS_CODE } = ERROR_CODES

type RegistrationEmailCaptureContainerProps = ConnectedProps<typeof connector> & {
  isModalView?: boolean
  showModalDelay?: number
  enableModalClose?: boolean
  onCloseRegistrationModal?: () => void
  isPreview?: boolean
  previewSubmitCallback?: (email: string, error?: string) => void
  previewComponents?: React.ReactElement
  /** Entry point to be tracked after landing in the app after registration */
  registrationEntryPoint?: string
}

const RegistrationEmailCaptureContainer: FC<RegistrationEmailCaptureContainerProps> = ({
  recaptchaKey,
  customerName,
  departingRedirectUrl,
  selectedPathwayOption,
  dispatchMixpanelForEmailConfirmation,
  selectedEssentialsTopicIds,
  isModalView = false,
  showModalDelay = 0,
  enableModalClose = false,
  onCloseRegistrationModal,
  trackEventWithObj,
  isPreview = false,
  previewSubmitCallback = () => {},
  previewComponents,
  registrationEntryPoint,
}) => {
  const navigate = useNavigate()
  const { activeLanguage } = useLyraIntl()

  const [isEmailError, setIsEmailError] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState('none')
  const [isGenerateVerificationTokenLoading, setIsGenerateVerificationTokenLoading] = useState(false)

  const captchaRef = useRef<HTMLFormElement>(null)
  const { state } = useLocation()
  const mixpanelEntryPoint = useDefaultMixpanelEntryPoint()
  const mixpanelPage = useDefaultMixpanelPage()
  const deviceUUID = useSelector(getDeviceUUID)
  /** Entry point to track post registration */
  const entryPointToTrackAfterRegistering = state?.registrationEntryPoint || registrationEntryPoint
  // TODO CEA-2593 Replace localstorage with redux
  const wellnessCheckInStorageStr = isPreview ? localStorage.getItem(LOCAL_STORAGE_KEY.WELLNESS_CHECK_IN) : ''

  const { renderGoogleSSOIframe, renderAppleSSOIframe } = useGetSsoIframeContent(
    selectedPathwayOption,
    entryPointToTrackAfterRegistering,
  )

  useTrackViewPage(
    REGISTRATION_PAGES.EMAIL_CAPTURE,
    {
      page: mixpanelPage?.page !== REGISTRATION_PAGES.EMAIL_CAPTURE ? mixpanelPage?.page : undefined, // For when this component is opened in a modal
    },
    mixpanelEntryPoint,
  )

  useEffect(() => {
    if (departingRedirectUrl) {
      window.location.replace(departingRedirectUrl as string)
    }
  }, [departingRedirectUrl])

  const submitForm = async (values: EmailCaptureFormValues) => {
    const sanitizedEmail = sanitizeEmailAddressInput(values.emailAddress)
    if (sanitizedEmail) {
      values.emailAddress = sanitizedEmail
    }

    setIsGenerateVerificationTokenLoading(true)
    try {
      await postEmailVerification({
        username: encodeURIComponent(values.emailAddress),
        language: encodeURIComponent(activeLanguage),
        ...(deviceUUID && { deviceUUID: encodeURIComponent(deviceUUID) }),
        ...(selectedPathwayOption && { pathwayOption: encodeURIComponent(selectedPathwayOption) }),
        ...(selectedEssentialsTopicIds && { essentialsTopicIds: encodeURIComponent(selectedEssentialsTopicIds) }),
        ...(entryPointToTrackAfterRegistering && { entryPoint: encodeURIComponent(entryPointToTrackAfterRegistering) }),
        ...(wellnessCheckInStorageStr && { wellnessCheckInBody: wellnessCheckInStorageStr }),
      })

      dispatchMixpanelForEmailConfirmation()

      // Wellness check in flow should stay on current page rather than redirect
      if (isPreview && previewSubmitCallback) {
        previewSubmitCallback(values.emailAddress)
      } else {
        navigate(`/register/verify?email=${encodeURIComponent(values.emailAddress)}`)
      }
    } catch (error: any) {
      if (error?.response?.status === USER_ALREADY_VERIFIED_STATUS_CODE) {
        if (isPreview && previewSubmitCallback) {
          previewSubmitCallback(values.emailAddress, 'registered')
        } else {
          navigate(`/register/verify?error=registered`)
        }
      } else {
        setIsEmailError(true)
      }
    } finally {
      setIsGenerateVerificationTokenLoading(false)
    }
  }

  const onSignUpButtonPress = async (values: EmailCaptureFormValues) => {
    setIsEmailError(false)
    if (recaptchaResponse === 'none') {
      try {
        await captchaRef?.current?.execute()
        submitForm(values)
      } catch (error: unknown) {
        console.error(error as string)
      }
    } else {
      submitForm(values)
    }
  }

  const onSignInLinkPress = () => {
    if (isPreview) {
      window.sessionStorage.setItem(REDIRECT_URL_KEY, JSON.stringify(PathwaysRedirectUrls.WELLNESS_CHECK_IN))
    }
    navigate(`/login`)
  }

  const handleRecaptchaSuccess = (response: string) => {
    setRecaptchaResponse(response)
  }

  const renderReCaptchaContent = () => {
    return (
      <>
        <ReCaptchaPrivacyAndTerms singleLineTerms={isModalView} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <ReCAPTCHA
          className={styles.hide}
          ref={captchaRef}
          size='invisible'
          badge='inline'
          sitekey={recaptchaKey}
          onChange={handleRecaptchaSuccess} // Success Callback
        />
      </>
    )
  }

  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const snapHeight = useRef(Layout.window.height).current - 40
  const snapPoints = [snapHeight]

  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])

  const onClose = useCallback(() => {
    bottomSheetRef.current?.close()
    if (onCloseRegistrationModal) {
      onCloseRegistrationModal()
    }
  }, [onCloseRegistrationModal])

  const formContent = isPreview ? (
    //TODO: Remove RegistrationVariantEmailCaptureForm and modify RegistrationEmailCaptureForm to support isPreview mode (https://lyrahealth.atlassian.net/browse/CEA-2582)
    <RegistrationVariantEmailCaptureForm
      isEmailError={isEmailError}
      onSignInLinkPress={onSignInLinkPress}
      onSignUpButtonPress={onSignUpButtonPress}
      reCaptchaContent={renderReCaptchaContent()}
      customAppleSSOContent={renderAppleSSOIframe()}
      customGoogleSSOContent={renderGoogleSSOIframe()}
      isGenerateVerificationTokenLoading={isGenerateVerificationTokenLoading}
      isPreview={isPreview}
      previewComponents={previewComponents}
    />
  ) : (
    <RegistrationEmailCaptureForm
      isModalView={isModalView}
      isEmailError={isEmailError}
      onSignInLinkPress={onSignInLinkPress}
      onSignUpButtonPress={onSignUpButtonPress}
      reCaptchaContent={renderReCaptchaContent()}
      customAppleSSOContent={renderAppleSSOIframe()}
      customGoogleSSOContent={renderGoogleSSOIframe()}
      isGenerateVerificationTokenLoading={isGenerateVerificationTokenLoading}
      customerName={customerName}
    />
  )

  return (
    <>
      {isModalView ? (
        <Modal
          modalContents={formContent}
          visible
          scrollable
          scrollableModalWidth='464px'
          scrollableModalHeight='auto'
          showCloseIcon={enableModalClose}
          showDelay={showModalDelay}
          onRequestClose={enableModalClose ? onClose : noop}
          onCloseEnd={enableModalClose ? onClose : noop}
          snapPoints={snapPoints}
          disableClose={!enableModalClose}
          onLayout={openBottomSheet}
          showCloseIconInBottomSheet={enableModalClose}
        />
      ) : (
        <div className={styles.rootContainer}>{formContent}</div>
      )}
    </>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    recaptchaKey: getCaptchaKey($$state),
    customerName: getCustomerName($$state),
    departingRedirectUrl: getDepartingRedirectURL($$state),
    selectedPathwayOption: getSelectedPathwayOption($$state),
    selectedEssentialsTopicIds: getSelectedEssentialsTopicIds($$state),
  }
}

const mapDispatchToProps = {
  dispatchMixpanelForEmailConfirmation,
  trackEventWithObj,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(RegistrationEmailCaptureContainer))
