import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CostMessagingModalContent,
  CoverageType,
  GetCostMessagingModalTextParams,
  isUnlimitedSessionLimit,
  SessionLimitByProgram,
} from '@lyrahealth-inc/shared-app-logic'

export const eapCoachingOrTherapyTitleForYourself = (
  <FormattedMessage
    defaultMessage='Coaching or therapy for yourself'
    description='Cost messaging modal EAP title for yourself text'
  />
)

export const eapCoachingOrTherapyTitleForFamily = (
  <FormattedMessage
    defaultMessage='Coaching or therapy for your family'
    description='Cost messaging modal EAP title for family text'
  />
)

export const eapProgramLevelSessionLimitTitleForYourself = (
  <FormattedMessage defaultMessage='For yourself' description='Cost messaging modal EAP title for yourself text' />
)

export const eapProgramLevelSessionLimitTitleForFamily = (isAdultDependent: boolean) => (
  <FormattedMessage
    defaultMessage='For your family ({isAdultDependent, select, true {18+} other {0-17}})'
    description='Cost messaging modal EAP title for your family text'
    values={{ isAdultDependent }}
  />
)

type ProgramLevelSessionLimitUserType = 'self' | 'adult_dependent' | 'child'

export const eapProgramLevelSessionLimitBody = (
  numberOfSessionsByProgram: SessionLimitByProgram,
  userTypeForCopy: ProgramLevelSessionLimitUserType,
  customerDisplayName: string,
) => (
  <FormattedMessage
    defaultMessage='{userTypeForCopy, select, adult_dependent {Adult dependents} child {Children} other {You}} get {therapySessionCount} therapy {userTypeForCopy, select, child {sessions} other {and {isUnlimitedCoaching, select, true {unlimited} other {{coachingSessionCount}}} coaching sessions,}} fully covered by {customerDisplayName}.'
    description='Cost messaging modal description for users with program breakdown'
    values={{
      userTypeForCopy,
      customerDisplayName,
      therapySessionCount: numberOfSessionsByProgram.therapy,
      coachingSessionCount: numberOfSessionsByProgram.coaching,
      isUnlimitedCoaching: isUnlimitedSessionLimit(numberOfSessionsByProgram.coaching),
    }}
  />
)

interface EAP_COST_MESSAGING_PARAMS {
  numberOfSessions: string
  customerDisplayName: string
  numberOfSessionsByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
}

const eapSessionForYourself = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total sessions, fully covered by {customerDisplayName}.'
      description='Cost messaging modal session description text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapSessionForFamily = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total sessions per dependent (child, spouse, or domestic partner), fully covered by {customerDisplayName}.'
      description='Cost messaging modal session description for family text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapMedicalSessionForYourself = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total covered medication consultations, fully covered by {customerDisplayName}.'
      description='Cost messaging modal description medical sessions text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapMedicalSessionForFamily = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total covered medication consultations per you and each dependent (child, spouse, or domestic partner), fully covered by {customerDisplayName}.'
      description='Cost messaging modal description medical sessions for family text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

export const eapAndHpiMedicationDescription = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for medical consultations may be available. We’ll need to verify your plan to estimate your cost.'
    description='Cost messaging modal description for EAP with HPI medication text'
  />
)

const eapCoachingOrTherapyTitleWithoutDependent = (
  <FormattedMessage
    defaultMessage='Coaching or therapy'
    description='Cost messaging modal EAP coaching or therapy title text'
  />
)

const hpiTherapyTitleForYourself = (
  <FormattedMessage
    defaultMessage='Therapy for yourself'
    description='Cost messaging modal HPI title for yourself text'
  />
)

const hpiTherapyDescriptionForYourself = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for mental health sessions may be available to you.'
    description='Cost messaging modal HPI description for yourself text'
  />
)

const hpiTherapyTitleForFamily = (
  <FormattedMessage
    defaultMessage='Therapy for your family'
    description='Cost messaging modal HPI therapy title for family text'
  />
)

const hpiTherapyDescriptionForFamily = (
  <FormattedMessage
    defaultMessage='Cost-sharing for mental health sessions may be available to your dependents (child, spouse, or domestic partner).'
    description='Cost messaging modal HPI therapy description for family text'
  />
)

const hpiTherapyTitleWithoutDependent = (
  <FormattedMessage
    defaultMessage='Therapy'
    description='Cost messaging modal HPI therapy without dependent title text'
  />
)

export const medicalConsultationTitle = (
  <FormattedMessage
    defaultMessage='Medication consultation'
    description='Cost messaging modal medication consultation title text'
  />
)

const eapAndHpiSessionTitle = (
  <FormattedMessage
    defaultMessage='Session coverage through your health plan'
    description='Cost messaging modal EAP and HPI session title text'
  />
)

const eapAndHpiSessionDescription = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for sessions may also be available. We’ll need to verify your plan to estimate your cost.'
    description='Cost messaging modal EAP and HPI session description text'
  />
)

const eapCoachingAndHpiTherapyCoachingTitle = (
  <FormattedMessage
    defaultMessage='Coaching'
    description='Cost messaging modal EAP coaching and HPI therapy coaching title text'
  />
)

const eapCoachingAndHpiTherapyTherapyTitle = (
  <FormattedMessage
    defaultMessage='Therapy'
    description='Cost messaging modal EAP coaching and HPI therapy therapy title text'
  />
)

const eapCoachingAndHpiTherapyCoachingTitleWithDependent = (
  <FormattedMessage
    defaultMessage='Coaching for yourself'
    description='Cost messaging modal EAP coaching and HPI therapy coaching title text'
  />
)

const eapCoachingAndHpiTherapyCoachingForFamilyTitle = (
  <FormattedMessage
    defaultMessage='Coaching for your family'
    description='Cost messaging modal EAP coaching and HPI therapy family title text'
  />
)

const eapCoachingAndHpiTherapyTherapyTitleWithDependent = (
  <FormattedMessage
    defaultMessage='Therapy for you and your family'
    description='Cost messaging modal EAP coaching and HPI therapy therapy title text'
  />
)

const eapCoachingAndHpiTherapyTherapyDescriptionBaseText = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for therapy sessions may be available. We’ll need to verify your plan to estimate your cost.'
    description='Cost messaging modal EAP coaching and HPI therapy therapy descripton family description text'
  />
)

export const eapCoachingAndHpiTherapyWithDependentsBaseContent = {
  title: eapCoachingAndHpiTherapyTherapyTitleWithDependent,
  description: eapCoachingAndHpiTherapyTherapyDescriptionBaseText,
}

export const eapCoachingAndHpiTherapyNoDependentsBaseContent = {
  title: eapCoachingAndHpiTherapyTherapyTitle,
  description: eapCoachingAndHpiTherapyTherapyDescriptionBaseText,
}

export const eapCoachingAndHpiTherapyWithDependentsCoachingContent = ({
  numberOfSessions,
  customerDisplayName,
}: EAP_COST_MESSAGING_PARAMS) => [
  {
    title: eapCoachingAndHpiTherapyCoachingTitleWithDependent,
    description: eapSessionForYourself(numberOfSessions, customerDisplayName),
  },
  {
    title: eapCoachingAndHpiTherapyCoachingForFamilyTitle,
    description: eapSessionForFamily(numberOfSessions, customerDisplayName),
  },
]
export const eapCoachingAndHpiTherapyNoDependentsCoachingContent = ({
  numberOfSessions,
  customerDisplayName,
}: EAP_COST_MESSAGING_PARAMS) => [
  {
    title: eapCoachingAndHpiTherapyCoachingTitle,
    description: eapSessionForYourself(numberOfSessions, customerDisplayName),
  },
]

export const eapOnlyNoDependentsBaseTexts = ({
  numberOfSessions,
  customerDisplayName,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: EAP_COST_MESSAGING_PARAMS) => {
  if (numberOfSessionsByProgram && isProgramLevelSessionLimitEnabled) {
    return [
      {
        title: eapProgramLevelSessionLimitTitleForYourself,
        description: eapProgramLevelSessionLimitBody(numberOfSessionsByProgram, 'self', customerDisplayName),
      },
    ]
  }
  return [
    {
      title: eapCoachingOrTherapyTitleWithoutDependent,
      description: eapSessionForYourself(numberOfSessions, customerDisplayName),
    },
  ]
}

export const eapOnlyWithDependentsBaseTexts = ({
  numberOfSessions,
  customerDisplayName,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: EAP_COST_MESSAGING_PARAMS) => {
  if (numberOfSessionsByProgram && isProgramLevelSessionLimitEnabled) {
    return [
      {
        title: eapProgramLevelSessionLimitTitleForYourself,
        description: eapProgramLevelSessionLimitBody(numberOfSessionsByProgram, 'self', customerDisplayName),
      },
      {
        title: eapProgramLevelSessionLimitTitleForFamily(true),
        description: eapProgramLevelSessionLimitBody(numberOfSessionsByProgram, 'adult_dependent', customerDisplayName),
      },
      {
        title: eapProgramLevelSessionLimitTitleForFamily(false),
        description: eapProgramLevelSessionLimitBody(numberOfSessionsByProgram, 'child', customerDisplayName),
      },
    ]
  }
  return [
    {
      title: eapCoachingOrTherapyTitleForYourself,
      description: eapSessionForYourself(numberOfSessions, customerDisplayName),
    },
    {
      title: eapCoachingOrTherapyTitleForFamily,
      description: eapSessionForFamily(numberOfSessions, customerDisplayName),
    },
  ]
}

export const eapAndHpiSessionBaseTexts = {
  title: eapAndHpiSessionTitle,
  description: eapAndHpiSessionDescription,
}

export const eapAndHpiWithHpiMedsBaseTexts = {
  title: medicalConsultationTitle,
  description: eapAndHpiMedicationDescription,
}

export const hpiOnlyNoDependentsBaseTexts = [
  {
    title: hpiTherapyTitleWithoutDependent,
    description: hpiTherapyDescriptionForYourself,
  },
]

export const hpiWithDependentsBaseTexts = [
  {
    title: hpiTherapyTitleForYourself,
    description: hpiTherapyDescriptionForYourself,
  },
  {
    title: hpiTherapyTitleForFamily,
    description: hpiTherapyDescriptionForFamily,
  },
]

const hpiWithMedsDescription = (
  <FormattedMessage
    defaultMessage='Cost-sharing for medical consultations may be available to you.'
    description='Cost messaging modal EAP and HPI description text'
  />
)

export const hpiOnlyWithMedsBaseDescription = {
  title: medicalConsultationTitle,
  description: hpiWithMedsDescription,
}

export const eapMedicalText = ({
  numberOfSessions,
  customerDisplayName,
  dependentsEnabled,
}: EAP_COST_MESSAGING_PARAMS & {
  dependentsEnabled: boolean
}) => {
  return {
    title: medicalConsultationTitle,
    description: dependentsEnabled
      ? eapMedicalSessionForFamily(numberOfSessions, customerDisplayName)
      : eapMedicalSessionForYourself(numberOfSessions, customerDisplayName),
  }
}

const getMedsTextIfEnabled = ({
  isCustomerEAPMeds,
  isCustomerHPIMeds,
  numberOfSessions,
  customerDisplayName,
  coverageType,
  dependentsEnabled,
  defaultToSessionsText = false,
}: GetCostMessagingModalTextParams & {
  coverageType: CoverageType
  dependentsEnabled: boolean
  defaultToSessionsText?: boolean
  numberOfSessions?: string
  customerDisplayName?: string
}): CostMessagingModalContent | undefined => {
  // Order of the if statements are important because we want to opt for the EAP copy if the program is both EAP and HPI supported
  if (isCustomerEAPMeds && numberOfSessions && customerDisplayName) {
    return eapMedicalText({ numberOfSessions, customerDisplayName, dependentsEnabled })
  } else if (isCustomerHPIMeds) {
    return coverageType === CoverageType.EAP_AND_HPI ? eapAndHpiWithHpiMedsBaseTexts : hpiOnlyWithMedsBaseDescription
  } else if (defaultToSessionsText) {
    return eapAndHpiSessionBaseTexts
  }

  return
}

const getEapOnlyCostMessagingModalText = ({
  isDependentDisabled,
  isCustomerEAPMeds,
  numberOfSessions,
  customerDisplayName,
  isCustomerProgramEAPCoaching,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: GetCostMessagingModalTextParams & EAP_COST_MESSAGING_PARAMS) => {
  const defaultMedsTextParams = {
    coverageType: CoverageType.EAP_ONLY,
    isCustomerEAPMeds,
    numberOfSessions,
    customerDisplayName,
    dependentsEnabled: !isDependentDisabled,
  }

  // Available copy/design only supports eap only customers with both therapy and coaching available. If needed,
  // will need to update logic and add new copy to support configurations that only have one or the other.
  if (!isCustomerProgramEAPCoaching) {
    return []
  }

  let eapOnlyModalContent: CostMessagingModalContent[] = []
  if (!isDependentDisabled) {
    eapOnlyModalContent = [
      ...eapOnlyWithDependentsBaseTexts({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
      }),
    ]
  } else {
    eapOnlyModalContent = [
      ...eapOnlyNoDependentsBaseTexts({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
      }),
    ]
  }

  const medsText = getMedsTextIfEnabled(defaultMedsTextParams)
  if (medsText) {
    eapOnlyModalContent.push(medsText)
  }

  return eapOnlyModalContent
}

const getHpiOnlyCostMessagingModalText = ({
  isDependentDisabled,
  isCustomerHPIMeds,
}: GetCostMessagingModalTextParams) => {
  const defaultMedsTextParams = {
    coverageType: CoverageType.HPI_ONLY,
    isCustomerHPIMeds,
    dependentsEnabled: !isDependentDisabled,
  }

  let hpiOnlyModalContent: CostMessagingModalContent[] = []
  if (!isDependentDisabled) {
    hpiOnlyModalContent = [...hpiWithDependentsBaseTexts]
  } else {
    hpiOnlyModalContent = [...hpiOnlyNoDependentsBaseTexts]
  }

  const medsText = getMedsTextIfEnabled(defaultMedsTextParams)
  if (medsText) {
    hpiOnlyModalContent.push(medsText)
  }

  return hpiOnlyModalContent
}

const getEapAndHpiCostMessagingModalText = ({
  isDependentDisabled,
  isCustomerEAPMeds,
  isCustomerHPIMeds,
  isCustomerProgramEAPCoaching,
  isCustomerProgramHPITherapy,
  isCustomerProgramEAPTherapy,
  numberOfSessions,
  customerDisplayName,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: GetCostMessagingModalTextParams & Partial<EAP_COST_MESSAGING_PARAMS>) => {
  let eapAndHpiCostMessagingModalText: CostMessagingModalContent[] = []

  const eapCoachingAndHpiTherapyCoachingContent = !isDependentDisabled
    ? eapCoachingAndHpiTherapyWithDependentsCoachingContent
    : eapCoachingAndHpiTherapyNoDependentsCoachingContent

  const eapCoachingAndHpiTherapyBaseContent = !isDependentDisabled
    ? eapCoachingAndHpiTherapyWithDependentsBaseContent
    : eapCoachingAndHpiTherapyNoDependentsBaseContent

  const eapCoachingAndEapTherapyBaseContent = !isDependentDisabled
    ? eapOnlyWithDependentsBaseTexts
    : eapOnlyNoDependentsBaseTexts

  const defaultMedsTextParams = {
    coverageType: CoverageType.EAP_AND_HPI,
    isCustomerEAPMeds,
    isCustomerHPIMeds,
    isCustomerProgramEAPCoaching,
    isCustomerProgramHPITherapy,
    numberOfSessions,
    customerDisplayName,
    dependentsEnabled: !isDependentDisabled,
    defaultToSessionsText: false,
  }

  const therapyIsOnlyHpiSupported = isCustomerProgramHPITherapy && !isCustomerProgramEAPTherapy
  // a program can be both EAP and HPI supported we want to enter the if block only when therapy is strictly HPI only
  if (therapyIsOnlyHpiSupported) {
    eapAndHpiCostMessagingModalText = [eapCoachingAndHpiTherapyBaseContent]
    if (isCustomerProgramEAPCoaching && numberOfSessions && customerDisplayName) {
      // adding to the front of the array so coaching content appears at the top
      eapAndHpiCostMessagingModalText = [
        ...eapCoachingAndHpiTherapyCoachingContent({ numberOfSessions, customerDisplayName }),
        ...eapAndHpiCostMessagingModalText,
      ]
    }
  } else if (!therapyIsOnlyHpiSupported && isCustomerProgramEAPCoaching && numberOfSessions && customerDisplayName) {
    eapAndHpiCostMessagingModalText = eapCoachingAndEapTherapyBaseContent({
      numberOfSessions,
      customerDisplayName,
      numberOfSessionsByProgram,
      isProgramLevelSessionLimitEnabled,
    })
    defaultMedsTextParams.defaultToSessionsText = true
  }

  const medsText = getMedsTextIfEnabled(defaultMedsTextParams)
  // there should at least be one other text in order to add the meds text, i.e. modal content can not only contain meds text
  if (eapAndHpiCostMessagingModalText.length > 0 && medsText) {
    eapAndHpiCostMessagingModalText.push(medsText)
  }
  return eapAndHpiCostMessagingModalText
}

export const getDefaultCostMessagingModalText = (
  numberOfSessions: string,
  customerDisplayName: string,
  isProgramLevelSessionLimitEnabled: boolean,
  numberOfSessionsByProgramStr?: string,
): {
  [key in CoverageType]: (params: GetCostMessagingModalTextParams) => CostMessagingModalContent[]
} => {
  const numberOfSessionsByProgram = numberOfSessionsByProgramStr
    ? (JSON.parse(numberOfSessionsByProgramStr) as SessionLimitByProgram)
    : undefined
  return {
    [CoverageType.EAP_ONLY]: (params: GetCostMessagingModalTextParams) => {
      return getEapOnlyCostMessagingModalText({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
        ...params,
      })
    },
    [CoverageType.HPI_ONLY]: (params: GetCostMessagingModalTextParams) => {
      return getHpiOnlyCostMessagingModalText({ ...params })
    },
    [CoverageType.EAP_AND_HPI]: (params: GetCostMessagingModalTextParams) => {
      return getEapAndHpiCostMessagingModalText({
        numberOfSessions,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
        customerDisplayName,
        ...params,
      })
    },
  }
}
