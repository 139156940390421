// @intl project:microsite

import React from 'react'
import { FormattedMessage } from 'react-intl'

import classNames from 'classnames'

import { useWindowSize } from '@lyrahealth-inc/shared-app-logic'

import styles from './header.module.scss'
import { NavigationMenu } from '../../atoms'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import ChevronIcon from '../../atoms/icons/ChevronIcon'

const LogOutMessage = (
  <FormattedMessage defaultMessage='Log out' description='Link text for a logged-in user to log out' />
)

type OwnProps = {
  handleLogout?: $TSFixMeFunction
  handleBackButtonClick?: $TSFixMeFunction
  trackMenuClick?: $TSFixMeFunction
  user?: $TSFixMe // TODO: PropTypes.instanceOf(Map)
  /** Applies to the sign up button as well, because the 2 buttons should always exist together */
  shouldHideLoginButton?: boolean
  loggedOutButtons?: $TSFixMe
  loggedInButtons?: $TSFixMe
  lyraLogo?: React.ReactElement
  logoSource?: string
  handleLogoClick?: () => void
  dropDownItems?: $TSFixMe
  icon?: $TSFixMe
  ghost?: boolean
  menuType?: string
  title?: string
  className?: string
  logoItems?: $TSFixMe
  logoClassName?: string
  navigationMenuProps?: $TSFixMeFunction
  shouldShowCareNavigatorHeaderEntrypoint: boolean
  /** CN button */
  careNavigatorEntryPoint: React.ReactNode
  isMobileSized?: boolean
  loginButton?: React.ReactElement
  signUpButton?: React.ReactElement
  headerNavMenu?: React.ReactElement
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Header.defaultProps

// @ts-expect-error TS(7022): 'Header' implicitly has type 'any' because it does... Remove this comment to see the full error message
export const Header = ({
  className,
  dropDownItems = [],
  ghost,
  handleLogout,
  handleLogoClick,
  handleBackButtonClick,
  icon,
  loggedInButtons = [],
  loggedOutButtons,
  logoItems,
  lyraLogo,
  logoClassName,
  logoSource,
  menuType,
  shouldHideLoginButton,
  title,
  trackMenuClick,
  user,
  navigationMenuProps,
  shouldShowCareNavigatorHeaderEntrypoint,
  careNavigatorEntryPoint,
  isMobileSized = false,
  loginButton,
  signUpButton,
  headerNavMenu,
}: Props) => {
  const [width] = useWindowSize()
  const isMinWidthLaptop = width >= 992
  const dropdownOptions: JSX.Element[] = [...dropDownItems]
  const ctas: { key: string; el: JSX.Element }[] = []
  const shouldUseCareNavigatorIconOverLogo = shouldShowCareNavigatorHeaderEntrypoint && isMobileSized
  const includeCareNavigatorButton = shouldShowCareNavigatorHeaderEntrypoint && !isMobileSized

  let isLoggedIn = false
  title = shouldShowCareNavigatorHeaderEntrypoint ? '' : title ?? (user ? `${user.get('firstname')}` : '')
  if (user && !user.isEmpty()) {
    isLoggedIn = true

    dropdownOptions.push(
      // @ts-expect-error TS(2322): Type '{ children: Element; key: string; name: stri... Remove this comment to see the full error message
      <li key={`list-item-${dropdownOptions.length > 0 ? dropdownOptions.length - 1 : 0}`} name='screenerButton'>
        <a href='#0' className={`logout updated-look-feel`} onClick={handleLogout}>
          {LogOutMessage}
        </a>
      </li>,
    )
  }

  if (isLoggedIn) {
    if (loggedInButtons && loggedInButtons.length > 0) {
      // outside the hamburger menu
      ctas.push({
        key: 'loggedInButtons',
        el: (
          <ul className={styles['loggedin-buttons-outside']} data-test-id='Header-outside-buttons'>
            {isMinWidthLaptop && loggedInButtons}
          </ul>
        ),
      })
    }

    // care navigator entrypoint button
    if (includeCareNavigatorButton) {
      ctas.push({
        key: 'careNavigatorEntryPointButton',
        el: (
          <div data-testId='Care-Navigator-Global-EntryPoint-Button' className={styles['icon-entrypoint-container']}>
            {careNavigatorEntryPoint}
          </div>
        ),
      })
    }

    // inside the hamburger menu
    ctas.push({
      key: 'hamburgerMenu',
      el: (
        <div
          className={
            shouldShowCareNavigatorHeaderEntrypoint
              ? styles['icon-menu-container-with-entrypoint']
              : styles['icon-menu-container']
          }
        >
          {icon}
          {headerNavMenu ? (
            <>
              <span className={styles['nav-menu-title']}>{title}</span>
              <div className={styles['nav-menu-container']}>{headerNavMenu}</div>
            </>
          ) : (
            <NavigationMenu
              menuType={menuType}
              trackMenuClick={trackMenuClick}
              title={title}
              alignment='right'
              {...navigationMenuProps}
            >
              <div className={styles['loggedin-buttons-inside']} data-test-id='Header-inside-buttons'>
                {!isMinWidthLaptop && loggedInButtons}
              </div>
              {dropdownOptions}
            </NavigationMenu>
          )}
        </div>
      ),
    })
  } else {
    ctas.push({ key: 'loggedOutButtons', el: loggedOutButtons })

    if (includeCareNavigatorButton) {
      ctas.push({
        key: 'careNavigatorEntryPointButton',
        el: (
          <div data-testId='Care-Navigator-Global-EntryPoint-Button' className={styles['icon-entrypoint-container']}>
            {careNavigatorEntryPoint}
          </div>
        ),
      })
    }

    if (!shouldHideLoginButton) {
      if (loginButton) {
        ctas.push({
          key: 'loginButton',
          el: <div>{loginButton}</div>,
        })
      }
      if (signUpButton) {
        ctas.push({
          key: 'signUpButton',
          el: <div>{signUpButton}</div>,
        })
      }
    }
  }

  return (
    <div
      className={classNames({ [className]: Boolean(className) }, { ghost: ghost }, styles.header)}
      data-testid='ui-core-header'
    >
      <div className='inner-container'>
        <div className='logo-container'>
          {handleBackButtonClick ? (
            <DefaultButton
              isOutlined
              customClass={styles['logo-back-button']}
              onClick={handleBackButtonClick}
              data-test-id='Header-backButton'
              aria-label='go back'
            >
              <ChevronIcon isFilled fillColor={styles.x_brand} direction='left' />
            </DefaultButton>
          ) : null}
          {shouldUseCareNavigatorIconOverLogo ? (
            careNavigatorEntryPoint
          ) : lyraLogo ? (
            lyraLogo
          ) : (
            <a className={`logo ${logoClassName}`} onClick={handleLogoClick} href='#0'>
              <img alt='Lyra logo' data-test-id='Header-logo' src={logoSource} />
            </a>
          )}
          {logoItems}
        </div>

        <div className='button-container'>
          {ctas.map(({ key, el }) => {
            let buttonsClassName
            if (key === 'loggedOutButtons') {
              buttonsClassName = 'loggedout-buttons'
            }
            return (
              <span key={'headerCTAS_' + key} className={buttonsClassName}>
                {el}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  logoClassName: '',
}

export default Header
