import React, { FunctionComponent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { UNAUTHENTICATED_REDIRECT_URL } from '@lyrahealth-inc/lyraweb-mobile/src/hooks/useShareSessionStorageAcrossTabs'
import { COMMON_MIXPANEL_PAGE } from '@lyrahealth-inc/shared-app-logic'
import { UnsubscribePage, UnsubscribePageType } from '@lyrahealth-inc/ui-core-crossplatform/src/microsite'

import { LWAPIError, setBannerAndErrorMessage } from '../../common/components/banner/banner-actions'
import LegalFooter from '../../common/components/footer/LegalFooter'
import { MIXPANEL_ACTION, MIXPANEL_EVENTS, trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { unsubscribe } from '../microsite/data/unsubscribe'
import { PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'

export const UnsubscribeContainer: FunctionComponent = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token')
  const paramType = searchParams.get('type')
  const [loading, setLoading] = useState(false)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const [type, _] = useState(
    paramType === UnsubscribePageType.promotion ? UnsubscribePageType.promotion : UnsubscribePageType.outcomes,
  )

  useEffect(() => {
    const unsubscribeAsync = async () => {
      try {
        setLoading(true)
        await unsubscribe({ token, type })
        let action = MIXPANEL_ACTION.UNSUBSCRIBE_OUTCOMES
        if (type === UnsubscribePageType.promotion) {
          action = MIXPANEL_ACTION.UNSUBSCRIBE_PROMOTIONAL
        }
        dispatch(
          trackEventWithObj({
            event: MIXPANEL_EVENTS.BUTTON_PRESS,
            page: COMMON_MIXPANEL_PAGE.UNSUBSCRIBE,
            action,
          }),
        )
      } catch (error) {
        dispatch(setBannerAndErrorMessage(error as LWAPIError, false))
        navigate(PAGE_ROUTES.CARE_TEAM_PHONE_NUMBERS)
      } finally {
        setSearchParams('')
        setLoading(false)
      }
    }

    if (token && !loading) {
      unsubscribeAsync()
    }
  }, [dispatch, formatMessage, loading, setSearchParams, token, type, navigate])

  const navigateToEditNotificationSettings = () => {
    window.sessionStorage.setItem(UNAUTHENTICATED_REDIRECT_URL, PAGE_ROUTES.PROFILE.UPDATE_LYRA_UPDATES_SETTINGS)
    navigate(PAGE_ROUTES.LOGIN)
  }

  return (
    <>
      <UnsubscribePage
        type={type}
        onContactCareNavigatorPress={() => navigate(PAGE_ROUTES.CARE_TEAM_PHONE_NUMBERS)}
        onEditNotificationSettingsPress={navigateToEditNotificationSettings}
      />
      <LegalFooter />
    </>
  )
}
