import React, { FunctionComponent } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { View, ViewStyle } from 'react-native'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'
import { AppleIcon } from '../illustrations/AppleIcon'
import { GoogleIcon } from '../illustrations/GoogleIcon'
import { SecondaryButton } from '../secondaryButton/SecondaryButton'

export interface SsoButtonProps {
  type: SSO_BUTTON_TYPE
  messageType: SSO_BUTTON_MESSAGE_TYPE
  disabled?: boolean
  onPress: () => void
  testId?: string
  style?: ViewStyle
}

export enum SSO_BUTTON_TYPE {
  APPLE = 'apple',
  GOOGLE = 'google',
}

export enum SSO_BUTTON_MESSAGE_TYPE {
  SIGN_IN = 'sign-in',
  CONTINUE = 'continue',
  SIGN_UP = 'sign-up',
}

const SSO_BUTTON_MESSAGES = {
  [SSO_BUTTON_TYPE.APPLE]: defineMessages({
    [SSO_BUTTON_MESSAGE_TYPE.SIGN_IN]: {
      defaultMessage: 'Sign in with Apple',
      description: 'Label on Apple sign in button',
    },
    [SSO_BUTTON_MESSAGE_TYPE.CONTINUE]: {
      defaultMessage: 'Continue with Apple',
      description: 'Label on Apple sign in button',
    },
    [SSO_BUTTON_MESSAGE_TYPE.SIGN_UP]: {
      defaultMessage: 'Sign up with Apple',
      description: 'Label on Apple sign up button',
    },
  }),
  [SSO_BUTTON_TYPE.GOOGLE]: defineMessages({
    [SSO_BUTTON_MESSAGE_TYPE.SIGN_IN]: {
      defaultMessage: 'Sign in with Google',
      description: 'Label on Google sign in button',
    },
    [SSO_BUTTON_MESSAGE_TYPE.CONTINUE]: {
      defaultMessage: 'Continue with Google',
      description: 'Label on Google sign in button',
    },
    [SSO_BUTTON_MESSAGE_TYPE.SIGN_UP]: {
      defaultMessage: 'Sign up with Google',
      description: 'Label on Google sign up button',
    },
  }),
}

export const SsoButton: FunctionComponent<SsoButtonProps> = ({
  type,
  messageType,
  disabled = false,
  onPress,
  testId,
  style,
}) => {
  const { isDarkMode } = useTheme()
  const { formatMessage } = useIntl()
  const buttonText = formatMessage(SSO_BUTTON_MESSAGES[type][messageType])

  let icon
  switch (type) {
    case SSO_BUTTON_TYPE.APPLE:
      icon = <AppleIcon size={20} />
      break
    case SSO_BUTTON_TYPE.GOOGLE:
      icon = (
        // TODO: Remove this wrapper when svg is replaced. Current one is broken for anything other than the default 24 size
        <View style={{ height: 18 }}>
          <GoogleIcon />
        </View>
      )
      break
  }

  return (
    <SecondaryButton
      testID={tID(testId || `SsoButton-${type}`)}
      text={buttonText}
      onPress={onPress}
      fullWidth
      leftIcon={icon}
      {...{ black: !isDarkMode }}
      disabled={disabled}
      style={style}
    />
  )
}
