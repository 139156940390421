import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { useTheme } from 'styled-components/native'

import {
  DirectDedicatedCareNavigatorInfoValues,
  getHelpEmailAddress,
  initializeICASChat,
  LYRIANS_SUPPORT_LINK,
  openICASChat,
} from '@lyrahealth-inc/shared-app-logic'

import { PressableOpacity } from '../atoms/pressableOpacity/PressableOpacity'
import { IS_WEB, Layout } from '../constants'
import { CareNavigatorHeaderEntryPointButton } from '../molecules/careNavigatorHeaderEntryPointButton/CareNavigatorHeaderEntryPointButton'
import { Modal } from '../organisms/modal/Modal'
import { ContactCareNavigatorConsolidatedPage as ContactCareNavigatorConsolidatedPageComponent } from '../pages/contactCareNavigatorConsolidated/ContactCareNavigatorConsolidatedPage'
import { mailTo, openUrl, ThemeType } from '../utils'

interface useCareNavigatorModalParams {
  isLoggedIn: boolean
  isInternational: boolean
  isLyrian: boolean
  showLiveChat: boolean
  customerPhoneNumber?: string
  userDedicatedCareNavigatorInfo?: DirectDedicatedCareNavigatorInfoValues
  countryIsoCode: string
  detectedCountryIsoCode?: string
  activeLanguage: string
  customerName?: string
  countryName?: string
  isDedicatedCareNavigatorEnabled?: boolean
  onPressEmailTrack?: () => void
  onPressFAQTrack?: () => void
  onPressPhoneCallTrack?: () => void
  onPressScheduleCallTrack?: () => void
  onPressLiveChatTrack?: () => void
  onEntryPointPressedTrack?: () => void //
  onPressPhoneCallInternational: () => void
  onPressFAQ: () => void
  onPressScheduleCall: () => void
  onPressLiveChat?: () => void
  loadDedicatedCareNavigator: () => void
  customEntryPointComponent?: React.ReactNode
  ChatComponent?: any
  isHomebaseFlow?: boolean
  useWebPortalForBottomSheet?: boolean
  disableBottomSheet?: boolean
  /** Show shortened dedicated CN cta description */
  showMinimalCTAText?: boolean
  /** If should show the dedicated CN/schedule call option */
  showScheduleCall?: boolean
  shouldInitializeICAS?: boolean
}

export const useCareNavigatorModal = ({
  isLoggedIn,
  isInternational,
  isLyrian,
  customerPhoneNumber,
  userDedicatedCareNavigatorInfo,
  showLiveChat,
  countryIsoCode,
  detectedCountryIsoCode,
  activeLanguage,
  customerName,
  countryName,
  isDedicatedCareNavigatorEnabled,
  onPressEmailTrack,
  onPressFAQTrack,
  onPressPhoneCallTrack,
  onPressScheduleCallTrack,
  onPressLiveChatTrack,
  onEntryPointPressedTrack,
  onPressPhoneCallInternational,
  onPressFAQ,
  onPressScheduleCall,
  onPressLiveChat,
  loadDedicatedCareNavigator,
  customEntryPointComponent,
  ChatComponent,
  isHomebaseFlow = false,
  useWebPortalForBottomSheet = false,
  showMinimalCTAText = false,
  showScheduleCall = true,
  shouldInitializeICAS = true,
  disableBottomSheet = false,
}: useCareNavigatorModalParams) => {
  const {
    colors,
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startChat, setStartChat] = useState(false)

  useEffect(() => {
    if (startChat) {
      setStartChat(false)
    }
  }, [startChat])

  useEffect(() => {
    if (isInternational && IS_WEB && shouldInitializeICAS) {
      const isoCode = countryIsoCode || detectedCountryIsoCode
      if (isoCode) {
        initializeICASChat({ customerLanguage: activeLanguage, countryIsoCode: isoCode, testMode: true })
      }
    }
  }, [countryIsoCode, isInternational, detectedCountryIsoCode, activeLanguage, shouldInitializeICAS])

  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const snapHeight = useRef(Layout.window.height).current * 0.9
  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])
  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const snapPoints = useMemo(() => {
    return isMobileSized ? [snapHeight] : [452]
  }, [isMobileSized, snapHeight])

  const handleExit = useCallback(() => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen, setIsModalOpen])

  const handleEntryPointPressed = useCallback(() => {
    if (isLoggedIn && isDedicatedCareNavigatorEnabled && !userDedicatedCareNavigatorInfo) {
      loadDedicatedCareNavigator()
    }
    onEntryPointPressedTrack && onEntryPointPressedTrack()
    setIsModalOpen(!isModalOpen)
  }, [
    isDedicatedCareNavigatorEnabled,
    isLoggedIn,
    isModalOpen,
    loadDedicatedCareNavigator,
    onEntryPointPressedTrack,
    userDedicatedCareNavigatorInfo,
  ])

  const handlePressEmail = useCallback(() => {
    onPressEmailTrack && onPressEmailTrack()
    if (isLyrian) {
      openUrl(LYRIANS_SUPPORT_LINK)
    } else {
      mailTo(getHelpEmailAddress({ isInternationalUser: isInternational }))
    }
  }, [isInternational, isLyrian, onPressEmailTrack])

  const handlePressFAQ = useCallback(() => {
    onPressFAQTrack && onPressFAQTrack()
    onPressFAQ()
    if (!isLoggedIn) {
      setIsModalOpen(false)
    }
  }, [isLoggedIn, onPressFAQ, onPressFAQTrack])

  const handlePressPhoneCall = useCallback(() => {
    onPressPhoneCallTrack && onPressPhoneCallTrack()
    if (isInternational || !isLoggedIn) {
      setIsModalOpen(false)
      onPressPhoneCallInternational()
    } else {
      openUrl(`tel:${customerPhoneNumber}`)
    }
  }, [customerPhoneNumber, isInternational, isLoggedIn, onPressPhoneCallInternational, onPressPhoneCallTrack])

  const handlePressScheduleCall = useCallback(() => {
    onPressScheduleCallTrack && onPressScheduleCallTrack()
    onPressScheduleCall()
  }, [onPressScheduleCall, onPressScheduleCallTrack])

  const handlePressLiveChat = useCallback(() => {
    onPressLiveChatTrack && onPressLiveChatTrack()
    if (onPressLiveChat) {
      onPressLiveChat()
    } else {
      if (isInternational && customerName && countryName) {
        setIsModalOpen(false)
        openICASChat({ customerName, countryName })
      } else {
        setIsModalOpen(false)
        setStartChat(!startChat) // Opens ChatLive
      }
    }
  }, [countryName, customerName, isInternational, onPressLiveChat, onPressLiveChatTrack, startChat])

  const CareNavigatorEntryPointComponent = useMemo(() => {
    return customEntryPointComponent ? (
      <PressableOpacity onPress={handleEntryPointPressed}>{customEntryPointComponent}</PressableOpacity>
    ) : (
      <CareNavigatorHeaderEntryPointButton
        isActive={isModalOpen}
        isInternational={isInternational}
        onPress={handleEntryPointPressed}
        suppressAccessibilitySelected={IS_WEB}
      />
    )
  }, [customEntryPointComponent, handleEntryPointPressed, isInternational, isModalOpen])

  const CareNavigatorModal = useMemo(() => {
    return (
      <>
        <Modal
          modalContents={
            <>
              <ContactCareNavigatorConsolidatedPageComponent
                customerPhoneNumber={customerPhoneNumber}
                isInternational={isInternational}
                isLyrianFlow={isLyrian}
                onPressEmail={handlePressEmail}
                onPressFAQ={handlePressFAQ}
                onPressPhoneCall={handlePressPhoneCall}
                onPressScheduleCall={handlePressScheduleCall}
                onPressLiveChat={handlePressLiveChat}
                onExit={handleExit}
                useSidePanelStyle={true}
                isHomebaseFlow={isHomebaseFlow}
                showLiveChat={showLiveChat}
                showScheduleCall={showScheduleCall ? isLoggedIn : false}
                hideCustomerPhoneNumber={!isLoggedIn}
                careNavigatorHeadshot={userDedicatedCareNavigatorInfo?.headShotURL}
                careNavigatorLicense={userDedicatedCareNavigatorInfo?.clinicalLicenses}
                careNavigatorName={userDedicatedCareNavigatorInfo?.firstName}
                showMinimalCTAText={showMinimalCTAText}
              />
            </>
          }
          // Show empty header since bottom sheet component already contains close button
          customHeader={<></>}
          disableBottomSheet={disableBottomSheet}
          onRequestClose={handleExit}
          visible={isModalOpen}
          bottomSheetRef={bottomSheetRef}
          snapPoints={snapPoints}
          onCloseEnd={() => {
            handleExit()
            closeBottomSheet()
          }}
          onLayout={openBottomSheet}
          isSidePanel={!isMobileSized}
          scrollable
          useWebPortalForBottomSheet={useWebPortalForBottomSheet}
          backgroundColor={colors.backgroundSecondary}
        />

        {showLiveChat && ChatComponent && <ChatComponent hideButton startChat={startChat} />}
      </>
    )
  }, [
    customerPhoneNumber,
    isInternational,
    isLyrian,
    handlePressEmail,
    handlePressFAQ,
    handlePressPhoneCall,
    handlePressScheduleCall,
    handlePressLiveChat,
    handleExit,
    isHomebaseFlow,
    showLiveChat,
    showScheduleCall,
    isLoggedIn,
    userDedicatedCareNavigatorInfo?.headShotURL,
    userDedicatedCareNavigatorInfo?.clinicalLicenses,
    userDedicatedCareNavigatorInfo?.firstName,
    showMinimalCTAText,
    isModalOpen,
    snapPoints,
    openBottomSheet,
    isMobileSized,
    useWebPortalForBottomSheet,
    colors.backgroundSecondary,
    ChatComponent,
    startChat,
    closeBottomSheet,
    disableBottomSheet,
  ])

  return useMemo(() => {
    return {
      /** Care navigator side panel component */
      careNavigatorModal: CareNavigatorModal,
      /** Care navigator link/button component */
      careNavigatorEntryPoint: CareNavigatorEntryPointComponent,
      /** Function which opens the side panel */
      openCareNavigatorModal: handleEntryPointPressed,
      isCareNavigatorModalOpen: isModalOpen,
      scheduleCallWithDedicatedCareNavigator: onPressScheduleCall,
    }
  }, [CareNavigatorEntryPointComponent, CareNavigatorModal, handleEntryPointPressed, isModalOpen, onPressScheduleCall])
}
