import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { CDN_ASSETS_LINK_BASE, CustomerName } from '@lyrahealth-inc/shared-app-logic'

import { Image, Subhead } from '../../../atoms'
import { SubheadSize } from '../../../styles'
import { ThemeType, tID } from '../../../utils'

export interface MicrositeCustomerBrandedBannerProps {
  customerName: string
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSection,
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
  paddingVertical: theme.spacing['24px'],
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '200px' : '258px',
  height: theme.breakpoints.isMobileSized ? '56px' : '72px',
}))

export const MicrositeCustomerBrandedBanner: FunctionComponent<MicrositeCustomerBrandedBannerProps> = ({
  customerName,
}) => {
  let customerLogoUrl
  if (customerName === CustomerName.INSIGHT_GLOBAL) {
    customerLogoUrl = `${CDN_ASSETS_LINK_BASE}/insight_global_banner.png`
  }

  if (!customerLogoUrl) {
    return null
  }

  return (
    <Container testID={tID('MicrositeCustomerBrandedBanner')}>
      <Subhead
        text={
          <FormattedMessage
            defaultMessage='Lyra Health is in partnership with: '
            description='Text for microsite customer branded banner'
          />
        }
        size={SubheadSize.XSMALL}
      />
      <ImageContainer source={customerLogoUrl} contentFit='fill' accessibilityIgnoresInvertColors />
    </Container>
  )
}
