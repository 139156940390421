import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import homeBaseIllustration from '../../assets/homebase_illustration.png'
import homeBaseIllustrationMobile from '../../assets/homebase_illustration_mobile.png'
import { Image } from '../image/Image'

type FindAProviderIllustrationProps = {
  size?: IllustrationSize
}

export enum IllustrationSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
}

export const FindAProviderIllustration: FunctionComponent<FindAProviderIllustrationProps> = ({
  size = IllustrationSize.MOBILE,
}) => {
  if (size === IllustrationSize.LAPTOP) {
    return (
      <Image
        source={homeBaseIllustration as ImageSourcePropType}
        style={{ height: 225, width: 350 }}
        contentFit='fill'
        accessibilityIgnoresInvertColors
        alt='Illustration of providers'
      />
    )
  } else if (size === IllustrationSize.TABLET) {
    return (
      <Image
        source={homeBaseIllustration as ImageSourcePropType}
        style={{ height: 200, width: 300 }}
        contentFit='fill'
        accessibilityIgnoresInvertColors
        alt='Illustration of providers'
      />
    )
  } else {
    return (
      <Image
        source={homeBaseIllustrationMobile as ImageSourcePropType}
        style={{ height: 137, width: 220 }}
        contentFit='fill'
        accessibilityIgnoresInvertColors
        alt='Illustration of providers'
      />
    )
  }
}
