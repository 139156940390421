import React, { FunctionComponent, KeyboardEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { ImageURISource, Platform, TextStyle, ViewStyle } from 'react-native'

import Slider, { SliderProps } from '@react-native-community/slider'
import * as Haptics from 'expo-haptics'
import { includes, isNil, isNumber, noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'
import { useFocusVisible } from 'use-focus-visible'

import { KEYS } from '@lyrahealth-inc/shared-app-logic'

import { BaseInput } from './BaseInput'
import sliderThumb from '../../assets/sliderThumb.png'
import { IS_WEB } from '../../constants'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { BodyText, Size } from '../bodyText/BodyText'
import { Subhead, Size as SubheadSize } from '../subhead/Subhead'

export interface SliderFieldProps extends SliderProps {
  label?: string
  error?: string
  name?: string
  minLabel?: string
  midLabel?: string
  maxLabel?: string
  readOnly?: boolean
  percentage?: boolean
  showValue?: boolean
  onFocus?: () => void
  onBlur?: () => void
  active?: boolean
  accessibilityLabelledBy?: string
  stepLabels?: MessageDescriptor[]
  showDivider?: boolean
  rangeContainerStyle?: ViewStyle
  rangeLabelStyle?: ViewStyle
  showStepMarkers?: boolean
  customStyles?: {
    componentColors?: object
    trackHeight?: number
    thumbStyle?: ViewStyle
    stepMarkerStyles?: Array<CSSObject>
    stepIndicatorContainerStyle?: ViewStyle
    minTrackStyle?: ViewStyle
    maxTrackStyle?: ViewStyle
    labelTextStyle?: TextStyle
  }
  onUseLayoutEffect?: (numberOfSteps: number, silderWidth: number) => void
  onThumbImagePressed?: (thumbImagePressed: boolean) => void
}

const Container = styled.View<{ theme: ThemeType; stepLabelPadding?: boolean }>(({ theme, stepLabelPadding }) => ({
  position: 'relative',
  paddingTop: stepLabelPadding ? theme.spacing['24px'] : theme.spacing['0px'],
}))

const ReadOnlySliderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  height: theme.spacing['40px'],
  justifyContent: 'center',
  marginBottom: theme.spacing['16px'],
}))

const ReadOnlyTrackContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  height: theme.spacing['12px'],
  marginBottom: theme.spacing['16px'],
  paddingTop: theme.spacing['8px'],
  width: '100%',
}))

const TrackFilled = styled.View<{
  width?: number
  color: string
  gapOffset?: number
  isMinTrack?: boolean
}>(({ width, color, isMinTrack, gapOffset = 0 }) => ({
  flexGrow: width,
  backgroundColor: color,
  borderRadius: '4px',
  // fills in the space gap between the left and right thumbImage
  marginRight: isMinTrack ? (-1 * gapOffset) / 2 - gapOffset / 3 : 0,
  marginLeft: !isMinTrack ? (-1 * gapOffset) / 2 - gapOffset / 3 : 0,
  // padding for slider to prevent thumbImage from overflowing on the sides
  padding: `0px 3px`,
}))

const LabelContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  height: theme.spacing['24px'],
  justifyContent: 'space-between',
  marginBottom: theme.spacing['40px'],
}))

const Label = styled.View<{ theme: ThemeType }>({
  flexDirection: 'row',
})

const ReadOnlyThumbImage = styled.View<{ theme: ThemeType; diameter: number; color: string }>(
  ({ theme, diameter, color }) => ({
    borderRadius: '50px',
    width: diameter,
    height: diameter,
    backgroundColor: color,
    borderColor: theme.colors.borderDefault,
    borderStyle: 'solid',
    borderWidth: '2px',
    zIndex: 1,
    margin: `-10px ${diameter / 2}px 0px ${diameter / 2}px`,
  }),
)

const StepLabelContainer = styled.View<{
  left: number
  borderColor: string
  backgroundColor: string
  shadowColor: string
}>(({ left, borderColor, backgroundColor, shadowColor }) => ({
  height: '28px',
  width: '28px',
  position: 'absolute',
  alignSelf: 'flex-start',
  borderRadius: '100px',
  backgroundColor: backgroundColor,
  borderColor: borderColor,
  borderWidth: '1px',
  paddingTop: '1px',
  top: 0,
  boxShadow: `0px 0px 8px ${shadowColor}`,
  ...(left && { left: left - 14 + 'px' }),
}))

const StepMarker = styled.View<{
  backgroundColor: string
  hide?: boolean
  customStepMarkerStyle?: CSSObject
}>(({ backgroundColor, hide, customStepMarkerStyle }) => {
  const { size, top } = Platform.select({
    ios: { size: 3, top: 8.25 },
    android: { size: 2, top: 21.75 },
    web: { size: 6, top: 15 },
  }) as { size: number; top: number }
  return {
    width: size,
    height: size,
    borderRadius: size,
    top,
    position: 'absolute',
    opacity: hide ? 0 : 1,
    backgroundColor,
    ...customStepMarkerStyle,
  }
})

export const STEP_INDICATOR_MARGIN_OFFSET = 10

export const SliderField: FunctionComponent<SliderFieldProps> = ({
  label,
  value,
  onValueChange,
  error,
  name,
  minimumValue,
  maximumValue,
  minLabel,
  midLabel,
  maxLabel,
  step = 1,
  readOnly,
  percentage,
  showValue = true,
  onFocus = noop,
  onBlur = noop,
  active,
  accessibilityLabelledBy,
  stepLabels,
  showDivider,
  rangeContainerStyle,
  rangeLabelStyle,
  showStepMarkers,
  customStyles = {},
  onUseLayoutEffect,
  onThumbImagePressed,
}) => {
  const { colors } = useTheme() as ThemeType
  const [sliderWidth, setSliderWidth] = useState(0)
  const [labelPositions, setLabelPositions] = useState<number[]>([])
  const componentColors = (customStyles.componentColors ??
    colors.components.sliderField) as typeof colors.components.sliderField
  const hasMaxMin = !isNil(maximumValue) && !isNil(minimumValue)
  const useStepLabels = !!step && !!stepLabels && hasMaxMin

  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const [thumbImagePressed, setThumbImagePressed] = useState(false)
  const { focusVisible, onBlur: fvBlur, onFocus: fvFocus } = useFocusVisible()
  const trackHeight = customStyles.trackHeight ?? 8
  const circleDiameter = thumbImagePressed ? 28 : 24
  const readOnlyCircleDiameter = 24
  const numberOfSteps = hasMaxMin ? (maximumValue - minimumValue) / step : 0
  // extends the left and right side of the slider container to hide round edges behind thumbImage
  const borderRadiusSliderPaddingOffset = (-1 * circleDiameter) / 8
  const defaultThumbStyle = {
    borderRadius: 50,
    backgroundColor: colors.backgroundPrimary,
    border: `2px solid ${componentColors.thumb.border}`,
    height: circleDiameter,
    width: circleDiameter,
    outline: focusVisible && `2px dashed ${componentColors.thumb.dashedOutline.background}`,
    cursor: 'pointer',
  }

  const containerStyle = {
    height: 32,
    marginBottom: 8,
    marginTop: 8,
    padding: 0,
  } as ViewStyle

  const sliderStyle = {
    outlineWidth: 0,
  }
  const accessibilityAttr = accessibilityLabelledBy
    ? { accessibilityLabelledBy }
    : { accessibilityLabel: `Slider for - ${label}` }
  const focus = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    fvFocus()
    onFocus(event)
  }

  const blur = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    fvBlur()
    onBlur(event)
  }

  const onSliderValueChange = (value: number): void => {
    if (!IS_WEB && useStepLabels) {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
    }
    onValueChange && onValueChange(value)
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    const currentValue = isNumber(value) ? value : 0
    const stepSize = step || 1
    const max = maximumValue || 1
    const min = minimumValue || 0
    const keyPressed = event.key
    const keys = [KEYS.ARROW_LEFT, KEYS.ARROW_RIGHT]
    if (includes(keys, keyPressed)) {
      event.stopPropagation()
      event.preventDefault()
    }
    switch (keyPressed) {
      case KEYS.ARROW_LEFT:
        const nextLeftTarget = currentValue - stepSize
        const nextLeftValue = nextLeftTarget < min ? min : nextLeftTarget
        onValueChange && onValueChange(nextLeftValue)
        break
      case KEYS.ARROW_RIGHT:
        const nextRightTarget = currentValue + stepSize
        const nextRightValue = nextRightTarget > max ? max : nextRightTarget
        onValueChange && onValueChange(nextRightValue)
        break
      default:
        break
    }
  }

  useLayoutEffect(() => {
    if (useStepLabels) {
      const thumbAdjustment = 10
      const newLabelPositions = stepLabels.map((_, index) => {
        const valueInPixels = ((sliderWidth - thumbAdjustment * 2) / numberOfSteps) * index
        return valueInPixels + thumbAdjustment
      })
      setLabelPositions(newLabelPositions)
    }
    if (onUseLayoutEffect) {
      onUseLayoutEffect(numberOfSteps, sliderWidth)
    }
  }, [maximumValue, minimumValue, numberOfSteps, sliderWidth, step, stepLabels, useStepLabels, onUseLayoutEffect])

  // Need to create a custom read-only view because on Android, setting `disabled` to `true`, `minimumTrackTintColor` is ignored.
  const renderReadOnlySlider = () => {
    let filledWidth, unFilledWidth
    if (isNumber(value) && maximumValue) {
      filledWidth = value / maximumValue
      unFilledWidth = 1 - filledWidth
    }
    return (
      <ReadOnlySliderContainer>
        <ReadOnlyTrackContainer>
          <TrackFilled
            width={filledWidth}
            color={componentColors.track.filled.background}
            gapOffset={readOnlyCircleDiameter}
            isMinTrack={true}
          />
          <ReadOnlyThumbImage diameter={readOnlyCircleDiameter} color={colors.backgroundPrimary} />
          <TrackFilled
            width={unFilledWidth}
            color={componentColors.track.unFilled.background}
            gapOffset={readOnlyCircleDiameter}
          />
        </ReadOnlyTrackContainer>
      </ReadOnlySliderContainer>
    )
  }

  // Convert slider value to a label
  const inputValueLabel = useMemo(() => {
    if (!isNil(value)) {
      if (percentage && maximumValue) {
        return `${Math.floor((value / maximumValue) * 100)} %`
      }
    }
    return `${value}`
  }, [value, maximumValue, percentage])

  const getStepMarkerBackgroundColor = (stepMarked: boolean, greaterThan: boolean) => {
    return stepMarked
      ? componentColors.stepMarker.active.background
      : greaterThan
      ? componentColors.stepMarker.filled.background
      : componentColors.stepMarker.unFilled.background
  }

  return (
    <BaseInput
      label={label}
      error={error}
      name={name}
      inputValueLabel={useStepLabels ? undefined : inputValueLabel}
      /* this prop is to determine whether to show the value label on the top right of the slider.
      it should only show the value for labels that have a numeric value in it. */
      showValue={showValue && !isNil(value) && value >= 0}
      labelRef={focusRef}
      inputValueColor={componentColors.text}
      showDivider={showDivider}
      style={rangeContainerStyle}
      percentageValue={percentage && Boolean(maximumValue)}
    >
      {readOnly ? (
        renderReadOnlySlider()
      ) : (
        <Container
          stepLabelPadding={useStepLabels}
          onLayout={({ nativeEvent }) => setSliderWidth(nativeEvent.layout.width)}
        >
          {stepLabels &&
            stepLabels.map((label, index) => {
              const hidden = value !== index * step
              return (
                <StepLabelContainer
                  borderColor={componentColors.floatingLabel.border}
                  backgroundColor={componentColors.floatingLabel.background}
                  shadowColor={componentColors.floatingLabel.shadow.fill}
                  key={label.id}
                  style={hidden && { opacity: 0 }}
                  aria-hidden={hidden}
                  left={labelPositions[index]}
                >
                  <Subhead
                    textAlign='center'
                    size={SubheadSize.XSMALL}
                    color={colors.textActive}
                    selectable={false}
                    text={<FormattedMessage {...label} />}
                  />
                </StepLabelContainer>
              )
            })}
          <Slider
            value={value}
            onValueChange={onSliderValueChange}
            step={step}
            minimumValue={minimumValue}
            maximumValue={maximumValue}
            minimumTrackTintColor={componentColors.track.minimumTint.background}
            // Only works for iOS unfortunately.
            maximumTrackTintColor={componentColors.track.maximumTint.background}
            thumbImage={IS_WEB ? undefined : (sliderThumb as ImageURISource)}
            focusable
            //@ts-ignore
            accessibilityValueMax={maximumValue}
            accessibilityValueMin={minimumValue}
            accessibilityValueNow={value}
            onFocus={focus}
            onBlur={blur}
            //@ts-ignore
            thumbStyle={
              IS_WEB && {
                ...defaultThumbStyle,
                ...customStyles.thumbStyle,
              }
            }
            onResponderStart={() => {
              setThumbImagePressed(true)
              onThumbImagePressed && onThumbImagePressed(true)
            }}
            onResponderEnd={() => {
              setThumbImagePressed(false)
              onThumbImagePressed && onThumbImagePressed(false)
            }}
            // custom patch props to allow us to style the slider bar
            minTrackStyle={{
              height: trackHeight,
              // set style on the left corners of the slider bar, can't put into 1 single style because borderRadius only accepts number type
              borderTopLeftRadius: trackHeight / 2,
              borderBottomLeftRadius: trackHeight / 2,
              marginRight: borderRadiusSliderPaddingOffset,
              top: thumbImagePressed ? 1 : 0,
              ...customStyles.minTrackStyle,
            }}
            maxTrackStyle={{
              height: trackHeight,
              // set style on the right corners of the slider bar, can't put into 1 single style because borderRadius only accepts number type
              borderTopRightRadius: trackHeight / 2,
              borderBottomRightRadius: trackHeight / 2,
              marginLeft: borderRadiusSliderPaddingOffset,
              top: thumbImagePressed ? 1 : 0,
              ...customStyles.maxTrackStyle,
            }}
            StepMarker={
              useStepLabels || showStepMarkers
                ? ({ stepMarked, greaterThan, index }) => {
                    const androidHideFirstLast = Platform.OS === 'android' && (index === 0 || index === numberOfSteps)
                    return (
                      <StepMarker
                        testID={tID(`${name}-SliderField-StepMarker-${index}`)}
                        backgroundColor={getStepMarkerBackgroundColor(stepMarked, greaterThan)}
                        hide={androidHideFirstLast}
                        {...(customStyles.stepMarkerStyles && {
                          customStepMarkerStyle: customStyles.stepMarkerStyles[index],
                        })}
                      />
                    )
                  }
                : undefined
            }
            stepIndicatorContainerStyle={{
              marginHorizontal: STEP_INDICATOR_MARGIN_OFFSET,
              position: 'relative',
              ...customStyles.stepIndicatorContainerStyle,
            }}
            style={containerStyle}
            sliderStyle={sliderStyle}
            tapToSeek
            stepThumbStyle={{ top: Platform.OS === 'android' ? 20 : 10 }}
            onKeyDown={handleKeyPress}
            {...accessibilityAttr}
          />
        </Container>
      )}

      {minLabel !== undefined && maxLabel !== undefined && (
        <LabelContainer style={rangeLabelStyle}>
          <Label>
            {useStepLabels && (
              <BodyText size={Size.SMALL} text={minimumValue} selectable={false} style={{ marginRight: 8 }} />
            )}
            <BodyText size={Size.SMALL} text={minLabel} selectable={false} style={customStyles.labelTextStyle} />
          </Label>
          {!!midLabel && <BodyText size={Size.SMALL} text={midLabel} selectable={false} />}
          <Label>
            <BodyText size={Size.SMALL} text={maxLabel} selectable={false} style={customStyles.labelTextStyle} />
            {useStepLabels && (
              <BodyText size={Size.SMALL} text={maximumValue} selectable={false} style={{ marginLeft: 8 }} />
            )}
          </Label>
        </LabelContainer>
      )}
    </BaseInput>
  )
}

export const SliderFieldRFF: FunctionComponent<FieldRenderProps<number | undefined>> = ({
  input: { value, onChange, name, onFocus, onBlur },
  meta: { touched, error, active },
  label,
  minimum,
  maximum,
  minLabel,
  midLabel,
  maxLabel,
  readOnly,
  percentage,
  initValue,
  accessibilityLabelledBy,
  hidden,
  stepLabels,
  showDivider,
  rangeContainerStyle,
  rangeLabelStyle,
}) => {
  // trigger on change on mount when initial value
  useEffect(() => {
    if (!hidden && !isNil(initValue) && isNil(value)) {
      onChange(initValue)
    }
  }, [hidden, initValue, onChange, value])
  return (
    <SliderField
      label={label}
      value={isNumber(value) ? value : initValue || 0}
      onValueChange={onChange}
      minimumValue={parseInt(minimum, 10)}
      maximumValue={parseInt(maximum, 10)}
      minLabel={minLabel}
      midLabel={midLabel}
      maxLabel={maxLabel}
      error={touched && error}
      name={name}
      readOnly={readOnly}
      percentage={percentage}
      onFocus={onFocus}
      onBlur={onBlur}
      active={active}
      accessibilityLabelledBy={accessibilityLabelledBy}
      stepLabels={stepLabels}
      showDivider={showDivider}
      rangeContainerStyle={rangeContainerStyle}
      rangeLabelStyle={rangeLabelStyle}
    />
  )
}
