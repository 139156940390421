import { CustomerConstantsMap } from '@lyrahealth-inc/shared-app-logic'

import { abbvie } from './customers/abbvie'
import { akamai } from './customers/akamai'
import { amazon } from './customers/amazon'
import { amazonkids } from './customers/amazonkids'
import { amex } from './customers/amex'
import { amgen } from './customers/amgen'
import { anaplan } from './customers/anaplan'
import { apple } from './customers/apple'
import { asurion } from './customers/asurion'
import { att } from './customers/att'
import { attunion } from './customers/attunion'
import { autodesk } from './customers/autodesk'
import { bbins } from './customers/bbins'
import { bluepearl } from './customers/bluepearl'
import { bms } from './customers/bms'
import { bringjoy } from './customers/bringjoy'
import { brookfield } from './customers/brookfield'
import { capitalgroup } from './customers/capitalgroup'
import { carhartt } from './customers/carhartt'
import { carpenterbenefits } from './customers/carpenterbenefits'
import { commonspirit } from './customers/commonspirit'
import { defaultCustomer } from './customers/default'
import { defaultCustomerWithoutMeds } from './customers/defaultWithoutMeds'
import { dell } from './customers/dell'
import { eyassist } from './customers/eyassist'
import { facebook } from './customers/facebook'
import { faegredrinker } from './customers/faegredrinker'
import { fidelity } from './customers/fidelity'
import { ford } from './customers/ford'
import { gap } from './customers/gap'
import { gfo } from './customers/gfo'
import { google } from './customers/google'
import { greylock } from './customers/greylock'
import { hologic } from './customers/hologic'
import { ivp } from './customers/ivp'
import { kiewit } from './customers/kiewit'
import { landOLakes } from './customers/landOLakes'
import { lyrians } from './customers/lyrians'
import { maxar } from './customers/maxar'
import { mcd } from './customers/mcd'
import { merck } from './customers/merck'
import { mgb } from './customers/mgb'
import { morganstanley } from './customers/morganstanley'
import { nationwidechildrens } from './customers/nationwidechildrens'
import { neibenefits } from './customers/neibenefits'
import { netapp } from './customers/netapp'
import { netflix } from './customers/netflix'
import { newyorkfed } from './customers/newyorkfed'
import { nextera } from './customers/nextera'
import { nike } from './customers/nike'
import { organon } from './customers/organon'
import { pinterest } from './customers/pinterest'
import { psjh } from './customers/psjh'
import { purestorage } from './customers/purestorage'
import { qualcomm } from './customers/qualcomm'
import { qualcomm16eap } from './customers/qualcomm16eap'
import { redbull } from './customers/redbull'
import { refactor219 } from './customers/refactor219'
import { roche } from './customers/roche'
import { servicenow } from './customers/servicenow'
import { skadden } from './customers/skadden'
import { spacex } from './customers/spacex'
import { square } from './customers/square'
import { starbucks } from './customers/starbucks'
import { tesla } from './customers/tesla'
import { thehartford } from './customers/thehartford'
import { uber } from './customers/uber'
import { uchealthorg } from './customers/uchealthorg'
import { united } from './customers/united'
import { vca } from './customers/vca'
import { virginiamason } from './customers/virginiamason'
import { walmart } from './customers/walmart'
import { walmartisrael } from './customers/walmartisrael'
import { zoetis } from './customers/zoetis'

export const customers: CustomerConstantsMap = {
  // -- INCOMPLETE --
  defaultCustomer: {
    copy: defaultCustomer,
  },
  '1884': {
    copy: kiewit,
  },
  abbvie: {
    copy: abbvie,
  },
  akamai: {
    copy: akamai,
  },
  amazon: {
    copy: amazon,
  },
  amazonkids: {
    copy: amazonkids,
  },
  amex: {
    copy: amex,
  },
  amgen: {
    copy: amgen,
    template: defaultCustomerWithoutMeds,
  },
  anaplan: {
    copy: anaplan,
    template: defaultCustomerWithoutMeds,
  },
  asurion: {
    copy: asurion,
    template: defaultCustomerWithoutMeds,
  },
  att: {
    copy: att,
    template: defaultCustomerWithoutMeds,
  },
  attunion: {
    copy: attunion,
  },
  autodesk: {
    copy: autodesk,
  },
  bbins: {
    copy: bbins,
  },
  bluepearlvet: {
    copy: bluepearl,
  },
  bms: {
    copy: bms,
  },
  bringjoy: {
    copy: bringjoy,
  },
  brookfield: {
    copy: brookfield,
  },
  capitalgroup: {
    copy: capitalgroup,
  },
  carhartt: {
    copy: carhartt,
  },
  carpenterbenefits: {
    copy: carpenterbenefits,
  },
  commonspirit: {
    copy: commonspirit,
  },
  dell: {
    copy: dell,
  },
  eyassist: {
    copy: eyassist,
  },
  // -- INCOMPLETE --
  facebook: {
    copy: facebook,
    template: defaultCustomerWithoutMeds,
  },
  faegredrinker: {
    copy: faegredrinker,
  },
  // -- INCOMPLETE --
  fidelity: {
    copy: fidelity,
    template: defaultCustomerWithoutMeds,
  },
  ford: {
    copy: ford,
  },
  gfo: {
    copy: gfo,
  },
  gap: {
    copy: gap,
  },
  // -- TO DO --
  // gilead: {
  //   copy: gilead,
  //   template: defaultCustomerWithoutMeds,
  // },
  // -- INCOMPLETE --
  google: {
    copy: google,
    template: defaultCustomerWithoutMeds,
  },
  greylock: {
    copy: greylock,
  },
  hologic: {
    copy: hologic,
  },
  ivp: {
    copy: ivp,
  },
  lol: {
    copy: landOLakes,
  },
  lyrians: {
    copy: lyrians,
  },
  maxar: {
    copy: maxar,
  },
  mcd: {
    copy: mcd,
  },
  merck: {
    copy: merck,
  },
  mgb: {
    copy: mgb,
  },
  morganstanley: {
    copy: morganstanley,
    template: defaultCustomerWithoutMeds,
  },
  nationwidechildrens: {
    copy: nationwidechildrens,
  },
  neibenefits: {
    copy: neibenefits,
  },
  netapp: {
    copy: netapp,
    template: defaultCustomerWithoutMeds,
  },
  // -- INCOMPLETE --
  netflix: {
    copy: netflix,
  },
  newyorkfed: {
    copy: newyorkfed,
  },
  nextera: {
    copy: nextera,
  },
  nike: {
    copy: nike,
  },
  organon: {
    copy: organon,
  },
  pinterest: {
    copy: pinterest,
    template: defaultCustomerWithoutMeds,
  },
  // -- INCOMPLETE --
  psjh: {
    copy: psjh,
    template: defaultCustomerWithoutMeds,
  },
  purestorage: {
    copy: purestorage,
  },
  // -- INCOMPLETE --
  qualcomm: {
    copy: qualcomm,
    template: defaultCustomerWithoutMeds,
  },
  'qualcomm-16eap': {
    copy: qualcomm16eap,
    template: qualcomm,
  },
  redbull: {
    copy: redbull,
  },
  refactor219: {
    copy: refactor219,
    template: defaultCustomerWithoutMeds,
  },
  roche: {
    copy: roche,
    template: defaultCustomerWithoutMeds,
  },
  servicenow: {
    copy: servicenow,
  },
  skadden: {
    copy: skadden,
  },
  spacex: {
    copy: spacex,
  },
  square: {
    copy: square,
  },
  starbucks: {
    copy: starbucks,
    template: defaultCustomerWithoutMeds,
  },
  tesla: {
    copy: tesla,
    template: defaultCustomerWithoutMeds,
  },
  thehartford: {
    copy: thehartford,
  },
  uber: {
    copy: uber,
  },
  // -- TO DO --
  vca: {
    copy: vca,
  },
  virginiamason: {
    copy: virginiamason,
  },
  walmart: {
    copy: walmart,
  },
  walmartisrael: {
    copy: walmartisrael,
  },
  zoetis: {
    copy: zoetis,
  },
  apple: {
    copy: apple,
  },
  united: {
    copy: united,
  },
  uchealthorg: {
    copy: uchealthorg,
  },
}
