import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../../atoms/bodyText/BodyText'
import { LyraHealthAppIcon } from '../../../atoms/icons/LyraHealthAppIcon'
import { Link } from '../../../atoms/link/Link'
import { ThemeType, tID } from '../../../utils'

export interface MicrositeMobileAppPromoBannerProps {
  onGetAppLinkPress: () => void
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundHighlightSecondary,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing['8px'],
  paddingVertical: '30px',
}))

export const MicrositeMobileAppPromoBanner: FunctionComponent<MicrositeMobileAppPromoBannerProps> = ({
  onGetAppLinkPress,
}) => {
  return (
    <Container testID={tID('MicrositeMobileAppPromoBanner')}>
      <LyraHealthAppIcon borderRadius={12} />
      <BodyText
        text={
          <FormattedMessage
            defaultMessage='Improve your well-being with the Lyra app. Available on iOS and Android. <link>Get the app</link>'
            description='Text to encourage users to download the Lyra mobile app'
            values={{
              link: (text: string) => (
                <Link
                  testID={tID('MicrositeMobileAppPromoBanner-getAppLink')}
                  text={text}
                  size={BodyTextSize.DEFAULT}
                  onPress={onGetAppLinkPress}
                  underline
                />
              ),
            }}
          />
        }
        size={BodyTextSize.DEFAULT}
      />
    </Container>
  )
}
