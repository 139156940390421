import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { COMMON_MIXPANEL_PROPERTIES, COMMON_SEARCH_FOR_CARE_SCREENS } from '@lyrahealth-inc/shared-app-logic'

import { getUserChildren } from '../../data/user/userSelectors'
import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'
import { routeToPageMap } from '../constants/mixpanelConstants'

export const useDefaultMixpanelPage = () => {
  const location = useLocation()
  const userChildren = useSelector(getUserChildren)?.toJS()

  return useMemo(() => {
    const currentRoute = location.pathname
    let page = routeToPageMap[currentRoute]
    if (currentRoute === PAGE_ROUTES.ONBOARD.ABOUT_CHILD) {
      page =
        userChildren?.length > 0
          ? COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_SELECTION
          : COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_INFORMATION
    }
    if (page) {
      return { [COMMON_MIXPANEL_PROPERTIES.PAGE]: page }
    }
    return {}
  }, [location.pathname, userChildren?.length])
}
