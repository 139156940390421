import React, { FC } from 'react'
import { Field } from 'react-final-form'
import { useIntl } from 'react-intl'
import { View, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { CheckboxButtonType, requiredFieldErrorMessage } from '@lyrahealth-inc/shared-app-logic'

import { CheckboxRFF, Subhead } from '../../atoms'
import { AlertLoudIcon } from '../../atoms/icons/AlertLoudIcon'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { InlineBanner } from '../inlineBanner/InlineBanner'

export interface RequiredCheckboxWithBannerProps {
  headerText: string | React.ReactNode
  checkboxLabel: string | React.ReactNode
  checkboxName: string
  bannerText?: string | React.ReactNode
  shouldShowBanner?: boolean
  baseInputStyle?: ViewStyle
}

const HeaderContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  fontWeight: 500,
  paddingBottom: theme.breakpoints.isMinWidthLaptop ? theme.spacing['16px'] : theme.spacing['24px'],
}))

export const RequiredCheckboxWithBanner: FC<RequiredCheckboxWithBannerProps> = ({
  headerText,
  checkboxLabel,
  checkboxName,
  bannerText,
  shouldShowBanner,
  baseInputStyle,
}) => {
  const intl = useIntl()
  const { colors } = useTheme()

  return (
    <View testID={tID('RequiredCheckboxWithBanner-container')}>
      <HeaderContainer size={SubheadSize.MEDIUM} text={headerText} />
      <Field
        name={checkboxName}
        component={CheckboxRFF}
        buttonType={CheckboxButtonType.CHECKBOX_DESCRIPTION_NO_OUTLINE}
        title={checkboxLabel}
        validate={(value: string | boolean) => (!!value ? undefined : intl.formatMessage(requiredFieldErrorMessage))}
        errorOnSubmitFailed
        baseInputStyle={baseInputStyle}
      />
      {shouldShowBanner && bannerText && (
        <InlineBanner
          testId={tID('RequiredCheckboxWithBanner-banner')}
          text={bannerText}
          backgroundColor={colors.backgroundHighlightPrimary}
          icon={<AlertLoudIcon fillColor={colors.iconActive} />}
          textColor={colors.textActive}
        />
      )}
    </View>
  )
}
