import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillGetCanonicallocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'

const DEFAULT_LOCALE = 'en'

// This check is because Safari 14.1 partially supports Intl.
// It is missing the dateTimeField option from the Intl spec.
const areAllDisplayNameFeaturesAvaliable = () => {
  try {
    new Intl.DisplayNames(DEFAULT_LOCALE, { type: 'dateTimeField' })
    return true
  } catch {
    return false
  }
}

// Do not change the order these are imported in a specific order based on this article https://formatjs.github.io/docs/polyfills
export const loadInitialIntlPolyfills = async () => {
  if (shouldPolyfillGetCanonicallocales()) {
    await import(
      /* webpackChunkName: "polyfill_getcanonicallocales" */ '@formatjs/intl-getcanonicallocales/polyfill-force'
    )
  }

  if (shouldPolyfillLocale()) {
    await import(/* webpackChunkName: "polyfill_locale" */ '@formatjs/intl-locale/polyfill-force')
  }

  const polyfillPromises = []

  if (shouldPolyfillPluralRules(DEFAULT_LOCALE)) {
    polyfillPromises.push(
      import(/* webpackChunkName: "polyfill_pluralrules" */ '@formatjs/intl-pluralrules/polyfill-force'),
    )
  }
  if (!(Intl as any).DisplayNames || !areAllDisplayNameFeaturesAvaliable()) {
    polyfillPromises.push(
      import(/* webpackChunkName: "polyfill_displaynames" */ '@formatjs/intl-displaynames/polyfill-force'),
    )
  }
  if (shouldPolyfillListFormat(DEFAULT_LOCALE)) {
    polyfillPromises.push(
      import(/* webpackChunkName: "polyfill_listformat" */ '@formatjs/intl-listformat/polyfill-force'),
    )
  }

  await Promise.all(polyfillPromises)

  if (shouldPolyfillNumberFormat(DEFAULT_LOCALE)) {
    await import(/* webpackChunkName: "polyfill_numberformat" */ '@formatjs/intl-numberformat/polyfill-force')
  }

  if (shouldPolyfillRelativeTimeFormat(DEFAULT_LOCALE)) {
    polyfillPromises.push(
      import(/* webpackChunkName: "polyfill_relativetimeformat" */ '@formatjs/intl-relativetimeformat/polyfill-force'),
    )
  }

  if (shouldPolyfillDateTimeFormat(DEFAULT_LOCALE)) {
    polyfillPromises.push(
      import(/* webpackChunkName: "polyfill_datetimeformat" */ '@formatjs/intl-datetimeformat/polyfill-force').then(
        async () =>
          await import(/* webpackChunkName: "polyfill_datetimeformat-tz" */ '@formatjs/intl-datetimeformat/add-all-tz'),
      ),
    )
  }
  await Promise.all(polyfillPromises)
}
