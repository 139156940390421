import React, { FunctionComponent, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { View, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import abstractFace from '../../assets/abstract_face.png'
import abstractFaceAlt1 from '../../assets/abstract_face_alt1.png'
import abstractFaceAlt2 from '../../assets/abstract_face_alt2.png'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { IS_WEB } from '../../constants'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { RibbonHeartIllustration } from '../illustrations/RibbonHeartIllustration'
import { Image } from '../image/Image'
import { Subhead } from '../subhead/Subhead'

export interface DefaultAvatarProps {
  displayName: string
  size?: number
  backgroundColor?: string
  fontColor?: string
  fontSize?: BodyTextSize
  style?: ViewStyle
  shape?: AvatarShape
  testId?: string
  borderRadius?: number
}

export interface ColoredAvatarProps {
  displayName: string
  size?: number
  style?: ViewStyle
  shape?: AvatarShape
  index?: number
  testId?: string
}

const DefaultIllustrations = [abstractFace, abstractFaceAlt1, abstractFaceAlt2]

export enum AvatarShape {
  CIRCULAR = 'circular',
  RECTANGULAR = 'rectangular',
}

export interface AvatarProps {
  details: AvatarDetails
  size?: number
  useLocalSrc?: boolean
  shape?: AvatarShape
  accessibilityLabel: string
  borderRadius?: number
  providerID?: string
  unsetBorderRadius?: boolean
  hasBadge?: boolean
  badgeSize?: number
  topBadgeMargin?: string
  leftBadgeMargin?: string
}

export interface AvatarDetails {
  src: string
  displayName: string
  hide?: boolean
}
export const avatarAltTextGeneric = defineMessage({
  defaultMessage: 'Photograph of {name}',
  description: 'Alt text description for the photograph of the person in the avatar',
})

export const avatarAltTextProvider = defineMessage({
  defaultMessage: 'Photograph of provider {name}',
  description: 'Alt text description for the photograph of the provider in the avatar',
})

export const avatarAltTextForDefaultIllustration = defineMessage({
  defaultMessage: 'Illustration used when the provider has no photograph to display',
  description:
    'Alt text description for the placeholder illustration used when the provider does not have a valid photograph to display',
})

interface AvatarContainerProps {
  size: number
  shape: AvatarShape
  borderRadius?: number
  unsetBorderRadius?: boolean
}
const AvatarContainer = styled(Image)<AvatarContainerProps>(
  ({
    theme: {
      breakpoints: { isMobileSized },
    },
    size,
    shape,
    borderRadius,
    unsetBorderRadius,
  }) => {
    let borderRadiusConstant = 2
    if (shape === AvatarShape.RECTANGULAR) {
      if (isMobileSized || !IS_WEB) {
        borderRadiusConstant = 4.66
      } else {
        borderRadiusConstant = 4.75
      }
    }
    return {
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: unsetBorderRadius
        ? 'unset'
        : borderRadius
        ? `${borderRadius}px`
        : `${size / borderRadiusConstant}px`,
      borderTopRightRadius: unsetBorderRadius
        ? `8px`
        : borderRadius
        ? `${borderRadius}px`
        : `${size / borderRadiusConstant}px`,
    }
  },
)

const DefaultAvatarContainer = styled.View<{
  theme: ThemeType
  size: number
  backgroundColor: string
  shape: AvatarShape
  borderRadius?: number
}>(
  ({
    theme: {
      breakpoints: { isMobileSized },
    },
    size,
    backgroundColor,
    shape,
    borderRadius,
  }) => {
    let borderRadiusConstant = 2
    if (shape === AvatarShape.RECTANGULAR) {
      if (isMobileSized || !IS_WEB) {
        borderRadiusConstant = 4.66
      } else {
        borderRadiusConstant = 4.75
      }
    }
    return {
      width: `${size}px`,
      height: `${size}px`,
      backgroundColor,
      borderRadius: borderRadius || `${size / borderRadiusConstant}px`,
      justifyContent: 'center',
    }
  },
)

const BadgeContainer = styled.View<{
  top?: string | number
  left?: string | number
}>(({ top, left }) => ({
  position: 'absolute',
  top,
  left,
}))

export const DefaultAvatar: FunctionComponent<DefaultAvatarProps> = ({
  displayName = '',
  backgroundColor,
  size = 54,
  fontSize = BodyTextSize.LARGE,
  fontColor,
  style,
  shape = AvatarShape.CIRCULAR,
  testId = 'DefaultAvatar',
  borderRadius = 16,
}) => {
  // assumes displayName always has first and last name split by space
  const [firstName, lastName] = displayName.toUpperCase().split(' ')
  const { formatMessage } = useIntl()
  const { colors } = useTheme() as ThemeType

  return (
    <DefaultAvatarContainer
      backgroundColor={backgroundColor || colors.backgroundHighlightPrimary}
      size={size}
      shape={shape}
      testID={tID(testId)}
      style={style}
      accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: displayName })}
      borderRadius={borderRadius}
    >
      <BodyText
        size={fontSize}
        /* eslint-disable-next-line formatjs/no-literal-string-in-jsx */
        text={displayName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : 'N/A'}
        color={fontColor || colors.textActive}
        textAlign='center'
      />
    </DefaultAvatarContainer>
  )
}

/**
 * Standard round Avatar Icon that will display
 * the image with the provided uri string or will default if the image source
 * is an empty string
 */
export const Avatar: FunctionComponent<AvatarProps> = ({
  details: { src, displayName, hide } = { src: '', displayName: '' },
  useLocalSrc, // useLocalSrc is when we have a local imported image while using 'require(...some png)'
  size = 48,
  shape = AvatarShape.CIRCULAR,
  accessibilityLabel,
  borderRadius,
  providerID,
  unsetBorderRadius,
  hasBadge = false,
  badgeSize,
  topBadgeMargin,
  leftBadgeMargin,
}) => {
  const [isDefaultAvatarShown, setIsDefaultAvatarShown] = useState(false)
  const { formatMessage } = useIntl()
  const topProviderBadge = hasBadge ? (
    <BadgeContainer top={topBadgeMargin} left={leftBadgeMargin}>
      <RibbonHeartIllustration width={badgeSize} height={badgeSize} />
    </BadgeContainer>
  ) : null

  if (hide) {
    return null
  } else {
    // There are 3 different default avatar illustrations. The illustrations need to be evenly
    // distributed amongst providers who do not have a valid photograph. For example, on the provider results page,
    // the providers without photographs should not all have the same default avatar illustration.
    // The illustrations should also be assigned consistently so that whenever a client sees a provider's avatar,
    // it will always show the same illustration.
    // The `providerID` is a uuid which consists of hexadecimal values so the first value in the uuid is
    // taken and converted into an integer using `parseInt`, then mod by the number of different default avatar
    // illustrations (3) to get an illustration assignment.
    const defaultIllustrationId = providerID ? parseInt(providerID[0], 16) % DefaultIllustrations.length : null
    const defaultAvatar = (
      <View style={{ display: isDefaultAvatarShown ? 'flex' : 'none' }}>
        {defaultIllustrationId !== null ? (
          <AvatarContainer
            size={size}
            source={DefaultIllustrations[defaultIllustrationId]}
            shape={shape}
            alt={formatMessage(avatarAltTextForDefaultIllustration)}
            accessibilityLabel={formatMessage(avatarAltTextForDefaultIllustration)}
            accessibilityRole='image'
            testID={tID('Avatar-default-illustration')}
            unsetBorderRadius={unsetBorderRadius}
            borderRadius={borderRadius}
          />
        ) : (
          <DefaultAvatar displayName={displayName} size={size} shape={shape} borderRadius={borderRadius} />
        )}
        {topProviderBadge}
      </View>
    )

    const photoAvatar = (
      <View style={{ display: isDefaultAvatarShown ? 'none' : 'flex' }}>
        <AvatarContainer
          size={size}
          source={useLocalSrc ? src : { uri: src }}
          shape={shape}
          accessibilityLabel={accessibilityLabel}
          accessibilityRole='image'
          testID={tID('Avatar')}
          borderRadius={borderRadius}
          alt={accessibilityLabel}
          onError={() => {
            setIsDefaultAvatarShown(true)
          }}
          unsetBorderRadius={unsetBorderRadius}
        />
        {topProviderBadge}
      </View>
    )

    return (
      <>
        {defaultAvatar}
        {photoAvatar}
      </>
    )
  }
}

/**
 * Default Avatar that rotates between the color themes.
 * Theme determined by the provided index.
 */
export const ColoredAvatar: FunctionComponent<ColoredAvatarProps> = ({
  displayName = '',
  size = 54,
  style,
  shape = AvatarShape.CIRCULAR,
  index = 0,
  testId,
}) => {
  const { formatMessage, locale } = useIntl()
  const { colors } = useTheme() as ThemeType
  const { tealTheme, greenTheme, goldTheme, peachTheme, periwinkleTheme } = colors.components.avatar
  const avatarThemes = [tealTheme, greenTheme, goldTheme, peachTheme, periwinkleTheme]

  const { background, text } = avatarThemes[index % avatarThemes.length]
  const displayInitial = displayName ? displayName.charAt(0).toLocaleUpperCase(locale) : 'N/A'

  return (
    <DefaultAvatarContainer
      backgroundColor={background}
      size={size}
      shape={shape}
      testID={tID(testId)}
      style={style}
      accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: displayName })}
    >
      <Subhead
        size={SubheadSize.MEDIUM}
        /* eslint-disable-next-line formatjs/no-literal-string-in-jsx */
        text={displayInitial}
        color={text}
        textAlign='center'
      />
    </DefaultAvatarContainer>
  )
}
