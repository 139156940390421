import { defineMessages } from 'react-intl'

import { HEADER_NAV_ITEM } from './types'

export const HEADER_NAV_ITEM_LABELS = defineMessages({
  [HEADER_NAV_ITEM.BENEFITS]: {
    defaultMessage: 'My benefits',
    description: 'Link to benefits page',
  },
  [HEADER_NAV_ITEM.ACCOUNT]: {
    defaultMessage: 'Account',
    description: 'Link to user profile settings',
  },
  [HEADER_NAV_ITEM.FAQS]: {
    defaultMessage: 'FAQs',
    description: 'Link to frequently asked questions from clients, shown in microsite header',
  },
  [HEADER_NAV_ITEM.REFERRAL]: {
    defaultMessage: 'Send a referral',
    description: 'Link to referral',
  },
  [HEADER_NAV_ITEM.LOG_OUT]: {
    defaultMessage: 'Log out',
    description: 'Link text for a logged-in user to log out',
  },

  [HEADER_NAV_ITEM.HOME]: {
    defaultMessage: 'Home',
    description: 'Link to the home page',
  },
  [HEADER_NAV_ITEM.CARE]: {
    defaultMessage: 'Care',
    description: 'Link to the care page',
  },
  [HEADER_NAV_ITEM.SESSIONS]: {
    defaultMessage: 'Sessions',
    description: "Link to the appointments dashboard page which shows the patient's scheduled sessions",
  },
  [HEADER_NAV_ITEM.PROGRESS]: {
    defaultMessage: 'Progress',
    description: 'Link to view a user’s assessment progress',
  },
  [HEADER_NAV_ITEM.ESSENTIALS]: {
    defaultMessage: 'Library',
    description: 'Link to Lyra Essentials',
  },
})
