import { IntlAdapter } from './IntlAdapter'
import { LANG_QUERY_PARAM } from '../constants'
import { LyraIntlState } from '../LyraIntl'
import { loadLocaleData } from '../utils/loadLocaleDataPolyfill'

export class BrowserIntlAdapter implements IntlAdapter {
  static readonly LOCAL_STORAGE_KEY = 'intl:lang'

  private localStorageSupported: boolean | null = null

  private readonly storageKey: string

  constructor(storageKeySuffix = '') {
    this.storageKey = storageKeySuffix
      ? `${BrowserIntlAdapter.LOCAL_STORAGE_KEY}:${storageKeySuffix}`
      : BrowserIntlAdapter.LOCAL_STORAGE_KEY
  }

  async getUserLanguages(): Promise<string[]> {
    const locales = []
    if (this.isLocalStorageSupported()) {
      const lang = window.localStorage.getItem(this.storageKey)
      if (lang != null) {
        locales.push(lang)
      }
    }
    return locales.concat(navigator.languages)
  }

  async onLanguageInit({ language, reactIntlConfig }: Readonly<LyraIntlState<string>>) {
    await loadLocaleData(reactIntlConfig.locale, false)
    document.documentElement.lang = language
  }

  async onLanguageChanged({ language, reactIntlConfig }: Readonly<LyraIntlState<string>>) {
    await loadLocaleData(reactIntlConfig.locale, false)
    document.documentElement.lang = language
    this.cacheUserLanguage(language)
    const url = new URL(window.location.href)
    const currentLang = url.searchParams.get(LANG_QUERY_PARAM)
    if (currentLang) {
      url.searchParams.delete(LANG_QUERY_PARAM)
      window.history.pushState(null, '', url.toString())
    }
  }

  cacheUserLanguage(locale: string) {
    if (!this.isLocalStorageSupported()) {
      return false
    }

    try {
      window.localStorage.setItem(this.storageKey, locale)
      return true
    } catch (e) {
      return false
    }
  }

  isLocalStorageSupported() {
    if (this.localStorageSupported === null) {
      try {
        this.localStorageSupported = window.localStorage !== null
        if (window.localStorage) {
          const testKey = 'intl:test_key'
          window.localStorage.setItem(testKey, '')
          window.localStorage.removeItem(testKey)
        }
      } catch (e) {
        this.localStorageSupported = false
      }
    }
    return this.localStorageSupported
  }
}
