import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'

import { CostMessagingModalContent, CostMessagingModalCoverageInfo } from '@lyrahealth-inc/shared-app-logic'

import { Link } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { tID } from '../../utils'
import { CoverageMessagingModal } from '../coverageMessagingModal/CoverageMessagingModal'

export interface CoverageMessagingLinkProps {
  coverageModalContents: CostMessagingModalContent[]
  costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
  linkText?: string
  size?: BodyTextSize
  underline?: boolean
}

export const CoverageMessagingLink: FunctionComponent<CoverageMessagingLinkProps> = ({
  coverageModalContents = [],
  costMessagingModalCoverageInfo = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  linkText,
  size = BodyTextSize.SMALL,
  underline = true,
}) => {
  const { formatMessage } = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showCoverageModal =
    isCostMessagingEnabled &&
    costMessagingModalCoverageInfo &&
    costMessagingModalCoverageInfo?.customerCoverageType &&
    coverageModalContents &&
    coverageModalContents.length > 0

  if (!showCoverageModal) {
    return <></>
  }

  return (
    <>
      <Link
        text={
          linkText ||
          formatMessage({
            defaultMessage: 'Understand your coverage',
            description: "Link to open a modal which explains the member's care coverage",
          })
        }
        size={size}
        onPress={() => {
          trackCoverageModalPress(true)
          setIsModalOpen(true)
        }}
        underline={underline}
        testID={tID('CoverageMessagingLink')}
      />
      <CoverageMessagingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={(isOpen) => setIsModalOpen(isOpen)}
        coverageModalContents={coverageModalContents}
        costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
        trackCoverageModalPress={trackCoverageModalPress}
      />
    </>
  )
}
