/**
 * Parses a string as an IETF BCP 47 language tag and returns true if the language is English.
 */
export const isLanguageEnglish = (languageTag: string): boolean => {
  try {
    return new Intl.Locale(languageTag).language === 'en'
  } catch {
    return false
  }
}
