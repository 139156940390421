import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const MinusIcon = ({ height = 2, width = 16, fillColor, ...rest }: MinusIconProps & SvgProps) => {
  const { colors } = useTheme()
  const fill = fillColor || colors.iconDefault
  return (
    <Svg width={width} height={height} viewBox='0 0 16 2' fill='none' testID={tID('MinusIcon')} {...rest}>
      <Path d='M1 1H15' stroke={fill} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </Svg>
  )
}

export type MinusIconProps = {
  height?: number
  width?: number
  fillColor?: string
}
