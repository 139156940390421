import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { isProviderAnyTypeOf, PROVIDER_TYPES, ProviderInfo } from '@lyrahealth-inc/shared-app-logic'

import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'

export const useNavigateToProviderMatches = ({ provider }: { provider: ProviderInfo }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  return useCallback(() => {
    if (isProviderAnyTypeOf({ provider, providerTypes: [PROVIDER_TYPES.COACH] })) {
      navigate({
        pathname: `/secure/onboard/${PAGE_ROUTES.COACHES}`,
        search: searchParams.toString(),
      })
    } else {
      navigate({
        pathname: `/secure/onboard/${PAGE_ROUTES.T1_MULTI_PROVIDERS}`,
        search: searchParams.toString(),
      })
    }
  }, [provider, searchParams, navigate])
}
