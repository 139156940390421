import { useEffect, useState } from 'react'

import { IS_WEB } from '@lyrahealth-inc/ui-core-crossplatform'

export const UNAUTHENTICATED_REDIRECT_URL = 'unauthenticatedRedirectUrl'
/**
 * This enables sharing sessionStorage data for the UNAUTHENTICATED_REDIRECT_URL
 *  between open browser windows and tabs.
 * For users who receive a direct path URL and then sign up for a new account
 *  the redirect url will not persist since an email link would open up in a new tab.
 * This ensures that a new window/tab thats open will capture any redirect urls from existing tabs
 *  and any requests to remove the redirect url will also be synced across tabs.
 */
export const useShareSessionStorageAcrossTabs = () => {
  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (!IS_WEB || initialized) {
      return
    }
    const channel = new BroadcastChannel('sync-session-storage')
    const originalRemoveItem = sessionStorage.removeItem
    // Intercept removeItem to send a delete request to all tabs
    sessionStorage.removeItem = (key) => {
      if (key === UNAUTHENTICATED_REDIRECT_URL) {
        channel.postMessage({ request: 'delete-redirect-url' })
      }
      originalRemoveItem.call(sessionStorage, key)
    }

    const sessionRedirectURL = sessionStorage.getItem(UNAUTHENTICATED_REDIRECT_URL)
    if (!sessionRedirectURL) {
      channel.postMessage({ request: 'sync-redirect-url' })
    }

    channel.onmessage = (e) => {
      if (e.data.request === 'delete-redirect-url') {
        originalRemoveItem.call(sessionStorage, UNAUTHENTICATED_REDIRECT_URL)
      }
      if (e.data.request === 'sync-redirect-url') {
        const sessionRedirectURL = sessionStorage.getItem(UNAUTHENTICATED_REDIRECT_URL)
        if (!!sessionRedirectURL) {
          channel.postMessage({ sessionRedirectURL, response: 'sync-redirect-url' })
        }
      }
      if (e.data.response === 'sync-redirect-url') {
        sessionStorage.setItem(UNAUTHENTICATED_REDIRECT_URL, e.data.sessionRedirectURL)
      }
    }
    setInitialized(true)
  }, [initialized])
}
