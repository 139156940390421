import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CostMessagingModalContent, CoverageType, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import {
  eapAndHpiMedicationDescription,
  eapCoachingOrTherapyTitleForFamily,
  eapCoachingOrTherapyTitleForYourself,
  medicalConsultationTitle,
} from './util/DefaultCoverageTexts'

const attCoachingOrTherapyDescriptionPerIssue = (numberOfSessions: string, customerName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} sessions per issue, per year fully covered by {customerName}.'
      description='Cost messaging modal  description text'
      values={{ numberOfSessions, customerName }}
    />
  )
}

const attCoachingOrTherapyDescriptionPerIssueForFamily = (numberOfSessions: string, customerName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} sessions per issue, per year for each dependent (child, spouse, or domestic partner), fully covered by {customerName}.'
      description='Cost messaging modal session description text'
      values={{ numberOfSessions, customerName }}
    />
  )
}

export const getAttUnionCostMessagingModalText = (numberOfSessions: string, customerDisplayName: string) => [
  {
    title: eapCoachingOrTherapyTitleForYourself,
    description: attCoachingOrTherapyDescriptionPerIssue(numberOfSessions, customerDisplayName),
  },
  {
    title: eapCoachingOrTherapyTitleForFamily,
    description: attCoachingOrTherapyDescriptionPerIssueForFamily(numberOfSessions, customerDisplayName),
  },
  {
    title: medicalConsultationTitle,
    description: eapAndHpiMedicationDescription,
  },
]

const getCostMessagingModalText = (
  numberOfSessions: string,
  customerDisplayName: string,
): { [key in CoverageType]: () => CostMessagingModalContent[] } => {
  const attUnionCostMessagingModalText = getAttUnionCostMessagingModalText(numberOfSessions, customerDisplayName)
  return {
    [CoverageType.EAP_ONLY]: () => {
      return attUnionCostMessagingModalText
    },
    [CoverageType.HPI_ONLY]: () => {
      return attUnionCostMessagingModalText
    },
    [CoverageType.EAP_AND_HPI]: () => {
      return attUnionCostMessagingModalText
    },
  }
}

export const attunion: GetCustomerCopyFunc = ({
  numberOfSessions,
  customerName,
  customerDisplayName,
  displayVisitsPerIssuePerYear,
}) => {
  return {
    costMessagingModalText: getCostMessagingModalText(
      displayVisitsPerIssuePerYear != undefined ? displayVisitsPerIssuePerYear.toString() : numberOfSessions || '',
      customerDisplayName ? customerDisplayName : customerName ?? '',
    ),
  }
}
