import React, { FunctionComponent, useContext, useMemo } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Platform, View, ViewStyle } from 'react-native'
import { TextInputMask, TextInputMaskProps } from 'react-native-masked-text'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { AppContext } from '../../context'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { getCommonStyles } from '../../styles/commonStyles'
import { getTypeStyles } from '../../styles/typeStyles'
import { deviceIsSamsung, tID } from '../../utils'
import { BodyText, Size } from '../bodyText/BodyText'
import { BaseInput } from '../formElements/BaseInput'

export interface MaskedInputProps extends Omit<TextInputMaskProps, 'style'> {
  label?: string
  error?: string
  name?: string
  mask: string
  readOnly?: boolean
  hidePlaceholder?: boolean
  placeholder?: string
  convert?: boolean
  inputAccessoryViewID?: string
  active?: boolean
  style?: ViewStyle
  baseInputStyle?: ViewStyle
  showErrorAsWarning?: boolean
  onTextChange?: ((text: string, rawText?: string | undefined) => void) | undefined
}

const TextInputMaskContainer = styled(TextInputMask)`
  ${Platform.select({ web: `outline-style: none; font-size: 16px;` })};
`

/**
 * Note: There is a bug in react native that causes controlled TextInputs to flicker.
 * This issue outlines the problem: https://github.com/facebook/react-native/issues/28223
 */

export const MaskedInput: FunctionComponent<MaskedInputProps> = ({
  label,
  value,
  onChange = noop,
  onFocus,
  onBlur,
  error,
  mask,
  name,
  type = 'custom',
  readOnly,
  placeholder,
  hidePlaceholder,
  convert = true, // determines whether or not we want to convert mask for ui-core
  active,
  inputAccessoryViewID,
  style,
  baseInputStyle,
  showErrorAsWarning,
  onTextChange,
}) => {
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const { colors } = useTheme()
  const commonStyles = getCommonStyles(colors)
  const { copyPasteDisabled } = useContext(AppContext)
  const isSamsung = deviceIsSamsung()
  /**
   * Since this masking library uses a different masking pattern than
   * react-maskedinput used in ui-core we need to remap the mask string.
   * 1 and 9 are for digits/numbers.
   * * and S are for alphanumeric.
   */
  const convertedMask = useMemo(() => {
    return mask.replace(/1/g, '9').replace(/\*/g, 'S')
  }, [mask])

  const regExpContainsLetters = /[a-zA-Z]/g
  const keyboardType = !regExpContainsLetters.test(convertedMask) ? 'number-pad' : 'default'
  const { lineHeight, ...prunedTypeStyles } = getTypeStyles(colors).bodyDefault
  const maskOptions = type === 'datetime' ? { format: mask } : { mask: convert ? convertedMask : mask }
  const errorStyles = error ? { borderColor: showErrorAsWarning ? colors.borderWarning : colors.inputOutlineError } : {}
  return (
    <BaseInput label={label} error={error} name={name} style={baseInputStyle} showErrorAsWarning={showErrorAsWarning}>
      {readOnly ? (
        <BodyText testID={tID(`${name}-MaskInput`)} size={Size.DEFAULT}>
          {value || '––'}
        </BodyText>
      ) : (
        <View style={[commonStyles.textInput, style, { backgroundColor: colors.inputBackgroundDefault }, errorStyles]}>
          <TextInputMaskContainer
            ref={focusRef}
            testID={tID(`${name}-MaskInput`)}
            type={type}
            keyboardType={keyboardType}
            options={maskOptions}
            value={value}
            onChangeText={onTextChange || onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={hidePlaceholder ? '' : placeholder ? placeholder : mask}
            style={[prunedTypeStyles, { height: '100%' }, Platform.OS !== 'ios' && { lineHeight }]}
            contextMenuHidden={copyPasteDisabled}
            // See InputField.tsx for a description of this device specific logic
            caretHidden={copyPasteDisabled && isSamsung}
            accessibilityLabel={label}
            placeholderTextColor={colors.inputTextPlaceholder}
            inputAccessoryViewID={inputAccessoryViewID}
          />
        </View>
      )}
    </BaseInput>
  )
}

export const MaskedInputRFF: FunctionComponent<FieldRenderProps<string>> = ({
  input: { value, onChange, onFocus, onBlur, name },
  meta: { touched, error, submitError, active },
  label,
  mask,
  keyboardType,
  readOnly,
  hidePlaceholder,
  placeholder,
  convert,
  inputAccessoryViewID,
  style,
  baseInputStyle,
}) => {
  return (
    <MaskedInput
      type='custom'
      mask={mask}
      label={label}
      value={value}
      onChange={onChange}
      onFocus={() => onFocus()}
      onBlur={() => onBlur()}
      error={touched && (error || submitError)}
      keyboardType={keyboardType}
      name={name}
      readOnly={readOnly}
      hidePlaceholder={hidePlaceholder}
      placeholder={placeholder}
      convert={convert}
      inputAccessoryViewID={inputAccessoryViewID}
      active={active}
      style={style}
      baseInputStyle={baseInputStyle}
    />
  )
}
