import React from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { Avatar } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { avatarAltTextProvider, AvatarDetails } from '../../atoms/icons/Avatar'
import { ChevronV2Icon } from '../../atoms/icons/ChevronV2Icon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { ThemeType, tID } from '../../utils'

export type ProviderAvatarDetails = Omit<AvatarDetails, 'hide'>

export interface ProviderSessionCardProps {
  avatarDetails: ProviderAvatarDetails
  backgroundColor?: string
  sessionInfo?: string | null
  sessionInfoColor?: string
  testID?: string
  imageSize?: number
  providerID?: string
  withChevron?: boolean
  onPress?: () => void
  borderStyle?: boolean
}

const SessionInfoContainer = styled.View({
  flexDirection: 'row',
  flex: '1',
})

const ProviderSessionCardContainer = styled(PressableOpacity)<{
  backgroundColor?: string
  theme: ThemeType
  borderStyle?: boolean
}>(({ backgroundColor, theme: { spacing, colors }, borderStyle }) => ({
  flexDirection: 'row',
  ...(!!backgroundColor && { backgroundColor }),
  ...(borderStyle && {
    padding: spacing['16px'],
    borderRadius: spacing['16px'],
    borderWidth: '1px',
    borderColor: colors.borderDefault,
  }),
}))

const AvatarContainer = styled.View({
  paddingRight: '12px',
  alignSelf: 'center',
})

const InformationContainer = styled.View({
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
})

const ChevronContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  width: '24px',
  alignItems: 'center',
  marginLeft: spacing['16px'],
  justifyContent: 'center',
}))

export const ProviderSessionCard = ({
  avatarDetails: { src, displayName } = { src: '', displayName: '' },
  backgroundColor,
  sessionInfo,
  sessionInfoColor,
  testID,
  imageSize,
  providerID,
  withChevron,
  onPress,
  borderStyle,
}: ProviderSessionCardProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  return (
    <ProviderSessionCardContainer
      onPress={onPress}
      backgroundColor={backgroundColor}
      testID={tID(testID)}
      borderStyle={borderStyle}
    >
      <SessionInfoContainer>
        <AvatarContainer>
          <Avatar
            details={{ src, displayName }}
            accessibilityLabel={formatMessage(avatarAltTextProvider, { name: displayName })}
            size={imageSize}
            providerID={providerID}
          />
        </AvatarContainer>
        <InformationContainer>
          <Subhead text={displayName} size={SubheadSize.XSMALL} numberOfLines={2} color={colors.textPrimary} />
          {!!sessionInfo && (
            <BodyText
              text={sessionInfo}
              size={BodyTextSize.SMALL}
              numberOfLines={2}
              color={sessionInfoColor || colors.iconDefault}
            />
          )}
        </InformationContainer>
      </SessionInfoContainer>
      {withChevron && onPress && (
        <ChevronContainer>
          <ChevronV2Icon fillColor={colors.iconDefault} size={12} />
        </ChevronContainer>
      )}
    </ProviderSessionCardContainer>
  )
}
