import { defineMessages } from 'react-intl'

export const FAQ_MESSAGES_DEFAULT = defineMessages({
  DOES_LYRA_HAVE_PROVIDERS_WHO_SHARE_MY_SOCIAL_CULTURAL_IDENTITIES: {
    defaultMessage:
      'Lyra has providers that self-identify across a broad range of racial, gender, sexual orientation, and other cultural identities.',
    description: 'Answer to FAQ - Does Lyra have providers who share my social/cultural identities?',
  },
  DOES_LYRA_HAVE_PROVIDERS_THAT_SPECIALIZE_IN_MY_SPECIFIC_NEEDS: {
    defaultMessage:
      'Lyra has providers with a broad range of clinical expertise and specializations, including racial stress and trauma, PTSD, and the needs of members from historically underrepresented communities.',
    description: 'Answer to FAQ - Does Lyra have providers that specialize in my specific needs?',
  },
})

export const FAQ_EMPLOYEE_MESSAGES_BEFORE_LAUNCH = defineMessages({
  HOW_MUCH_DOES_LYRA_COST: {
    defaultMessage:
      'When your benefit launches, signing up for a Lyra account is free. Once you sign up, you can learn more about the coverage, eligibility, and costs.',
    description: 'Answer to FAQ before launch - How much does Lyra cost?',
  },
  WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER: {
    defaultMessage:
      'Lyra provides mental and emotional health care that is effective, convenient, and personalized. We’ll match you to proven treatments and the right care for your needs, whether that’s short-term care with one of our providers or other resources such as mental wellness content and exercises, or work-life services.',
    description: 'Answer to FAQ before launch - What type of services does Lyra offer?',
  },
  HOW_DO_I_GET_STARTED: {
    defaultMessage:
      'When your benefit launches, create a free account. Then use our online platform or contact our team directly to tell us what you’re going through. We’ll share how we can help with different care options and mental wellness tools that fit your needs and preferences, and get you started right away.',
    description: 'Answer to FAQ before launch - How do I get started?',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER_FIRST_PARAGRAPH: {
    defaultMessage:
      'The way you will meet will depend on your needs, preferences, location, and provider. Options may include video, in-person, or live messaging.',
    description:
      'First Paragraph of Answer to FAQ before launch - How will I meet with my provider? explaining the different ways to meet with a provider.',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER_SECOND_PARAGRAPH: {
    defaultMessage:
      'Once your benefit launches, reach out to our Care Navigator team for additional support finding the right provider and preferred meeting method for your needs.',
    description:
      'Second Paragraph of Answer to FAQ before launch - How will I meet with my provider? specifying where to reach out for additional support.',
  },
})

export const FAQ_MEMBER_MESSAGES_BEFORE_LAUNCH = defineMessages({
  HOW_MUCH_DOES_LYRA_COST: {
    defaultMessage:
      'When your benefit launches, signing up for a Lyra account is free. Once you sign up, you can learn more about what’s included in your Lyra membership.',
    description: 'Answer to FAQ before launch - How much does Lyra cost?',
  },
  WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER: {
    defaultMessage:
      'Lyra provides mental and emotional healthcare that is effective, convenient, and personalized. We’ll match you to proven treatments and the right care for your needs, whether that’s short-term care within our network of top providers or other mental wellness resources.',
    description: 'Answer to FAQ before launch - What type of services does Lyra offer?',
  },
  HOW_DO_I_GET_STARTED: {
    defaultMessage:
      'When your benefit launches, create an account. Then use our online platform or contact our team directly to tell us what you’re going through. We’ll share care options and mental wellness tools that fit your needs and preferences, and get you started right away.',
    description: 'Answer to FAQ before launch - How do I get started?',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER: {
    defaultMessage:
      'You’ll meet with your provider through video conference via your computer or smartphone. Our providers use secure and regulation compliant platforms to protect your privacy.',
    description: 'Answer to FAQ - How will I meet with my provider?',
  },
})

export const FAQ_EMPLOYEE_MESSAGES = defineMessages({
  IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL: {
    defaultMessage:
      'Lyra is a confidential benefit. When you use Lyra, this will not be revealed to your employer without your ' +
      'permission, except as required by law and within HIPAA guidelines. For more details on how Lyra manages ' +
      'information about participants, please see our <link1>privacy policy</link1> and <link2>HIPAA Notice (U.S)</link2>.',
    description: 'Answer to FAQ - Is the information I share confidential?',
  },
  HOW_MUCH_DOES_LYRA_COST: {
    defaultMessage:
      'Signing up for a Lyra account is free. Once you sign up, you can learn more about the coverage, eligibility, and costs offered by your employer.',
    description: 'Answer to FAQ - How much does Lyra cost?',
  },
  WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER: {
    defaultMessage:
      'Lyra provides mental and emotional health care that is effective, convenient, and personalized. We’ll match you to proven treatments and the right care for your needs, whether that’s short-term care with our network of top providers or other resources available through your employer’s benefits plan such as mental wellness content and exercises, or work-life services.',
    description: 'Answer to FAQ - What type of services does Lyra offer?',
  },
  HOW_DO_I_GET_STARTED: {
    defaultMessage:
      'First, create a free account. Then use our online platform or contact our team directly to tell us what you’re going through. We’ll share how we can help with different care options and mental wellness tools that fit your needs and preferences, and get you started right away.',
    description: 'Answer to FAQ - How do I get started?',
  },
  HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED: {
    defaultMessage:
      'It usually takes just a few minutes to create an account and get access to mental wellness content and exercises. Or if you’re looking to meet with a provider, answer a few questions about how you are feeling, your needs, and your concerns, and we will recommend some options for you.',
    description: 'Answer to FAQ - How long does it take to get started?',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER_FIRST_PARAGRAPH: {
    defaultMessage:
      'The way you will meet will depend on your needs, preferences, location, and provider. Options may include video, in-person, or live messaging.',
    description:
      'First Paragraph of Answer to FAQ - How will I meet with my provider? explaining the different ways to meet with a provider.',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER_SECOND_PARAGRAPH: {
    defaultMessage:
      '<link>Reach out to our Care Navigator team</link> for additional support finding the right provider and preferred meeting method for your needs.',
    description:
      'Second Paragraph of Answer to FAQ - How will I meet with my provider? specifying where to reach out for additional support.',
  },
})

export const FAQ_EMPLOYEE_COST_MESSAGES = defineMessages({
  EAP_ONLY_WITH_DEPENDENTS: {
    defaultMessage:
      '{customerName} provides coverage for <bold>{programLevelSessionLimitEnabled, select, true {{therapySessionCount} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{coachingSessionCount}}} coaching} other {{sessionCount} mental health}} sessions</bold> per year for you and each of your dependents at no cost to you.',
    description: 'Answer to FAQ - How much does Lyra cost? - EAP Only with Dependents',
  },
  EAP_ONLY_WITHOUT_DEPENDENTS: {
    defaultMessage:
      '{customerName} provides coverage for <bold>{programLevelSessionLimitEnabled, select, true {{therapySessionCount} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{coachingSessionCount}}} coaching} other {{sessionCount} mental health}} sessions</bold> per year at no cost to you.',
    description: 'Answer to FAQ - How much does Lyra cost? - EAP Only without Dependents',
  },
  EAP_HPI_WITH_DEPENDENTS: {
    defaultMessage:
      '{customerName} provides coverage for <bold>{programLevelSessionLimitEnabled, select, true {{therapySessionCount} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{coachingSessionCount}}} coaching} other {{sessionCount} mental health}} sessions</bold> per year for you and each of your dependents at no cost to you. Depending on your health plan, cost-sharing for sessions may also be available. Sign up to learn more.',
    description: 'Answer to FAQ - How much does Lyra cost? - EAP and HPI with Dependents',
  },
  EAP_HPI_WITHOUT_DEPENDENTS: {
    defaultMessage:
      '{customerName} provides coverage for <bold>{programLevelSessionLimitEnabled, select, true {{therapySessionCount} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{coachingSessionCount}}} coaching} other {{sessionCount} mental health}} sessions</bold> per year at no cost to you. Depending on your health plan, cost-sharing for sessions may also be available. Sign up to learn more.',
    description: 'Answer to FAQ - How much does Lyra cost? - EAP and HPI without Dependents',
  },
  HPI_ONLY_WITH_DEPENDENTS: {
    defaultMessage:
      'Depending on your health plan, cost-sharing for mental health sessions may be available for you and your dependents. Sign up to learn what your coverage includes.',
    description: 'Answer to FAQ - How much does Lyra cost? - HPI Only with Dependents',
  },
  HPI_ONLY_WITHOUT_DEPENDENTS: {
    defaultMessage:
      'Depending on your health plan, cost-sharing for mental health sessions may be available. Sign up to learn what your coverage includes.',
    description: 'Answer to FAQ - How much does Lyra cost? - HPI Only without Dependents',
  },
  CUSTOM_CUSTOMER_WITH_DEPENDENTS: {
    defaultMessage:
      '{customerName} provides coverage for a set number of mental health sessions for you and your dependents. Sign up to learn what your coverage includes.',
    description: 'Answer to FAQ - How much does Lyra cost? - Custom Customer with Dependents',
  },
  CUSTOM_CUSTOMER_WITHOUT_DEPENDENTS: {
    defaultMessage:
      '{customerName} provides coverage for a set number of mental health sessions. Sign up to learn what your coverage includes.',
    description: 'Answer to FAQ - How much does Lyra cost? - Custom Customer without Dependents',
  },
  ATT_BARGAINED: {
    defaultMessage:
      '{customerName} provides coverage for <bold>{sessionCount} mental health sessions</bold> per issue, per year for you and each of your dependents at no cost to you.',
    description: 'Answer to FAQ - How much does Lyra cost? - AT&T Bargained (custom copy)',
  },
})

export const FAQ_MEMBER_MESSAGES = defineMessages({
  IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL: {
    defaultMessage:
      'Lyra will abide by all legal and HIPAA guidelines regarding sharing information. For more details on how ' +
      'Lyra manages information about participants, please see our <link1>privacy policy</link1> and <link2>HIPAA Notice (U.S)</link2>.',
    description: 'Answer to FAQ - Is the information I share confidential?',
  },
  HOW_MUCH_DOES_LYRA_COST: {
    defaultMessage: 'Once you sign up, you can learn more about what’s included in your Lyra membership.',
    description: 'Answer to FAQ - How much does Lyra cost?',
  },
  WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER: {
    defaultMessage:
      'Lyra provides mental and emotional healthcare that is effective, convenient, and personalized. We’ll match you to proven treatments and the right care for your needs, whether that’s short-term care within our network of top providers or other mental wellness resources.',
    description: 'Answer to FAQ before launch - What type of services does Lyra offer?',
  },
  HOW_DO_I_GET_STARTED: {
    defaultMessage:
      'First, create an account. Then use our online platform or contact our team directly to tell us what you’re going through. We’ll share care options and mental wellness tools that fit your needs and preferences, and get you started right away.',
    description: 'Answer to FAQ - How do I get started?',
  },
  HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED: {
    defaultMessage:
      'It usually takes just a few minutes to create an account and get access to mental wellness resources. If you’re looking to meet with a provider, answer a few questions about your needs and your concerns, and we will recommend some options for you.',
    description: 'Answer to FAQ - How long does it take to get started?',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER: {
    defaultMessage:
      'You’ll meet with your provider through video conference via your computer or smartphone. Our providers use secure and regulation compliant platforms to protect your privacy.',
    description: 'Answer to FAQ - How will I meet with my provider?',
  },
})

export const FAQ_QUESTIONS = defineMessages({
  HOW_IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL: {
    defaultMessage: 'Is the information I share confidential?',
    description: 'Under the FAQ section (About Lyra), frequently asked question 1',
  },
  HOW_MUCH_DOES_LYRA_COST: {
    defaultMessage: 'How much does Lyra cost?',
    description: 'Under the FAQ section (About Lyra), frequently asked question 2',
  },
  WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER: {
    defaultMessage: 'What type of services does Lyra offer?',
    description: 'Under the FAQ section (About Lyra), frequently asked question 3',
  },
  DOES_LYRA_HAVE_PROVIDERS_WHO_SHARE_MY_SOCIAL_CULTURAL_IDENTITIES: {
    defaultMessage: 'Does Lyra have providers who share my social/cultural identities?',
    description: 'Under the FAQ section (About Lyra Providers), frequently asked question 2',
  },
  DOES_LYRA_HAVE_PROVIDERS_THAT_SPECIALIZE_IN_MY_SPECIFIC_NEEDS: {
    defaultMessage: 'Does Lyra have providers that specialize in my specific needs?',
    description: 'Under the FAQ section (About Lyra Providers), frequently asked question 3',
  },
  HOW_DO_I_GET_STARTED: {
    defaultMessage: 'How do I get started?',
    description: 'Under the FAQ section (How does it work?), frequently asked question 1',
  },
  HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED: {
    defaultMessage: 'How long does it take to get started?',
    description: 'Under the FAQ section (How does it work?), frequently asked question 2',
  },
  HOW_WILL_I_MEET_WITH_MY_PROVIDER: {
    defaultMessage: 'How will I meet with my provider?',
    description: 'Under the FAQ section (How will I meet with my provider?), frequently asked question 3',
  },
})
