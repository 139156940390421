import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const SessionsValuePropSpotIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 96 96' fill='none'>
      <Path
        d='M48.3684 0.0163574C41.742 0.0163574 35.3128 1.1527 29.2593 3.39313C14.8473 8.73527 4.71509 20.5786 1.45973 35.8861C-0.691542 45.8665 -0.416467 55.0976 2.30582 64.0935C5.78124 75.5821 14.3123 85.2704 25.7118 90.6733C31.3707 93.3614 37.872 94.8961 45.0315 95.2338C45.9516 95.2756 46.8868 95.2983 47.8164 95.2983C53.5607 95.2983 59.3392 94.511 64.9924 92.9593C65.4268 92.8511 65.8404 92.7392 66.2767 92.6102C71.3571 91.1229 75.929 88.7705 79.8407 85.6271C84.0883 82.2332 87.5845 77.9307 90.2215 72.8466C90.7413 71.8449 91.2383 70.7901 91.7866 69.5286C94.8029 62.5246 96.2598 54.6841 96.0018 46.8454C95.8804 43.3795 95.3644 39.6954 94.4728 35.8861C93.0045 29.7054 90.6104 23.8928 87.5485 19.0667C86.71 17.771 85.9398 16.6821 85.1772 15.7032C79.6738 8.60437 71.0991 3.62268 60.3882 1.30446C56.4537 0.450785 52.4092 0.0182543 48.3703 0.0182543L48.3684 0.0163574Z'
        fill='white'
      />
      <Mask
        id='mask0_284_5166'
        //style="mask-type:alpha"
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='96'
        height='96'
      >
        <Path
          d='M48.3684 0.0163574C41.742 0.0163574 35.3128 1.1527 29.2593 3.39313C14.8473 8.73527 4.71509 20.5786 1.45973 35.8861C-0.691542 45.8665 -0.416467 55.0976 2.30582 64.0935C5.78124 75.5821 14.3123 85.2704 25.7118 90.6733C31.3707 93.3614 37.872 94.8961 45.0315 95.2338C45.9516 95.2756 46.8868 95.2983 47.8164 95.2983C53.5607 95.2983 59.3392 94.511 64.9924 92.9593C65.4268 92.8511 65.8404 92.7392 66.2767 92.6102C71.3571 91.1229 75.929 88.7705 79.8407 85.6271C84.0883 82.2332 87.5845 77.9307 90.2215 72.8466C90.7413 71.8449 91.2383 70.7901 91.7866 69.5286C94.8029 62.5246 96.2598 54.6841 96.0018 46.8454C95.8804 43.3795 95.3644 39.6954 94.4728 35.8861C93.0045 29.7054 90.6104 23.8928 87.5485 19.0667C86.71 17.771 85.9398 16.6821 85.1772 15.7032C79.6738 8.60437 71.0991 3.62268 60.3882 1.30446C56.4537 0.450785 52.4092 0.0182543 48.3703 0.0182543L48.3684 0.0163574Z'
          fill='#FFE9E3'
        />
      </Mask>
      <G mask='url(#mask0_284_5166)'>
        <Path
          d='M55.6587 18.1246C55.6587 18.1246 51.6682 11.0657 45.3242 13.3158C38.9801 15.5659 39.4918 19.1481 36.4234 21.5017C33.3549 23.8554 30.3859 24.5722 29.2609 28.0509C28.1358 31.5296 29.0558 34.8032 26.1904 38.7936C23.325 42.784 18.5162 58.9528 24.3485 59.6677C30.1808 60.3825 63.3309 63.9628 63.3309 63.9628C63.3309 63.9628 71.0051 56.8003 70.1867 49.6378C69.3683 42.4754 67.5264 43.6004 67.8331 39.1002C68.1397 34.5981 68.1397 30.9143 65.6845 28.4591C63.2293 26.0039 64.661 18.7399 56.6802 19.6598L55.6567 18.1246H55.6587Z'
          fill='#7F6E51'
        />
        <Path
          d='M10.7423 90.9738C10.7423 90.9738 15.1409 62.7342 21.9968 57.5171C28.8526 52.3001 39.4937 50.8664 43.279 50.8664C47.0644 50.8664 52.2834 49.7414 61.2858 53.1165C70.2901 56.4936 78.1674 59.1539 83.6931 70.3048C89.2188 81.4577 91.3673 102.228 91.3673 102.228C91.3673 102.228 82.4665 112.153 51.0548 112.664C19.6431 113.176 12.0725 105.4 11.9709 101.306C11.8693 97.2143 10.7423 90.9718 10.7423 90.9718V90.9738Z'
          fill='#00A4B3'
        />
        <Path
          d='M41.1305 43.7319C41.1305 43.7319 39.9019 50.5638 40.2105 52.105C40.5172 53.6442 43.3825 58.457 48.8047 58.3595C54.2268 58.2619 57.1937 53.8374 57.3988 52.105C57.6039 50.3726 55.5569 43.5407 55.0452 40.2691C54.5334 36.9975 43.9958 35.6495 41.1305 43.7339V43.7319Z'
          fill='#D49D73'
        />
        <Path
          d='M38.0639 35.9282C38.0639 35.9282 37.1379 47.8995 48.1893 48.2062C59.2386 48.5128 60.3657 39.1003 60.6723 38.1783C60.979 37.2564 64.6628 36.0298 64.0495 32.8577C63.4362 29.6857 60.3657 30.299 60.3657 30.299C60.3657 30.299 60.7141 20.7829 53.6333 20.6814C46.5525 20.5798 41.437 21.8064 40.0033 26.7188C38.5696 31.6311 38.0639 35.9262 38.0639 35.9262V35.9282Z'
          fill='#E6B087'
        />
        <Path
          d='M39.6969 31.2209C39.6969 31.2209 37.0366 27.4356 35.1947 30.6076C33.3528 33.7796 38.7749 37.0532 38.7749 37.0532L39.6949 31.2209H39.6969Z'
          fill='#E6B087'
        />
        <Path
          d='M48.3807 70.4037C48.3807 70.4037 61.8015 67.258 63.0786 67.4069C64.2578 67.5444 65.096 67.8702 66.0642 70.6806C66.9966 73.3893 73.3727 100.685 73.384 100.749C74.0602 104.49 72.3518 104.85 70.7319 105.496C69.1289 106.136 51.24 110.335 46.4782 111.441C45.2312 111.731 43.8656 111.768 42.8126 111.409C41.9782 111.122 41.0891 110.887 40.136 107.828C38.9248 103.94 33.4717 78.023 33.4717 78.023C33.4717 78.023 32.8878 74.8321 36.0805 73.8658C39.2733 72.8995 48.3807 70.4037 48.3807 70.4037Z'
          fill='#EDE6DE'
        />
        <Path
          d='M44.8073 70.6862C44.8073 70.6862 40.0398 71.4548 40.1095 72.9278C40.1792 74.4008 40.2094 76.6592 42.5827 76.4709C44.9561 76.2825 57.9438 73.0653 58.7349 72.2911C59.526 71.5169 58.8969 67.9776 57.6367 67.7666C56.3766 67.5556 52.8052 68.7461 52.8052 68.7461C52.8052 68.7461 51.2644 65.753 48.2657 66.6741C45.2669 67.5971 44.8073 70.6862 44.8073 70.6862Z'
          fill='#85CCD5'
        />
        <Path
          d='M55.8617 32.0672C55.79 31.8482 55.6526 31.649 55.4754 31.5017C55.3419 31.3743 55.1826 31.2787 55.0074 31.2389C54.4638 31.1154 53.9919 31.3464 53.735 31.7904C53.6016 31.9557 53.504 32.1508 53.4622 32.3739C53.4304 32.5371 53.4224 32.6964 53.4363 32.8458C53.4104 33.0529 53.3985 33.2619 53.4045 33.467C53.4184 33.919 53.6753 34.3153 54.0934 34.4925C54.4977 34.6638 55.0054 34.5861 55.32 34.2595C55.8716 33.6861 56.1125 32.8338 55.8597 32.0672H55.8617Z'
          fill='#AA8052'
        />
        <Path
          d='M45.8754 31.4519C46.0247 31.0437 45.7838 30.5339 45.4692 30.289C45.1306 30.0281 44.595 29.9525 44.2226 30.2014C43.8702 30.4363 43.5974 30.749 43.4441 31.1353C43.2808 31.3523 43.1832 31.6191 43.1792 31.888C43.1733 32.2563 43.3365 32.6426 43.6133 32.8995C43.8284 33.1942 44.0633 33.3854 44.4337 33.4351C44.7921 33.4829 45.1107 33.3734 45.3756 33.1265C45.7141 32.8119 45.9092 32.3479 45.9212 31.888C45.9251 31.7326 45.9072 31.5873 45.8754 31.4499V31.4519Z'
          fill='#AA8052'
        />
        <Path
          d='M50.099 31.7426C49.9855 30.4244 47.9126 30.4125 48.0261 31.7426C48.1854 33.5925 47.823 35.3508 46.8951 36.9637C46.23 38.1226 48.0181 39.166 48.6832 38.0091C49.7784 36.1015 50.2861 33.937 50.097 31.7426H50.099Z'
          fill='#C49069'
        />
        <Path
          d='M55.1567 20.753C55.1567 20.753 59.0715 30.287 60.3658 30.3009C61.6601 30.3149 61.3873 23.3316 61.3873 23.3316L55.1567 20.751V20.753Z'
          fill='#7F6E51'
        />
        <Path
          d='M55.5131 20.2851L55.1566 20.751C55.1566 20.751 48.6114 23.2978 44.175 23.5168C39.7385 23.7359 39.0814 30.3647 39.0814 30.3647L37.8767 25.2154L43.7369 18.5328L48.4462 17.9294L55.5131 20.2851Z'
          fill='#7F6E51'
        />
        <Path
          d='M57.9644 29.2416C56.7258 27.8079 54.9457 27.0253 53.054 27.0532C52.2854 27.0652 52.2834 28.2599 53.054 28.2479C54.655 28.2241 56.0747 28.8772 57.1201 30.0859C57.6239 30.6693 58.4662 29.821 57.9644 29.2416Z'
          fill='#AA8052'
        />
        <Path
          d='M47.4545 25.9282C45.7381 25.2891 43.8663 25.4902 42.3111 26.4539C41.658 26.8582 42.2574 27.8916 42.9145 27.4854C44.2247 26.673 45.6942 26.5435 47.1359 27.0792C47.8567 27.348 48.1694 26.1931 47.4525 25.9263L47.4545 25.9282Z'
          fill='#AA8052'
        />
        <Path
          d='M52.6539 39.7513C50.8916 41.0834 48.5738 41.4478 46.5229 40.5598C45.8239 40.2571 45.2146 41.2866 45.9195 41.5912C48.3468 42.6426 51.1266 42.3937 53.2572 40.7828C53.8625 40.3248 53.2672 39.2874 52.6539 39.7513Z'
          fill='#C49069'
        />
        <Path
          d='M39.0796 30.3647C39.0796 30.3647 41.491 29.7374 43.1477 27.794C44.8044 25.8505 45.802 23.9768 48.3647 23.6881C50.9274 23.3993 55.1568 20.753 55.1568 20.753L49.878 18.4093L41.4014 21.197L37.8749 25.2173L39.0796 30.3667V30.3647Z'
          fill='#7F6E51'
        />
      </G>
    </Svg>
  )
}
