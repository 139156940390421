import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { FlatList } from 'react-native'

import { uniqueId } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { CARE_ADVISOR_EMAIL } from '@lyrahealth-inc/shared-app-logic'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { Link } from '../../atoms/link/Link'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { XButton } from '../../atoms/xButton/XButton'
import { BodyTextSize } from '../../styles'
import { Flex1SafeAreaView, Flex1View, SpacedView } from '../../templates/content/CommonViews'
import { mailTo, openUrl, ThemeType, tID } from '../../utils'

export interface LiveChatUnavailableHelpProps {
  onExit: () => void
  phoneNumber: string
  showExitButton?: boolean
}

const Container = styled(Flex1SafeAreaView)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['32px'] : 0,
  marginHorizontal: theme.breakpoints.isMobileSized ? `${theme.spacing['16px']}` : 0,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const StyledXButton = styled(XButton)({
  alignSelf: 'flex-end',
})

const HeaderContainer = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  marginBottom: theme.spacing['16px'],
}))

const StyledBodyText = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const BulletPoint = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  borderRadius: '3px',
  backgroundColor: colors.textSecondary,
  height: '6px',
  marginRight: spacing['12px'],
  width: '6px',
}))

const ListItemText = styled(BodyText)<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  color: colors.textSecondary,
  lineHeight: spacing['32px'],
}))

const ListItem = styled.View({
  alignItems: 'center',
  flexDirection: 'row',
})

export const LiveChatUnavailableHelp: FunctionComponent<LiveChatUnavailableHelpProps> = ({
  phoneNumber,
  onExit,
  showExitButton = true,
}) => {
  const { colors, spacing } = useTheme()

  const renderItem = ({ item }: { item: any }) => {
    return (
      <ListItem key={uniqueId()}>
        <BulletPoint />
        <ListItemText text={item} size={BodyTextSize.DEFAULT} />
      </ListItem>
    )
  }

  return (
    <Container testID={tID('LiveChatUnavailableHelp')}>
      <Flex1View>
        {showExitButton && (
          <StyledXButton onPress={onExit} showExitText={false} testID={tID('LiveChatUnavailableHelp-exit-button')} />
        )}
        <SpacedView marginBottom={spacing['8px']}>
          <HeaderContainer
            size={SubheadSize.LARGE}
            text={
              <FormattedMessage
                defaultMessage='Live chat is offline'
                description='Header of section with information on how users can still get help even though live chat is unavailable'
              />
            }
          />
          <StyledBodyText
            color={colors.textSecondary}
            text={
              <FormattedMessage
                defaultMessage='Our live chat isn’t available right now, but we’re still here to help.'
                description='Tells the user that help is available by means other than live chat'
              />
            }
          />
          <StyledBodyText
            color={colors.textSecondary}
            text={
              <FormattedMessage
                defaultMessage='Contact us at {phoneLink} if you need immediate assistance, or email {emailLink} and we’ll respond within 1 business day.'
                description='Information how to contact us via phone or email for help'
                values={{
                  phoneLink: <Link text={phoneNumber} onPress={() => openUrl(`tel:${phoneNumber}`)} />,
                  emailLink: <Link text={CARE_ADVISOR_EMAIL} onPress={() => mailTo('care@lyrahealth.com')} />,
                }}
              />
            }
          />
        </SpacedView>

        <SpacedView marginBottom={spacing['8px']}>
          <Subhead
            text={
              <FormattedMessage
                defaultMessage='Live Chat Business Hours:'
                description='Header of the section listing ways in which a provider can be met'
              />
            }
            size={SubheadSize.SMALL}
          />
        </SpacedView>
        <StyledBodyText
          color={colors.textSecondary}
          text={
            <FlatList
              data={[
                <FormattedMessage
                  key={uniqueId()}
                  defaultMessage='Monday-Friday: 6 AM to 6 PM PT'
                  description='Description of a set of hours representing 6:00 - 18:00 in GMT-0800 on weekdays'
                />,
                <FormattedMessage
                  key={uniqueId()}
                  defaultMessage='Closed on all holidays'
                  description='Description of the service in question being inaccessible on company-wide non-working days'
                />,
              ]}
              renderItem={renderItem}
            />
          }
        ></StyledBodyText>

        <BodyText
          color={colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='If this is a life-threatening emergency please dial 9-1-1 or go to your nearest hospital’s emergency room.'
              description='Emergency message'
            />
          }
        />
      </Flex1View>
    </Container>
  )
}
