import React from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { Header as UICoreHeader } from '@lyrahealth-inc/ui-core'
import { BodyTextSize, PrimaryButton, SecondaryButton, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'
import { ButtonSize } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/baseButton/BaseButton'

type DefaultHeaderProps = {
  user?: $TSFixMe // TODO: PropTypes.instanceOf(Map)
  customClass?: string
  handleBackButtonClick?: $TSFixMeFunction
  handleLogout?: $TSFixMeFunction
  handleLogin?: () => void
  handleSignUpClick?: () => void
  trackMenuClick?: $TSFixMeFunction
  lyraLogo: React.ReactElement
  handleLogoClick?: $TSFixMeFunction
  handleRegister?: $TSFixMeFunction
  ghost?: boolean
  shouldHideLoginButton?: boolean
  loggedOutButtons?: $TSFixMe[]
  loggedInButtons?: $TSFixMe[]
  loginButtonClassName?: string
  navigationMenuProps?: $TSFixMe
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  headerNavMenu?: React.ReactElement
  careNavigatorModal?: React.ReactElement
  careNavigatorEntryPoint?: React.ReactElement
  isMobile?: boolean
}

const SignInButton = styled(SecondaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
}))

const SignUpButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['32px'],
}))

const DefaultHeader: React.FC<DefaultHeaderProps> = ({
  customClass,
  handleBackButtonClick,
  handleLogout,
  handleLogin,
  handleSignUpClick,
  trackMenuClick,
  user,
  lyraLogo,
  handleLogoClick,
  shouldHideLoginButton,
  handleRegister,
  loggedOutButtons,
  loggedInButtons,
  ghost,
  loginButtonClassName,
  navigationMenuProps = {},
  shouldShowCareNavigatorHeaderEntrypoint = false,
  careNavigatorModal,
  careNavigatorEntryPoint,
  isMobile = false,
  headerNavMenu,
}) => {
  const { breakpoints } = useTheme()
  const shouldShowSeparateLoginAndSignUp = breakpoints.isMinWidthMobileXs
  const shouldShowSmallerSize = breakpoints.isMobileSized
  /** One off button sizings that aren't finalized in design system yet. */
  const buttonTextSize = shouldShowSmallerSize ? BodyTextSize.CAPTION : undefined
  const buttonStyle = { ...(shouldShowSmallerSize && { height: 34 }) }

  const loginButton = shouldShowSeparateLoginAndSignUp && (
    <SignInButton
      text={<FormattedMessage defaultMessage='Log in' description='In the page header, a button to the login page' />}
      onPress={() => {
        handleLogin && handleLogin()
      }}
      testID={tID('SignIn-button')}
      customTextSize={buttonTextSize}
      style={buttonStyle}
      isSmall
    />
  )

  const signUpButton = (
    <SignUpButton
      text={
        shouldShowSeparateLoginAndSignUp ? (
          <FormattedMessage
            defaultMessage='Sign up'
            description='In the page header, a button that directs the user to sign up'
          />
        ) : (
          <FormattedMessage
            defaultMessage='Log in/Sign Up'
            description='In the page header, a button to the login page'
          />
        )
      }
      onPress={() => {
        if (shouldShowSeparateLoginAndSignUp) {
          handleSignUpClick && handleSignUpClick()
        } else {
          handleLogin && handleLogin()
        }
      }}
      testID={tID('SignUp-button')}
      size={ButtonSize.SMALL}
      customTextSize={buttonTextSize}
      style={buttonStyle}
    />
  )

  return (
    <>
      <UICoreHeader
        className={customClass}
        notification
        handleBackButtonClick={handleBackButtonClick}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        trackMenuClick={trackMenuClick}
        user={user}
        icon={''}
        lyraLogo={lyraLogo}
        handleLogoClick={handleLogoClick}
        shouldHideLoginButton={shouldHideLoginButton}
        handleRegister={handleRegister}
        loggedOutButtons={loggedOutButtons}
        loggedInButtons={loggedInButtons}
        ghost={ghost}
        loginButtonClassName={loginButtonClassName}
        navigationMenuProps={navigationMenuProps}
        shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
        careNavigatorEntryPoint={careNavigatorEntryPoint}
        isMobileSized={isMobile}
        loginButton={loginButton}
        signUpButton={signUpButton}
        headerNavMenu={headerNavMenu}
      />
      {careNavigatorModal}
    </>
  )
}

export default DefaultHeader
