import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const CareNavigatorValuePropSpotIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 96 96' fill='none'>
      <G clip-path='url(#clip0_284_5165)'>
        <Path
          d='M48.3684 0.375C41.742 0.375 35.3128 1.51134 29.2593 3.75177C14.8473 9.09391 4.71509 20.9373 1.45973 36.2447C-0.691542 46.2251 -0.416467 55.4563 2.30582 64.4521C5.78124 75.9408 14.3123 85.6291 25.7118 91.0319C31.3707 93.72 37.872 95.2548 45.0315 95.5925C45.9516 95.6342 46.8868 95.657 47.8164 95.657C53.5607 95.657 59.3392 94.8697 64.9924 93.3179C65.4268 93.2097 65.8404 93.0978 66.2767 92.9688C71.3571 91.4815 75.929 89.1292 79.8407 85.9857C84.0883 82.5919 87.5845 78.2893 90.2215 73.2052C90.7413 72.2035 91.2383 71.1488 91.7866 69.8872C94.8029 62.8833 96.2598 55.0427 96.0018 47.204C95.8804 43.7381 95.3644 40.054 94.4728 36.2447C93.0045 30.0641 90.6104 24.2515 87.5485 19.4253C86.71 18.1296 85.9398 17.0407 85.1772 16.0618C79.6738 8.96301 71.0991 3.98132 60.3882 1.66311C56.4537 0.809428 52.4092 0.376897 48.3703 0.376897L48.3684 0.375Z'
          fill='white'
        />
        <Mask
          id='mask0_284_5165'
          //   style='mask-type:luminance'
          maskUnits='userSpaceOnUse'
          x='4'
          y='4'
          width='96'
          height='96'
        >
          <Path
            d='M91.8834 47.3331C91.7657 44.0113 91.2573 40.57 90.4568 37.1496C89.1478 31.6386 86.9965 26.2055 84.0466 21.5539C83.3617 20.4991 82.6598 19.4823 81.8972 18.5053C76.3654 11.3723 67.9823 7.40365 59.4018 5.54643C49.8444 3.47483 39.6818 4.08 30.4944 7.48143C17.2035 12.4062 8.15453 23.1948 5.2027 37.0718C4.06825 42.3286 3.73627 48.2702 4.24468 53.9785H4.20484C4.49889 57.2225 5.08508 60.3887 5.96342 63.3007C9.22827 74.0893 17.1618 82.6507 27.2675 87.4389C32.7405 90.0379 38.8566 91.3279 45.0733 91.6219C51.4247 91.916 57.8747 91.1135 63.8372 89.4725C64.228 89.3758 64.5998 89.2772 64.9906 89.1595C69.6612 87.7917 73.7266 85.6803 77.2058 82.8859C80.998 79.8563 84.1244 76.0451 86.5488 71.3746C87.0762 70.3577 87.5448 69.3428 87.9944 68.3051C90.8476 61.6787 92.1186 54.4869 91.8644 47.3539L91.8834 47.335V47.3331Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_284_5165)'>
          <Path
            d='M49.759 98.2825C77.2941 98.2825 99.6157 75.9608 99.6157 48.4257C99.6157 20.8906 77.2941 -1.43103 49.759 -1.43103C22.2238 -1.43103 -0.0977783 20.8906 -0.0977783 48.4257C-0.0977783 75.9608 22.2238 98.2825 49.759 98.2825Z'
            fill='white'
            stroke='#3BA5B5'
            stroke-miterlimit='10'
          />
        </G>
        <Path
          d='M48.3357 39.8123C48.042 40.0755 47.5896 40.0534 47.3243 39.7618C45.7267 38.0125 40.3936 33.0155 36.2267 38.9917C31.4537 45.8377 43.9618 58.3672 47.4334 60.4389C47.6491 60.568 47.9155 60.5766 48.1386 60.461C49.8453 59.5777 57.4912 55.245 61.0198 46.8945C64.9995 37.4761 56.7872 35.2901 53.9577 36.1389C51.9126 36.7528 49.414 38.8441 48.3357 39.811V39.8123Z'
          fill='#F47352'
        />
        <Path
          d='M70.0342 60.6106L66.8329 59.4671C66.9014 59.2592 73.7081 38.4422 65.9304 27.0639C62.5991 22.1898 56.9312 19.6878 49.085 19.624C41.1561 19.5626 35.2401 22.0433 31.4977 27.0072C22.8954 38.4115 28.0223 59.3891 28.0743 59.5994L24.7973 60.4807C24.7383 60.2491 23.3727 54.7442 23.1459 47.6375C23.0112 43.4344 23.3089 39.5408 24.0295 36.0654C24.9462 31.6355 26.5598 27.8648 28.8209 24.8643C33.1917 19.0641 39.9062 16.125 48.7826 16.125C48.8912 16.125 48.9999 16.125 49.111 16.125C58.1149 16.1982 64.7042 19.2011 68.7017 25.0509C70.7714 28.0798 72.1181 31.8671 72.704 36.3064C73.1623 39.7771 73.1623 43.6612 72.6993 47.8478C71.9196 54.9191 70.1122 60.3838 70.0342 60.613V60.6106Z'
          fill='#3BA5B5'
        />
        <Path
          d='M29.7453 46.9287C29.1594 44.2637 28.1576 41.386 26.3101 39.444C24.4342 37.4759 21.7242 36.8829 19.4254 38.3784C17.0227 39.9425 15.9784 42.8485 15.8154 45.7805C15.6547 48.6865 16.0776 51.8524 16.8738 54.6261C17.6417 57.3029 18.9624 60.242 21.2872 61.6808C22.4472 62.3991 23.671 62.5314 24.7697 62.1912C24.7815 62.1912 24.7933 62.1912 24.8075 62.1912C27.7867 62.1534 29.5681 59.1221 30.0359 56.2374C30.5344 53.1565 30.4139 49.967 29.7453 46.9287Z'
          fill='#068298'
        />
        <Path
          d='M79.374 41.0506C78.1974 38.5675 75.8088 37.1193 73.1721 37.7832C70.4126 38.4801 68.5343 40.8923 67.4806 43.6212C66.4363 46.324 65.8717 49.4663 65.7772 52.3581C65.685 55.1483 66.0394 58.3686 67.8019 60.4949C68.1232 60.88 68.4753 61.1943 68.8485 61.4423C68.1138 64.8516 66.6466 68.0789 64.0146 70.3825C62.3774 71.8166 60.3786 72.5135 58.2877 72.8703C57.1371 73.0664 55.97 73.1963 54.8028 73.3026C54.6752 73.1278 54.5217 72.9601 54.3398 72.8018C54.0562 72.5537 53.7586 72.3269 53.4514 72.1166C53.3286 71.9347 53.1679 71.7811 52.9647 71.6819C50.7769 70.6093 48.0363 69.9005 45.6595 70.7274C43.2922 71.5519 41.8085 73.9311 42.9047 76.4449C43.8947 78.7201 46.4675 79.8423 48.7734 79.7407C51.0297 79.6415 53.2104 78.5618 54.5973 76.7048C54.6422 76.6434 54.6871 76.5796 54.7272 76.5182C55.5872 76.4355 56.4472 76.348 57.3048 76.2465C59.4855 75.9842 61.619 75.5637 63.5941 74.5241C67.0884 72.686 69.5148 69.2649 70.9158 65.558C71.3151 64.5043 71.6199 63.4222 71.8561 62.3236C74.3487 62.5953 76.5766 60.5162 77.7674 58.1985C79.185 55.4413 80.0473 52.3746 80.3427 49.2749C80.6049 46.5555 80.534 43.4959 79.3763 41.0506H79.374Z'
          fill='#3BA5B5'
        />
        <Path
          d='M80.342 49.2773C80.0443 52.377 79.1819 55.4437 77.7643 58.2009C76.6492 60.3721 74.6197 62.3402 72.3138 62.3473C71.6854 62.2268 71.0096 62.0945 70.308 61.9551C69.6488 61.8228 69.0369 61.544 68.4817 61.1754C68.4769 61.173 68.4722 61.1707 68.4698 61.1683C68.2359 60.9746 68.0115 60.7525 67.8012 60.4973C66.0364 58.3733 65.6843 55.1507 65.7765 52.3605C65.871 49.4687 66.438 46.3288 67.4799 43.6236C68.5336 40.8947 70.4143 38.4825 73.1714 37.7856C75.8081 37.1193 78.1991 38.5699 79.3757 41.053C80.5333 43.4983 80.6018 46.5556 80.342 49.2773Z'
          fill='#068298'
        />
        <Path
          d='M54.8266 76.5086C54.7935 76.5086 54.7604 76.5157 54.7273 76.5181C54.6848 76.5795 54.6423 76.6433 54.595 76.7047C53.2082 78.5618 51.0275 79.6415 48.7712 79.7407C46.4676 79.8447 43.8948 78.7201 42.9025 76.4449C41.8086 73.931 43.2923 71.5519 45.6596 70.7273C48.0364 69.8981 50.7794 70.6092 52.9648 71.6818C53.168 71.7811 53.3287 71.9323 53.4515 72.1166C53.7587 72.3268 54.0563 72.5536 54.3399 72.8017C54.4462 72.8938 54.543 72.9907 54.6305 73.0876C54.6305 73.0876 54.6305 73.0892 54.6305 73.0923C54.9045 73.6026 55.1195 74.2216 55.1857 74.9493C55.2329 75.4762 55.0864 76.003 54.8242 76.5086H54.8266Z'
          fill='#068298'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_284_5165'>
          <Rect width='96' height='96' fill='white' transform='translate(0.0310059)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
