import React from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import jwtDecode from 'jwt-decode'

import { IDENTITY_PROVIDERS, PATHWAY_QUERY_PARAM, REGISTRATION_ENTRY_POINT_KEY } from '@lyrahealth-inc/shared-app-logic'

import { GoogleJwtCredentials, GoogleJwtResponse, SignUpWithGoogleButton } from './email/SignUpWithGoogleButton'
import {
  CLICK_SSO_SIGN_UP_BUTTON,
  GET_GOOGLE_SIGN_UP_TOKEN,
  GET_SIGN_UP_BUTTON_WIDTH_MESSAGE,
  SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE,
} from '../../common/constants/appConstants'
import { getGoogleSSOClientId } from '../../data/appGlobals/appGlobalsSelectors'
import { trackEventWithObj } from '../../data/mixpanel'

type GoogleSSORegistrationProps = ConnectedProps<typeof connector>

const GoogleRegistrationContainer = ({ googleSSOClientId }: GoogleSSORegistrationProps) => {
  const { locale } = useIntl()
  const [buttonWidth, setButtonWidth] = React.useState(0)
  const [searchParams] = useSearchParams()
  const selectedPathwayOption = searchParams.get(PATHWAY_QUERY_PARAM) ?? undefined
  const entryPoint = searchParams.get(REGISTRATION_ENTRY_POINT_KEY)

  window.top?.postMessage(GET_SIGN_UP_BUTTON_WIDTH_MESSAGE, '*')
  window.onmessage = (e) => {
    const { message, width } = e.data
    if (message === SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE) {
      setButtonWidth(width)
    }
  }

  const handleGoogleResponse = (response: GoogleJwtResponse) => {
    if (response.credential) {
      const account: GoogleJwtCredentials = jwtDecode(response.credential)
      const pathwayQueryParams = selectedPathwayOption ? `&pathwayOption=${selectedPathwayOption}` : ''
      const entryPointQueryParam = entryPoint ? `&${REGISTRATION_ENTRY_POINT_KEY}=${entryPoint}` : ''
      const queryParameters = `isSSO=true&firstName=${account.given_name}&lastName=${
        account.family_name
      }&id=${encodeURIComponent(account.email)}${pathwayQueryParams}${entryPointQueryParam}`

      window.top?.postMessage({ message: GET_GOOGLE_SIGN_UP_TOKEN, token: response.credential }, '*')
      const customerRegistrationUrl =
        window.location !== window.parent.location ? document.referrer : window.location.href
      window.parent.location.href = customerRegistrationUrl + `register/account-setup/basic-info?${queryParameters}`
    }
  }

  const onButtonPress = () => {
    window.top?.postMessage({ message: CLICK_SSO_SIGN_UP_BUTTON, identityProvider: IDENTITY_PROVIDERS.GOOGLE }, '*')
  }

  return (
    <SignUpWithGoogleButton
      clientId={googleSSOClientId}
      locale={locale}
      buttonWidth={buttonWidth}
      responseHandler={handleGoogleResponse}
      onButtonPress={onButtonPress}
    />
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    googleSSOClientId: getGoogleSSOClientId($$state),
  }
}

const connector = connect(mapStateToProps, { trackEventWithObj })

export default connector(GoogleRegistrationContainer)
