import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import { MicrositeConfig, MicrositeConfigSectionNames } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../../utils/utils'
import { useGetMicrositeSections } from '../../hooks/useGetMicrositeSections'
import {
  LegalAndCopyrightFooter,
  LegalAndCopyrightFooterProps,
} from '../../organisms/legalAndCopyrightFooter/LegalAndCopyrightFooter'
import { LyraMembershipSummaryProps } from '../../organisms/lyraMembershipSummary/LyraMembershipSummary'
import { LyraOfferingsCarouselProps } from '../../organisms/lyraOfferingsCarousel/LyraOfferingsCarousel'
import { MicrositeMobileAppPromoSectionProps } from '../../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoSection'
import { PathwaysCareNavigatorSectionProps } from '../../organisms/pathwaysCareNavigatorSection/PathwaysCareNavigatorSection'
import { PathwaysFAQSectionProps } from '../../organisms/pathwaysFAQSection/PathwaysFAQSection'
import { PathwaysHomepageHeroSectionProps } from '../../organisms/PathwaysHomepageHeroSection/PathwaysHomepageHeroSection'
import { PathwaysSelfCareSectionProps } from '../../organisms/pathwaysSelfCareSection/PathwaysSelfCareSection'

export interface ComingSoonMicrositeHomePageProps {
  heroSectionProps: Pick<
    PathwaysHomepageHeroSectionProps,
    | 'pathwaysType'
    | 'startDate'
    | 'onNotifyMeOnLaunchPress'
    | 'onExploreOnYourOwnCTAPress'
    | 'isRegistrationAndLoginDisabled'
  >
  lyraOfferingsSectionProps: Pick<
    LyraOfferingsCarouselProps,
    | 'customerPropertiesMap'
    | 'pathwaysType'
    | 'isInternational'
    | 'meetWithAnExpertTabPress'
    | 'wellnessResourcesTabPress'
    | 'lyraOfferingsTabPress'
  >
  selfCareSectionProps: Pick<PathwaysSelfCareSectionProps, 'language' | 'pathwaysType' | 'onContentClicked'>
  careNavigatorSectionProps: Pick<PathwaysCareNavigatorSectionProps, 'pathwaysType'>
  faqSectionProps: Pick<
    PathwaysFAQSectionProps,
    | 'membershipType'
    | 'beforeLaunch'
    | 'startDate'
    | 'hasMembershipInCustomer'
    | 'onNotifyMeOnLaunchPress'
    | 'onQuestionPress'
    | 'customerCostProperties'
    | 'isCostMessagingEnabled'
    | 'isRegistrationAndLoginDisabled'
    | 'isProgramLevelSessionLimitEnabled'
  >
  legalAndCopyrightFooterProps: LegalAndCopyrightFooterProps
  membershipSectionProps: LyraMembershipSummaryProps
  mobileAppPromoSectionProps: MicrositeMobileAppPromoSectionProps
  micrositeConfig: MicrositeConfig
}

export const ComingSoonMicrositeHomePage: FunctionComponent<ComingSoonMicrositeHomePageProps> = ({
  heroSectionProps,
  lyraOfferingsSectionProps,
  selfCareSectionProps,
  careNavigatorSectionProps,
  faqSectionProps,
  legalAndCopyrightFooterProps,
  membershipSectionProps,
  mobileAppPromoSectionProps,
  micrositeConfig,
}) => {
  const sectionToPropsMap = {
    [MicrositeConfigSectionNames.HERO]: heroSectionProps,
    [MicrositeConfigSectionNames.OFFERINGS_CAROUSEL]: {
      ...lyraOfferingsSectionProps,
      ...micrositeConfig[MicrositeConfigSectionNames.OFFERINGS_CAROUSEL],
    },
    [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: mobileAppPromoSectionProps,
    [MicrositeConfigSectionNames.SELF_CARE]: selfCareSectionProps,
    [MicrositeConfigSectionNames.TESTIMONIALS]: {},
    [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: membershipSectionProps,
    [MicrositeConfigSectionNames.CARE_NAVIGATOR]: careNavigatorSectionProps,
    [MicrositeConfigSectionNames.FAQ]: faqSectionProps,
  }

  const sectionsDisplayed = useGetMicrositeSections(micrositeConfig) as Array<{
    name: Exclude<
      MicrositeConfigSectionNames,
      MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER | MicrositeConfigSectionNames.CUSTOMER_BRANDED_BANNER
    >
    component: FunctionComponent
    backgroundColor?: string
  }>

  return (
    <View testID={tID('ComingSoonMicrositeHomePage')}>
      {sectionsDisplayed.map((section) => {
        const { name: sectionName, component: Component, backgroundColor } = section
        const sectionProps = { ...sectionToPropsMap[sectionName], ...(backgroundColor && { backgroundColor }) }
        return <Component key={sectionName} {...sectionProps} />
      })}
      <LegalAndCopyrightFooter {...legalAndCopyrightFooterProps} />
    </View>
  )
}
