import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BaseText } from '../../../atoms/baseText/BaseText'
import { Divider } from '../../../atoms/divider/Divider'
import { PressableOpacity } from '../../../atoms/pressableOpacity/PressableOpacity'
import { HeadlineSize, TextType } from '../../../styles'
import { tID } from '../../../utils'

export interface Tabs {
  name: string
  onPress: () => void
  active?: boolean
}

const TabContainer = styled(PressableOpacity)(({ theme }) => ({
  marginHorizontal: theme.spacing['12px'],
}))

const TabText = styled(BaseText)<{ active: boolean }>(({ theme, active }) => {
  const baseColor = theme.colors.textInactive
  const activeColor = theme.colors.textActive
  return {
    color: active ? activeColor : baseColor,
    marginHorizontal: theme.spacing['12px'],
    marginBottom: theme.spacing['8px'],
  }
})

const ActiveDivider = styled(Divider)<{}>(({ theme }) => ({
  borderTopLeftRadius: theme.spacing['4px'],
  borderTopRightRadius: theme.spacing['4px'],
}))

/**
 * Component that represents a tab on the toolbar
 */
export const ToolbarTab: FunctionComponent<Tabs> = ({ name, onPress, active = false }) => {
  const { colors } = useTheme()
  return (
    <TabContainer onPress={onPress} testID={tID('ToolbarTab')}>
      <TabText type={TextType.HEADLINE} size={HeadlineSize.EYEBROW} active={active} text={name} />
      {active && <ActiveDivider height={4} color={colors.iconActive} />}
    </TabContainer>
  )
}
