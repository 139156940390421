import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { getLegalNoticeLink, useLyraIntl } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { CoverageTermsContent as CoverageLink } from '../../organisms'
import { ContentAreaView } from '../../templates/content/ContentArea'
import { ThemeType } from '../../utils'

export type LegalFooterProps = {
  footerLinkPressed: (url: string) => void
  showHIPAALink?: boolean
  backgroundColor?: string
  legalNotice?: React.ReactNode
  removeBottomSpacing?: boolean
  isInternational?: boolean
  removeSpacing?: boolean
}

const LinksContainer = styled(ContentAreaView)<{ backgroundColor: string; removeBottomSpacing?: boolean }>(
  ({ backgroundColor, removeBottomSpacing }) => ({
    backgroundColor,
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    ...(!removeBottomSpacing && { bottom: '10px' }),
  }),
)

const Divider = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  paddingHorizontal: theme.spacing['4px'],
}))

export const LegalFooter: FunctionComponent<LegalFooterProps> = ({
  footerLinkPressed,
  legalNotice,
  showHIPAALink = true,
  backgroundColor,
  removeBottomSpacing = false,
  isInternational = false,
}) => {
  const { activeLanguage } = useLyraIntl()
  const { colors } = useTheme()
  // eslint-disable-next-line formatjs/no-literal-string-in-jsx
  const divider = <Divider text=' | ' size={BodyTextSize.SMALL} color={colors.dividerSecondary} />

  return (
    <LinksContainer
      backgroundColor={backgroundColor || colors.backgroundPrimary}
      removeBottomSpacing={removeBottomSpacing}
    >
      {legalNotice && (
        <>
          <CoverageLink fromLegalFooter legalLanguage={legalNotice} />
          {divider}
        </>
      )}
      {!!showHIPAALink && (
        <>
          <PressableOpacity onPress={() => footerLinkPressed(getLegalNoticeLink(activeLanguage, 'HIPAA_NOTICE'))}>
            <BodyText
              text={<FormattedMessage defaultMessage='HIPAA Notice' description='Link text for HIPAA notice' />}
              size={BodyTextSize.SMALL}
              color={colors.textSecondary}
              underline
            />
          </PressableOpacity>
          {divider}
        </>
      )}
      <PressableOpacity
        onPress={() => footerLinkPressed(getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY', isInternational))}
      >
        <BodyText
          text={<FormattedMessage defaultMessage='Privacy Policy' description='Link text for privacy policy' />}
          size={BodyTextSize.SMALL}
          color={colors.textSecondary}
          underline
        />
      </PressableOpacity>
      {divider}
      <PressableOpacity onPress={() => footerLinkPressed(getLegalNoticeLink(activeLanguage, 'TERMS_OF_USE'))}>
        <BodyText
          text={<FormattedMessage defaultMessage='Terms of Use' description='Link text for terms of use' />}
          size={BodyTextSize.SMALL}
          color={colors.textSecondary}
          underline
        />
      </PressableOpacity>
      {divider}
      <PressableOpacity onPress={() => footerLinkPressed(getLegalNoticeLink(activeLanguage, 'ACCESSIBILITY'))}>
        <BodyText
          text={<FormattedMessage defaultMessage='Accessibility' description='Link text for accessibility' />}
          size={BodyTextSize.SMALL}
          color={colors.textSecondary}
          underline
        />
      </PressableOpacity>
    </LinksContainer>
  )
}
