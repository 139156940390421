import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { ShieldTriageIcon } from '../../atoms/icons/ShieldTriageIcon'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

const AnswersConfidential = styled.View<{ theme: ThemeType; omitMobileSpacing: boolean; customStyle?: CSSObject }>(
  ({ theme, omitMobileSpacing, customStyle }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    ...(!theme.breakpoints.isMinWidthTablet &&
      !omitMobileSpacing && {
        marginTop: theme.spacing['24px'],
        marginBottom: theme.spacing['48px'],
        alignSelf: 'center',
      }),
    ...customStyle,
  }),
)

const LockIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

export const ConfidentialityNotice: FunctionComponent<{ omitMobileSpacing?: boolean; customStyle?: CSSObject }> = ({
  omitMobileSpacing = false,
  customStyle,
}) => {
  const intl = useIntl()
  const { colors } = useTheme() as ThemeType

  return (
    <AnswersConfidential
      omitMobileSpacing={omitMobileSpacing}
      customStyle={customStyle}
      testID={tID('ConfidentialityNotice')}
    >
      <LockIconContainer>
        <ShieldTriageIcon />
      </LockIconContainer>
      <BodyText
        text={
          <FormattedMessage
            defaultMessage='Responses are confidential'
            description='Notice that says your answers are confidential'
          />
        }
        size={BodyTextSize.BADGE}
        color={colors.textSecondary}
        accessibilityLabel={intl.formatMessage({
          defaultMessage: 'Responses are confidential',
          description: 'Notice that says your answers are confidential',
        })}
        accessibilityRole='text'
      />
    </AnswersConfidential>
  )
}
