import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { scroller } from 'react-scroll'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  FAQ_ROUTE,
  getCountryFlag,
  MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT,
  MIXPANEL_CARE_NAVIGATOR_OPTIONS,
  S4C_EVENTS,
  S4C_PROPERTIES,
  useFlags,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import {
  openUrl,
  useCareNavigatorModal as useCareNavigatorModalShared,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { useDefaultMixpanelPage } from './useDefaultMixpanelPage'
import { getDetectedCountryISO, getShouldShowLiveChat } from '../../data/appGlobals/appGlobalsSelectors'
import { getCurrentCountryName } from '../../data/currentCountry/currentCountrySelectors'
import {
  getCustomerLaunchDate,
  getCustomerName,
  getCustomerPhone,
  getDefaultDirectCareNavigatorBookingLink,
  getIsCustomerLyra,
} from '../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { getDirectDedicatedCareNavigatorInfo, getDirectDedicatedCareNavigatorLink } from '../../data/user/userActions'
import {
  getId,
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getIsUserLoggedIn,
  getUserCountryCode,
  getUserDedicatedCareNavigatorInfo,
  getUserDirectDedicatedCareNavigatorLink,
  getUsername,
  getUserSalesforceId,
} from '../../data/user/userSelectors'
import ChatLive from '../components/chatLive/ChatLive'
import { goToCareNavigatorSchedulingPage } from '../utils/onboardUtils'

type useCareNavigatorModalParams = {
  /** Mixpanel 'entryPoint' for click events on the CN modal */
  clickEntryPoint?: string
  /** If entryPoint isn't predetermined when the hook is called, use this function which will override `clickEntryPoint`. eg ForkedTriage entry point changes if the meds modal opens and the entry point is unknown until the CN modal is triggered. clickEntryPoint would not update in time. */
  getClickEntryPoint?: () => string
  showMinimalCTAText?: boolean
  /** Returns the mixpanel event name for the CN entry point click event */
  getCareNavigatorEntryPointClickEvent?: () => string
  showScheduleCall?: boolean
  isHomebaseFlow?: boolean
  disableBottomSheet?: boolean
}

export const useCareNavigatorModal = ({
  clickEntryPoint,
  showMinimalCTAText,
  getClickEntryPoint,
  getCareNavigatorEntryPointClickEvent,
  showScheduleCall,
  isHomebaseFlow,
  disableBottomSheet,
}: useCareNavigatorModalParams) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isDedicatedCareNavigatorEnabled } = useFlags()
  const { activeLanguage } = useLyraIntl()
  const mixpanelPage = useDefaultMixpanelPage()
  const userId = useSelector(getId)
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isUserInternational = useSelector(getIsUserInternational)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)
  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational
  const isLyrian = useSelector(getIsCustomerLyra)
  const customerPhoneNumber = useSelector(getCustomerPhone)
  const userDedicatedCareNavigatorInfo = useSelector(getUserDedicatedCareNavigatorInfo)?.toJS()
  const showLiveChat = useSelector(getShouldShowLiveChat)
  const countryIsoCode = useSelector(getUserCountryCode)
  const detectedCountryIsoCode = useSelector(getDetectedCountryISO)
  const customerName = useSelector(getCustomerName)
  const countryName = useSelector(getCurrentCountryName)
  const userDirectDedicatedCareNavigatorLink = useSelector(getUserDirectDedicatedCareNavigatorLink)
  const defaultDirectCareNavigatorBookingLink = useSelector(getDefaultDirectCareNavigatorBookingLink)
  const email = useSelector(getUsername)
  const salesforceId = useSelector(getUserSalesforceId)
  const launchDate = useSelector(getCustomerLaunchDate)
  const isMicrositeHomepage = ['/'].includes(pathname)

  useFetcher([
    [
      async () => await dispatch(getDirectDedicatedCareNavigatorInfo),
      [userId],
      isDedicatedCareNavigatorEnabled && userDedicatedCareNavigatorInfo == null,
    ],
  ])

  const getEntryPoint = useCallback(() => {
    return getClickEntryPoint ? getClickEntryPoint() : clickEntryPoint
  }, [clickEntryPoint, getClickEntryPoint])

  const getOnPressMixpanelProps = useCallback(() => {
    return {
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: getEntryPoint(),
      ...getCountryFlag(isUserInternational),
      [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
    }
  }, [getEntryPoint, isUserInternational])

  const onEntryPointPressedTrack = useCallback(() => {
    const entrypoint = getEntryPoint()
    const commonProps = {
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: entrypoint,
      ...mixpanelPage,
      [S4C_PROPERTIES.CONTACT_OPTIONS]: MIXPANEL_CARE_NAVIGATOR_OPTIONS({
        isInternationalUser: isInternational,
        isLyrian,
        isSidePanel: true,
      }),
      [COMMON_MIXPANEL_PROPERTIES.BUTTON_TEXT]: MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT(isUserInternational),
      ...getCountryFlag(isUserInternational),
    }
    dispatch(
      trackEventWithObj({
        event:
          (getCareNavigatorEntryPointClickEvent && getCareNavigatorEntryPointClickEvent()) ||
          S4C_EVENTS.CLICK_TO_CONTACT_CN,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,

        ...commonProps,
      }),
    )
    dispatch(
      trackEventWithObj({
        event: S4C_EVENTS.VIEW_GENERAL_CONTACT_CN_TEAM,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.PAGE_LOAD,
        ...commonProps,
      }),
    )
  }, [
    dispatch,
    getCareNavigatorEntryPointClickEvent,
    getEntryPoint,
    isInternational,
    isLyrian,
    isUserInternational,
    mixpanelPage,
  ])

  const onPressEmailTrack = useCallback(() => {
    dispatch(
      trackEventWithObj({
        event: COMMON_MIXPANEL_EVENTS.CLICK_EMAIL_CN,
        ...getOnPressMixpanelProps(),
      }),
    )
  }, [dispatch, getOnPressMixpanelProps])

  const onPressFAQTrack = useCallback(() => {}, [])

  const onPressPhoneCallTrack = useCallback(() => {
    dispatch(
      trackEventWithObj({
        event: COMMON_MIXPANEL_EVENTS.CLICK_PHONE_CN,
        ...getOnPressMixpanelProps(),
      }),
    )
  }, [dispatch, getOnPressMixpanelProps])

  const onPressScheduleCallTrack = useCallback(() => {
    dispatch(
      trackEventWithObj({
        event: COMMON_MIXPANEL_EVENTS.CLICK_SCHEDULE_A_CN_CALL,
        ...getOnPressMixpanelProps(),
      }),
    )
  }, [dispatch, getOnPressMixpanelProps])

  const onPressLiveChatTrack = useCallback(() => {
    dispatch(
      trackEventWithObj({
        event: S4C_EVENTS.CLICK_CHAT_LIVE,
        ...getOnPressMixpanelProps(),
      }),
    )
  }, [dispatch, getOnPressMixpanelProps])

  const onPressPhoneCallInternational = useCallback(() => {
    return navigate('/care-team-phone-numbers')
  }, [navigate])

  const onPressFAQ = useCallback(() => {
    if (isLoggedIn) {
      openUrl(`${window.location.origin}${FAQ_ROUTE}`)
    } else {
      if (isMicrositeHomepage) {
        scroller.scrollTo('faq', { smooth: true })
      } else {
        navigate('/#faq')
      }
    }
  }, [isLoggedIn, isMicrositeHomepage, navigate])

  const loadDedicatedCareNavigator = useCallback(() => {
    dispatch(getDirectDedicatedCareNavigatorInfo(userId))
  }, [dispatch, userId])

  const onPressScheduleCall = useCallback(async () => {
    let careNavigatorLink = userDedicatedCareNavigatorInfo?.ScheduleOnceURL
    if (!isDedicatedCareNavigatorEnabled) {
      try {
        careNavigatorLink = await dispatch(getDirectDedicatedCareNavigatorLink(userId))
        console.log('fetch link', careNavigatorLink)
      } catch (e) {
        console.error('Failed to get the user’s dedicated care navigator link', e)
        careNavigatorLink = userDirectDedicatedCareNavigatorLink
      }
    }
    goToCareNavigatorSchedulingPage({
      defaultDirectCareNavigatorBookingLink,
      directDedicatedCareNavigatorLink: careNavigatorLink,
      name,
      email,
      salesforceId,
      launchDate,
      isLyrian,
    })
  }, [
    defaultDirectCareNavigatorBookingLink,
    dispatch,
    email,
    isDedicatedCareNavigatorEnabled,
    isLyrian,
    launchDate,
    salesforceId,
    userDedicatedCareNavigatorInfo?.ScheduleOnceURL,
    userDirectDedicatedCareNavigatorLink,
    userId,
  ])

  return useCareNavigatorModalShared({
    isLoggedIn,
    isInternational,
    isLyrian,
    customerPhoneNumber,
    userDedicatedCareNavigatorInfo,
    showLiveChat,
    isDedicatedCareNavigatorEnabled,
    onEntryPointPressedTrack,
    onPressEmailTrack,
    onPressFAQTrack,
    onPressPhoneCallTrack,
    onPressScheduleCallTrack,
    onPressLiveChatTrack,
    ChatComponent: ChatLive,
    useWebPortalForBottomSheet: true,
    onPressPhoneCallInternational,
    onPressFAQ,
    loadDedicatedCareNavigator,
    onPressScheduleCall,
    countryIsoCode,
    detectedCountryIsoCode,
    activeLanguage,
    customerName,
    countryName,
    showMinimalCTAText,
    showScheduleCall,
    isHomebaseFlow,
    disableBottomSheet,
  })
}
