import React from 'react'
import { defineMessage } from 'react-intl'

import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { DefaultCustomerRedirectionHeaderText } from './default'

export const united: GetCustomerCopyFunc = () => {
  const subpopulations: CustomerSubpopulations = {
    header: <DefaultCustomerRedirectionHeaderText customerName='United' />,
    options: [
      {
        label: defineMessage({
          defaultMessage: 'United Airlines Employee',
          description: 'United Airlines Employee member label',
        }),
        description: defineMessage({
          defaultMessage: 'I am a United Airlines employee or eligible dependent',
          description: 'United Airlines Employee member description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'United Ground Express (UGE) Employee',
          description: 'United Ground Express member label',
        }),
        description: defineMessage({
          defaultMessage: 'I am a United Ground Express (UGE) employee or eligible dependent',
          description: 'United Ground Express member description',
        }),
      },
    ],
  }
  return {
    subpopulations,
  }
}
