export const TREATMENT_RECOMMENDATIONS_ROUTE = '/secure/onboard/treatment-options'

export const REDIRECT_URL_KEY = 'REDIRECT_URL'

export const NEGATIVE_PADDING_PATHS = [
  '/secure/onboard/t1-multi-providers',
  '/secure/onboard/t1-multi-providers/latest-provider-matches',
  '/secure/onboard/coaches',
  '/secure/onboard/coaches/latest-provider-matches',
  '/secure/onboard/provider/:id',
  '/secure/onboard/provider/:id/schedule',
  '/secure/wellness/toolkit/:id',
]
