import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { Tooltip } from '../../atoms/tooltip/Tooltip'
import { tID } from '../../utils'

export const BlendedCareMedicineIcon: FunctionComponent<BlendedCareMedicineIconProps> = ({
  backgroundColor,
  fillColor,
  showTooltip = false,
  tooltipLabel = 'Blended Care Medicine',
  width = 44,
  height = 44,
}) => {
  const { colors } = useTheme()
  const icon = (
    <Svg
      width={width}
      height={height}
      fill={fillColor || colors.iconActive}
      viewBox={'0 0 44 44'}
      testID={tID('BlendedCareMedicineIcon')}
    >
      <Rect width={44} height={44} fill={backgroundColor || colors.backgroundHighlightPrimary} />
      <Path
        fill={fillColor || colors.iconActive}
        d='M9.896 11.182h5.69l6.008 14.659h.256l6.008-14.66h5.69V33h-4.475V18.799h-.181l-5.647 14.094h-3.047l-5.646-14.147h-.181V33H9.896V11.182Z'
      />
    </Svg>
  )

  return showTooltip ? (
    <Tooltip content={tooltipLabel} placement='bottom' hoverEnabled={true}>
      {icon}
    </Tooltip>
  ) : (
    <>{icon}</>
  )
}

type BlendedCareMedicineIconProps = {
  backgroundColor?: string
  fillColor?: string
  showTooltip?: boolean
  tooltipLabel?: string
  width?: number
  height?: number
}
