import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { LegalFooter, LegalFooterProps } from '../../organisms/legalFooter/LegalFooter'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'

const CopyrightText = styled(BodyText)<{ theme: ThemeType; removeSpacing?: boolean }>(({ theme, removeSpacing }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  ...(!removeSpacing && { marginBottom: theme.spacing['16px'] }),
}))

export const LegalAndCopyrightFooter: React.FC<LegalFooterProps> = (props) => {
  const { colors } = useTheme() as ThemeType
  return (
    <>
      <CopyrightText
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        text={`© ${new Date().getFullYear()} Lyra Health, INC • 270 East Ln, Burlingame, CA 94010`}
        size={BodyTextSize.SMALL}
        color={colors.textSecondary}
        removeSpacing={props.removeSpacing}
      />
      <LegalFooter backgroundColor={colors.backgroundTransparent} removeBottomSpacing {...props} />
    </>
  )
}
