import { AxiosResponse } from 'axios'

import {
  GetNextQuestionActions,
  GetTriageMetadataActions,
  GetWordCloudWordsActionData,
  NewSearchData,
  PatchOrPutTriageSearchActions,
  PostChildConcernContact,
  PostTreatmentRecommendationsActions,
  PutResetTriageSearchProgressActions,
  RiskFactorsResponse,
  SearcherInfo,
  SearchRequestData,
  TriageScreenerNames,
} from '@lyrahealth-inc/shared-app-logic'

import { setBannerAndErrorMessage } from '../../../common/components/banner/banner-actions'
import { axiosInstance } from '../../../common/http/axiosInstance'
import { CLEAR_DISPLAYED_PROVIDERS } from '../../onboard/data/onboardDataActions'
import { CLEAR_TRIAGE_ENTRYPOINT } from '../../register/data/registerActions'
export const SET_SCREENERS = 'SET_SCREENERS'
export const MARK_SELF_HARM_CONTACT_MODAL_AS_SHOWN = 'MARK_SELF_HARM_CONTACT_MODAL_AS_SHOWN'
export const RESET_SELF_HARM_CONTACT_MODAL_SHOWN_INDICATOR = 'RESET_SELF_HARM_CONTACT_MODAL_SHOWN_INDICATOR'
export const GET_ALL_TRIAGE_SEARCHES = 'GET_ALL_TRIAGE_SEARCHES'
export const POST_RISK_FACTORS = 'POST_RISK_FACTORS'
export const POST_TRIAGE_SEARCH = 'POST_TRIAGE_SEARCH'
export const PUT_OR_PATCH_TRIAGE_SEARCH = 'PUT_OR_PATCH_TRIAGE_SEARCH'
export const SET_SELECTED_SEARCH_ID = 'SET_SELECTED_SEARCH_ID'
export const SET_TRIAGE_QUESTIONS = 'SET_TRIAGE_QUESTIONS'
export const GET_NEXT_TRIAGE_SCREEN = 'GET_NEXT_TRIAGE_SCREEN'
export const POST_TREATMENT_RECOMMENDATIONS = 'POST_TREATMENT_RECOMMENDATIONS'
export const PUT_RESET_TRIAGE_SEARCH_PROGRESS = 'PUT_RESET_TRIAGE_SEARCH_PROGRESS'
export const UPDATING_SEARCH = 'UPDATING_SEARCH'
export const CREATING_SEARCH = 'CREATING_SEARCH'
export const LOADING_NEXT_QUESTION = 'LOADING_NEXT_QUESTION'
export const CLEAR_TRIAGE = 'CLEAR_TRIAGE'
export const CLEAR_TRIAGE_DEMOGRAPHICS = 'CLEAR_TRIAGE_DEMOGRAPHICS'
export const LOADING_TREATMENT_RECOMMENDATIONS = 'LOADING_TREATMENT_RECOMMENDATIONS'
export const SET_SEARCHER_INFO = 'SET_SEARCHER_INFO'
export const SET_HIGH_ALERT = 'SET_HIGH_ALERT'
export const MARK_HIGH_ALERT_CALLBACK_REQUESTED = 'MARK_HIGH_ALERT_CALLBACK_REQUESTED'
export const SHOULD_CREATE_NEW_SEARCH = 'SHOULD_CREATE_NEW_SEARCH'
export const SET_HAS_RESUMED_COMPLETED_SEARCH = 'SET_HAS_RESUMED_COMPLETED_SEARCH'
export const UI_BACK_FROM_TREATMENT_RECS = 'UI_BACK_FROM_TREATMENT_RECS'
export const GET_WORD_CLOUD_WORDS = 'GET_WORD_CLOUD_WORDS'
export const LOADING_WORD_CLOUD = 'LOADING_WORD_CLOUD'

export const markSelfHarmContactModalAsShown = () => {
  return {
    type: MARK_SELF_HARM_CONTACT_MODAL_AS_SHOWN,
  }
}

export const resetSelfHarmContactModalShownIndicator = () => {
  return {
    type: RESET_SELF_HARM_CONTACT_MODAL_SHOWN_INDICATOR,
  }
}

export const getScreeners = ({ age = 18, version = 'latest' }: GetTriageMetadataActions) => {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.get(`/trs/v1/metadata?age=${age}&version=${version}`)
      dispatch({ type: SET_SCREENERS, data: { screeners: response.data, version } })
      return response.data
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const getAllTriageSearches = ({ userId }: { userId: string }) => {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.get(`/trs/v1/search?lyra_id=${userId}`)
      dispatch({ type: GET_ALL_TRIAGE_SEARCHES, data: response.data })
      // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (error: any) {
      // If it returns with a 404 set searches to empty in store
      dispatch({ type: GET_ALL_TRIAGE_SEARCHES, data: [] })
    }
  }
}

export const postRiskFactors = ({ lyra_id, monthsToLookBack }: { lyra_id: string; monthsToLookBack: number }) => {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.post<RiskFactorsResponse>(`/trs/v1/risk_factors`, {
        lyra_id,
        monthsToLookBack,
      })
      dispatch({ type: POST_RISK_FACTORS, data: response.data })
      // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (error: any) {
      dispatch({ type: POST_RISK_FACTORS, data: undefined })
    }
  }
}

export const postTriageSearch = (newSearchData: NewSearchData) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: UPDATING_SEARCH, data: true })
      dispatch({ type: CREATING_SEARCH, data: true })
      const response = await axiosInstance.post('/trs/v1/search', newSearchData)
      dispatch({ type: POST_TRIAGE_SEARCH, data: response.data })
      dispatch({ type: SHOULD_CREATE_NEW_SEARCH, data: false })
      return response.data
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    } finally {
      dispatch({ type: CREATING_SEARCH, data: false })
      dispatch({ type: UPDATING_SEARCH, data: false })
    }
  }
}

// Would trigger an updating search action
export const putTriageSearch = ({ id, data }: PatchOrPutTriageSearchActions) => {
  const { triage_metadata, questionnaires, search, context, metadata_version } = data
  const newSearch = { ...search, triage_metadata, metadata_version, body: { ...search?.body, questionnaires }, context }

  return async (dispatch: any) => {
    try {
      dispatch({ type: UPDATING_SEARCH, data: true })
      const response = await axiosInstance.put(`/trs/v1/search/${id}`, newSearch)
      dispatch({ type: PUT_OR_PATCH_TRIAGE_SEARCH, data: response.data })
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    } finally {
      dispatch({ type: UPDATING_SEARCH, data: false })
    }
  }
}

// Would trigger an updating search action
export const patchTriageSearch = ({ id, data }: PatchOrPutTriageSearchActions) => {
  const { triage_metadata, questionnaires, triage_status, context, selected_treatment_option, metadata_version } = data
  return async (dispatch: any) => {
    try {
      dispatch({ type: UPDATING_SEARCH, data: true })
      const response = await axiosInstance.patch(`/trs/v1/search/${id}`, {
        triage_metadata,
        questionnaires,
        triage_status,
        context,
        selected_treatment_option,
        metadata_version,
      })
      dispatch({ type: PUT_OR_PATCH_TRIAGE_SEARCH, data: response.data })
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    } finally {
      dispatch({ type: UPDATING_SEARCH, data: false })
    }
  }
}

export const getNextTriageQuestion = ({ id, skipScreener }: GetNextQuestionActions) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_NEXT_QUESTION, data: true })
      const response = await axiosInstance.get(`/trs/v1/search/${id}/next_question`, {
        params: { skip_screener: skipScreener },
      })
      dispatch({ type: GET_NEXT_TRIAGE_SCREEN, data: response.data })
      return response.data
    } catch (error: any) {
      // Expect 404 at last screener
      if (error?.response?.status !== 404) {
        dispatch(setBannerAndErrorMessage(error, true))
        throw error
      }
    } finally {
      dispatch({ type: LOADING_NEXT_QUESTION, data: false })
    }
  }
}

export const postTreatmentRecommendations = ({ id, data, params }: PostTreatmentRecommendationsActions) => {
  return async (dispatch: any): Promise<AxiosResponse<any> | undefined> => {
    let response
    try {
      dispatch({ type: LOADING_TREATMENT_RECOMMENDATIONS, data: true })
      response = await axiosInstance.post(`/trs/v1/search/${id}/treatment_recommendations`, data, { params })
      dispatch({ type: POST_TREATMENT_RECOMMENDATIONS, data: response.data })
      return response.data
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
      throw error
    } finally {
      dispatch({ type: LOADING_TREATMENT_RECOMMENDATIONS, data: false })
    }
    return response?.data
  }
}

export const putResetTriageSearchProgress = ({ searchId, metadataId }: PutResetTriageSearchProgressActions) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: UPDATING_SEARCH, data: true })
      const response = await axiosInstance.put(`/trs/v1/search/${searchId}/reset_progress?screener_id=${metadataId}`)
      dispatch({ type: PUT_RESET_TRIAGE_SEARCH_PROGRESS, data: response.data })
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    } finally {
      dispatch({ type: UPDATING_SEARCH, data: false })
    }
  }
}

export const postChildConcernContact = ({ userId, data }: PostChildConcernContact) => {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.post(`/unsecured/v1/utils/childConcern/${userId}`, data)
      return response.data
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const getWordCloudWords = (data: GetWordCloudWordsActionData) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_WORD_CLOUD, data: true })
      const response = await axiosInstance.post('/trs/v1/word_cloud_element', data)
      const responseData = {
        screenerName: data.triageScreenerName,
        response: response.data,
      }
      dispatch({
        type: GET_WORD_CLOUD_WORDS,
        data: responseData,
      })
      return responseData
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
      return null
    } finally {
      dispatch({ type: LOADING_WORD_CLOUD, data: false })
    }
  }
}

export const setSelectedSearchId = (searchId: string) => {
  return {
    type: SET_SELECTED_SEARCH_ID,
    data: searchId,
  }
}

export const setTriageQuestions = (triageQuestions: TriageScreenerNames[]) => {
  return {
    type: SET_TRIAGE_QUESTIONS,
    data: triageQuestions,
  }
}

export const clearTriage = () => {
  return async (dispatch: any) => {
    dispatch({ type: CLEAR_TRIAGE })
    dispatch({ type: CLEAR_DISPLAYED_PROVIDERS })
    dispatch({ type: CLEAR_TRIAGE_DEMOGRAPHICS })
    dispatch({ type: CLEAR_TRIAGE_ENTRYPOINT })
  }
}

export const setLoadingTreatmentRecommendations = () => {
  return {
    type: LOADING_TREATMENT_RECOMMENDATIONS,
  }
}

export const setSearcherInfo = (searcherInfo: SearcherInfo) => {
  return {
    type: SET_SEARCHER_INFO,
    data: searcherInfo,
  }
}

export const setHighAlert = (highAlertInfo: { showModal: boolean; isActiveSI: boolean }) => {
  return {
    type: SET_HIGH_ALERT,
    data: highAlertInfo,
  }
}

export const markHighAlertCallbackRequested = () => {
  return {
    type: MARK_HIGH_ALERT_CALLBACK_REQUESTED,
  }
}

export const setShouldCreateNewSearch = (value: boolean) => {
  return {
    type: SHOULD_CREATE_NEW_SEARCH,
    data: value,
  }
}

export const setHasResumedCompletedSearch = (value: boolean) => {
  return {
    type: SET_HAS_RESUMED_COMPLETED_SEARCH,
    data: value,
  }
}

export const setUIBackFromTreatmentRecs = (value: boolean) => {
  return {
    type: UI_BACK_FROM_TREATMENT_RECS,
    data: value,
  }
}

export const postSpeculativeSearch = (
  lyraId: string,
  searchData: SearchRequestData,
): Promise<AxiosResponse<boolean>> => {
  return axiosInstance.post<boolean>(`/v1/provider/speculative/${lyraId}`, searchData)
}
