import React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const SessionLocationIcon: React.FC<SessionLocationIconProps> = ({ backgroundColor, fillColor, size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='-6 -6 37 37' fill='none'>
      <Circle cx={12} cy={12} r={18} fill={backgroundColor || colors.backgroundHighlightPrimary} />
      <Path
        fill={fillColor || colors.iconActive}
        d='M12,14 C9.52,14 7.5,11.98 7.5,9.5 C7.5,7.02 9.52,5 12,5 C14.48,5 16.5,7.02 16.5,9.5 C16.5,11.98 14.48,14 12,14 M12,1 C7.04,1 3,5.04 3,10 C3,16.46 11.16,23.52 11.51,23.82 C11.65,23.94 11.83,24 12,24 C12.17,24 12.35,23.94 12.49,23.82 C12.84,23.52 21,16.46 21,10 C21,5.04 16.96,1 12,1'
      />
    </Svg>
  )
}

type SessionLocationIconProps = {
  backgroundColor?: string
  fillColor?: string
  size?: number | string
}
