import React from 'react'

import { NavBarProps } from '@lyrahealth-inc/shared-app-logic'
import { colors, NavBar } from '@lyrahealth-inc/ui-core-crossplatform'

/**
 * New L&F header using NavBar x-platform component
 */
const NavBarHeader: React.FC<NavBarProps> = ({
  isInternational,
  isTransparent,
  onExit,
  onBack,
  onCareNavigatorHeaderEntryPointButtonPress,
  backgroundColor = colors.ui_oatmeal1,
  borderBottomColor,
  shouldShowCareNavigatorHeaderEntrypoint = true,
  careNavigatorModal,
  careNavigatorEntryPoint,
  title,
  showBackButton,
  style,
}) => {
  return (
    <NavBar
      backgroundColor={backgroundColor}
      borderBottomColor={borderBottomColor}
      onExit={onExit}
      onBack={onBack}
      title={title}
      shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
      isInternational={isInternational}
      isTransparent={isTransparent}
      onCareNavigatorHeaderEntryPointButtonPress={onCareNavigatorHeaderEntryPointButtonPress}
      careNavigatorModal={careNavigatorModal}
      careNavigatorEntryPoint={careNavigatorEntryPoint}
      showBackButton={showBackButton}
      style={style}
    />
  )
}

export default NavBarHeader
