import { MessageDescriptor } from 'react-intl'

import { EligibilityType } from '../../features'
import { PROGRAM_MEMBERSHIP_TYPES } from '../../features/common/constants/constants'
import { ProgramCustomerPropertyId } from '../../features/common/constants/customerProgram'
import { CustomerCopy } from '../customer/Customer'
import { DependentTypes } from '../dependentTypes/DependentTypes'

import type { CountryCode as CountryIsoCode } from 'libphonenumber-js'

export interface UserObject {
  user: User
  customer?: Customer
  params?: Param[]
  country?: Country
}

export interface SignedOutUserObject extends Omit<UserObject, 'user'> {
  user: null
}

export interface Country {
  id?: number
  name: string
  isoCode: CountryIsoCode
  emergencyPhoneNumbersList?: string[]
  outOfCountryNumbersList?: string[]
  tollFreeNumbersList?: string[]
}

export interface Param {
  mixPanelToken?: string
  captcha?: string
  captchaNovalidation?: string
  oktaBaseUrl?: string
  oktaClientId?: string
  oktaAppleSSOClientId?: string
  oktaGoogleSSOClientId?: string
  captchaNovalidationCustomers?: string
  googleSSOClientId?: string
  stripePublishableKey?: string
  essentialsCmsUrl?: string
  essentialsDomain?: string
  hashedLyraId?: string
  pendoHashedLyraId?: string
}

export interface SessionHistory {
  visitDate: string
  attendance: Attendance
}

export enum Attendance {
  NO_SHOW = 'no_show',
  LAST_MINUTE_CANCELLATION = 'last_minute_cancellation',
  ATTENDED = 'attended',
}

export interface CustomerCopies extends CustomerCopy {
  _FEATURE_VARIATIONS: {
    // store copies variations for different features if applicable
    [flag: string]: CustomerCopy
  }
}

export interface Customer {
  id?: number
  name?: string
  info?: string
  enabled?: boolean
  oktaGroupId?: unknown
  eligibilityName?: string
  customerType?: string
  customerProperties?: CustomerProperty[]
  programCoverageBreakdown?: ProgramCoverageBreakdown
  supportedPrograms?: string[]
  customerPropertiesMap?: CustomerPropertiesMap
  healthPlansList?: HealthPlansList[]
  nonIntegratedHealthPlansList?: HealthPlansList[]
  attestations?: unknown
  dependentTypes?: DependentTypes
  internalCustomer?: boolean
  customerCountries: Array<{
    countryId: number
    countryIsoCode: CountryIsoCode
    countryName: string
    customerId: number
    customerName: string
    id: number
  }>
  country?: unknown
  internationalSupport: boolean
  isActive?: boolean
  copies?: CustomerCopies
  defaultCareManager?: {
    createDate: string | null
    updateDate: string | null
    contactPref: string
    initContact: string
    username: string | null
    roles: string[]
    firstname: string
    lastname: string
    phone: string
    gender: boolean
    customers: unknown
    disabled: boolean
    emailVerified: boolean
    registeredBy: string | null
    lyraId: string | null
    tempLyraId: string | null
    migratedToOkta: boolean | null
    informedConsent: boolean
    mfa: boolean
    healthPlan: boolean
    id: string | null
    lyraidStatus: string
    hipaaconsented: boolean
    allowUserResearch: boolean
    termsAndConditions: boolean
    dob: string | null
    timeZone: string
    preferredAppointmentTypes: string[]
  }
}

export type HealthPlanCostSharingType = 'copay' | 'deductible' | null

export interface HealthPlansList {
  eligibility_name: string
  name: string
  enabled?: boolean
  post_deductible_amount?: unknown
  post_deductible_type?: unknown
  pre_deductible_amount?: unknown
  pre_deductible_type?: unknown
  accumulators_source?: unknown
  trading_partner_id?: string
  payment_type?: HealthPlanCostSharingType
  id?: number
  add_supervising_provider?: boolean
  copay?: string
  coverage_plan_description?: unknown
  routes?: unknown
  use_chc?: boolean
  apply_admin_fee?: boolean
  fixed_rates_dict?: unknown
  chc_claims_trading_partner_id?: string
  chc_eligibility_trading_partner_id?: string
  claim_billable_to_customer?: boolean
  cost_sensitivity?: boolean
  taxonomy_code?: string
  procedure_modifier?: string
  apply_fixed_rates?: boolean
  include_rendering_provider?: boolean
  coaching_default_codes?: unknown
  fees: unknown
  subscriber_address_for_minor_dependent?: boolean
  apply_fees: boolean
  dependent_eligibility_with_subscriber: boolean
  upfront_cost_share_collection?: boolean
  waive_deductible_for_teletherapy: boolean
  trading_partner_name: string
  shared_claims_folder: boolean
}

export type HealthPlanPartial = Partial<HealthPlansList>

export interface CustomerPropertiesMap {
  lwMobileEnabled?: string
  mobileBCActivitiesEnabled?: string
  essentialsDisabled?: string
  considerMeds?: string
  stressManagement?: string
  healthPlanDefaultCopay?: string
  healthPlanOpenBhbSessionLimit?: string
  phone?: string
  cancellationLimit?: string
  considerLyraTherapy?: string
  allowUserResearch?: string
  cancellationsIncludedInSessionLimits?: string
  hasLogo?: string
  videoTherapy?: string
  eligibility?: string
  numberOfSessions?: string
  numberOfSessionsByProgram?: string
  displayVisitsPerIssuePerYear?: number
  worklifephone?: string
  useABProviderResults?: string
  justAsHarmfulPages?: string
  enabledChat?: string
  videoTherapyText?: string
  worklife?: string
  directAccessTherapy?: string
  email?: string
  blendedCareMeds?: string
  coachingChargeAmount?: string
  healthPlanExtensionEnabled?: string
  isLyraCodeRequiredAtPayment?: string
  sessionCountStartsOn?: string
  healthPlanSessionLimit?: string
  infractionHandlingEnabled?: string
  guidedSelfCareEnabled?: string
  launchDate?: string
  approveWithNoEligibilityCheck?: string
  liveMessagingEnabled?: string
  isLyraCodeEnabled?: string
  hideDependentField?: string
  isDependentTypesEnabled?: string
  optOutOfEligibilityCheck?: string
  hasBlockingEligibilityCheck?: string
  checkAdultDependentEligibilityUsingDependentInfo?: string
  checkChildDependentEligibilityUsingDependentInfo?: string
  mobileCopyPasteDisabled?: string
  internationalVersionThreeFeatureFlag?: string
  isAutoApprovalEnabled?: string
  isAttestationEnabled?: string
  healthPlanExcludeBCAdminFee?: string
  faqText?: string
  shouldHideChildSearchCTA?: string
  isAppleSSODisabled?: string
  isGoogleSSODisabled?: string
  benefitsPortalLink?: string
  defaultDirectCareNavigatorBookingLink?: string
  shouldSeeCareAdvocateRequestForm?: string
  programMembershipType?: PROGRAM_MEMBERSHIP_TYPES
  icasUsername?: string
  icasPassword?: string
  isICASHubLinkDisabled?: string
  isHealthPlanDirect?: string
  infractionMessagingDisabled?: string
  oktaPasswordPolicy?: string
  passwordResetFailedText?: MessageDescriptor
  employeeRegistrationNomenclature?: string
  dependentRegistrationNomenclature?: string
  hideEthnicityRegistrationQuestion?: string
  isMFARequiredForUsers?: string
  workLifeServiceDependentCareCode?: string
  alcoholUseDisorderTherapy?: string
  isWorkforceHubDisabled?: string
  isWorkforceHubEventsDisabled?: string
  micrositeConfig?: string
  isSecondaryHealthPlanCollectionEnabled?: string
  lyraSocialCareEnabled?: string
  isComplexCareEnabled?: string
  isPhoneNumberRequiredAtRegistration?: string
  lastDayOfCare?: string
}

interface ProgramCoverageBreakdown {
  '2000'?: YearProgramCoverage // mocked year, any number would work
}

export interface YearProgramCoverage {
  [ProgramCustomerPropertyId.blendedCareMeds]?: EAPAndHPIProgramSupport
  [ProgramCustomerPropertyId.considerLyraTherapy]?: EAPAndHPIProgramSupport
  [ProgramCustomerPropertyId.directAccessTherapy]?: EAPAndHPIProgramSupport
  [ProgramCustomerPropertyId.guidedSelfCareEnabled]?: EAPAndHPIProgramSupport
  [ProgramCustomerPropertyId.mystrength_accesscode]?: EAPAndHPIProgramSupport
  [ProgramCustomerPropertyId.considerMeds]?: EAPAndHPIProgramSupport
  [ProgramCustomerPropertyId.stressManagement]?: EAPAndHPIProgramSupport
}

export interface EAPAndHPIProgramSupport {
  eapSupported: boolean
  bhbSupported: boolean
  specialtyBhbSupported: boolean
}

interface CustomerProperty {
  createDate?: string
  updateDate?: unknown
  version?: unknown
  name: string
  value: string
  valueType: string
}

export interface CustomerCountry {
  id: number
  countryId: number
  countryName: string
  countryIsoCode: CountryIsoCode
  customerId: number
  customerName: string
  countryEmergencyPhoneNumbersList?: string[]
}

export enum ContactPref {
  UNASSIGNED = 'Unassigned',
  EMAIL = 'Email',
  PHONE = 'Phone',
  TEXT = 'Text',
}

export interface User {
  createDate?: unknown
  updateDate?: unknown
  contactPref?: string
  initContact?: string
  username?: string
  roles?: Role[]
  firstname: string
  lastname: string
  preferredFirstName?: string | null
  preferredLastName?: string | null
  phone?: string | null
  gender?: Gender
  customers?: Customers
  disabled?: boolean
  emailVerified?: boolean
  registeredBy?: string
  lyraId: string
  tempLyraId?: unknown
  migratedToOkta?: boolean
  informedConsent?: boolean
  mfa?: boolean
  healthPlan?: boolean
  lyraCode?: unknown
  dependentType?: unknown
  eligibilityType?: EligibilityType | null
  oktaSessionToken?: string | null
  extendedProperties?: { employee_id?: string }
  race?: unknown
  raceVersionId?: unknown
  attestationIds?: unknown
  attestationDate?: unknown
  isRespondedToSatisfactionSurvey?: unknown
  friendlyLyraCode?: string | null
  id?: string
  lyraidStatus?: string
  hipaaconsented?: boolean
  allowUserResearch?: boolean
  termsAndConditions?: boolean
  dob?: string
  timeZone: string | null
  preferredAppointmentTypes?: string[]
  customerCountries?: CustomerCountry
  isInternational: boolean
  displayLanguage?: string
  directDedicatedCareNavigatorLink?: string
  salesforceId?: string
  internationalConsent?: INTERNATIONAL_CONSENT_STATUS
  isClientDataAvailable?: boolean
  phoneCountryCode?: CountryIsoCode
  hasIndicatedIsGuardian?: boolean
  isHealthKitEnabled?: boolean
  notifications?: Partial<Record<NotificationItemName, NotificationItemState>>
}

export enum INTERNATIONAL_CONSENT_STATUS {
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  NONE = 'None',
}

interface Customers {
  id: number
  name: string
}

export interface Gender {
  id?: number
  gender: string
}

interface Role {
  id: number
  role: string
}

export interface Capacity {
  AlcoholUseDisorderTherapy?: number
  BlendedCareTherapy?: number
  Coaching?: number
  DirectAccess?: number
  MedicationManagement?: number
  SingleSessionCoaching?: number
}

export interface ProviderExpeditedBookingConfig {
  [key: string]: { enabled?: boolean; grace_period?: number }
}

export interface ProviderUserObject {
  access_token: string
  user: ProviderUser
}
export interface ProviderUser {
  access_type: string
  available: boolean
  capacity_updated_at: string
  capacity_value: Capacity
  email: string
  employment_status?: string
  expedited_booking_config?: ProviderExpeditedBookingConfig
  first_name: string
  grace_period?: number
  guaranteed_time_eligible: boolean
  id: string
  in_person_preference?: boolean
  is_registered: boolean
  last_name: string
  lyratherapy_providers?: ProviderUser[]
  lyra_type: string
  modalities: string[]
  onsite: boolean
  outcomes_agreed: boolean
  outcomes_agreed_at: string
  outcomes_enabled: boolean
  role: string
  roles: string[]
  same_day_booking_enabled?: boolean
  scheduling_enabled: boolean
  text_sessions_enabled?: boolean
  zendesk_jwt: string
  program_taxonomies?: {
    clientele: string
    offering: string
    partner: string
    treatment: string
  }[]
  practice_name?: string
  practice_id?: string
}

export interface BaseCountryItem {
  countryIsoCode: CountryIsoCode
  countryName: string
}

export interface CountryValues extends BaseCountryItem {
  customerCountryId?: number
  customerId?: number
  customerName?: string
  countryId: number
  outOfCountryNumbersList: string[] | null
  tollFreeNumbersList: string[] | null
}

export interface AllCountryValues {
  id: number
  name: string
  isoCode: string
  outOfCountryNumbersList: string[] | null
  tollFreeNumbersList: string[] | null
  emergencyPhoneNumbersList: string[] | null
  internationalConsent?: {
    createDate: Date
    updateDate: Date
    id: number
    version: number
    consentText: string
  }
}

export interface DirectDedicatedCareNavigatorInfoValues {
  clinicalLicenses: string
  firstName: string
  lastInitial: string
  headShotURL: string
  memberBio: string
  name: string
  ScheduleOnceURL: string
}

export type DirectDedicatedCareNavigatorInfoData = DirectDedicatedCareNavigatorInfoValues[]

export type UserNotificationItem = {
  name: NotificationItemName
  state: NotificationItemState
}

export type NotificationItemName = 'updateEmailAddress' | 'collectAutoEnrollmentBackgroundInfo' | 'updatePhoneNumber'
export enum NotificationItemStatus {
  NEW = 'new',
  SEEN = 'seen',
  DISMISSED = 'dismissed',
  COMPLETED = 'completed',
}
export type NotificationItemState = `${NotificationItemStatus}`

export type ChangeEmailConfirmationRequest = {
  token: string
}

export type ChangeEmailConfirmationResponse = {
  type: 'user_change_email' | 'auto_enrollment_change_email'
  token?: string
  errorMessage?: string
}
