import React from 'react'
import { defineMessage } from 'react-intl'

import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { DefaultCustomerRedirectionHeaderText } from './default'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

export const lyrians: GetCustomerCopyFunc = () => {
  const subpopulations: CustomerSubpopulations = {
    header: <DefaultCustomerRedirectionHeaderText customerName='Lyra' />,
    options: [
      {
        label: defineMessage({
          defaultMessage: 'Full-Time Employee',
          description: 'Lyra full-time employee service label',
        }),
        description: defineMessage({
          defaultMessage: 'I am a Full-Time employee or Eligible Dependent of Lyra Health or Lyra Clinical Associates.',
          description: 'Lyra full-time employee service description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'Part-Time Employee',
          description: 'Lyra part-time employee service label',
        }),
        description: defineMessage({
          defaultMessage: 'I am a Part-Time Employee of Lyra Health or Lyra Clinical Associates.',
          description: 'Lyra part-time employee service description',
        }),
      },
    ],
  }
  return {
    shouldDisplayCareTeamContactInfo: false,
    displaySessionLimit: false,
    customerName: 'Lyra',
    legalNotice: <CoverageNotice />,
    subpopulations,
  }
}
