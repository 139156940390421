import React, { FunctionComponent } from 'react'
import Svg, { Ellipse, G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const IntelligentMatchingValuePropSpotIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 96 96' fill='none'>
      <Path
        d='M48.4091 0.402588C41.7826 0.402588 35.3535 1.53893 29.3 3.77936C14.888 9.1215 4.75574 20.9649 1.50038 36.2723C-0.650892 46.2527 -0.375818 55.4839 2.34647 64.4797C5.82189 75.9684 14.353 85.6567 25.7524 91.0595C31.4114 93.7476 37.9126 95.2824 45.0722 95.62C45.9922 95.6618 46.9275 95.6846 47.857 95.6846C53.6014 95.6846 59.3798 94.8973 65.0331 93.3455C65.4675 93.2373 65.8811 93.1254 66.3174 92.9964C71.3977 91.5091 75.9696 89.1567 79.8814 86.0133C84.1289 82.6195 87.6252 78.3169 90.2621 73.2328C90.7819 72.2311 91.2789 71.1764 91.8272 69.9148C94.8435 62.9109 96.3005 55.0703 96.0425 47.2316C95.9211 43.7657 95.4051 40.0816 94.5134 36.2723C93.0451 30.0916 90.651 24.279 87.5892 19.4529C86.7507 18.1572 85.9804 17.0683 85.2178 16.0894C79.7144 8.9906 71.1397 4.00891 60.4289 1.69069C56.4944 0.837015 52.4498 0.404485 48.411 0.404485L48.4091 0.402588Z'
        fill='white'
      />
      <Mask
        id='mask0_284_5163'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='0'
        y='-1'
        width='97'
        height='97'
      >
        <Path
          d='M96.0137 46.9544C95.8765 43.3201 95.3279 39.5659 94.4537 35.8287C93.0308 29.8116 90.6822 23.8801 87.4594 18.7887C86.7051 17.6401 85.9508 16.5259 85.1108 15.463C79.0765 7.663 69.9051 3.343 60.5451 1.303C50.1051 -0.959857 38.9965 -0.291286 28.9679 3.41157C14.4479 8.79443 4.55651 20.5887 1.33365 35.743C0.0993688 41.4859 -0.277774 47.983 0.28794 54.2059H0.236512C0.562226 57.7544 1.19651 61.2001 2.15651 64.3887C5.72223 76.1659 14.3965 85.5259 25.4365 90.7544C31.4194 93.6001 38.0879 95.0059 44.8937 95.3316C51.8365 95.6573 58.8822 94.783 65.3965 92.983C65.8251 92.8801 66.2365 92.7773 66.6479 92.6401C71.7565 91.1487 76.1965 88.8344 79.9851 85.783C84.1337 82.4744 87.5451 78.3087 90.1851 73.2001C90.7679 72.0859 91.2651 70.9887 91.7622 69.8401C94.8822 62.6059 96.2708 54.7373 95.9965 46.9544H96.0137Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_284_5163)'>
        <Ellipse cx='28.0305' cy='28.5' rx='30' ry='30.5' fill='white' />
        <Path
          d='M83.0708 44.2973C82.7622 41.8801 82.008 39.463 81.2708 37.1487C80.4994 34.7315 79.5737 32.3658 78.5451 30.0344C78.168 29.1944 76.9337 28.9887 76.1965 29.4173C75.3222 29.9315 75.2022 30.9087 75.5794 31.7658C76.4537 33.7373 77.208 35.7601 77.8765 37.8001C77.4822 37.0973 76.7794 36.6344 75.9222 36.8744C75.048 37.1144 74.448 38.0915 74.7222 38.983C75.3565 40.9544 76.128 43.1144 75.8194 45.223C75.6308 46.4744 74.928 47.983 73.6765 48.4801C72.9565 48.7715 72.4251 49.3201 72.4251 50.1258C72.4251 50.8115 71.7565 52.8515 72.2194 53.4344C73.5394 55.0287 78.6651 52.6115 80.6022 51.2744C82.5222 49.9373 83.3794 46.6801 83.0708 44.2973Z'
          fill='#99553F'
        />
        <Path
          d='M56.8765 32.7601C56.4651 36.0858 55.3165 37.3887 56.4651 41.8801C58.8479 48.2401 58.0251 49.2001 60.7165 52.2515C62.3622 54.1201 64.6936 54.3258 64.6936 54.3258V59.2972L75.5794 57.8401L74.5508 49.9544C74.5508 49.9544 77.6365 46.8515 78.4594 45.1887C81.1165 39.8401 75.7851 41.2458 75.7851 41.2458C75.7851 41.2458 76.6079 31.0801 71.2594 31.0801C65.9108 31.0801 56.8765 32.7429 56.8765 32.7429V32.7601Z'
          fill='#BF7760'
        />
        <Path
          d='M55.1965 30.2916C53.8251 33.0687 53.8251 35.8458 55.1965 36.823C57.0137 38.1087 60.6994 35.3316 65.5165 35.1601C69.2365 35.023 71.2937 34.8001 72.8023 35.5716C75.5451 36.9601 74.3794 40.9373 76.4365 41.3487C78.4937 41.7601 78.3051 41.4001 78.7165 40.1487C79.128 38.8973 80.9108 34.183 78.0308 29.3144C74.9965 24.2058 58.7794 23.0573 55.1965 30.2916Z'
          fill='#99553F'
        />
        <Path
          d='M79.0593 42.2915C79.0593 42.2915 79.0422 42.1715 79.0251 42.12L79.0936 42.5829C79.0593 42.3257 79.0422 42.0686 78.9908 41.8115C78.9393 41.5715 78.8365 41.3486 78.7336 41.1429C78.7165 41.1086 78.6822 41.0572 78.665 41.0229C78.5965 40.9372 78.4422 40.6972 78.3222 40.5943C78.0479 40.3543 77.7393 40.1315 77.3622 40.0457C77.3622 40.0457 77.345 40.0457 77.3279 40.0457C77.1736 40.0286 77.0193 40.0115 76.865 39.9772C76.8479 39.9772 76.8136 39.9772 76.7965 39.9772C76.6422 39.9772 76.4536 40.0115 76.2993 40.0457C76.2822 40.0457 76.2479 40.0457 76.2308 40.0457C76.0593 40.0972 75.8879 40.1829 75.7165 40.2515C75.6822 40.2686 75.6308 40.3029 75.5965 40.32C75.4079 40.4572 75.2022 40.5943 75.0479 40.7657C74.8936 40.9372 74.7565 41.1257 74.6022 41.3315C74.4822 41.52 74.4136 41.7429 74.3965 41.9657C74.3451 42.1886 74.3622 42.4286 74.4308 42.6515C74.4822 42.8743 74.5851 43.0629 74.7393 43.2343C74.8593 43.4229 75.0136 43.56 75.2193 43.68C75.3565 43.7315 75.4936 43.8 75.6308 43.8515C75.8193 43.9029 76.0079 43.9029 76.1965 43.9029C76.2993 44.0229 76.4022 44.1429 76.5565 44.2286C76.745 44.3486 76.9679 44.4172 77.1908 44.4343C77.4136 44.4857 77.6536 44.4686 77.8765 44.4C78.0136 44.3486 78.1508 44.28 78.2879 44.2286C78.5451 44.0743 78.7508 43.8686 78.905 43.6115C78.9565 43.4743 79.025 43.3372 79.0765 43.2C79.1622 42.8915 79.1622 42.6 79.0765 42.2915H79.0593Z'
          fill='#BF7760'
        />
        <Path
          d='M36.8879 106.543C38.1394 101.537 47.2422 71.8801 55.1279 64.3715C62.9965 56.8629 74.4822 52.903 79.5908 55.5944C88.2994 60.1887 92.8594 75.6344 92.8594 94.8344C92.8594 114.034 36.9051 106.526 36.9051 106.526L36.8879 106.543Z'
          fill='#FFAF9B'
        />
        <Path
          d='M34.4536 50.5029L33.9222 59.2972C33.9222 59.2972 26.2422 61.2687 22.7622 57.7715L23.3622 47.1429L34.4708 50.4858L34.4536 50.5029Z'
          fill='#EBA06F'
        />
        <Path
          d='M20.5337 54.0344C13.2822 56.743 8.44795 63.6687 5.60224 70.8687C4.52224 73.5944 3.78509 76.7315 3.68224 80.2801C3.27081 94.4744 52.8137 106.166 52.8137 106.166C52.8137 106.166 51.9908 73.5944 47.448 64.8172C42.9051 56.0401 38.1908 56.0058 32.4994 55.7144C27.5622 56.1258 22.5737 53.263 20.5337 54.0344Z'
          fill='#5DAD3E'
        />
        <Path
          d='M25.2137 29.7258L42.0309 31.8343C42.0309 31.8343 42.0309 47.9143 39.0137 50.4172C34.4709 54.7372 25.8994 51.12 23.328 47.7258C20.7394 44.3486 23.928 30.9772 25.2137 29.7086V29.7258Z'
          fill='#EBA06F'
        />
        <Path
          d='M36.0994 42.0343C36.0308 41.7086 35.9451 41.4 35.8936 41.0743C35.8936 41.0743 35.8594 40.8 35.8765 40.9372C35.8765 40.8686 35.8765 40.8172 35.8765 40.7486C35.8765 40.6629 35.8765 40.56 35.8765 40.4743C35.8765 40.44 35.8936 40.2 35.8765 40.3372C35.9279 39.8915 35.7908 39.3943 35.3794 39.2743C35.0365 39.1886 34.5736 39.3943 34.5051 39.8743C34.4194 40.5429 34.4708 41.1943 34.6079 41.8458C34.6594 42.12 34.7279 42.3943 34.7965 42.6686C34.8308 42.8058 34.8651 42.9429 34.8822 43.08C34.8822 43.1486 34.8994 43.2172 34.9165 43.2858C34.9165 43.2858 34.9165 43.3543 34.9165 43.3886C34.9165 43.5086 34.9165 43.6115 34.9165 43.7315C34.9165 43.7315 34.9165 43.7829 34.9165 43.8172C34.9165 43.8515 34.8994 43.9372 34.8994 43.9543C34.8994 43.9715 34.8994 43.9886 34.8994 44.0058C34.8994 44.04 34.8651 44.0743 34.8651 44.0915C34.8651 44.0915 34.8136 44.16 34.8136 44.1772C34.8136 44.1772 34.8136 44.1943 34.7965 44.2115C34.7279 44.28 34.6422 44.3315 34.5736 44.3658C34.5736 44.3658 34.5736 44.3658 34.5394 44.3658C34.4708 44.3829 34.4194 44.4 34.3508 44.4172C34.3336 44.4172 34.3336 44.4172 34.3336 44.4172C34.2651 44.4172 34.2136 44.4172 34.1451 44.4172C34.0251 44.4172 33.8879 44.4172 33.7679 44.4C33.3908 44.3658 33.0479 44.8286 33.0651 45.2572C33.0822 45.7543 33.3736 46.08 33.7679 46.1143C34.3336 46.1829 34.9508 46.0972 35.4479 45.72C35.9965 45.3258 36.3051 44.6743 36.3736 43.92C36.4251 43.2858 36.2879 42.6343 36.1508 42.0172L36.0994 42.0343Z'
          fill='#CD7A56'
        />
        <Path
          d='M31.848 39.103C31.848 39.103 31.848 39.0172 31.8309 38.983C31.7794 38.7772 31.6766 38.6058 31.5223 38.4515C31.4709 38.4001 31.4023 38.3487 31.3337 38.2972C31.3337 38.2972 31.3337 38.2972 31.3166 38.2972C31.2652 38.2801 31.1452 38.2287 31.0937 38.2115C31.0937 38.2115 31.0937 38.2115 31.0766 38.2115C31.0423 38.2115 30.8709 38.1772 30.8366 38.1772C30.7852 38.1772 30.768 38.1772 30.6994 38.1772C30.648 38.1772 30.6137 38.1772 30.5623 38.1944C30.2194 38.2801 29.9794 38.5715 29.8766 38.9144C29.8423 39.0001 29.8252 39.103 29.808 39.1887C29.7909 39.3087 29.7737 39.4287 29.7566 39.5315C29.7566 39.6172 29.7566 39.703 29.7737 39.7715C29.808 39.9601 29.8423 40.1487 29.9623 40.303C30.0823 40.4915 30.2366 40.663 30.4423 40.7658C30.5452 40.8344 30.648 40.8687 30.768 40.8687C30.888 40.8858 30.9909 40.8858 31.1109 40.8687C31.2309 40.8344 31.3166 40.783 31.4023 40.7144C31.488 40.6458 31.5737 40.5772 31.6252 40.4744C31.6252 40.4744 31.6252 40.4401 31.6423 40.423C31.6595 40.4058 31.6937 40.3887 31.7109 40.3544C31.7623 40.2515 31.8137 40.1487 31.848 40.0458C31.8652 39.9772 31.8823 39.8915 31.8823 39.8058C31.8823 39.6858 31.9166 39.5658 31.8823 39.4458C31.8823 39.3258 31.848 39.223 31.8309 39.103H31.848Z'
          fill='#CD7A56'
        />
        <Path
          d='M39.2023 40.0801C39.2023 40.0801 39.2023 39.9086 39.168 39.8401C39.168 39.8401 39.168 39.8229 39.168 39.8058C39.1337 39.7372 39.1166 39.6515 39.0652 39.5829C39.0309 39.5144 38.9794 39.4458 38.9109 39.3943C38.8937 39.3772 38.7566 39.2743 38.7394 39.2572C38.6709 39.2058 38.6023 39.1886 38.5166 39.1544C38.4823 39.1544 38.4652 39.1543 38.3966 39.1372C38.3966 39.1372 38.3109 39.1372 38.2766 39.1372C38.2423 39.1372 38.1566 39.1372 38.1394 39.1372C38.1052 39.1372 38.0537 39.1372 38.0194 39.1544C37.9852 39.1544 37.8652 39.2229 37.8994 39.2058C37.848 39.2229 37.8309 39.2401 37.7966 39.2572C37.728 39.2915 37.6594 39.3429 37.608 39.4115C37.5566 39.4629 37.5052 39.5315 37.4709 39.6001C37.4709 39.6001 37.4709 39.6172 37.4537 39.6344C37.4194 39.7029 37.3852 39.7886 37.3509 39.8572C37.3509 39.8915 37.3337 39.9258 37.3337 39.9601C37.3337 39.9943 37.3337 40.0286 37.3337 40.0629C37.3337 40.1658 37.2994 40.2686 37.2994 40.3886C37.2994 40.5086 37.2994 40.6115 37.3166 40.7315C37.3166 40.8515 37.3509 40.9544 37.3852 41.0572C37.4194 41.1772 37.4709 41.2629 37.5394 41.3486C37.608 41.4344 37.6766 41.5201 37.7794 41.5715C37.8823 41.6401 37.9852 41.6743 38.1052 41.6743C38.2252 41.6915 38.328 41.6915 38.448 41.6743C38.5166 41.6401 38.5852 41.6229 38.6537 41.5886C38.688 41.5715 38.7052 41.5372 38.7223 41.5201C38.7909 41.4858 38.8766 41.4515 38.9452 41.4001C38.9966 41.3486 39.0309 41.2801 39.0823 41.2286C39.168 41.0915 39.2023 40.9543 39.2023 40.8001C39.2194 40.6629 39.2366 40.5086 39.2537 40.3715C39.2537 40.3029 39.2709 40.2515 39.2709 40.1829C39.2709 40.1829 39.2709 40.1658 39.2709 40.1486L39.2023 40.0801Z'
          fill='#CD7A56'
        />
        <Path
          d='M40.7451 36.7372C39.8194 36.3087 38.7908 36.0001 37.7108 35.8458C37.2651 35.7772 36.8022 35.9487 36.7851 36.223C36.7679 36.4972 37.0936 36.7715 37.5565 36.8401C37.7622 36.8744 37.9679 36.9087 38.1736 36.9601C38.6536 37.0801 39.1165 37.2172 39.5622 37.3887C39.6308 37.423 39.6994 37.4401 39.7679 37.4744C39.9736 37.5601 40.1794 37.6287 40.4194 37.6115C40.6251 37.6115 40.8479 37.543 40.9679 37.4572C41.2079 37.2687 41.1736 36.9087 40.7622 36.7372H40.7451Z'
          fill='#CD7A56'
        />
        <Path
          d='M33.0308 47.9658C33.0308 47.9658 32.928 47.9315 32.9108 47.9315H32.8765C32.7737 47.8972 32.6708 47.8629 32.5851 47.8287C32.3794 47.7601 32.1908 47.6744 32.0022 47.5887C31.8994 47.5372 31.8137 47.5029 31.728 47.4515C31.6937 47.4515 31.6594 47.4172 31.6594 47.4172C31.6422 47.4172 31.608 47.4001 31.5908 47.3829C31.4022 47.2801 31.2308 47.1601 31.0422 47.0572C30.8708 46.9544 30.7165 46.8344 30.5622 46.7144C30.5622 46.7144 30.528 46.6972 30.5108 46.6801C30.5108 46.6801 30.4594 46.6287 30.4422 46.6287C30.3737 46.5601 30.3051 46.5087 30.2365 46.4401C30.0994 46.3201 29.9794 46.1829 29.8594 46.0458C29.808 45.9772 29.7394 45.9087 29.688 45.8401C29.688 45.8401 29.688 45.8401 29.688 45.8229C29.6537 45.7887 29.6365 45.7372 29.6022 45.7029C29.3965 45.4115 28.9508 45.1715 28.5051 45.2572C28.128 45.3258 27.8194 45.6344 28.0422 45.9429C29.0194 47.3658 30.7337 48.4972 32.808 49.1144C33.2365 49.2515 33.7165 49.0287 33.768 48.7544C33.8194 48.4287 33.5108 48.1544 33.048 48.0172L33.0308 47.9658Z'
          fill='#CD7A56'
        />
        <Path
          d='M31.4537 35.4172C31.3165 35.3486 31.128 35.2801 30.9051 35.2972C30.648 35.3315 30.3908 35.3658 30.1337 35.4172C29.8765 35.4686 29.6194 35.5544 29.3622 35.6401C28.9337 35.7772 28.5222 35.9829 28.1794 36.1887C28.008 36.2915 27.888 36.4115 27.8537 36.5486C27.8194 36.6686 27.8708 36.8229 28.008 36.8915C28.1451 36.9601 28.3337 37.0287 28.5565 37.0115C28.7794 37.0115 29.0194 36.9429 29.1908 36.8229C29.3108 36.7544 29.4308 36.6858 29.568 36.6172C29.7565 36.5315 29.9451 36.4629 30.1508 36.3944C30.3737 36.3429 30.5965 36.2915 30.8365 36.2572C30.768 36.2572 30.7165 36.2572 30.648 36.2744C30.768 36.2744 30.8708 36.2572 30.9737 36.2058C31.0765 36.1715 31.1794 36.1372 31.2822 36.0858C31.4365 36.0001 31.5908 35.8458 31.608 35.7258C31.6251 35.5886 31.608 35.4686 31.4537 35.3829V35.4172Z'
          fill='#CD7A56'
        />
        <Path
          d='M66.768 46.8515C66.648 46.4057 66.2708 46.1315 65.8594 46.2515C65.808 46.2515 65.7737 46.2686 65.7223 46.2857C65.6365 46.2857 65.5508 46.2857 65.4651 46.2857C65.4651 46.2857 65.4651 46.2857 65.4308 46.2857C65.3794 46.2857 65.328 46.2515 65.2937 46.2343C65.2594 46.2343 65.2251 46.2 65.1908 46.1829C65.3451 46.2343 65.1908 46.1829 65.1565 46.1657C65.088 46.1143 65.0023 46.0457 64.9337 46.0115C64.8994 45.9772 64.8651 45.9429 64.8308 45.8915C64.7965 45.8572 64.7623 45.8057 64.728 45.7715C64.6937 45.6686 64.6251 45.5657 64.5737 45.48C64.5737 45.4286 64.5565 45.3943 64.5394 45.3429C64.5394 45.3429 64.5051 45.1372 64.5051 45.1715C64.5051 45.2229 64.5051 45.0172 64.5051 45C64.488 44.6743 64.4708 44.3315 64.4708 44.0057C64.4708 43.3372 64.4708 42.6515 64.5223 41.9829C64.5565 41.5372 64.1623 41.1086 63.7851 41.1257C63.3565 41.1429 63.0823 41.5029 63.048 41.9829C62.9965 42.7543 62.9794 43.5257 63.0137 44.3143C63.0308 45.0857 63.048 45.8743 63.408 46.56C64.008 47.7086 65.208 48.3086 66.3223 47.9829C66.6994 47.88 66.9565 47.3657 66.8365 46.92L66.768 46.8515Z'
          fill='#99553F'
        />
        <Path
          d='M69.1679 40.2172C69.1679 40.1144 69.1508 40.0286 69.1336 39.9258C69.1165 39.8229 69.0651 39.7201 69.0136 39.6344C68.9279 39.4458 68.7393 39.2744 68.5679 39.1715C68.4822 39.1201 68.3793 39.0686 68.2765 39.0515C68.2593 39.0515 68.2422 39.0515 68.2251 39.0515C68.1393 39.0515 68.0365 39.0172 67.9508 39.0172C67.6593 39.0172 67.385 39.1201 67.1793 39.3258C66.9565 39.5315 66.8193 39.8572 66.8536 40.1658C66.8536 40.3029 66.8879 40.4229 66.9222 40.5429C66.9565 40.6115 66.9736 40.6801 67.0079 40.7486C67.025 40.8172 67.0593 40.8686 67.0765 40.9372C67.1279 41.0401 67.1622 41.1772 67.2308 41.2801C67.3508 41.4515 67.4708 41.5886 67.6422 41.6744C67.8822 41.7944 68.1393 41.8458 68.4136 41.7772C68.5851 41.7258 68.7565 41.6401 68.8936 41.5029C68.9965 41.4001 69.0993 41.2286 69.1336 41.0915C69.1679 40.9201 69.1679 40.8172 69.1336 40.6801C69.1336 40.5944 69.1679 40.5086 69.1679 40.4229C69.1679 40.3715 69.1679 40.3029 69.1679 40.2515V40.2172Z'
          fill='#99553F'
        />
        <Path
          d='M60.9566 42.1715C60.9566 42.0172 60.9395 41.88 60.9052 41.7258C60.9052 41.64 60.8709 41.5715 60.8366 41.5029C60.7852 41.3658 60.7337 41.2286 60.648 41.1086C60.5795 40.9715 60.4766 40.8858 60.3395 40.8C60.2366 40.7315 60.1337 40.6972 60.0137 40.6972C59.8937 40.68 59.7909 40.68 59.6709 40.6972C59.5337 40.7315 59.3966 40.8172 59.2937 40.92C59.1052 41.1258 58.9852 41.3315 58.8995 41.6058C58.848 41.7943 58.8137 42 58.848 42.1886C58.848 42.3258 58.8823 42.4629 58.9166 42.5829C58.9509 42.7029 59.0023 42.8229 59.0709 42.9429C59.1395 43.0629 59.2252 43.1658 59.2937 43.2515C59.3795 43.3543 59.4995 43.44 59.6023 43.5086C59.7395 43.5943 59.8766 43.6286 60.0309 43.6286C60.1509 43.6286 60.2537 43.6115 60.3566 43.56C60.4595 43.5258 60.5623 43.4743 60.6309 43.3886C60.7337 43.2858 60.8023 43.1486 60.8537 43.0115C60.9052 42.8572 60.9395 42.6858 60.9395 42.5315C60.9395 42.48 60.9395 42.4458 60.9223 42.3943C60.9223 42.3258 60.9395 42.2572 60.9395 42.1715H60.9566Z'
          fill='#99553F'
        />
        <Path
          d='M70.0941 37.0972C69.0484 36.7886 67.8998 36.6857 66.8027 36.7715C66.357 36.8057 66.0484 37.1315 66.1684 37.4057C66.2884 37.7143 66.717 37.9029 67.197 37.8515C67.2998 37.8515 67.3855 37.8515 67.4884 37.8515C67.8827 37.8515 68.277 37.8686 68.6541 37.92C68.9627 37.9715 69.2713 38.04 69.5627 38.1257C69.9741 38.2457 70.5227 38.0743 70.6427 37.8172C70.7798 37.5086 70.5398 37.2343 70.0941 37.0972Z'
          fill='#99553F'
        />
        <Path
          d='M60.2715 38.1175C59.2069 38.3527 58.1517 38.8176 57.2325 39.4229C56.8589 39.6683 56.7461 40.1025 56.9837 40.2847C57.2379 40.4968 57.7042 40.4548 58.0996 40.1779C58.1897 40.1281 58.2647 40.0867 58.3548 40.037C58.7 39.8465 59.0534 39.671 59.4085 39.5337C59.7035 39.4297 60.0068 39.3406 60.3033 39.2748C60.7215 39.181 61.1189 38.7659 61.0997 38.4827C61.0707 38.1463 60.728 38.0222 60.2715 38.1175Z'
          fill='#99553F'
        />
        <Path
          d='M70.2822 47.8286C69.8194 47.6743 69.4079 47.8629 69.1508 48.2229C69.1508 48.2572 69.0822 48.3086 69.0651 48.3429C69.0137 48.4115 68.9451 48.4801 68.8937 48.5658C68.7737 48.7029 68.6537 48.8229 68.5337 48.9429C68.4994 48.9772 68.4651 49.0115 68.4308 49.0286C68.3622 49.0801 68.2765 49.1486 68.2079 49.2001C68.0537 49.3201 67.8994 49.4229 67.7279 49.5258C67.6594 49.5772 67.5737 49.6115 67.5051 49.6458C67.5051 49.6458 67.4708 49.6458 67.4537 49.6629C67.4194 49.6629 67.4022 49.6972 67.3679 49.6972C67.1965 49.7829 67.0079 49.8515 66.8365 49.9201C66.6651 49.9886 66.4937 50.0229 66.3222 50.0743C66.2879 50.0743 66.2365 50.0915 66.2022 50.0915C66.0994 50.1086 65.9794 50.1258 65.8765 50.1429C65.4308 50.1943 65.0365 50.5886 65.1051 51.0001C65.1737 51.3943 65.5679 51.7201 66.0479 51.6686C68.0022 51.4115 69.6994 50.3486 70.7108 48.8229C70.9508 48.4801 70.6765 47.9486 70.2822 47.8115V47.8286Z'
          fill='#99553F'
        />
        <Path
          d='M37.5394 26.28C35.7052 25.68 33.6994 25.44 31.728 25.5429C31.5566 25.4229 31.3852 25.3029 31.1966 25.2172C29.6709 24.5658 27.8195 24.96 26.328 25.5429C24.9909 26.0743 23.7737 26.8458 22.7795 27.8229C22.4709 28.0972 22.1966 28.4058 21.9223 28.7315C21.9223 28.7315 21.9223 28.7486 21.9052 28.7658C21.4766 29.2972 21.1166 29.88 20.8252 30.4972C20.7395 30.5829 20.6709 30.6858 20.6195 30.8058C20.5166 31.0629 20.448 31.3029 20.3623 31.56C20.2766 31.8 20.1909 32.0229 20.1052 32.2629C19.1795 35.2458 18.4937 39.12 19.4709 42.0686C19.9166 43.44 21.0309 47.76 22.488 48.2915C24.888 49.3029 23.928 43.8686 24.0823 41.88C24.1509 41.0743 24.6823 40.3543 24.7852 39.5658C24.888 38.7429 24.9395 37.9029 24.9909 37.08C25.008 36.72 25.0252 36.3772 25.0595 36.0172C25.0595 35.9829 25.0595 35.9829 25.0595 35.9486C25.0595 35.8458 25.0766 35.76 25.0937 35.6572C25.1109 35.5029 25.128 35.3315 25.1623 35.1772C25.2137 34.8 25.2823 34.44 25.3852 34.08C25.4195 33.9258 25.4709 33.7715 25.5223 33.6172C25.5223 33.5829 25.5223 33.5829 25.5395 33.5658C28.4194 34.3715 31.4194 34.7829 34.4194 34.7486C35.9109 34.7315 37.4194 34.6115 38.8937 34.3543C40.2994 34.1143 41.7566 33.5829 42.4594 32.28C44.1566 29.1429 40.008 27.12 37.5223 26.2972L37.5394 26.28Z'
          fill='#99553F'
        />
        <Path
          d='M26.6536 40.7658C26.4822 39.943 25.9851 39.1201 25.2136 38.743C24.7679 38.5201 24.2879 38.4173 23.7908 38.4858C23.3279 38.5544 22.9508 38.743 22.5736 39.0344C22.1451 39.3601 21.8022 39.6858 21.5965 40.2001C21.4422 40.5944 21.4251 41.023 21.4765 41.4344C21.5794 42.2573 21.9908 42.9944 22.5222 43.6116C23.0194 44.1773 23.7394 44.6401 24.4765 44.8116C25.0251 44.9316 25.6422 44.9487 26.0194 44.4516C26.3108 44.0744 26.3108 43.5601 26.3451 43.1144C26.3622 42.6516 26.3279 42.1887 26.2422 41.7258C26.5679 41.5201 26.7222 41.1773 26.6365 40.783L26.6536 40.7658Z'
          fill='#EBA06F'
        />
      </G>
    </Svg>
  )
}
