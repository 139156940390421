/* eslint-disable formatjs/no-literal-string-in-jsx */
import React from 'react'

import { GetCustomerCopyFunc, TREATMENT_OPTIONS, TREATMENT_RECOMMENDATION_TIER } from '@lyrahealth-inc/shared-app-logic'

import { BodyText } from '../atoms'

export const uchealthorg: GetCustomerCopyFunc = () => {
  const uchealthorgIndividualTherapyConfiguration = {
    title: 'UCHealth Behavioral Health Access',
    description: (
      <BodyText
        text={
          'Connect with UCHealth providers to access individual or group therapy, medication management, substance use disorder treatment, inpatient behavioral health services, detox, or residential care. Behavioral health clinicians are available to help individuals with behavioral health conditions, mental health symptoms, and substance use disorders. Whether youʼre dealing with the stress of daily living or facing a crisis, you can find compassionate care at a UCHealth Behavioral Health (BH) facility.'
        }
      />
    ),
    buttonText: 'Access UCHealth BH',
    url: 'https://www.uchealth.org/services/behavioral-health',
  }

  return {
    treatmentOptions: [
      {
        option: TREATMENT_OPTIONS.ONSITE_THERAPY,
        config: { customCopy: uchealthorgIndividualTherapyConfiguration },
        optionRanking: { [TREATMENT_RECOMMENDATION_TIER.TIER_2]: 2, [TREATMENT_RECOMMENDATION_TIER.TIER_3]: 1 },
      },
    ],
  }
}
