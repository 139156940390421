import React, { Suspense, useMemo } from 'react'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import { ActionSheetProvider, connectActionSheet } from '@expo/react-native-action-sheet'
import { FocusVisibleManager } from 'use-focus-visible'

import { useShareSessionStorageAcrossTabs } from '@lyrahealth-inc/lyraweb-mobile/src/hooks/useShareSessionStorageAcrossTabs'
import {
  LAUNCHDARKLY_WEB_CLIENT_ID,
  LD_SHARED_CONTEXT_KEYS,
  shouldDisableCostlyVendors,
  withLDProvider,
} from '@lyrahealth-inc/shared-app-logic'
import { RootLayout } from '@lyrahealth-inc/ui-core'
import { LoadingIndicator, ThemeProvider, TooltipProvider } from '@lyrahealth-inc/ui-core-crossplatform'

import ErrorBoundaryWrapper from './common/components/error/ErrorBoundaryWrapper/ErrorBoundaryWrapper'
import { matchPaths } from './common/utils/stringUtils'
import AppContainer from './features/appContainer/AppContainer'
import ApplicationContext from './features/applicationContext/ApplicationContext'
import { __setGlobalRouter } from './features/routing/legacyRouterShim'
import { createRouter } from './features/routing/rootRoutes'

import type { Store } from 'redux'

const scrollTopExlusionPaths = ['/secure/onboard/about/:id']

const App: React.FC<{ store: Store }> = ({ store }) => {
  const router = useMemo(() => {
    const router_ = createRouter()
    // The following lines adapts legacy S4C code, which heavily relied on
    // routing state in the store with react-router-redux (no longer exists)
    __setGlobalRouter(router_)
    // Note: subscribe() is marked as internal/private to react-router, but it is safe to use
    router_.subscribe(({ location }) => {
      if (!matchPaths(location.pathname, scrollTopExlusionPaths)) {
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
    })
    return router_
  }, [])

  useShareSessionStorageAcrossTabs()

  return (
    <GestureHandlerRootView>
      <Provider store={store}>
        <TooltipProvider>
          <ApplicationContext>
            <SafeAreaProvider>
              <FocusVisibleManager>
                <ThemeProvider>
                  <ActionSheetProvider>
                    <AppContainer>
                      <ErrorBoundaryWrapper>
                        <RootLayout>
                          <Suspense fallback={<LoadingIndicator />}>
                            <RouterProvider router={router} />
                          </Suspense>
                        </RootLayout>
                      </ErrorBoundaryWrapper>
                    </AppContainer>
                  </ActionSheetProvider>
                </ThemeProvider>
              </FocusVisibleManager>
            </SafeAreaProvider>
          </ApplicationContext>
        </TooltipProvider>
      </Provider>
    </GestureHandlerRootView>
  )
}

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_WEB_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  context: {
    kind: 'user',
    key: LD_SHARED_CONTEXT_KEYS.LAUNCH_DARKLY_USER_UUID,
  },
  options: {
    evaluationReasons: true,
    sendEvents: !shouldDisableCostlyVendors,
  },
  // @ts-expect-error TS(2345): Argument of type 'FunctionComponent<{ store: Store... Remove this comment to see the full error message
})(connectActionSheet(App))
